<template>
  <div>
    <body>
      <div class="limiter">
        <div class="container-login100">
          <div class="wrap-login100">
            <div class="login100-form-title" :style="cssProps">
              <span class="login100-form-title-1">Conecta-STV</span>
            </div>

            <p class="login-box-msg mt-3">
              <b> Complemento Cadastral </b> <br />
              Você está a apenas um passo do seu Perfil.
            </p>

            <div class="card-body">
              <div class="form-group row mt-4">
                <div class="col-sm-12">
                  <label id="label-decoration" class="mb-0"
                    >Selecione sua Unidade</label
                  >

                  <v-select
                    placeholder="--Selecione sua Unidade--"
                    v-model="selectedUnidade"
                    :options="optionUnidades"
                    required
                    label="nome_unidade"
                  >
                  </v-select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <label id="label-decoration" class="mb-0"
                    >Selecione sua Equipe</label
                  >

                  <v-select
                    placeholder="--Selecione sua Equipe--"
                    v-model="selectedEquipe"
                    :options="optionEquipes"
                    required
                    label="nome_equipe"
                  >
                  </v-select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <label id="label-decoration" class="mb-0"
                    >Qual sua função?</label
                  >
                  <div class="input-group mb-0">
                    <b-form-input
                      v-model="funcao"
                      placeholder="Função exercida...."
                    ></b-form-input>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-id-card"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <b-button
                  @click="onSubmit"
                  pill
                  variant="primary"
                  :disabled="statusButton"
                  class="float-right"
                  ><i class="fas fa-save"></i> Salvar</b-button
                >
                <b-button type="reset" @click="logout" pill variant="secondary"
                  ><i class="fas fa-times-circle"></i> Cancelar</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "PrimeiroAcesso",

  data() {
    return {  
      funcao: "",

      selectedUnidade: "",
      optionUnidades: [],

      selectedEquipe: "",
      optionEquipes:[],

      cssProps: {
        backgroundImage: `url(${require("./Components/img/teste1.jpg")})`,
      }, 
    };
  },

  computed: {
    statusButton() {
      return this.funcao.length > 4 && 
      this.selectedUnidade != "" && this.selectedUnidade != null &&
      this.selectedEquipe != "" && this.selectedEquipe != null ? false : true
    }
  },

  mounted() {
    this.dadosAtualizacaoCadastral();
  },

  methods: {

    async logout() {     
      try {
          //Requisição de Logout
          const response = await axios.post('auth/logout')

          //Limpar localStorage
          localStorage.clear();

          delete axios.defaults.headers.common["Authorization"];

          //Redirecionamento para Dashboard
          window.location.href="/";

      } catch (error) {
          alert('Erro no logout');
      }
    },

    async onSubmit() {
      //console.log(this.selectedUnidade.pk_id_unidade)
     // console.log(this.selectedEquipe.pk_id_equipe)

      const perfil = JSON.parse(localStorage.getItem("storageData"));

      try {
        const response = await axios.post("atualizacaoCadastral/addComplementoCadastral", {
          idUnidade: this.selectedUnidade.pk_id_unidade,
          idEquipe: this.selectedEquipe.pk_id_equipe,
          funcao: this.funcao,
          idUsuario: perfil.pk_id_usuario
        });

        localStorage.setItem( 'storageData', JSON.stringify(response.data));

        alert("Complemento cadastral efetuado com sucesso!!")

        //Redirecionamento para Dashboard
        window.location.href="perfil";

      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
      
    },

    async dadosAtualizacaoCadastral() {
      try {
        const response = await axios.get("atualizacaoCadastral/dadosComplementoCadastral");

        this.optionEquipes = response.data.equipes;
        this.optionUnidades = response.data.unidades;

      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>

<style scoped src="./Components/css/style.css">
</style>