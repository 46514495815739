var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('aside',{staticClass:"main-sidebar sidebar-dark-primary elevation-4"},[_c('router-link',{staticClass:"brand-link",attrs:{"to":"/perfil"}},[_c('img',{staticClass:"brand-image img-circle elevation-3",staticStyle:{"opacity":"0.8"},attrs:{"src":require("../../../assets/adminLTE/dist/img/logo3.png"),"alt":"AdminLTE Logo"}}),_c('span',{staticClass:"brand-text font-weight-light"},[_vm._v("ConectaSTV")])]),_c('div',{staticClass:"sidebar"},[_c('nav',{staticClass:"mt-2"},[_c('ul',{staticClass:"\n            nav nav-pills nav-sidebar\n            flex-column\n            nav-child-indent nav-flat\n          ",attrs:{"data-widget":"treeview","role":"menu","data-accordion":"false"}},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/home"}},[_c('i',{staticClass:"far fa-list-alt nav-icon"}),_c('p',[_vm._v("Home")])])],1),(
              _vm.user['nivel_acesso'] == 'Administrador' || 
              _vm.user['nivel_acesso'] == 'Colaborador' || 
              _vm.user['nivel_acesso'] == 'CIPA'
            )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/feed"}},[_c('i',{staticClass:"fas fa-rss nav-icon"}),_c('p',[_vm._v("Adicionar Feeds")])])],1):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/perfil"}},[_c('i',{staticClass:"fas fa-address-card nav-icon"}),_c('p',[_vm._v("Perfil")])])],1),_c('li',{staticClass:"nav-item has-treeview"},[_vm._m(0),_c('ul',{staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/novo-chamado"}},[_c('i',{staticClass:"fas fa-headset nav-icon"}),_c('p',[_vm._v("Nova Solicitação")])])],1),(_vm.visualizaSolicitacao == true)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/ticket-recebido"}},[_c('i',{staticClass:"fas fa-file-download nav-icon"}),_c('p',[_vm._v("Solicitação Recebidas")])])],1):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/historico-chamado"}},[_c('i',{staticClass:"fas fa-history nav-icon"}),_c('p',[_vm._v("Histórico Chamados")])])],1)])]),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/unidades"}},[_c('i',{staticClass:"fas fa-university nav-icon"}),_c('p',[_vm._v("Unidades")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/equipes"}},[_c('i',{staticClass:"fas fa-users nav-icon"}),_c('p',[_vm._v("Equipe")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/instrucao-trabalho-lista"}},[_c('i',{staticClass:"fas fa-file-invoice nav-icon"}),_c('p',[_vm._v("Instrução de Trabalho")])])],1),(
              _vm.user['extrato_email_nfe'] == '1'
            )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/relatorio-email"}},[_c('i',{staticClass:"fas fa-envelope nav-icon"}),_c('p',[_vm._v("Extrato e-mail NFe's")])])],1):_vm._e(),_c('li',{staticClass:"nav-item has-treeview"},[_vm._m(1),_c('ul',{staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/agenda"}},[_c('i',{staticClass:"fas fa-tasks nav-icon"}),_c('p',[_vm._v("Minha Agenda")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/agenda-equipe"}},[_c('i',{staticClass:"fas fa-tasks nav-icon"}),_c('p',[_vm._v("Agenda Equipe")])])],1),(_vm.user.nivel_atendimento == 'Agenda Médica' ||
                        _vm.user.matricula == '8489')?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/agenda-medica"}},[_c('i',{staticClass:"fas fa-tasks nav-icon"}),_c('p',[_vm._v("Agenda Medica")])])],1):_vm._e()])]),(
              _vm.user['funcao'] == 'Portaria' || 
              _vm.user['funcao'] == 'Dev' || 
              _vm.user['funcao'] == 'VIGILANTE'
            )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/controle-acesso"}},[_c('i',{staticClass:"fas fa-exchange-alt nav-icon"}),_c('p',[_vm._v("Controle de Acesso")])])],1):_vm._e(),_c('li',{staticClass:"nav-header"},[_vm._v("Links")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_c('i',{staticClass:"nav-icon fas fa-people-arrows nav-icon"}),_c('p',[_vm._v(" Solicitação/Chamado "),_c('i',{staticClass:"right fas fa-angle-left"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_c('i',{staticClass:"nav-icon fas fa-calendar-alt nav-icon"}),_c('p',[_vm._v(" Agendas "),_c('i',{staticClass:"right fas fa-angle-left"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"http://webmail.stv.com.br","target":"_blank"}},[_c('i',{staticClass:"fas fa-envelope nav-icon"}),_c('p',[_vm._v("WebMail")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"http://biblioteca.stv.com.br","target":"_blank"}},[_c('i',{staticClass:"fas fa-book nav-icon"}),_c('p',[_vm._v("Biblioteca")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"http://ava.stv.com.br/","target":"_blank"}},[_c('i',{staticClass:"fas fa-graduation-cap nav-icon"}),_c('p',[_vm._v("Educação Corporativa")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"http://portaldocolaborador.stv.com.br/index.php/logon/login","target":"_blank"}},[_c('i',{staticClass:"fas fa-user-circle nav-icon"}),_c('p',[_vm._v("Portal do Colaborador")])])])}]

export { render, staticRenderFns }