<template>
  <div>
    <b-overlay :show="overlayShow" rounded="sm">
      
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Instrução de Trabalho</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/perfil">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">It</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section v-if="showsection" class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-success card-outline">
                  <div class="card-header">
                    <div class="card-title">
                      <b> Título: </b> {{ iT.titulo }}
                    </div>

                    <div
                      v-if="iT.fk_id_usuario_instrucao_trabalho == idUser"
                      class="float-right ml-2"
                    >
                      <a
                        class="btn btn-sm btn-outline-secondary"
                        v-b-modal.versaoIt
                      >
                        <i class="fas fa-code-branch"></i> Versões
                      </a>
                    </div>

                    <div class="float-right">
                      <div class="card-title">
                        <i style="color: #777672">{{ iT.tipo }} </i>
                      </div>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12">
                        <span v-html="iT.conteudo"></span>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="iT.fk_id_usuario_instrucao_trabalho == idUser"
                    class="card-footer"
                  >
                    <div class="float-right">
                      <router-link
                        :to="
                          '/instrucao-trabalho-editar?id=' +
                          iT.pk_id_instrucao_trabalho
                        "
                      >
                        <a class="btn btn-primary mr-2">
                          <i class="fas fa-pencil-alt"></i> Editar
                        </a>
                      </router-link>

                      <a class="btn btn-danger" @click="excluirIt">
                        <i class="fas fa-trash-alt"></i> Excluir
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <b-modal v-if="itVersoes['0']" id="versaoIt" title="VERSÕES" ok-only>
          <b-table striped hover :items="itVersoes" :fields="fields">
            <template #cell(titulo_versao)="row">
              <router-link
                :to="
                  '/instrucao-trabalho-versao?id=' +
                  row.item.pk_id_instrucao_trabalho_versao
                "
              >
                <div>
                  <div>
                    <span class="text-muted small"
                      ><i class="fas fa-quote-right"></i
                    ></span>
                    {{ row.item.titulo_versao }}
                  </div>
                </div>
              </router-link>
            </template>

            <template #cell(tipo_versao)="row">
              <div class="text-muted">
                <div class="small">
                  <span><i class="fas fa-file-alt"></i></span>
                  {{ row.item.tipo_versao }}
                </div>
              </div>
            </template>

            <template #cell(data_hora_versao)="row">
              <div class="text-muted">
                <div class="small">
                  <span><i class="fas fa-clock"></i></span>
                  {{ row.item.data_hora_versao | moment }}
                </div>
              </div>
            </template>
          </b-table>
        </b-modal>
      </div>
    </b-overlay>
  </div>
</template>

<script>
const axios = require("axios").default;
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "InstrucaoTrabalho",

  data() {
    return {
      showsection: false,
      overlayShow: true,

      iT: [],
      idUser: "",

      fields: [
        {
          key: "titulo_versao",
          label: "Título",
          sortable: true,
        },
        {
          key: "tipo_versao",
          label: "Tipo",
          sortable: true,
        },
        {
          key: "data_hora_versao",
          label: "Data",
          sortable: true,
        },
      ],
      itVersoes: [],
    };
  },

  mounted() {
    const perfil = JSON.parse(localStorage.getItem("storageData"));
    (this.idUser = perfil.pk_id_usuario), this.visualizarInstrucaoTrabalho();
  },

  methods: {
    async visualizarInstrucaoTrabalho() {
      try {
        const response = await axios.post("instrucaoTrabalho/instrucaoTrabalhoVisualizar", {
          idIT: this.$route.query.id,
        });

        this.iT = response.data.it;

        //console.log(this.iT);

        this.itVersoes = response.data.itVersao.original;
        this.showsection = true;
        this.overlayShow = false;
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async excluirIt() {
      try {
        const response = await axios.post("instrucaoTrabalho/excluirTrabalhoVisualizar", {
          idItExcluir: this.iT.pk_id_instrucao_trabalho,
        });

        alert("Sua It foi excluida com sucesso!!!");

        this.$router.push("instrucao-trabalho-lista");
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("lll");
      // return moment(date).format('LT - L');
    },
  },
};
</script>

<style scoped>
</style>