<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Ativa/Desativa Usuario</h1>
          </div>

          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Ativa/Desativa Usuario</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          
          <div class="col-md-12">
            <div class="card card-primary card-outline">

              <h1>AtivaDesativaUsuario</h1>
              
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script scoped>

export default {
  name: "AtivaDesativaUsuario",
};
</script>

<style>
</style>