<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Ativar-Desativar Equipe</h1>
          </div>

          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Ativar-Desativar Equipe</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="login-logo">
                <a><b>Equipes </b>Cadastradas</a>
              </div>
              <div class="card-body login-card-body">
                <b-container fluid>
                  <b-row>
                    <b-input-group class="mb-2">
                      <b-input-group-append>
                        <b-form-input
                          size="sm"
                          id="filter-input"
                          v-model="configTableEquipe.filter"
                          type="search"
                          placeholder="Pesquisar..."
                        ></b-form-input>
                      </b-input-group-append>
                    </b-input-group>

                    <b-table
                      :busy="isBusy"
                      striped
                      outlined
                      hover
                      bordered
                      :borderless="false"
                      :noCollapse="false"
                      :dark="false"
                      :fixed="false"
                      :small="true"
                      :footClone="true"
                      head-variant="dark"
                      :items="itemsEquipe"
                      :fields="fieldsEquipe"
                      :current-page="configTableEquipe.currentPage"
                      :per-page="configTableEquipe.perPage"
                      :filter="configTableEquipe.filter"
                      :filter-included-fields="configTableEquipe.filterOn"
                      :sort-by.sync="configTableEquipe.sortBy"
                      :sort-desc.sync="configTableEquipe.sortDesc"
                      :sort-direction="configTableEquipe.sortDirection"
                      stacked="md"
                      show-empty
                    >
                      <template #empty>
                        <h5 class="text-center">
                          {{ tableEquipe.emptyTexts }}
                        </h5>
                      </template>

                      <template #emptyfiltered>
                        <h5 class="text-center">
                          {{ tableEquipe.emptyFilteredTexts }}
                        </h5>
                      </template>

                      <template #table-busy>
                        <div class="text-center text-danger my-3">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong> Carregando...</strong>
                        </div>
                      </template>

                      <template #cell(nome_equipe)="row">
                        <div>
                          <span class="small"
                            ><i class="fas fa-users text-muted"></i
                          ></span>
                          {{ row.item.nome_equipe }}
                        </div>
                      </template>

                      <template #cell(nome)="row">
                        <div v-if="row.item.nome">
                          <span class="small"
                            ><i class="fas fa-user-tie text-muted"></i
                          ></span>
                          {{ row.item.nome }}
                        </div>

                        <div v-else>
                          <span class="small"
                            ><i class="fas fa-user-slash text-muted"></i
                          ></span>
                          Sem Lider
                        </div>
                      </template>

                      <template #cell(actions)="row">
                        <div class="text-center">
                          <b-button-group v-if="row.item.visualiza_solicitacao == 'Sim'">

                            <b-button
                              :disabled="true"
                              variant="success"              
                              @click="ativarEquipe(row.item.pk_id_equipe)"
                              >Ativar <i class="fas fa-toggle-on"></i
                            ></b-button>

                            <b-button
                              variant="danger"       
                              @click="desativarEquipe(row.item.pk_id_equipe)"
                              >Desativar <i class="fas fa-toggle-off"></i
                            ></b-button>

                          </b-button-group>

                          <b-button-group v-else>

                            <b-button
                              variant="success"              
                              @click="ativarEquipe(row.item.pk_id_equipe)"
                              >Ativar <i class="fas fa-toggle-on"></i
                            ></b-button>

                            <b-button
                              :disabled="true"
                              variant="danger"       
                              @click="desativarEquipe(row.item.pk_id_equipe)"
                              >Desativar <i class="fas fa-toggle-off"></i
                            ></b-button>

                          </b-button-group>
                        </div>
                      </template>
                    </b-table>

                    <b-tfoot>
                      <div class="text-muted">
                        <div class="small">
                          <span><i class="fas fa-list-ol"></i></span> Total de
                          registros: <b>{{ itemsEquipe.length }}</b>
                        </div>
                      </div>
                    </b-tfoot>

                    <b-col sm="5" md="0" class="my-1">
                      <b-form-group
                        label="Quantidade"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="8"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="configTableEquipe.perPage"
                          :options="configTableEquipe.pageOptions"
                          size="sm"
                        >
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col sm="5" md="4" class="my-1">
                      <b-pagination
                        v-model="configTableEquipe.currentPage"
                        :total-rows="visitanteRows"
                        :per-page="configTableEquipe.perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </b-container>
              </div>

              <!-- /.card-body -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "AtivaDesativaEquipe",

  data() {
    return {
      isBusy: true,

      /////////////////////////<====TABELA DE EQUIPES====>///////////////////////////////////////////
      itemsEquipe: [],

      tableEquipe: {
        emptyTexts: "Não há Equipes cadastrado!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsEquipe: [
        {
          key: "nome_equipe",
          label: "Nome Equipe",
          sortable: true,
        },

        {
          key: "nome",
          label: "Líder Equipe",
          sortable: true,
        },

        {
          key: "actions",
          label: "Ativar/Desativar",
        },
      ],

      configTableEquipe: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
    };
  },

  computed: {
    visitanteRows() {
      return this.itemsEquipe.length;
    },
  },

  mounted() {
    this.tabelaEquipes();
  },

  methods: {

    async tabelaEquipes() {
      this.isBusy = true;
      try {
        const response = await axios.get("equipe/todasEquipes");

        this.itemsEquipe = response.data;

        console.log(response.data);

        this.isBusy = false;
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async ativarEquipe(idExcluir) {
      try {
        const response = await axios.post("equipe/equipeAtiva", {
          idEquipe: idExcluir,
        });

       // alert(response.data);

        this.refreshTabelaEquipes();
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async desativarEquipe(idExcluir) {
      try {
        const response = await axios.post("equipe/equipeDesativa", {
          idEquipe: idExcluir,
        });

        //alert(response.data);

        this.refreshTabelaEquipes();
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async refreshTabelaEquipes() {
      try {
        const response = await axios.get("equipe/todasEquipes");

        this.itemsEquipe = response.data;

        console.log(response.data);

      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>

<style>
</style>