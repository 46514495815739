<template>
  <div>
    <b-overlay :show="overlayShow" rounded="sm">
      
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Equipes</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Equipes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section v-if="showsection" class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary card-outline">
                  <div class="card-body">
                    <div class="row">
                      <div
                        v-for="equipe in dadosEquipes"
                        :key="equipe.pk_id_equipe"
                        class="col-md-3"
                      >
                      <router-link
                          :to="
                            '/membros-equipe?id=' + equipe.pk_id_equipe
                          "
                        >
                          <div class="box">
                        <div class="card mb-4 box-shadow">
                          <img
                            class="card-img-top"
                            src="../../../assets/img/geral/equipe.jpg"
                            alt="imagem equipe"
                          />
                          <div class="mb-3">
                            <br />
                            <h4
                              class="lead"
                              style="text-align: center; color: #545454"
                            >
                              <b
                                ><strong>{{ equipe.nome_equipe }}</strong></b
                              >
                            </h4>
                          </div>
                        </div>

                         </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-overlay>
  </div>
</template>

<script scoped>
const axios = require("axios").default;

export default {
  name: "Equipes",

  data() {
    return {
      dadosEquipes: [],
      showsection: false,
      overlayShow: true,
    };
  },

  mounted() {
    this.todasEquipes();
  },

  methods: {
    async todasEquipes() {
      try {
        //Requisição atualizar dados
        const response = await axios.get("equipe/todasEquipes");

        this.dadosEquipes = response.data;

        this.showsection = true;
        this.overlayShow = false;

        //console.log(this.dadosEquipes);
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.centralizarItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.box:hover {
  transition: 0.1s;
}

.box:hover {
  transform: scale(1.0);
  background: #444444;
  z-index: 4;
  box-shadow: 4px 4px 4px #444444;

}

a:link {
  text-decoration: none;
}
</style>