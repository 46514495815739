<template>
<div>
    <div class="container-fluid">
        <div class="row no-gutter">
            <!-- The image half -->
            <div class="col-md-6 d-none d-md-flex bg-image"></div>


            <!-- The content half -->
            <div class="col-md-6 bg-light">
                <div class="login d-flex align-items-center py-5">

                    <!-- Demo content-->
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 col-xl-7 mx-auto">
                                <h3 class="display-4">Conecta-STV</h3>
                                <p class="text-muted mb-4">Faça login para iniciar sua sessão.</p>
                                <form @submit.prevent="handleSubmit">

                                    <ErrorComponents v-if="error" :errorComponents="error"/> 

                                    <div class="form-group mb-3">
                                        <input name="cpf" id="cpf" type="text" placeholder="Usuário cpf" v-model.trim="cpf" v-mask="'###.###.###-##'" required autofocus class="form-control rounded-pill border-1 shadow px-4">
                                    </div>
                                    <div class="form-group mb-5">
                                        <input name="password" id="password" type="password" placeholder="Senha" v-model.trim="senha" required class="form-control rounded-pill border-1 shadow px-4 text-primary">
                                    </div>
                                    <!-- <div class="custom-control custom-checkbox mb-3">
                                        <input id="customCheck1" type="checkbox" checked class="custom-control-input">
                                        <label for="customCheck1" class="custom-control-label">Lembrar-me</label>
                                    </div> -->
                                    <button type="submit" class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm">Entrar</button>
                                    <div class="text-center d-flex justify-content-between mt-4"><p>Esqueceu sua senha? <a href="recuperar-senha" class="font-italic text-muted"> <u>Clique aqui</u></a></p></div>
                                    <p class="login-card-footer-text">Primeiro acesso? <a href="#" class="font-italic text-muted" v-b-modal.modal-1> <u>Clique aqui</u></a></p>
                                </form>
                            </div>
                        </div>
                    </div><!-- End -->

                </div>
            </div><!-- End -->

        </div>  
    </div>

    <!-- Modal -->
    <b-modal id="modal-1" title="PRIMEIRO ACESSO!" ok-only>
        <p>
            O primeiro acesso é feito com a seu "Usuario: CPF" e seu "CPF" como senha, conforme exemplo a baixo:
        </p>

        <p>
            <b>Usuário:</b> <i style="color: red"> CPF </i>
            <br>
            <b>Senha:</b> <i style="color: red"> CPF </i>
        </p>

        <p>
            Após o primeiro acesso, a senha poderá ser alterada ao completar seu cadastro em Perfil.
        </p>             
    </b-modal>

</div>
</template>

<script>
const axios = require('axios').default
import ErrorComponents from './Components/ErrorComponents.vue'
import LoginLayoutComponents from './ComponentsLayout/LoginLayoutComponents.vue'

export default {
    name: 'FormLogin',

    data() {
        return {
            cpf: '',
            senha: '',
            error: '',
        }
    },

    methods: {
        async handleSubmit(){
            try {
                //Requisição de login
                const response = await axios.post('auth/login',{
                    cpf: this.cpf,
                    senha: this.senha,
                });

                //Salvando token e dados na localStorage
                localStorage.setItem( 'storageData', JSON.stringify(response.data.perfil.original));
                localStorage.setItem( 'equipeUser', JSON.stringify(response.data.equipe.original));
                localStorage.setItem( 'token', response.data.token.original.access_token);

                //Redirecionamento para Dashboard
                window.location.href="home";

            } catch (error) {
                //console.log(error.response.status)

                const erroHttp = error.response.status;
                switch (erroHttp) {
                case 401:
                    this.error = ('Usuario ou senha incorreta - [Erro] '+error.response.status);
                    break;
                case 404:
                    this.error = ('Pagina não encontrada na Api - [Erro] '+error.response.status);
                    break;
                case 403:
                    this.error = ('Usuario não encontrado na base de dados - [Erro] '+error.response.status);
                    break;
                }

            } 
              
        },
    },
    
    components: {
        LoginLayoutComponents,
        ErrorComponents,
    }
}
</script>

<style>


</style>