<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>
              <b>Equipe:</b> <i>{{ equipe }}</i>
            </h1>

            <div class="mt-2" v-if="gestorEquipe.nome">
              <div>
                <div class="card-title">
                  <b>Responsável:</b> {{ gestorEquipe.nome }}
                  <ul class="ml-4 mb-0 fa-ul text-muted">
                    <li class="small">
                      <span class="fa-li"><i class="fas fa-envelope"></i></span>
                      {{ gestorEquipe.email }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="mt-2" v-else>
              <div>
                <div class="card-title">
                  <b>Responsável: </b>
                  <i>Equipe sem Responsável selecionado</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Equipe</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <b-tabs
      active-nav-item-class="font-weight-bold text-uppercase text-info"
      content-class="mt-3"
      justified
    >
      <b-tab title="Membros Equipe" active>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary card-outline">
                  <div class="mt-2">
                    <b-button
                      v-if="idUsuario == gestorEquipe.fk_id_usuario_equipe || nivelAcesso == 'Administrador'"
                      class="btn-sm float-right mr-4"
                      variant="outline-dark"
                      v-b-modal.modal-adicionar-membro
                      ><i class="fas fa-user-plus"></i> Adicionar
                      Membro</b-button
                    >

                    <b-modal
                      id="modal-adicionar-membro"
                      ref="modal"
                      hide-footer
                      title="Adicionar membro a equipe."
                    >
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label id="label-decoration" class="mb-0" for="nome"
                            >Membro</label
                          >
                          <div>
                            <AutocompleteComponents
                              v-model="userSolicitante"
                              :options="usersSolicitante"
                              label-key="nome"
                              value-key="pk_id_usuario"
                              placeholder="Pesquisar..."
                              @shouldSearch="pesquisarUsuarios"
                              @select="onSelect"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="mt-4">
                        <b-form-group>
                          <div class="mt-2 mb-3">
                            Selecione o nível de relação deste funcionário à
                            esta equipe:
                          </div>

                          <b-form-radio-group
                            id="btn-nivel-relacao"
                            v-model="selectedNivelRelacao"
                            :options="optionsNivelRelacao"
                            class="mb-3"
                            value-field="value"
                            text-field="text"
                          ></b-form-radio-group>

                          <b-tooltip
                            target="btn-nivel-relacao"
                            triggers="hover"
                          >
                            <p>
                              Escolha o nível de relação do usuário com essa
                              equipe.
                            </p>
                            <div><b>Relação direta.</b></div>
                            <div><b>Relação indireta.</b></div>
                          </b-tooltip>
                        </b-form-group>
                      </div>

                      <b-button
                        :disabled="statusButton"
                        @click="addMembro"
                        variant="primary"
                        class="btn-sm float-right"
                        ><i class="fas fa-save"></i> Salvar</b-button
                      >
                    </b-modal>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div
                        v-for="membros in itemsMembros"
                        :key="membros.pk_id_usuario"
                        class="
                          col-12 col-sm-6 col-md-4
                          d-flex
                          align-items-stretch
                        "
                      >
                        <div class="card bg-light box">
                          <div class="card-body mt-2">
                            <router-link
                              :to="
                                '/perfil-usuario?id=' + membros.pk_id_usuario
                              "
                            >
                              <div class="row">
                                <div class="col-8">
                                  <h2 class="lead">
                                    <b>{{ membros.nome }}</b>
                                  </h2>
                                  <br />
                                  <p class="text-muted text-sm">
                                    <b>Função: </b> {{ membros.funcao }}
                                  </p>
                                  <ul class="ml-4 mb-0 fa-ul text-muted">
                                    <li class="small">
                                      <span class="fa-li"
                                        ><i class="fas fa-lg fa-phone"></i
                                      ></span>
                                      Ramal: {{ membros.ramal }}
                                    </li>
                                    <li class="small">
                                      <span class="fa-li"
                                        ><i class="fas fa-lg fa-university"></i
                                      ></span>
                                      Unidade: {{ membros.nome_unidade }}
                                    </li>
                                    <li class="small">
                                      <span class="fa-li"
                                        ><i class="fas fa-lg fa-at"></i
                                      ></span>
                                      E-mail: {{ membros.email }}
                                    </li>
                                    <li class="small">
                                      <span class="fa-li"
                                        ><i
                                          class="fas fa-lg fa-exchange-alt"
                                        ></i
                                      ></span>
                                      Relação: {{ membros.nivel_relacao }}
                                    </li>
                                  </ul>
                                </div>
                                <div class="col-4 text-center">
                                  <img
                                    v-if="membros.foto"
                                    :src="
                                      require(`../../../assets/img/profiles/${membros.foto}`)
                                    "
                                    class="img-circle img-fluid"
                                    alt="img usuario"
                                  />
                                  <img
                                    v-else
                                    :src="
                                      require(`../../../assets/img/profiles/profile.png`)
                                    "
                                    class="img-circle img-fluid"
                                    alt="img usuario"
                                  />
                                </div>
                              </div>
                            </router-link>
                          </div>

                          <div
                            v-if="
                              idUsuario == gestorEquipe.fk_id_usuario_equipe || nivelAcesso == 'Administrador'
                            "
                            class="card-footer"
                          >
                            <div class="text-right">
                              <div>
                                <div class="text-right">
                                  <b-button
                                    variant="danger"
                                    size="sm"
                                    @click="
                                      excluirVinculoEquipe(
                                        membros.pk_id_usuario
                                      )
                                    "
                                  >
                                    <i class="fas fa-trash-alt"></i> Excluir
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </b-tab>
      <b-tab title="Atividades da Equipe">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary card-outline">
                  <div class="mt-2">
                    <b-button
                      v-if="idUsuario == gestorEquipe.fk_id_usuario_equipe"
                      class="btn-sm float-right mr-4"
                      variant="outline-dark"
                      v-b-modal.modal-adicionar-atividade
                      ><i class="fas fa-clipboard-list"></i> Adicionar
                      Atividade</b-button
                    >

                    <b-modal
                      id="modal-adicionar-atividade"
                      ref="modal"
                      hide-footer
                      title="Adicionar atividade a equipe."
                    >
                      <b-card no-body>
                        <b-tabs card fill>
                          <b-tab title="Atividades">
                            <b-card-text>
                              <b-container fluid>
                                <b-row>
                                  <b-input-group class="mb-2">
                                    <b-input-group-append>
                                      <b-form-input
                                        size="sm"
                                        id="filter-input"
                                        v-model="configTableAddAtividade.filter"
                                        type="search"
                                        placeholder="Pesquisar..."
                                      ></b-form-input>
                                    </b-input-group-append>
                                  </b-input-group>

                                  <b-table
                                    :busy="isBusy"
                                    striped
                                    outlined
                                    bordered
                                    :borderless="false"
                                    :noCollapse="false"
                                    :dark="false"
                                    :fixed="false"
                                    :small="true"
                                    :footClone="true"
                                    head-variant="dark"
                                    :items="itemsAddAtividade"
                                    :fields="fieldsAddAtividade"
                                    :current-page="
                                      configTableAddAtividade.currentPage
                                    "
                                    :per-page="configTableAddAtividade.perPage"
                                    :filter="configTableAddAtividade.filter"
                                    :filter-included-fields="
                                      configTableAddAtividade.filterOn
                                    "
                                    :sort-by.sync="
                                      configTableAddAtividade.sortBy
                                    "
                                    :sort-desc.sync="
                                      configTableAddAtividade.sortDesc
                                    "
                                    :sort-direction="
                                      configTableAddAtividade.sortDirection
                                    "
                                    stacked="md"
                                    show-empty
                                  >
                                    <template #empty>
                                      <h5 class="text-center">
                                        {{ tableAddAtividade.emptyTexts }}
                                      </h5>
                                    </template>

                                    <template #emptyfiltered>
                                      <h5 class="text-center">
                                        {{
                                          tableAddAtividade.emptyFilteredTexts
                                        }}
                                      </h5>
                                    </template>

                                    <template #table-busy>
                                      <div class="text-center text-danger my-3">
                                        <b-spinner
                                          class="align-middle"
                                        ></b-spinner>
                                        <strong> Carregando...</strong>
                                      </div>
                                    </template>

                                    <template #cell(nome)="row">
                                      <div>
                                        <span class="small"
                                          ><i
                                            class="fas fa-thumbtack text-muted"
                                          ></i
                                        ></span>
                                        {{ row.item.nome }}
                                      </div>
                                    </template>

                                    <template #cell(actions)="row">
                                      <div class="text-center">
                                        <b-button-group>
                                          <b-button
                                            variant="success"
                                            size="sm"
                                            @click="
                                              integrarAtividade(
                                                row.item
                                                  .pk_id_atendimento_categoria
                                              )
                                            "
                                          >
                                            <i class="fas fa-check"></i
                                          ></b-button>
                                        </b-button-group>
                                      </div>
                                    </template>
                                  </b-table>

                                  <b-tfoot>
                                    <div class="text-muted">
                                      <div class="small">
                                        <span
                                          ><i class="fas fa-list-ol"></i
                                        ></span>
                                        Total:
                                        <b>{{ itemsAddAtividade.length }}</b>
                                      </div>
                                    </div>
                                  </b-tfoot>

                                  <b-col sm="5" md="0" class="my-1">
                                    <b-form-group
                                      label="Quantidade"
                                      label-for="per-page-select"
                                      label-cols-sm="6"
                                      label-cols-md="4"
                                      label-cols-lg="8"
                                      label-align-sm="right"
                                      label-size="sm"
                                      class="mb-0"
                                    >
                                      <b-form-select
                                        id="per-page-select"
                                        v-model="
                                          configTableAddAtividade.perPage
                                        "
                                        :options="
                                          configTableAddAtividade.pageOptions
                                        "
                                        size="sm"
                                      >
                                      </b-form-select>
                                    </b-form-group>
                                  </b-col>

                                  <b-col sm="5" md="4" class="my-1">
                                    <b-pagination
                                      v-model="
                                        configTableAddAtividade.currentPage
                                      "
                                      :total-rows="addAtividadeRows"
                                      :per-page="
                                        configTableAddAtividade.perPage
                                      "
                                      align="fill"
                                      size="sm"
                                      class="my-0"
                                    ></b-pagination>
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-card-text>
                          </b-tab>

                          <b-tab title="Nova Atividade">
                            <b-card-text>
                              <p class="login-box-msg mt-4">
                                Adicione uma nova atividade:
                              </p>
                              <form @submit="addAtividadeNova">
                                <div class="col-sm-12">
                                  <label
                                    id="label-decoration"
                                    class="mb-0"
                                    for="atividade"
                                    >Atividade:</label
                                  ><br />
                                  <div class="input-group mb-0">
                                    <input
                                      type="text"
                                      name="atividade"
                                      v-model.trim="atividadeNova"
                                      class="form-control form-control-sm"
                                      id="atividade"
                                      placeholder="Atividade"
                                    />
                                    <div class="input-group-append">
                                      <div class="input-group-text">
                                        <span
                                          class="fas fa-clipboard-list"
                                        ></span>
                                      </div>
                                    </div>
                                  </div>

                                  <b-button
                                    :disabled="statusButtonSave"
                                    type="submit"
                                    @click="
                                      $bvModal.hide('modal-adicionar-atividade')
                                    "
                                    variant="primary"
                                    class="btn-sm btn-block mt-4 mb-4"
                                    ><i class="fas fa-save"></i>
                                    Salvar</b-button
                                  >
                                </div>
                              </form>
                            </b-card-text>
                          </b-tab>
                        </b-tabs>
                      </b-card>
                    </b-modal>
                  </div>

                  <div class="card-body">
                    <b-container fluid>
                      <b-row>
                        <b-input-group class="mt-0 mb-1">
                          <b-input-group-append>
                            <b-form-input
                              size="sm"
                              id="filter-input"
                              v-model="configTableAtividades.filter"
                              type="search"
                              placeholder="Pesquisar..."
                            ></b-form-input>
                          </b-input-group-append>
                        </b-input-group>

                        <b-table
                          :busy="isBusy"
                          striped
                          outlined
                          hover
                          bordered
                          head-variant="dark"
                          :items="itemsAtividades"
                          :fields="fieldsAtividades"
                          :current-page="configTableAtividades.currentPage"
                          :per-page="configTableAtividades.perPage"
                          :filter="configTableAtividades.filter"
                          :filter-included-fields="
                            configTableAtividades.filterOn
                          "
                          :sort-by.sync="configTableAtividades.sortBy"
                          :sort-desc.sync="configTableAtividades.sortDesc"
                          :sort-direction="configTableAtividades.sortDirection"
                          stacked="md"
                          show-empty
                          small
                        >
                          <template #empty>
                            <h5 class="text-center">
                              {{ tableAtividades.emptyTexts }}
                            </h5>
                          </template>

                          <template #emptyfiltered>
                            <h5 class="text-center">
                              {{ tableAtividades.emptyFilteredTexts }}
                            </h5>
                          </template>

                          <template #table-busy>
                            <div class="text-center text-danger my-3">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong> Carregando...</strong>
                            </div>
                          </template>

                          <template #cell(nome)="row">
                            <div class="text-left">
                              <span
                                ><i class="fab fa-whmcs text-muted"></i
                              ></span>
                              {{ row.item.nome }}
                            </div>
                          </template>

                          <template #cell(data_hora_cadastro)="row">
                            <div class="text-center">
                              <span class="small"
                                ><i class="fas fa-clock text-muted"></i
                              ></span>
                              <b> {{ row.item.data_hora_cadastro | moment }}</b>
                            </div>
                          </template>

                          <template #cell(actions)="row">
                            <div class="text-center">
                              <b-button
                                v-if="
                                  idUsuario == gestorEquipe.fk_id_usuario_equipe
                                "
                                class="ml-1"
                                variant="danger"
                                size="sm"
                                @click="
                                  desvincularAtividade(
                                    row.item.pk_id_atendimento_categoria
                                  )
                                "
                              >
                                <i class="fas fa-trash-alt"></i>
                              </b-button>
                            </div>
                          </template>
                        </b-table>

                        <b-tfoot>
                          <div class="text-muted">
                            <div class="small">
                              <span><i class="fas fa-list-ol"></i></span> Total
                              de registros: <b>{{ itemsAtividades.length }}</b>
                            </div>
                          </div>
                        </b-tfoot>

                        <b-col sm="5" md="0" class="my-1">
                          <b-form-group
                            label="Quantidade"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="8"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                          >
                            <b-form-select
                              id="per-page-select"
                              v-model="configTableAtividades.perPage"
                              :options="configTableAtividades.pageOptions"
                              size="sm"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col sm="5" md="4" class="my-1">
                          <b-pagination
                            v-model="configTableAtividades.currentPage"
                            :total-rows="atividadesRow"
                            :per-page="configTableAtividades.perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script scoped>
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\
const axios = require("axios").default;
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "MembrosEquipe",

  components: {
    AutocompleteComponents, //===>Autocomplete<===\\
  },

  data() {
    return {
      equipe: "",
      isBusy: true,
      gestorEquipe: [],
      gestorEquipeAtividades: [],
      atividadeNova: "",

      userSolicitante: "", //===>Autocomplete<===\\
      usersSolicitante: [], //===>Autocomplete<===\\
      userSelectSolicitante: "", //===>Autocomplete<===\\

      idUsuario: "",
      nivelAcesso: "",

      itemsMembros: [],

      selectedNivelRelacao: "1",

      selectedNivelRelacao: "1",
      optionsNivelRelacao: [
        { text: "Relação direta", value: "1" },
        { text: "Relação indireta", value: "2" },
      ],

      tableMembros: {
        emptyTexts: "Não há membros cadastrado nesta equipe!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsMembros: [
        {
          key: "foto",
          label: "#",
        },

        {
          key: "nome",
          label: "Nome",
          sortable: true,
        },

        {
          key: "nivel_relacao",
          label: "Relação",
          sortable: true,
        },

        {
          key: "funcao",
          label: "Função",
          sortable: true,
        },

        {
          key: "email",
          label: "E-mail",
          sortable: true,
        },

        {
          key: "ramal",
          label: "Ramal",
          sortable: true,
        },

        {
          key: "nome_unidade",
          label: "Unidade",
          sortable: true,
        },

        {
          key: "actions",
          label: "Ação",
        },
      ],

      configTableMembros: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },

      itemsAtividades: [],

      tableAtividades: {
        emptyTexts: "Não há atividades cadastradas nesta equipe!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsAtividades: [
        {
          key: "nome",
          label: "Atividades",
          sortable: true,
        },
        {
          key: "data_hora_cadastro",
          label: "Hora/Data",
          sortable: true,
        },

        {
          key: "actions",
          label: "#",
        },
      ],

      configTableAtividades: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },

      /////////////////////////<====TABELA DE EQUIPES====>///////////////////////////////////////////
      itemsAddAtividade: [],

      tableAddAtividade: {
        emptyTexts: "Não há atividade cadastrada!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsAddAtividade: [
        {
          key: "nome",
          label: "Atividades",
          sortable: true,
        },

        {
          key: "actions",
          label: "Integrar",
        },
      ],

      configTableAddAtividade: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
    };
  },

  computed: {
    membrosRows() {
      return this.itemsMembros.length;
    },

    atividadesRow() {
      return this.itemsAtividades.length;
    },

    addAtividadeRows() {
      return this.itemsAddAtividade.length;
    },

    statusButton() {
      return this.userSelectSolicitante != "" && this.userSolicitante != ""
        ? false
        : true;
    },

    statusButtonSave() {
      return this.atividadeNova != "" ? false : true;
    },
  },

  mounted() {
    this.equipeMembros();
  },

  filters: {
    moment: function (date) {
      return moment(date).format("LT - L");
    },
  },

  methods: {
    async equipeMembros() {
      try {
        //Requisição atualizar dados
        const response = await axios.post("equipe/membrosEquipe", {
          idEquipe: this.$route.query.id,
        });

        console.log()

        const perfil = JSON.parse(localStorage.getItem("storageData"));

        console.log(perfil.nivel_atendimento)

        
        this.idUsuario = perfil.pk_id_usuario;
        this.nivelAcesso = perfil.nivel_atendimento;

        this.equipe = response.data.equipe.original.nome_equipe;
        this.gestorEquipe = response.data.gestorEquipe.original;

        this.itemsMembros = response.data.membros;

        console.log(response.data.membros);

        this.gestorEquipeAtividades = response.data.gestorEquipe.original;

        this.itemsAtividades = response.data.atividadesEquipe.original;

        this.itemsAddAtividade = response.data.atividadesGeral.original;

        this.isBusy = false;
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async integrarAtividade(idAtividade) {
      //alert('teste')

      try {
        const response = await axios.post("equipe/addAtividade", {
          idAtividadeAdd: idAtividade,
          idEquipe: this.$route.query.id,
        });

        alert(response.data);

        this.equipeMembros();
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    pesquisarUsuarios(query) {
      //===>Autocomplete<===\\
      const idEquipe = this.$route.query.id;
      axios
        .get(`autocomplete/userAutocompleteMembrosEquipe/${query}/${idEquipe}`)
        .then((response) => {
          this.usersSolicitante = response.data;
        });
    },

    onSelect(userSolicitante) {
      //===>Autocomplete<===\\
      //console.log(userSolicitante);
      this.userSelectSolicitante = userSolicitante;
    },

    async excluirVinculoEquipe(idUser) {
      try {
        const response = await axios.post("equipe/excluirVinculo", {
          idUsuario: idUser,
          idEquipe: this.$route.query.id,
        });

        alert(response.data), this.equipeMembros();
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async addMembro() {
      try {
        const response = await axios.post("equipe/addMembrosEquipe", {
          idEquipe: this.$route.query.id,
          idUsuario: this.userSelectSolicitante.pk_id_usuario,
          nivelRelacao: this.selectedNivelRelacao,
        });

        alert(response.data);

        this.userSolicitante = "";
        this.usersSolicitante = [];
        this.userSelectSolicitante = "";
        this.selectedNivelRelacao = "1";

        this.equipeMembros();
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async desvincularAtividade(idExcluir) {
      try {
        const response = await axios.post("equipe/excluirAtividadeEquipe", {
          idEquipe: this.$route.query.id,
          idAtividadeExcluir: idExcluir,
        });

        alert(response.data);

        this.equipeMembros();
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async addAtividadeNova(event) {
      event.preventDefault();

      try {
        const response = await axios.post("equipe/addAtividadeNew", {
          idEquipe: this.$route.query.id,
          atividadeNew: this.atividadeNova,
        });

        alert(response.data);

        this.atividadeNova = "";

        this.equipeMembros();
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>


<style scoped>
#label-decoration {
  color: rgb(139, 139, 139);
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
}

#input-autocomplete {
  border-style: solid;
}

.box:hover {
  transition: 0.1s;
}

.box:hover {
  transform: scale(1);
  background: #a1a1a1;
  z-index: 4;
  box-shadow: 4px 4px 4px #a1a1a1;
}

a:link {
  text-decoration: none;
}

.lead {
  color: #444444;
}
</style>