<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Criar Equipe</h1>
          </div>

          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Criar Equipe</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="login-logo">
                  <a><b>Registrar </b>Equipe</a>
                </div>
                <div class="card-body login-card-body">
                  <p class="login-box-msg">
                    Insira uma nova equipe e seu lider, não é obritatório
                    vincular um lider a equipe neste primeiro momento.
                  </p>

                  <form @submit.prevent="handleSubmit">
                    <div class="mb-3">
                      <label
                        id="label-decoration"
                        class="mb-0"
                        for="nome-destino"
                        >Líder</label
                      >
                      <div>
                        <AutocompleteComponents
                          v-model="criarEquipe.userLider"
                          :options="criarEquipe.usersLider"
                          label-key="nome"
                          value-key="pk_id_usuario"
                          placeholder="Pesquisar..."
                          @shouldSearch="criarEquipePesquisarUsuarios"
                          @select="criarEquipeonSelect"
                        />
                      </div>
                    </div>

                    <div class="mb-3">
                      <label id="label-decoration" class="mb-0" for="equipe"
                        >Equipe</label
                      >
                      <input
                        type="text"
                        name="equipe"
                        class="form-control"
                        id="equipe"
                        v-model.trim="criarEquipe.equipe"
                        placeholder="Digite a nova equipe"
                        required
                      />
                    </div>

                    <div class="mb-3">
                      <label
                        id="label-decoration"
                        class="mb-0"
                        for="centro-custo"
                        >Centro de Custo</label
                      >
                      <input
                        v-mask="'##-###'"
                        type="text"
                        name="centro-custo"
                        class="form-control"
                        id="equipe"
                        v-model.trim="criarEquipe.centroCusto"
                        placeholder="Centro de Custo da equipe"
                      />
                    </div>

                    <div class="mt-4">
                      <b-button
                        type="submit"
                        variant="primary"
                        class="btn btn-primary btn-block"
                        ><i class="fas fa-save"></i> Salvar</b-button
                      >
                    </div>
                  </form>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
          </div>

          <div class="col-md-6">
            <div class="card">
              <div class="login-logo">
                <a><b>Equipes </b>Cadastradas</a>
              </div>
              <div class="card-body login-card-body">
                <b-container fluid>
                  <b-row>
                    <b-input-group class="mb-2">
                      <b-input-group-append>
                        <b-form-input
                          size="sm"
                          id="filter-input"
                          v-model="configTableEquipe.filter"
                          type="search"
                          placeholder="Pesquisar..."
                        ></b-form-input>
                      </b-input-group-append>
                    </b-input-group>

                    <b-table
                      :busy="isBusy"
                      striped
                      outlined
                      hover
                      bordered
                      :borderless="false"
                      :noCollapse="false"
                      :dark="false"
                      :fixed="false"
                      :small="true"
                      :footClone="true"
                      head-variant="light"
                      :items="itemsEquipe"
                      :fields="fieldsEquipe"
                      :current-page="configTableEquipe.currentPage"
                      :per-page="configTableEquipe.perPage"
                      :filter="configTableEquipe.filter"
                      :filter-included-fields="configTableEquipe.filterOn"
                      :sort-by.sync="configTableEquipe.sortBy"
                      :sort-desc.sync="configTableEquipe.sortDesc"
                      :sort-direction="configTableEquipe.sortDirection"
                      stacked="md"
                      show-empty
                    >
                      <template #empty>
                        <h5 class="text-center">
                          {{ tableEquipe.emptyTexts }}
                        </h5>
                      </template>

                      <template #emptyfiltered>
                        <h5 class="text-center">
                          {{ tableEquipe.emptyFilteredTexts }}
                        </h5>
                      </template>

                      <template #table-busy>
                        <div class="text-center text-danger my-3">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong> Carregando...</strong>
                        </div>
                      </template>

                      <template #cell(nome_equipe)="row">
                        <div>
                          <span class="small"
                            ><i class="fas fa-users text-muted"></i
                          ></span>
                          {{ row.item.nome_equipe }}
                        </div>
                      </template>

                      <template #cell(nome)="row">
                        <div v-if="row.item.nome">
                          <span class="small"
                            ><i class="fas fa-user-tie text-muted"></i
                          ></span>
                          {{ row.item.nome }}
                        </div>

                        <div v-else>
                          <span class="small"
                            ><i class="fas fa-user-slash text-muted"></i
                          ></span>
                          Sem Lider
                        </div>
                      </template>

                      <template #cell(centro_custo_unidade)="row">
                        <div v-if="row.item.centro_custo_unidade">
                          <span class="small"
                            ><i class="fas fa-donate text-muted"></i
                          ></span>
                          {{ row.item.centro_custo_unidade }}-{{
                            row.item.centro_custo_setor
                          }}
                        </div>

                        <div v-else>
                          <span class="small">Sem C.C.</span>
                        </div>
                      </template>

                      <template #cell(actions)="row">
                        <div class="text-center">
                          <b-button
                            variant="secondary"
                            size="sm"
                            @click="editarEquipe(row.item.pk_id_equipe)"
                            v-b-modal.editEquipe
                          >
                            <i class="fas fa-pen"></i>
                          </b-button>

                          <b-button
                            class="ml-1"
                            variant="danger"
                            size="sm"
                            @click="excluirEquipe(row.item.pk_id_equipe)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </b-button>
                        </div>
                      </template>
                    </b-table>

                    <!--
                      <b-tfoot>
                        <div class="text-muted">
                          <div class="small">
                            <span><i class="fas fa-list-ol"></i></span> Total de
                            registros: <b>{{ itemsEquipe.length }}</b>
                          </div>
                        </div>
                      </b-tfoot>
                      -->

                    <b-col sm="7" md="0" class="my-1">
                      <b-form-group
                        label="Quantidade"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="8"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="configTableEquipe.perPage"
                          :options="configTableEquipe.pageOptions"
                          size="sm"
                        >
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col sm="5" md="4" class="my-1">
                      <b-pagination
                        v-model="configTableEquipe.currentPage"
                        :total-rows="visitanteRows"
                        :per-page="configTableEquipe.perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </b-container>

                <b-modal id="editEquipe" title="Editar Chip" hide-footer>
                  <div class="card-body">
                    <form @submit="onSubmitEdit">
                      <!--
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="label-editar mb-0" for="nome"
                            >Nome Completo</label
                          >
                          <div>
                            <Autocomplete
                              v-model="chipEditar.user"
                              :options="chipEditar.users"
                              label-key="nome"
                              value-key="pk_id_usuario"
                              placeholder="Pesquisar..."
                              @shouldSearch="pesquisarUsuariosEditar"
                              @select="onSelectEditar"
                            />
                          </div>
                        </div>
                      </div>
                      -->

                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="label-editar mb-0" for="nome-equipe"
                            >Nome Equipe</label
                          >
                          <div class="input-group">
                            <input
                              type="text"
                              name="nome-equipe"
                              class="form-control"
                              v-model.trim="equipeEditar.nome"
                              id="nome-equipe"
                              required
                              placeholder="Nome Equipe"
                            />
                            <div class="input-group-append">
                              <div class="input-group-text">
                                <span class="fas fa-users"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <b-button
                          type="submit"
                          block
                          variant="primary"
                          class="float-right"
                          ><i class="fas fa-save"></i> Salvar</b-button
                        >
                      </div>

                      <!--
                      <div>
                        <b-button
                          type="submit"
                          @click="$bvModal.hide('editEquipe')"
                          block
                          variant="primary"
                          class="float-right"
                          ><i class="fas fa-save"></i> Salvar</b-button
                        >
                      </div>
                      -->
                    </form>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const axios = require("axios").default;
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\

export default {
  name: "AlterarSenha",

  data() {
    return {
      isBusy: true,

      criarEquipe: {
        equipe: "",
        centroCusto: "",

        userLider: "", //===>Autocomplete<===\\
        usersLider: [], //===>Autocomplete<===\\
        userSelectLider: "", //===>Autocomplete<===\\
      },

      /////////////////////////<====TABELA DE VISITANTE ENTRADA====>///////////////////////////////////////////
      itemsEquipe: [],

      equipeEditar: {
        nome: "",
        idEquipeEditar: "",

        optionsStatusEditar: [
          { text: "Ativa", value: "Ativa" },
          { text: "Inativa", value: "Inativa" },
          { text: "Suspensa", value: "Suspensa" },
          { text: "Cancelada", value: "Cancelada" },
        ],
      },

      tableEquipe: {
        emptyTexts: "Não há Equipes cadastrado!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsEquipe: [
        {
          key: "nome_equipe",
          label: "Nome Equipe",
          sortable: true,
        },

        {
          key: "nome",
          label: "Líder Equipe",
          sortable: true,
        },

        {
          key: "centro_custo_unidade",
          label: "C.C.",
          sortable: true,
        },

        {
          key: "actions",
          label: "Ação",
        },
      ],

      configTableEquipe: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
    };
  },

  components: {
    AutocompleteComponents,
  },

  computed: {
    visitanteRows() {
      return this.itemsEquipe.length;
    },
  },

  mounted() {
    this.optionEquipes();
  },

  methods: {
    criarEquipepesquisarUsuarios(query) {
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.criarEquipe.usersLider = response.data;
      });
    },

    criarEquipeonSelect(userLider) {
      this.criarEquipe.userSelectLider = userLider;
    },

    async optionEquipes() {
      this.isBusy = true;
      try {
        const response = await axios.get("equipe/todasEquipes");

        this.optionEquipe = response.data;
        this.itemsEquipe = response.data;

        console.log(response.data);

        this.isBusy = false;
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async handleSubmit() {
      if (this.criarEquipe.userSelectLider) {
        var numeroIdLider = this.criarEquipe.userSelectLider["pk_id_usuario"];
      } else {
        var numeroIdLider = null;
      }

      try {
        const response = await axios.post("equipe/addEquipe", {
          nomeEquipe: this.criarEquipe.equipe,
          centroCustoEquipe: this.criarEquipe.centroCusto,
          idLider: numeroIdLider,
        });

        this.criarEquipe.equipe = "";
        this.criarEquipe.userLider = "";
        this.criarEquipe.userSelectLider = "";
        this.criarEquipe.centroCusto = ""
        
        alert(response.data);

        console.log(response.data);

        this.optionEquipes();
      } catch (error) {
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 404:
            this.error =
              "Pagina não encontrada na Api! - [Erro] " + error.response.status;
            break;
        }
      }
    },

    async excluirEquipe(idExcluir) {
      try {
        //Requisição
        const response = await axios.post("equipe/excluirRegistroEquipe", {
          idEquipe: idExcluir,
        });

        alert(response.data);

        this.optionEquipes();
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async editarEquipe(idEditar) {
      this.onResetEdit();

      try {
        axios
          .get(`equipe/editarRegistroEquipe/${idEditar}`)
          .then((response) => {
            console.log(response);

            this.equipeEditar.nome = response.data["0"].nome_equipe;
            this.equipeEditar.idEquipeEditar = response.data["0"].pk_id_equipe;

            console.log(this.equipeEditar);
          });
      } catch (error) {
        //console.log(error.response.status)

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 401:
            this.error = "[Erro] " + error.response.status;
            break;
          case 404:
            this.error =
              "Pagina não encontrada na Api! - [Erro] " + error.response.status;
            break;
        }
      }
    },

    onResetEdit() {
      this.equipeEditar.nome = "";
      this.equipeEditar.idEquipeEditar = "";
    },

    async onSubmitEdit(event) {
      event.preventDefault();

      try {
        //Requisição atualizar dados
        const response = await axios.post("equipe/addEditarEquipe", {
          nomeEquipe: this.equipeEditar.nome,
          idRegistroEquipe: this.equipeEditar.idEquipeEditar,
        });

        alert(response.data);

        this.optionEquipes();
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 401:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);

            this.statusButton = false;
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );

            this.statusButton = false;
            break;
        }
      }
    },
  },
};
</script>

<style>
</style>