<template>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Config Equipe</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item active">Config Equipe</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="card card-primary">
              <div class="card-header">
                <h3 id="header-h3" class="card-title mb-0">
                  Editar/Adicionar Lider e C.C.
                </h3>
              </div>

              <div class="card-body">
                <div class="form-group row mb-0">
                  <div class="col-sm-12">
                    <div class="float-right">
                      <button
                        v-b-modal.addEquipeModal
                        type="button"
                        class="btn badge bagde-light"
                      >
                        <i
                          id="icon"
                          class="fas fa-plus-circle text-secondary"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <label id="label-decoration" class="mb-0">Equipes</label>

                    <v-select
                      placeholder="-Selecione uma Equipe-"
                      v-model="editarEquipe.selectedEquipe"
                      :options="editarEquipe.optionEquipe"
                      label="nome_equipe"
                    >
                    </v-select>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <label id="label-decoration" class="mb-0" for="nome-destino"
                      >Usuário Líder</label
                    >
                    <div>
                      <AutocompleteComponents
                        v-model="editarEquipe.userLider"
                        :options="editarEquipe.usersLider"
                        label-key="nome"
                        value-key="pk_id_usuario"
                        placeholder="Pesquisar..."
                        @shouldSearch="pesquisarUsuarios"
                        @select="onSelect"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <label id="label-decoration" class="mb-0" for="centro-custo"
                      >Centro de Custo</label
                    >
                    <input
                      v-mask="'##-###'"
                      type="text"
                      name="centro-custo"
                      class="form-control"
                      id="equipe"
                      v-model.trim="editarEquipe.editarCentroCusto"
                      placeholder="Centro de Custo da equipe"
                      required
                    />
                  </div>
                </div>

                <div class="mt-4">
                  <b-button
                    :disabled="statusButton"
                    @click="onSubmit"
                    variant="primary"
                    class="btn btn-primary btn-block"
                    ><i class="fas fa-save"></i> Salvar</b-button
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-8">
            <div class="card card-secondary">
              <div class="card-header mb-2">
                <h3 id="header-h3" class="card-title mb-0">
                  Equipes
                </h3>
              </div>

              <b-container fluid>
                <b-row>
                  <b-input-group class="mt-2 mb-2 ml-2">
                    <b-input-group-append>
                      <b-form-input
                        size="sm"
                        id="filter-input"
                        v-model="configTableEquipe.filter"
                        type="search"
                        placeholder="Pesquisar..."
                      ></b-form-input>
                    </b-input-group-append>
                  </b-input-group>

                  <b-table
                    :busy="isBusy"
                    striped
                    outlined
                    hover
                    bordered
                    :borderless="false"
                    :noCollapse="false"
                    :dark="false"
                    :fixed="false"
                    :small="true"
                    :footClone="true"
                    head-variant="dark"
                    :items="itemsEquipe"
                    :fields="fieldsEquipe"
                    :current-page="configTableEquipe.currentPage"
                    :per-page="configTableEquipe.perPage"
                    :filter="configTableEquipe.filter"
                    :filter-included-fields="configTableEquipe.filterOn"
                    :sort-by.sync="configTableEquipe.sortBy"
                    :sort-desc.sync="configTableEquipe.sortDesc"
                    :sort-direction="configTableEquipe.sortDirection"
                    stacked="md"
                    show-empty
                  >
                    <template #empty>
                      <h5 class="text-center">
                        {{ tableEquipe.emptyTexts }}
                      </h5>
                    </template>

                    <template #emptyfiltered>
                      <h5 class="text-center">
                        {{ tableEquipe.emptyFilteredTexts }}
                      </h5>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-danger my-3">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Carregando...</strong>
                      </div>
                    </template>

                    <template #cell(nome_equipe)="row">
                      <div>
                        <span class="small"
                          ><i class="fas fa-users text-muted"></i
                        ></span>
                        {{ row.item.nome_equipe }}
                      </div>
                    </template>

                    <template #cell(nome)="row">
                      <div v-if="row.item.nome">
                        <span class="small"
                          ><i class="fas fa-user-tie text-muted"></i
                        ></span>
                        {{ row.item.nome }}
                      </div>

                      <div v-else>
                        <span class="small"
                          ><i class="fas fa-user-slash text-muted"></i
                        ></span>
                        Sem Lider
                      </div>
                    </template>

                    <template #cell(centro_custo_unidade)="row">
                      <div v-if="row.item.centro_custo_unidade">
                        <span class="small"
                          ><i class="fas fa-donate text-muted"></i
                        ></span>
                        {{ row.item.centro_custo_unidade }}-{{
                          row.item.centro_custo_setor
                        }}
                      </div>

                      <div v-else>
                        <span class="small">Sem C.C.</span>
                      </div>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-center">
                        <b-button
                          variant="secondary"
                          size="sm"
                          @click="getEditarEquipe(row.item.pk_id_equipe)"
                          v-b-modal.editEquipe
                        >
                          <i class="fas fa-pen"></i>
                        </b-button>

                        <b-button
                          class="ml-1"
                          variant="danger"
                          size="sm"
                          @click="excluirEquipe(row.item.pk_id_equipe)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </b-button>
                      </div>
                    </template>
                  </b-table>

                  <b-tfoot>
                    <div class="text-muted">
                      <div class="small">
                        <span><i class="fas fa-list-ol"></i></span> Total de
                        registros: <b>{{ itemsEquipe.length }}</b>
                      </div>
                    </div>
                  </b-tfoot>

                  <b-col sm="5" md="0" class="my-1">
                    <b-form-group
                      label="Quantidade"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="8"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="configTableEquipe.perPage"
                        :options="configTableEquipe.pageOptions"
                        size="sm"
                      >
                      </b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col sm="5" md="4" class="my-1">
                    <b-pagination
                      v-model="configTableEquipe.currentPage"
                      :total-rows="visitanteRows"
                      :per-page="configTableEquipe.perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal id="addEquipeModal" size="lg" title="Adicionar Nova Equipe" hide-footer>
      <div class="card-body">
        <div class="login-logo">
          <a><b>Registrar </b>Equipe</a>
        </div>
        <div class="card-body login-card-body">
          <p class="login-box-msg">
            Insira uma nova equipe e seu lider, não é obritatório vincular um
            lider a equipe neste primeiro momento.
          </p>

          <form @submit.prevent="handleSubmit">
            <div class="mb-3">
              <label id="label-decoration" class="mb-0" for="nome-destino"
                >Líder</label
              >
              <div>
                <AutocompleteComponents
                  v-model="criarEquipe.userLider"
                  :options="criarEquipe.usersLider"
                  label-key="nome"
                  value-key="pk_id_usuario"
                  placeholder="Pesquisar..."
                  @shouldSearch="criarEquipepesquisarUsuarios"
                  @select="criarEquipeonSelect"
                />
              </div>
            </div>

            <div class="mb-3">
              <label id="label-decoration" class="mb-0" for="equipe"
                >Equipe</label
              >
              <input
                type="text"
                name="equipe"
                class="form-control"
                id="equipe"
                v-model.trim="criarEquipe.equipe"
                placeholder="Digite a nova equipe"
                required
              />
            </div>

            <div class="mb-3">
              <label id="label-decoration" class="mb-0" for="centro-custo"
                >Centro de Custo</label
              >
              <input
                v-mask="'##-###'"
                type="text"
                name="centro-custo"
                class="form-control"
                id="equipe"
                v-model.trim="criarEquipe.centroCusto"
                placeholder="Centro de Custo da equipe"
              />
            </div>

            <div class="mt-4">
              <b-button
                type="submit"
                variant="primary"
                class="btn btn-primary btn-block"
                ><i class="fas fa-save"></i> Salvar</b-button
              >
            </div>
          </form>
        </div>
      </div>
    </b-modal>

    <b-modal id="editEquipe" title="Editar Chip" hide-footer>
      <div class="card-body">
        <form @submit="onSubmitEdit">
        
          <div class="form-group row">
            <div class="col-sm-12">
              <label class="label-editar mb-0" for="nome-equipe"
                >Nome Equipe</label
              >
              <div class="input-group">
                <input
                  type="text"
                  name="nome-equipe"
                  class="form-control"
                  v-model.trim="equipeEditar.nome"
                  id="nome-equipe"
                  required
                  placeholder="Nome Equipe"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-users"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <b-button type="submit" block variant="primary" class="float-right"
              ><i class="fas fa-save"></i> Salvar</b-button
            >
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
var moment = require("moment");
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\
moment.locale("pt-br");

export default {
  name: "AdicionaLider",

  data() {
    return {
      isBusy: true,

      editarEquipe: {
        userLider: "", //===>Autocomplete<===\\
        usersLider: [], //===>Autocomplete<===\\
        userSelectLider: "", //===>Autocomplete<===\\

        selectedEquipe: "",
        optionEquipe: [],

        editarCentroCusto: "",
      },

      criarEquipe: {
        equipe: "",
        centroCusto: "",

        userLider: "", //===>Autocomplete<===\\
        usersLider: [], //===>Autocomplete<===\\
        userSelectLider: "", //===>Autocomplete<===\\
      },

      equipeEditar: {
        nome: "",
        idEquipeEditar: "",
      },

      statusButton: false,

      /////////////////////////<====TABELA DE VISITANTE ENTRADA====>///////////////////////////////////////////
      itemsEquipe: [],

      tableEquipe: {
        emptyTexts: "Não há Equipes cadastrado!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsEquipe: [
        {
          key: "nome_equipe",
          label: "Nome Equipe",
          sortable: true,
        },

        {
          key: "nome",
          label: "Líder Equipe",
          sortable: true,
        },

        {
          key: "centro_custo_unidade",
          label: "C.C.",
          sortable: true,
        },
        {
          key: "actions",
          label: "Ação",
        },
      ],

      configTableEquipe: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
    };
  },

  /////////Autocomplete//////////
  components: {
    AutocompleteComponents,
  },
  /////////Autocomplete//////////

  computed: {
    visitanteRows() {
      return this.itemsEquipe.length;
    },
  },

  mounted() {
    this.optionEquipes();
  },

  filters: {
    moment: function (date) {
      return moment(date).format("LT - L");
    },
  },

  methods: {
    pesquisarUsuarios(query) {
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.editarEquipe.usersLider = response.data;
      });
    },

    onSelect(userLider) {
      //===>Autocomplete<===\\
      console.log(userLider);
      this.editarEquipe.userSelectLider = userLider;
    },

    criarEquipepesquisarUsuarios(query) {
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.criarEquipe.usersLider = response.data;
      });
    },

    criarEquipeonSelect(userLider) {
      this.criarEquipe.userSelectLider = userLider;
    },

    async optionEquipes() {
      this.isBusy = true;
      try {
        const response = await axios.get("equipe/todasEquipes");

        this.editarEquipe.optionEquipe = response.data;
        this.itemsEquipe = response.data;

        this.isBusy = false;
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async onSubmit() {
      try {
        if (this.editarEquipe.userSelectLider == "") {
          alert("Nem um usuário válido selecionado");
        } else if (this.editarEquipe.userLider == "") {
          alert("Nem um usuário válido selecionado");
        } else if (this.editarEquipe.selectedEquipe == "") {
          alert('O campo "Equipe" é obrigatório');
        } else {
          const response = await axios.post("equipe/addLider", {
            idLider: this.editarEquipe.userSelectLider["pk_id_usuario"],
            idEquipe: this.editarEquipe.selectedEquipe["pk_id_equipe"],
            centroCustoEquipe: this.editarEquipe.editarCentroCusto,
          });

          (this.editarEquipe.userLider = ""),
            (this.editarEquipe.userSelectLider = ""),
            (this.editarEquipe.selectedEquipe = ""),
            (this.editarEquipe.editarCentroCusto = ""),
            alert(response.data);

          this.optionEquipes();
        }
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async handleSubmit() {
      if (this.criarEquipe.userSelectLider) {
        var numeroIdLider = this.criarEquipe.userSelectLider["pk_id_usuario"];
      } else {
        var numeroIdLider = null;
      }

      try {
        const response = await axios.post("equipe/addEquipe", {
          nomeEquipe: this.criarEquipe.equipe,
          centroCustoEquipe: this.criarEquipe.centroCusto,
          idLider: numeroIdLider,
        });

        this.criarEquipe.equipe = "";
        this.criarEquipe.userLider = "";
        this.criarEquipe.userSelectLider = "";
        this.criarEquipe.centroCusto = "";

        alert(response.data);

        console.log(response.data);

        this.optionEquipes();
      } catch (error) {
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 404:
            this.error =
              "Pagina não encontrada na Api! - [Erro] " + error.response.status;
            break;
        }
      }
    },

    async excluirEquipe(idExcluir) {
      try {
        //Requisição
        const response = await axios.post("equipe/excluirRegistroEquipe", {
          idEquipe: idExcluir,
        });

        alert(response.data);

        this.optionEquipes();
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async getEditarEquipe(idEquipe) {
      this.onResetEdit();

      this.itemsEquipe.forEach((atividades, i) => {
          
        if (atividades.pk_id_equipe == idEquipe) {


          this.equipeEditar.nome = atividades.nome_equipe;
          this.equipeEditar.idEquipeEditar = atividades.pk_id_equipe;

        }
      });
    },

    onResetEdit() {
      this.equipeEditar.nome = "";
      this.equipeEditar.idEquipeEditar = "";
    },

    async onSubmitEdit(event) {
      event.preventDefault();

      try {
        //Requisição atualizar dados
        const response = await axios.post("equipe/addEditarEquipe", {
          nomeEquipe: this.equipeEditar.nome,
          idRegistroEquipe: this.equipeEditar.idEquipeEditar,
        });

        alert(response.data);

        this.onResetEdit();

        this.optionEquipes();
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 401:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);

            this.statusButton = false;
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );

            this.statusButton = false;
            break;
        }
      }
    },
  },
};
</script>


<style scoped>
#label-decoration {
  color: rgb(139, 139, 139);
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
}

#icon {
  font-size: 25px;
}

.conteudo-perfil {
  color: #575757;
}
</style>