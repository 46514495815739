<template>
  
</template>

<script scoped>
export default {
    name: 'HeaderComponents',
}
</script>

<style scoped src="../../../assets/adminLTE/main-dashboard.css"/>
