import { render, staticRenderFns } from "./NavbarComponents.vue?vue&type=template&id=64ea89f6&scoped=true&"
import script from "./NavbarComponents.vue?vue&type=script&scoped=true&lang=js&"
export * from "./NavbarComponents.vue?vue&type=script&scoped=true&lang=js&"
import style0 from "./NavbarComponents.vue?vue&type=style&index=0&id=64ea89f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ea89f6",
  null
  
)

export default component.exports