<template>
  <div>
    <b-overlay :show="overlayShow" rounded="sm">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Solicitação</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">Home</li>
                  <li class="breadcrumb-item active">Solicitação</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <b-tabs
          content-class="mt-3"
          align="center"
          active-nav-item-class="font-weight-bold text-uppercase text-success"
          ><b-tab title="Solicitação em atendimento" active>
            <section class="content">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-3">
                    <div class="card card-primary card-outline">
                      <div class="card-body box-profile">
                        <p class="mb-5">
                          <router-link :to="'/ticket-recebido'">
                            <b-button
                              type="button"
                              class="float-left mb-2"
                              variant="outline-secondary"
                              size="sm"
                            >
                              <i id="size-ico" class="fas fa-undo"> Voltar</i>
                            </b-button>
                          </router-link>
                        </p>

                        <h3 class="profile-username"><b>Título</b></h3>
                        <p class="text-muted">
                          {{ dadosTicketAtendendo.titulo }}
                        </p>
                        <br />

                        <b>Atividade</b> <br />
                        <p class="text-muted">
                          {{ dadosTicketAtendendo.nome_categora_atendimento }}
                        </p>

                        <b>Solicitante</b> <br />
                        <p class="text-muted">
                          {{ dadosTicketAtendendo.nome_requerente }}
                        </p>

                        <b>Protocolo</b> <br />
                        <p class="text-muted">
                          {{ dadosTicketAtendendo.protocolo }}
                        </p>

                        <b>Status</b> <br />
                        <p class="text-muted">
                          {{ dadosTicketAtendendo.status }}
                        </p>

                        <b>Atribuido</b> <br />
                        <p
                          v-if="dadosTicketAtendendo.nome_destino"
                          class="text-muted"
                        >
                          {{ dadosTicketAtendendo.nome_destino }}
                        </p>
                        <p v-else class="text-muted">Equipe</p>

                        <p>
                          <!--Fazer um V-If status == "Atendimento"-->
                          <b-button
                            v-if="dadosTicketAtendendo.status == 'Atendimento'"
                            class="btn btn-block"
                            variant="secondary"
                            size="sm"
                            @click="showModal"
                            >Transferir</b-button
                          >

                          <b-modal
                            id="transferir"
                            ref="modal-transfer"
                            size="sm"
                            hide-footer
                            title="Transferir Atendimento"
                          >
                            <v-select
                              placeholder="-Selecione Usuário-"
                              v-model="userTransferir"
                              :options="membrosEquipe"
                              label="nome"
                            >
                            </v-select>

                            <b-button
                              class="btn btn-block mt-4"
                              variant="primary"
                              size="sm"
                              :disabled="statusButtonTransfer"
                              @click="hideModal"
                              ><i class="fas fa-exchange-alt"></i>
                            </b-button>
                          </b-modal>

                          <!--Fazer um V-If status == "Aberto" OR status == "Fechado"-->
                          <b-button
                            v-if="
                              dadosTicketAtendendo.status == 'Aberto' ||
                              dadosTicketAtendendo.status == 'Fechado'
                            "
                            class="btn btn-block"
                            variant="secondary"
                            size="sm"
                            @click="atenderTicket"
                            >Atender</b-button
                          >
                        </p>

                        <!--Fazer um V-If status == "Atendimento"-->
                        <b-button
                          v-if="dadosTicketAtendendo.status == 'Atendimento'"
                          :disabled="statusButton"
                          class="btn btn-block mb-3"
                          variant="danger"
                          size="sm"
                          @click="fecharTicket"
                          >Fechar</b-button
                        >

                        <div v-if="anexoChamados['0']">
                          <b-dropdown
                            size="sm"
                            variant="outline-secondary"
                            dropright
                            text="Anexos"
                            class="btn-block"
                          >
                            <div
                              v-for="anexo in anexoChamados"
                              :key="anexo.pk_id_anexo"
                            >
                              <b-dropdown-item-button
                                @click="
                                  downloadAnexo(anexo.pk_id_anexo, anexo.nome)
                                "
                              >
                                <i class="fas fa-file-download"></i>
                                <span id="nome-anexo">
                                  <span id="data-anexo">
                                    {{
                                      anexo.data_hora_cadastro | momentTimeAnexo
                                    }}</span
                                  >
                                  - {{ anexo.nome }}</span
                                >
                              </b-dropdown-item-button>
                            </div>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-9">
                    <div
                      id="back-descricao"
                      class="card card-primary card-outline"
                    >
                      <div class="card-header">
                        <div id="descricao" class="mb-3">
                          <b>Descrição:</b> <br />
                          <p class="text-muted" align="justify">
                            {{ dadosTicketAtendendo.descricao }}
                          </p>
                        </div>

                        <!--Fazer um V-If dos botoes-->
                        <div
                          class="mb-0"
                          v-if="dadosTicketAtendendo.status != 'Fechado'"
                        >
                          <div class="input-group mb-0">
                            <b-form-textarea
                              id="textarea-state"
                              v-model="contentHTML"
                              :state="contentHTML.length >= 10"
                              placeholder="O que foi feito e qual o proximo passo...?"
                              rows="3"
                            ></b-form-textarea>

                            <span class="input-group-append">
                              <b-button
                                :disabled="statusButton"
                                @click="addNovoHistoricoTicket"
                                variant="info"
                                ><i class="fas fa-save"></i
                              ></b-button>
                            </span>

                            <b-form-invalid-feedback class="mt-0 mb-0">
                              Insira pelo menos 10 letras
                            </b-form-invalid-feedback>
                          </div>

                          <div
                            style="text-align: right"
                            class="mt-0 float-right"
                          >
                            <input
                              ref="files"
                              type="file"
                              class="d-none"
                              accept=".png, .jpg, .jpeg, .doc,.docx,.pdf,.xls,.xlsx"
                              @change="handleFile"
                              multiple
                            />

                            <button
                              type="button"
                              @click="openFileDialog()"
                              class="btn badge bagde-light"
                              style="font-size: 16px"
                            >
                              <i class="fas fa-paperclip text-secondary"></i>
                            </button>

                            <div class="mt-1 mb-0">
                              <p
                                id="ndome-anexo"
                                v-for="(filesAnexo, index) in fileReceipt"
                                :key="index"
                                class="mt-0 mb-0"
                              >
                                {{ filesAnexo.name }}
                                <button
                                  type="button"
                                  @click="apagarAnexo(index)"
                                  class="btn badge bagde-light"
                                >
                                  <i class="fa fa-trash text-danger"></i>
                                </button>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card-body" id="backBody">
                        <div class="timeline timeline-inverse">
                          <div class="time-label">
                            <span class="bg-red">
                              {{ dataAtual | moment }}</span
                            >
                          </div>

                          <div
                            v-for="atendendoHistorico in dadosTicketAtendendoHistorico"
                            :key="
                              atendendoHistorico.pk_id_atendimento_historico
                            "
                          >
                            <i class="fas fa-user bg-green"></i>
                            <div class="timeline-item">
                              <span class="time"
                                ><i class="fas fa-clock"></i>
                                {{
                                  atendendoHistorico.data_hora_cadastro
                                    | momentTime
                                }}</span
                              >
                              <h3 class="timeline-header">
                                <a>{{
                                  atendendoHistorico.nome_user_add_historico
                                }}</a>
                              </h3>

                              <div class="timeline-body">
                                <span
                                  v-html="atendendoHistorico.historico"
                                ></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </b-tab>

          <!--Formulario Local-->

          <b-tab
            v-if="dadosTicketAtendendo.formulario == 'Sim'"
            title="Formulário"
          >
            <section class="content">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-3">
                    <div class="card card-primary card-outline">
                      <div class="card-body box-profile">
                        <p class="mb-5">
                          <router-link :to="'/ticket-recebido'">
                            <b-button
                              type="button"
                              class="float-left mb-2"
                              variant="outline-secondary"
                              size="sm"
                            >
                              <i id="size-ico" class="fas fa-undo"> Voltar</i>
                            </b-button>
                          </router-link>
                        </p>

                        <h3 class="profile-username"><b>Título</b></h3>
                        <p class="text-muted">
                          {{ dadosTicketAtendendo.titulo }}
                        </p>
                        <br />

                        <b>Atividade</b> <br />
                        <p class="text-muted">
                          {{ dadosTicketAtendendo.nome_categora_atendimento }}
                        </p>

                        <b>Solicitante</b> <br />
                        <p class="text-muted">
                          {{ dadosTicketAtendendo.nome_requerente }}
                        </p>

                        <b>Protocolo</b> <br />
                        <p class="text-muted">
                          {{ dadosTicketAtendendo.protocolo }}
                        </p>

                        <b>Status</b> <br />
                        <p class="text-muted">
                          {{ dadosTicketAtendendo.status }}
                        </p>

                        <b>Atribuido</b> <br />
                        <p
                          v-if="dadosTicketAtendendo.nome_destino"
                          class="text-muted"
                        >
                          {{ dadosTicketAtendendo.nome_destino }}
                        </p>
                        <p v-else class="text-muted">Equipe</p>

                        <p>
                          <!--Fazer um V-If status == "Atendimento"-->
                          <b-button
                            v-if="dadosTicketAtendendo.status == 'Atendimento'"
                            class="btn btn-block"
                            variant="secondary"
                            size="sm"
                            @click="showModal"
                            >Transferir</b-button
                          >

                          <b-modal
                            id="transferir"
                            ref="modal-transfer"
                            size="sm"
                            hide-footer
                            title="Transferir Atendimento"
                          >
                            <v-select
                              placeholder="-Selecione Usuário-"
                              v-model="userTransferir"
                              :options="membrosEquipe"
                              label="nome"
                            >
                            </v-select>

                            <b-button
                              class="btn btn-block mt-4"
                              variant="primary"
                              size="sm"
                              :disabled="statusButtonTransfer"
                              @click="hideModal"
                              ><i class="fas fa-exchange-alt"></i>
                            </b-button>
                          </b-modal>

                          <!--Fazer um V-If status == "Aberto" OR status == "Fechado"-->
                          <b-button
                            v-if="
                              dadosTicketAtendendo.status == 'Aberto' ||
                              dadosTicketAtendendo.status == 'Fechado'
                            "
                            class="btn btn-block"
                            variant="secondary"
                            size="sm"
                            @click="atenderTicket"
                            >Atender</b-button
                          >
                        </p>

                        <!--Fazer um V-If status == "Atendimento"-->
                        <b-button
                          v-if="dadosTicketAtendendo.status == 'Atendimento'"
                          :disabled="statusButton"
                          class="btn btn-block mb-3"
                          variant="danger"
                          size="sm"
                          @click="fecharTicket"
                          >Fechar</b-button
                        >

                        <div v-if="anexoChamados['0']">
                          <b-dropdown
                            size="sm"
                            variant="outline-secondary"
                            dropright
                            text="Anexos"
                            class="btn-block"
                          >
                            <div
                              v-for="anexo in anexoChamados"
                              :key="anexo.pk_id_anexo"
                            >
                              <b-dropdown-item-button
                                @click="
                                  downloadAnexo(anexo.pk_id_anexo, anexo.nome)
                                "
                              >
                                <i class="fas fa-file-download"></i>
                                <span id="nome-anexo">
                                  <span id="data-anexo">
                                    {{
                                      anexo.data_hora_cadastro | momentTimeAnexo
                                    }}</span
                                  >
                                  - {{ anexo.nome }}</span
                                >
                              </b-dropdown-item-button>
                            </div>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-9">
                    <div class="card card-primary card-outline">
                      <div class="card-header">
                        <h3 class="card-title">
                          <b>Solicitação de Pessoal</b>
                        </h3>
                      </div>
                      <!-- /.card-header -->
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-6">
                            <!-- text input -->
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-building"></i> DIVISÃO:</label
                              >
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_divisao
                                "
                                disabled
                                type="text"
                                class="form-control form-control-sm"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-calendar-alt"></i> DATA DE
                                ADMISSÃO:</label
                              >
                              <input
                                v-model="
                                  dataAdmissao
                                "
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="Data de admissão"
                                disabled=""
                              />
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-sm-6">
                            <!-- text input -->
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-portrait"></i> CARGO:</label
                              >
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_cargo
                                "
                                type="text"
                                class="form-control form-control-sm"
                                disabled=""
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-user-shield"></i>
                                LIDER:</label
                              >
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_nome_lider
                                "
                                type="text"
                                class="form-control form-control-sm"
                                disabled=""
                              />
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-user-tie"></i> GESTOR:</label
                              >
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_nome_gestor
                                "
                                type="text"
                                class="form-control form-control-sm"
                                disabled=""
                              />
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-coins"></i> C.CUSTO:</label
                              >
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_centro_custo
                                "
                                type="text"
                                class="form-control form-control-sm"
                                disabled=""
                              />
                            </div>
                          </div>
                        </div>

                        <div class="tab-custom-content mb-4"></div>

                        <div class="form-group row">
                          <label
                            id="label-style"
                            for="razao-social"
                            class="col-sm-2 col-form-label"
                            >R. Social:</label
                          >
                          <div class="col-sm-10">
                            <input
                              v-model="
                                dadosTicketAtendendo.solicitacao_pessoal_razao_social
                              "
                              type="text"
                              class="form-control form-control-sm"
                              id="razao-social"
                              disabled=""
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            id="label-style"
                            for="endereco"
                            class="col-sm-2 col-form-label"
                            >Endereço:</label
                          >
                          <div class="col-sm-10">
                            <input
                              v-model="
                                dadosTicketAtendendo.solicitacao_pessoal_endereco
                              "
                              type="text"
                              class="form-control form-control-sm"
                              id="endereco"
                              disabled=""
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            id="label-style"
                            for="bairro"
                            class="col-sm-2 col-form-label"
                            >Bairro:</label
                          >
                          <div class="col-sm-10">
                            <input
                              v-model="
                                dadosTicketAtendendo.solicitacao_pessoal_bairro
                              "
                              type="text"
                              class="form-control form-control-sm"
                              id="bairro"
                              disabled=""
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            id="label-style"
                            for="escala"
                            class="col-sm-2 col-form-label"
                            >Escala:</label
                          >
                          <div class="col-sm-10">
                            <input
                              v-model="
                                dadosTicketAtendendo.solicitacao_pessoal_escala
                              "
                              type="text"
                              class="form-control form-control-sm"
                              id="escala"
                              disabled=""
                            />
                          </div>
                        </div>

                        <div class="tab-custom-content mb-4 mt-4"></div>
                      </div>

                      <div class="card-header">
                        <h3 class="card-title"><b>Perfil de Cargo</b></h3>
                      </div>
                      <div class="card-body">
                        <!-- input states -->

                        <div class="row">
                          <div class="col-sm-4">
                            <!-- textarea -->
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-bookmark"></i>
                                CONHECIMENTOS</label
                              >
                              <textarea
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_conhecimento
                                "
                                class="form-control"
                                rows="3"
                                disabled="Conhecimento ..."
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-bookmark"></i>
                                HABILIDADES</label
                              >
                              <textarea
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_habilidade
                                "
                                class="form-control"
                                rows="3"
                                disabled="Habilidades ..."
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-bookmark"></i> ATITUDES</label
                              >
                              <textarea
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_atitude
                                "
                                class="form-control"
                                rows="3"
                                disabled="Atitudes ..."
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-sm-12">
                            <!-- textarea -->
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fab fa-black-tie"></i> REQUISITOS DE
                                EXPERIÊNCIA PROFISSIONAL</label
                              >
                              <textarea
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_requisito_experiencia
                                "
                                class="form-control"
                                rows="3"
                                disabled=""
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-sm-6">
                            <!-- text input -->
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-user-clock"></i> IDADE
                            REFERENCIAL:</label
                              >
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_idade_referencial
                                "
                                type="text"
                                class="form-control form-control-sm"
                                disabled=""
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-venus-mars"></i>
                                GENERO:</label
                              >
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_genero
                                "
                                type="text"
                                class="form-control form-control-sm"
                                disabled=""
                              />
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-adjust"></i> TURNO:</label
                              >
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_turno
                                "
                                type="text"
                                class="form-control form-control-sm"
                                disabled=""
                              />
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-asterisk"></i> MOTIVO:</label
                              >
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_motivo
                                "
                                type="text"
                                class="form-control form-control-sm"
                                disabled=""
                              />
                            </div>
                          </div>
                        </div>

                        <div class="tab-custom-content mb-4 mt-4"></div>

                        <div class="row">
                          <div class="col-sm-12">
                            <!-- textarea -->
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-money-check-alt"></i> SALÁRIO
                                E BENEFÍCIOS</label
                              >
                              <textarea
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_salario_beneficio
                                "
                                class="form-control"
                                rows="3"
                                disabled=""
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div class="tab-custom-content mb-4 mt-4"></div>

                        <div class="row">
                          <div class="col-sm-12">
                            <!-- textarea -->
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-file-alt"></i> DESCRIÇÃO DE
                                ATIVIDADES (CONFORME IT - CENTRO
                                RECRUTAMENTO)</label
                              >
                              <textarea
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_salario_descricao_atividade
                                "
                                class="form-control"
                                rows="3"
                                disabled=""
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div class="tab-custom-content mb-4 mt-4"></div>

                        <div class="row">
                          <div class="col-sm-12">
                            <!-- textarea -->
                            <div class="form-group">
                              <label id="label-style"
                                ><i class="fas fa-file-signature"></i>
                                OBSERVAÇÕES:</label
                              >
                              <textarea
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_observacao
                                "
                                class="form-control"
                                rows="3"
                                disabled=""
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--
                      <b-card header="Form Data Result">
                        <pre class="m-0">{{ dadosTicketAtendendo }}</pre>
                      </b-card>
                      -->
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </b-tab>

          <b-tab title="Instruções de trabalho">
            <div class="card-body">
              <b-container fluid>
                <b-row>
                  <b-input-group class="mt-0 mb-1">
                    <b-input-group-append>
                      <b-form-input
                        size="sm"
                        id="filter-input"
                        v-model="configTableItVinculada.filter"
                        type="search"
                        placeholder="Pesquisar..."
                      ></b-form-input>
                    </b-input-group-append>
                  </b-input-group>

                  <b-table
                    :busy="isBusy"
                    :striped="true"
                    :outlined="true"
                    :hover="true"
                    :bordered="false"
                    :borderless="false"
                    :noCollapse="false"
                    :dark="false"
                    :fixed="false"
                    :small="true"
                    :footClone="true"
                    head-variant="dark"
                    :items="itemsItVinculada"
                    :fields="fieldsItVinculada"
                    :current-page="configTableItVinculada.currentPage"
                    :per-page="configTableItVinculada.perPage"
                    :filter="configTableItVinculada.filter"
                    :filter-included-fields="configTableItVinculada.filterOn"
                    :sort-by.sync="configTableItVinculada.sortBy"
                    :sort-desc.sync="configTableItVinculada.sortDesc"
                    :sort-direction="configTableItVinculada.sortDirection"
                    stacked="md"
                    show-empty
                  >
                    <template #empty>
                      <h5 class="text-center">
                        {{ tableItVinculada.emptyTexts }}
                      </h5>
                    </template>

                    <template #emptyfiltered>
                      <h5 class="text-center">
                        {{ tableItVinculada.emptyFilteredTexts }}
                      </h5>
                    </template>

                    <template #table-busy>
                      <div class="text-center text-danger my-3">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Carregando...</strong>
                      </div>
                    </template>


                    <template #cell(titulo)="row">
                      <router-link
                        :to="
                          '/instrucao-trabalho?id=' +
                          row.item.pk_id_instrucao_trabalho
                        "
                        target="_blank"
                      >
                        <div>
                          <span><i class="fas fa-edit text-muted"></i></span>
                          {{ row.item.titulo }}
                        </div>
                      </router-link>
                    </template>

                    <template #cell(tipo)="row">
                      <div>
                        <span
                          ><i class="fas fa-edit small text-muted"></i
                        ></span>
                        {{ row.item.tipo }}
                      </div>
                    </template>

                    <template #cell(nome)="row">
                      <div>
                        <span><i class="far fa-user text-muted"></i></span>
                        {{ row.item.nome }}
                      </div>
                    </template>

                    <template #cell(nome_equipe)="row">
                      <div>
                        <span><i class="fas fa-user-tie text-muted"></i></span>
                        {{ row.item.nome_equipe }}
                      </div>
                    </template>

                    <template #cell(data_hora)="row">
                      <div class="small">
                        <span><i class="fas fa-history text-muted"></i></span>
                        {{ row.item.data_hora | momentIt }}
                      </div>
                    </template>
                  </b-table>

                  <b-tfoot>
                    <div class="text-muted">
                      <div class="small">
                        <span><i class="fas fa-list-ol"></i></span> Total de
                        registros:
                        <b>{{ itemsItVinculada.length }}</b>
                      </div>
                    </div>
                  </b-tfoot>

                  <b-col sm="5" md="0" class="my-1">
                    <b-form-group
                      label="Quantidade"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="8"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="configTableItVinculada.perPage"
                        :options="configTableItVinculada.pageOptions"
                        size="sm"
                      >
                      </b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col sm="5" md="4" class="my-1">
                    <b-pagination
                      v-model="configTableItVinculada.currentPage"
                      :total-rows="itVinculadaRows"
                      :per-page="configTableItVinculada.perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-overlay>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "SolicitacaoAtendimento",

  data() {
    return {
      /////////////////////////<====Tabela Ticket Aberto====>///////////////////////////////////////////
      isBusy: true,

      dataAdmissao:"",

      itemsItVinculada: [],

      tableItVinculada: {
        emptyTexts: "Não há instrução de trabalho vinculado a esta atividade",
        emptyFilteredTexts:
          "Não há instrução de trabalho que correspondam à sua pesquisa",
      },

      fieldsItVinculada: [
        {
          key: "titulo",
          label: "Título",
          sortable: true,
        },

        {
          key: "tipo",
          label: "Tipo",
          sortable: true,
        },

        {
          key: "nome",
          label: "Criador",
          sortable: true,
        },

        {
          key: "nome_equipe",
          label: "Equipe",
          sortable: true,
        },

        {
          key: "data_hora",
          label: "Data Criação",
          sortable: true,
        },
      ],

      configTableItVinculada: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
      /////////////////////////<====Tabela Ticket Aberto====>///////////////////////////////////////////

      contentHTML: "",
      dataAtual: new Date(),
      overlayShow: true,

      dadosTicketAtendendo: [],
      dadosTicketAtendendoHistorico: [],
      membrosEquipe: [],

      anexoChamados: [],

      fileReceipt: [],

      userTransferir: "",
    };
  },

  computed: {
    itVinculadaRows() {
      return this.itemsItVinculada.length;
    },

    statusButton() {
      return this.contentHTML.length > 9 ? false : true;
    },

    statusButtonTransfer() {
      return this.userTransferir != null && this.userTransferir != ""
        ? false
        : true;
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("MMM Do YY");
    },

    momentTime: function (date) {
      return moment(date).format("LLL");
    },

    momentTimeAnexo: function (date) {
      return moment(date).format("lll");
    },

    momentIt: function (date) {
      return moment(date).format("L - LTS");
    },
  },

  mounted() {
    this.getTicket();
    this.getIt();
  },

  methods: {
    async getTicket() {
      try {
        const equipe = JSON.parse(localStorage.getItem("equipeUser"));
        const equipeUser = equipe;

        const response = await axios.post("chamado/getTicket", {
          idTicket: this.$route.query.id,
          dadosEquipe: equipeUser,
        });

        this.dadosTicketAtendendo = response.data.ticketAtendendo.original;
        this.dadosTicketAtendendoHistorico =
          response.data.ticketAtendendoHistorico.original.ticketAtendendoHistorico;
        this.membrosEquipe = response.data.membrosEquipe.original;

        this.anexoChamados =
          response.data.ticketAtendendoHistorico.original.anexo;

        if(this.dadosTicketAtendendo.solicitacao_pessoal_data_admissao == "" || this.dadosTicketAtendendo.solicitacao_pessoal_data_admissao == null){
          this.dataAdmissao = ""
        }else{
          this.dataAdmissao = moment(this.dadosTicketAtendendo.solicitacao_pessoal_data_admissao).format('LLL')
        }

        this.overlayShow = false;
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async getIt() {
      this.isBusy = true;
      try {
        const equipe = JSON.parse(localStorage.getItem("equipeUser"));
        const equipeUser = equipe;

        const response = await axios.post("instrucaoTrabalho/getItVinculada", {
          idTicket: this.$route.query.id,
          dadosEquipe: equipeUser,
        });

        var itEquipeArray = response.data.equipe.flat();

        var itGeralArray = response.data.geral;

        this.itemsItVinculada = itEquipeArray.concat(itGeralArray);

        //console.log(this.itemsItVinculada);

        this.isBusy = false;
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async atenderTicket() {
      this.overlayShow = true;

      try {
        const perfil = JSON.parse(localStorage.getItem("storageData"));

        const response = await axios.post("chamado/atenderTicket", {
          idTicket: this.$route.query.id,
          idUsuario: perfil.pk_id_usuario,
          nomeAtendente: perfil.nome,
        });

        this.getTicket();

        this.overlayShow = false;

        //alert(response.data);
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            this.overlayShow = false;
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            this.overlayShow = false;
            break;
        }
      }
    },

    async fecharTicket() {
      this.overlayShow = true;

      try {
        const perfil = JSON.parse(localStorage.getItem("storageData"));

        const response = await axios.post("chamado/fecharTicket", {
          idTicket: this.$route.query.id,
          contentTextArea: this.contentHTML,
          idUsuario: perfil.pk_id_usuario,
        });

        alert(response.data);

        this.contentHTML = "";

        this.getTicket();

        this.overlayShow = false;

        console.log(response.data);
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            this.overlayShow = false;
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            this.overlayShow = false;
            break;
        }
      }
    },

    async transferirTicket() {
      this.overlayShow = true;

      try {
        const perfil = JSON.parse(localStorage.getItem("storageData"));

        const response = await axios.post("chamado/transferirTicket", {
          idTicket: this.$route.query.id,
          dadosUserDestino: this.userTransferir,
          idUsuario: perfil.pk_id_usuario,
          userOrigen: this.dadosTicketAtendendo.nome_destino,
        });

        //alert(response.data);

        this.getTicket();

        this.overlayShow = false;
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            this.overlayShow = false;
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            this.overlayShow = false;
            break;
        }
      }
    },

    async addNovoHistoricoTicket() {
      this.overlayShow = true;

      var self = this;

      const perfil = JSON.parse(localStorage.getItem("storageData"));

      let formData = new FormData();
      for (let i = 0; i < this.fileReceipt.length; i++) {
        let file = self.fileReceipt[i];
        formData.append("files[" + i + "]", file);
      }

      formData.append("idTicket", this.$route.query.id);
      formData.append("contentTextArea", this.contentHTML);
      formData.append("idUsuario", perfil.pk_id_usuario);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        //Requisição atualizar dados
        await axios
          .post("chamado/addNovoHistoricoTicket", formData, config)
          .then((response) => {
            // console.log(response.data);

            self.$refs.files.value = "";
            self.fileReceipt = [];

            this.contentHTML = "";

            this.getTicket();

            this.overlayShow = false;
          });
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            this.overlayShow = false;
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            this.overlayShow = false;
            break;
        }
      }
    },

    showModal() {
      this.$refs["modal-transfer"].show();
    },

    hideModal() {
      this.transferirTicket();

      this.$refs["modal-transfer"].hide();
    },

    async downloadAnexo(idAnexo, nomeAnexo) {
      await axios
        .get(`anexo/downloadAnexoChamado/${idAnexo}`, { responseType: "blob" })
        .then((response) => {
          //console.log(response.data);
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var filelink = document.createElement("a");
          filelink.href = fileURL;
          filelink.setAttribute("download", nomeAnexo);
          document.body.appendChild(filelink);
          filelink.click();
        });
    },

    openFileDialog() {
      this.$refs.files.value = null;
      this.$refs.files.click();
    },

    apagarAnexo(e) {
      this.fileReceipt.splice(e, 1);
    },

    handleFile() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.fileReceipt.push(this.$refs.files.files[i]);

        //console.log(this.fileReceipt);
      }
    },
  },
};
</script>


<style scoped>
#nome-anexo {
  font-size: 12px;
  font-style: italic;
  color: dimgray;
}

#data-anexo {
  font-style: normal;
  font-weight: bold;
  color: black;
}

#descricao {
  background-color: #f8f8fa;
  padding: 10px 20px 1px 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

#backBody {
  background-color: #343a40;
}

#back-descricao {
  background-color: #e7e7e7;
}

/* #size-ico{
  font-size:16px;
  
} */
</style>