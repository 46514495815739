<template>
<div>
    <div class="container-fluid">
        <div class="row no-gutter">
            <!-- The image half -->
            <div class="col-md-6 d-none d-md-flex bg-image"></div>


            <!-- The content half -->
            <div class="col-md-6 bg-light">
                <div class="login d-flex align-items-center py-5">

                    <!-- Demo content-->
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 col-xl-7 mx-auto">
                                <h3 class="display-4">Conecta-STV</h3>
                                <p class="text-muted mb-4">Recupere sua senha.</p>

                                <form @submit.prevent="handleSubmit">

                                    <ErrorComponents v-if="error" :errorComponents="error"/>
                                    <CorrectComponents v-if="correct" :correctComponents="correct"/>

                                    <div class="form-group my-4">
                                        <input name="cpf" id="cpf" type="text" placeholder="Digite seu cpf!" v-model.trim="cpf" v-mask="'###.###.###-##'" required autofocus class="form-control rounded-pill border-1 shadow px-4">
                                    </div>
                                    <button type="submit" class="btn btn-primary btn-block text-uppercase mb-4 rounded-pill shadow-sm">Enviar</button>
                             

                                    <a href="/login" class="font-italic text-muted"><u>Login...</u></a>
                                    <p class="login-card-footer-text">Como recuperar sua senha? <a href="#" class="font-italic text-muted" v-b-modal.modal-2> <u>Clique aqui</u></a></p>
                                </form>

                            </div>
                        </div>
                    </div><!-- End -->

                </div>
            </div><!-- End -->

        </div>  
    </div>

    <!-- Modal -->
    <b-modal id="modal-2" title="RECUPERAR SENHA!" ok-only>
        <p>
            Para recuperar sua senha, insira seu <b>"CPF"</b> no campo indicado e click em <b>"Enviar"</b>. 
        </p>

        <p>
            Após este procedimento sera enviado uma nova senha provissoria para seu <b>e-mail...</b> 
        </p>             
    </b-modal>

</div>
</template>

<script>
const axios = require('axios').default
import ErrorComponents from './Components/ErrorComponents.vue'
import CorrectComponents from './Components/CorrectComponents.vue'
import LoginLayoutComponents from './ComponentsLayout/LoginLayoutComponents.vue'

export default {
    name: 'FormRecoverPassword',

    data() {
        return {
            cpf: '',
            error: '',
            correct: '',
        }
    },

    methods: {
        async handleSubmit(){
            try {
                //Requisição de login
                const response = await axios.post('auth/recoverPassword',{
                    cpf: this.cpf,
                });

                this.correct = response.data.message
                this.error = ''

            } catch (error) {
                //console.log(error.response.status)

                const erroHttp = error.response.status;
                switch (erroHttp) {
                case 401:
                    this.error = ('CPF não encontrado! - [Erro] '+error.response.status);
                    this.correct = '';
                    break;
                case 404:
                    this.error = ('Pagina não encontrada na Api! - [Erro] '+error.response.status);
                    this.correct = '';
                    break;
                }

            }   
        },
    },

    components: {
        LoginLayoutComponents,
        ErrorComponents,
        CorrectComponents
    }
}
</script>

<style>

</style>