<template>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Controle de acesso</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item active">Controle de acesso</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary card-outline">
              <div class="card-header">
                <div class="card-title mb-0">
                  <b>
                    <u>{{ unidadeDados.nome }}</u></b
                  ><br /><br />
                  <ul class="ml-4 mb-0 fa-ul text-muted">
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-phone"></i
                      ></span>
                      Telefone: {{ unidadeDados.telefone }}
                    </li>
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-building"></i
                      ></span>
                      Endereço: {{ unidadeDados.endereco }}
                    </li>
                  </ul>
                </div>

                <div class="float-right" style="padding: 0px 0px 0px 10px">
                  <router-link :to="'/ramais-unidade?id=' + idUnidade">
                    <button class="btn-sm btn-outline-secondary">
                      <i class="fas fa-phone-alt"></i> Ramais
                    </button>
                  </router-link>
                </div>

                <div class="float-right" style="padding: 0px 0px 0px 10px">
                  <router-link
                    :to="'/controle-acesso-historico?id=' + idUnidade"
                  >
                    <button class="btn-sm btn-outline-secondary">
                      <i class="fas fa-history"></i> Histórico
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="card card-primary">
              <div class="card-header">
                <h3 id="header-h3" class="card-title mb-0">
                  Cadastrar visitante
                </h3>
              </div>

              <div class="card-body">
                <div class="form-group row text-center">
                  <div class="col-sm-12 mr">
                    <b-form-radio-group
                      id="btn-radios-2"
                      v-model="selectedTipoVisitante"
                      :options="optionsVisitante"
                      button-variant="outline-secondary"
                      name="radio-btn-outline"
                      buttons
                      size="sm"
                    ></b-form-radio-group>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <label id="label-decoration" class="mb-0" for="doc"
                      >Documento</label
                    ><br />
                    <div class="input-group mb-0">
                      <input
                        type="text"
                        name="doc"
                        @blur="
                          completeDoc([
                            usuarioVisitante.doc,
                            selectedTipoVisitante,
                          ])
                        "
                        v-mask="'####################'"
                        class="form-control form-control-sm"
                        v-model.trim="usuarioVisitante.doc"
                        id="doc"
                        placeholder="Pesquisar...."
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-id-card-alt"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <label id="label-decoration" class="mb-0" for="cpf"
                      >CPF</label
                    ><br />
                    <div class="input-group mb-0">
                      <input
                        type="text"
                        name="cpf"
                        class="form-control form-control-sm"
                        v-mask="'###.###.###-##'"
                        v-model.trim="usuarioVisitante.cpf"
                        id="cpf"
                        placeholder="CPF"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-id-card"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <label id="label-decoration" class="mb-0" for="rg">RG</label
                    ><br />
                    <div class="input-group mb-0">
                      <input
                        type="text"
                        name="rg"
                        class="form-control form-control-sm"
                        v-mask="'####################'"
                        v-model.trim="usuarioVisitante.rg"
                        id="rg"
                        placeholder="RG"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="far fa-id-card"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <label id="label-decoration" class="mb-0" for="matricula"
                      >Matrícula</label
                    ><br />
                    <div class="input-group mb-0">
                      <input
                        type="text"
                        name="matricula"
                        class="form-control form-control-sm"
                        v-model.trim="usuarioVisitante.matricula"
                        id="matricula"
                        placeholder="Matricula"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-id-badge"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <label id="label-decoration" class="mb-0" for="nome"
                      >Nome</label
                    ><br />
                    <div class="input-group mb-0">
                      <input
                        type="text"
                        name="nome"
                        class="form-control form-control-sm"
                        v-model.trim="usuarioVisitante.nome"
                        id="nome"
                        required
                        placeholder="Nome"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-user-alt"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <label id="label-decoration" class="mb-0" for="telefone"
                      >Telefone</label
                    ><br />
                    <div class="input-group mb-0">
                      <input
                        type="text"
                        name="telefone"
                        class="form-control form-control-sm"
                        v-mask="'(##) #####-####'"
                        v-model.trim="usuarioVisitante.telefone"
                        id="telefone"
                        placeholder="Telefone"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-phone"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <label id="label-decoration" class="mb-0" for="empresa"
                      >Empresa</label
                    ><br />
                    <div class="input-group mb-0">
                      <input
                        type="text"
                        name="empresa"
                        class="form-control form-control-sm"
                        v-model.trim="usuarioVisitante.empresa"
                        id="empresa"
                        placeholder="Empresa"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-building"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-12">
                    <label id="label-decoration" class="mb-0" for="nome-destino"
                      >Destino</label
                    >
                    <div>
                      <AutocompleteComponents
                        v-model="userSolicitante"
                        :options="usersSolicitante"
                        label-key="nome"
                        value-key="pk_id_usuario"
                        placeholder="Destinatário..."
                        @shouldSearch="pesquisarUsuarios"
                        @select="onSelect"
                      />
                    </div>
                  </div>
                </div>

                <div class="mt-4">
                  <b-button
                    :disabled="statusButton"
                    @click="onSubmit"
                    variant="primary"
                    size="sm"
                    class="float-right"
                    ><i class="fas fa-save"></i> Salvar</b-button
                  >
                  <b-button
                    type="reset"
                    @click="onReset"
                    variant="secondary"
                    size="sm"
                    ><i class="fas fa-times-circle"></i> Reset</b-button
                  >
                </div>
                
              </div>
            </div>
          </div>

          <div class="col-md-8">
            <div class="card card-secondary">
              <div class="card-header mb-2">
                <h3 id="header-h3" class="card-title mb-0">
                  Lista de Visitantes
                </h3>
              </div>

              <b-tabs
                active-nav-item-class="font-weight-bold text-uppercase"
                content-class="mt-3"
                fill
                align="center"
              >
                <!----Tabela Entrada---->
                <b-tab title="Entrada" active>
                  <b-container fluid>
                    <b-row>
                      <b-input-group class="mt-2 mb-2 ml-2">
                        <b-input-group-append>
                          <b-form-input
                            size="sm"
                            id="filter-input"
                            v-model="configTableVisitante.filter"
                            type="search"
                            placeholder="Pesquisar..."
                          ></b-form-input>
                        </b-input-group-append>
                      </b-input-group>

                      <b-table
                        :busy="isBusy"
                        striped
                        outlined
                        hover
                        bordered
                        :borderless="false"
                        :noCollapse="false"
                        :dark="false"
                        :fixed="false"
                        :small="true"
                        :footClone="true"
                        head-variant="dark"
                        :items="itemsVisitante"
                        :fields="fieldsVisitante"
                        :current-page="configTableVisitante.currentPage"
                        :per-page="configTableVisitante.perPage"
                        :filter="configTableVisitante.filter"
                        :filter-included-fields="configTableVisitante.filterOn"
                        :sort-by.sync="configTableVisitante.sortBy"
                        :sort-desc.sync="configTableVisitante.sortDesc"
                        :sort-direction="configTableVisitante.sortDirection"
                        stacked="md"
                        show-empty
                      >
                        <template #empty>
                          <h5 class="text-center">
                            {{ tableVisitante.emptyTexts }}
                          </h5>
                        </template>

                        <template #emptyfiltered>
                          <h5 class="text-center">
                            {{ tableVisitante.emptyFilteredTexts }}
                          </h5>
                        </template>

                        <template #table-busy>
                          <div class="text-center text-danger my-3">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong> Carregando...</strong>
                          </div>
                        </template>

                        <!-- A custom formatted header cell for field 'name' -->
                        <template #head(data_hora_entrada)="data">
                          <span class="text-info">{{ data.label }}</span>
                        </template>

                        <template #cell(nome_visitante)="row">
                          <div class="user-panel pb-1 text-center">
                            <div
                              v-if="row.item.foto_visitante"
                              class="image mr-2"
                            >
                              <img
                                :src="
                                  require(`../../../assets/img/foto_visitante/${row.item.foto_visitante}`)
                                "
                                class="img-circle elevation-1"
                                alt="User Image"
                              />
                            </div>

                            <div v-else class="image mr-2">
                              <img
                                :src="
                                  require(`../../../assets/adminLTE/dist/img/user2-160x160.jpg`)
                                "
                                class="img-circle elevation-1"
                                alt="User Image"
                              />
                            </div>

                            <div class="small">
                              {{ row.item.nome_visitante }}
                            </div>
                          </div>
                        </template>

                        <template #cell(empresa)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-building text-muted"></i
                            ></span>
                            {{ row.item.empresa }}
                          </div>
                        </template>

                        <template #cell(matricula)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-id-badge text-muted"></i
                            ></span>
                            {{ row.item.matricula }}
                          </div>
                        </template>

                        <template #cell(nome_destino)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-door-open text-muted"></i
                            ></span>
                            {{ row.item.nome_destino }}
                          </div>
                        </template>

                        <template #cell(data_hora_entrada)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-clock text-muted"></i
                            ></span>
                            <b class="text-info">
                              {{ row.item.data_hora_entrada | moment }}</b
                            >
                          </div>
                        </template>

                        <template #cell(actions)="row">
                          <div class="text-center">
                            <b-button
                              variant="danger"
                              @click="
                                saidaVisitante(row.item.pk_id_controle_acesso)
                              "
                            >
                              <i class="fas fa-sign-out-alt"></i>
                              Sair!
                            </b-button>
                          </div>
                        </template>
                      </b-table>

                      <b-tfoot>
                        <div class="text-muted">
                          <div class="small">
                            <span><i class="fas fa-list-ol"></i></span> Total de
                            registros: <b>{{ itemsVisitante.length }}</b>
                          </div>
                        </div>
                      </b-tfoot>

                      <b-col sm="5" md="0" class="my-1">
                        <b-form-group
                          label="Quantidade"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="8"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="configTableVisitante.perPage"
                            :options="configTableVisitante.pageOptions"
                            size="sm"
                          >
                          </b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col sm="5" md="4" class="my-1">
                        <b-pagination
                          v-model="configTableVisitante.currentPage"
                          :total-rows="visitanteRows"
                          :per-page="configTableVisitante.perPage"
                          align="fill"
                          size="sm"
                          class="my-0"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-tab>

                <!----Tabela Saida---->
                <b-tab title="Saída">
                  <b-container fluid>
                    <b-row>
                      <b-input-group class="mt-2 mb-2 ml-2">
                        <b-input-group-append>
                          <b-form-input
                            size="sm"
                            id="filter-input"
                            v-model="configTableVisitanteSaida.filter"
                            type="search"
                            placeholder="Pesquisar..."
                          ></b-form-input>
                        </b-input-group-append>
                      </b-input-group>

                      <b-table
                        striped
                        outlined
                        hover
                        bordered
                        :borderless="false"
                        :noCollapse="false"
                        :dark="false"
                        :fixed="false"
                        :small="true"
                        :footClone="true"
                        head-variant="dark"
                        :items="itemsVisitanteSaida"
                        :fields="fieldsVisitanteSaida"
                        :current-page="configTableVisitanteSaida.currentPage"
                        :per-page="configTableVisitanteSaida.perPage"
                        :filter="configTableVisitanteSaida.filter"
                        :filter-included-fields="
                          configTableVisitanteSaida.filterOn
                        "
                        :sort-by.sync="configTableVisitanteSaida.sortBy"
                        :sort-desc.sync="configTableVisitanteSaida.sortDesc"
                        :sort-direction="
                          configTableVisitanteSaida.sortDirection
                        "
                        stacked="md"
                        show-empty
                      >
                        <template #empty>
                          <h5 class="text-center">
                            {{ tableVisitanteSaida.emptyTexts }}
                          </h5>
                        </template>

                        <template #emptyfiltered>
                          <h5 class="text-center">
                            {{ tableVisitanteSaida.emptyFilteredTexts }}
                          </h5>
                        </template>

                        <!-- A custom formatted header cell for field 'name' -->
                        <template #head(data_hora_saida)="data">
                          <span class="text-danger">{{ data.label }}</span>
                        </template>

                        <template #cell(nome_visitante)="row">
                          <div class="user-panel pb-1 text-center">
                            <div
                              v-if="row.item.foto_visitante"
                              class="image mr-2"
                            >
                              <img
                                :src="
                                  require(`../../../assets/img/foto_visitante/${row.item.foto_visitante}`)
                                "
                                class="img-circle elevation-1"
                                alt="User Image"
                              />
                            </div>

                            <div v-else class="image mr-2">
                              <img
                                :src="
                                  require(`../../../assets/adminLTE/dist/img/user2-160x160.jpg`)
                                "
                                class="img-circle elevation-1"
                                alt="User Image"
                              />
                            </div>

                            <div class="small ml-2">
                              {{ row.item.nome_visitante }}
                            </div>
                          </div>
                        </template>

                        <template #cell(empresa)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-building text-muted"></i
                            ></span>
                            {{ row.item.empresa }}
                          </div>
                        </template>

                        <template #cell(matricula)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-id-badge text-muted"></i
                            ></span>
                            {{ row.item.matricula }}
                          </div>
                        </template>

                        <template #cell(nome_destino)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-door-open text-muted"></i
                            ></span>
                            {{ row.item.nome_destino }}
                          </div>
                        </template>

                        <template #cell(data_hora_saida)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-clock text-muted"></i
                            ></span>
                            <b class="text-danger"> {{ row.item.data_hora_saida | moment }}</b>
                          </div>
                        </template>
                      </b-table>

                      <b-tfoot>
                        <div class="text-muted">
                          <div class="small">
                            <span><i class="fas fa-list-ol"></i></span> Total de
                            registros: <b>{{ itemsVisitanteSaida.length }}</b>
                          </div>
                        </div>
                      </b-tfoot>

                      <b-col sm="5" md="0" class="my-1">
                        <b-form-group
                          label="Quantidade"
                          label-for="per-page-select"
                          label-cols-sm="6"
                          label-cols-md="4"
                          label-cols-lg="8"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                          <b-form-select
                            id="per-page-select"
                            v-model="configTableVisitanteSaida.perPage"
                            :options="configTableVisitanteSaida.pageOptions"
                            size="sm"
                          >
                          </b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col sm="5" md="4" class="my-1">
                        <b-pagination
                          v-model="configTableVisitanteSaida.currentPage"
                          :total-rows="visitanteSaidaRows"
                          :per-page="configTableVisitanteSaida.perPage"
                          align="fill"
                          size="sm"
                          class="my-0"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
var moment = require("moment");
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\
moment.locale("pt-br");

export default {
  name: "ControleAcessoFormulario",

  data() {
    return {
      isBusy: true,

      userSolicitante: "", //===>Autocomplete<===\\
      usersSolicitante: [], //===>Autocomplete<===\\
      userSelectSolicitante: "", //===>Autocomplete<===\\

      unidadeDados: {
        nome: "",
        telefone: "",
        endereco: "",
      },

      usuarioVisitante: {
        doc: "",
        cpf: "",
        rg: "",
        matricula: "",
        nome: "",
        telefone: "",
        empresa: "",
        destino: "",
        tipo: "",
        idVisitante: "",
      },

      idUnidade: this.$route.query.id,
      statusButton: false,

      selectedTipoVisitante: "Visitante",
      optionsVisitante: [
        { text: "Visitante", value: "Visitante" },
        { text: "Funcionário", value: "Funcionario" },
      ],

      /////////////////////////<====TABELA DE VISITANTE ENTRADA====>///////////////////////////////////////////
      itemsVisitante: [],

      tableVisitante: {
        emptyTexts: "Não há usuarios cadastrado!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsVisitante: [
        {
          key: "nome_visitante",
          label: "Nome",
          sortable: true,
        },

        {
          key: "empresa",
          label: "Empresa",
          sortable: true,
        },

        {
          key: "matricula",
          label: "Matricula",
          sortable: true,
        },

        {
          key: "nome_destino",
          label: "Destino",
          sortable: true,
        },

        {
          key: "data_hora_entrada",
          label: "Entrada",
          sortable: true,
        },

        {
          key: "actions",
          label: "Ação",
        },
      ],

      configTableVisitante: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },

      /////////////////////////<====TABELA DE VISITANTE SAIDA====>///////////////////////////////////////////

      itemsVisitanteSaida: [],

      tableVisitanteSaida: {
        emptyTexts: "Não há usuarios cadastrado!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsVisitanteSaida: [
        {
          key: "nome_visitante",
          label: "Nome",
          sortable: true,
        },

        {
          key: "empresa",
          label: "Empresa",
          sortable: true,
        },

        {
          key: "matricula",
          label: "Matricula",
          sortable: true,
        },

        {
          key: "nome_destino",
          label: "Destino",
          sortable: true,
        },

        {
          key: "data_hora_saida",
          label: "Saída",
          sortable: true,
        },
      ],

      configTableVisitanteSaida: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
    };
  },

  /////////Autocomplete//////////
  components: {
    AutocompleteComponents,
  },
  /////////Autocomplete//////////

  computed: {
    visitanteRows() {
      return this.itemsVisitante.length;
    },

    visitanteSaidaRows() {
      return this.itemsVisitanteSaida.length;
    },
  },

  mounted() {
    this.dadosVisitantesUnidade();
  },

  filters: {
    moment: function (date) {
      return moment(date).format("LT - L");
    },
  },

  methods: {
    pesquisarUsuarios(query) {
      //===>Autocomplete<===\\

      // fetch(`http://127.0.0.1:8000/api/autocomplete/userAutocomplete/${query}`)
      // .then((response) => response.json())
      // .then((r) => {
      //   this.usersSolicitante = r;
      // });

      // fetch(`http://conecta.projetos.com.br/api/autocomplete/userAutocomplete/${query}`)
      // .then(response => response.json())
      // .then((r) => {
      //   this.usersSolicitante = r;
      // });

      axios.get(`autocomplete/userAutocomplete/${query}`).then(response => {
        this.usersSolicitante = response.data;
      })
    },

    async completeDoc(doc) {
      try {
        const response = await axios.post("unidade/usuarioVisitante", {
          documento: doc["0"],
          tipoVisitante: doc["1"],
        });

        if (response.data.dadoUser) {
          this.usuarioVisitante.cpf = response.data.dadoUser["cpf"];
          this.usuarioVisitante.rg = response.data.dadoUser["rg"];
          this.usuarioVisitante.matricula = response.data.dadoUser["matricula"];
          this.usuarioVisitante.nome = response.data.dadoUser["nome"];
          this.usuarioVisitante.telefone = response.data.dadoUser["telefone"];

          this.usuarioVisitante.empresa = response.data.empresa;
          this.usuarioVisitante.tipo = response.data.tipo;
          this.usuarioVisitante.idVisitante = response.data.idVisitante;
        } else {
          alert(response.data);
        }
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async dadosVisitantesUnidade() {
        this.isBusy = true;
      try {
        const response = await axios.post("unidade/unidadeControleAcesso", {
          idUnidade: this.$route.query.id,
        });

        (this.unidadeDados.nome =
          response.data.dadosUnidade.original.nome_unidade),
          (this.unidadeDados.telefone =
            response.data.dadosUnidade.original.telefone),
          (this.unidadeDados.endereco =
            response.data.dadosUnidade.original.endereco),
          (this.itemsVisitante = response.data.visitantesUnidadeEntrada),
          (this.itemsVisitanteSaida = response.data.visitantesUnidadeSaida),
   

        this.isBusy = false;
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    onSelect(userSolicitante) {
      //===>Autocomplete<===\\
      //console.log(userSolicitante);
      this.userSelectSolicitante = userSolicitante;
    },

    onReset() {
      
        (this.usuarioVisitante.cpf = ""),
        (this.usuarioVisitante.rg = ""),
        (this.usuarioVisitante.matricula = ""),
        (this.usuarioVisitante.nome = ""),
        (this.usuarioVisitante.telefone = ""),
        (this.usuarioVisitante.empresa = ""),
        (this.userSelectSolicitante = ""),
        (this.userSolicitante = "");
    },

    async onSubmit() {
      try {
        if (this.userSelectSolicitante == "") {
          alert('O campo "Destino" é obrigatório');
        } else if (this.usuarioVisitante.nome == "") {
          alert('O campo "Nome" é obrigatório');
        } else if (!(this.usuarioVisitante.cpf || this.usuarioVisitante.rg || this.usuarioVisitante.matricula)) {
          alert('Necessario informar um documento, RG, CPF ou Matricula!');
        } else {
        

          const response = await axios.post("unidade/addVisitante", {
            nome: this.usuarioVisitante.nome,
            cpf: this.usuarioVisitante.cpf,
            telefone: this.usuarioVisitante.telefone,
            idUsuario: this.usuarioVisitante.idVisitante,
            idUnidade: this.$route.query.id,
            idDestino: this.userSelectSolicitante["pk_id_usuario"],
            empresa: this.usuarioVisitante.empresa,
            rg: this.usuarioVisitante.rg,
            matricula: this.usuarioVisitante.matricula,
            tipo: this.usuarioVisitante.tipo,
            tipoNovoVisitante: this.selectedTipoVisitante,
          });

          console.log(response.data)

          alert(response.data);

          this.dadosVisitantesUnidade();
          this.onReset();
        }
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async saidaVisitante(idUserVisitante) {
      try {
        const response = await axios.post("unidade/visitanteSaida", {
          idUser: idUserVisitante,
        });

        alert(response.data);

        this.dadosVisitantesUnidade();
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>


<style scoped>
#label-decoration {
  color: rgb(139, 139, 139);
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
}

.conteudo-perfil {
  color: #575757;
}
</style>