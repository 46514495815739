<template>
  <div>
    <footer class="main-footer">
      <strong>Copyright &copy; 2023</strong>
      Todos os direitos reservados.
      <div class="float-right d-none d-sm-inline-block">
        <b>Conecta-STV</b> 1.0.0
      </div>
    </footer>

    <aside
      class="
        control-sidebar control-sidebar-dark
        sidebar-dark-primary
        elevation-4
      "
    >
      <div>
        <div class="brand-link">
          <div class="ml-5">
            <i class="fas fa-cogs"></i>
            <span class="brand-text font-weight-light"> Configurações</span>
          </div>
        </div>
      </div>

      <div class="sidebar">
        <nav class="mt-2">
          <ul
            class="
              nav nav-pills nav-sidebar
              flex-column
              nav-child-indent nav-flat
            "
          >
            <li class="nav-item">
              <router-link to="/adiciona-lider" class="nav-link">
                <i class="fas fa-sitemap nav-icon"></i>
                <p>Config Equipe</p>
              </router-link>
            </li>

            <!--
            <li class="nav-item">
              <router-link to="/criar-equipe" class="nav-link">
                <i class="fas fa-plus-square nav-icon"></i>
                <p>Criar Equipe</p>
              </router-link>
            </li>
            -->

            <li class="nav-item">
              <router-link to="/ativa-desativa-equipe" class="nav-link">
                <i class="nav-icon fas fa-users-slash"></i>
                <p>Ativar/Desativar Equipe</p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/troca-usuario-equipe" class="nav-link">
                <i class="nav-icon fas fa-exchange-alt"></i>
                <p>Trocar Usuário de Equipe</p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/alterar-senha-usuarios" class="nav-link">
                <i class="nav-icon fas fa-key"></i>
                <p>Trocar Senha</p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/stv-flix" class="nav-link">
                <i class="fab fa-youtube nav-icon"></i>
                <p>STV-Flix</p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/ativar-extrato-email" class="nav-link">
                <i class="fas fa-envelope nav-icon"></i>
                <p>Liberação Extrato de Email</p>
              </router-link>
            </li>

            <!--
            <li class="nav-item">
              <router-link to="/ativa-desativa-usuario" class="nav-link">
                <i class="fas fa-user-slash nav-icon"></i>
                <p>Ativar/Desativar Usuário</p>
              </router-link>
            </li>
            -->

            <!-- <li class="nav-header">Links</li> -->
          </ul>
        </nav>
      </div>
    </aside>
  </div>
</template>

<script scoped>
import "bootstrap/dist/js/bootstrap.bundle";
import "../../../assets/adminLTE/dist/js/adminlte";

export default {
  name: "FooterComponents",
};
</script>

<style>
</style>