import Vue from 'vue'
import VueRouter from 'vue-router'

//-->LOGIN<--//
import FormLogin from '../views/Login/FormLogin.vue'
import FormRecoverPassword from '../views/Login/FormRecoverPassword.vue'
//-->UNIDADE<--//
import Unidade from '../views/Dashboard/ViewUnidade/Unidade.vue'
import MembrosUnidade from '../views/Dashboard/ViewUnidade/MembrosUnidade.vue'
//-->HOME<--//
import Home from '../views/Dashboard/ViewHome/Home.vue'
//-->PERFIL<--//
import PerfilUsuario from '../views/Dashboard/ViewPerfil/PerfilUsuario.vue'
import Perfil from '../views/Dashboard/ViewPerfil/Perfil.vue'
//-->EQUIPE<--//
import Equipes from '../views/Dashboard/ViewEquipe/Equipes.vue'
import MembrosEquipe from '../views/Dashboard/ViewEquipe/MembrosEquipe.vue'
//-->ALTERA SENHA<--//
import AlterarSenha from '../views/Dashboard/ViewAlterarSenha/AlterarSenha.vue'
//-->INSTRUÇÃO DE TRABALHO<--//
import InstrucaoTrabalhoLista from '../views/Dashboard/ViewInstruçãoTrabalho/InstrucaoTrabalhoLista.vue'
import InstrucaoTrabalhoNova from '../views/Dashboard/ViewInstruçãoTrabalho/InstrucaoTrabalhoNova.vue'
import InstrucaoTrabalho from '../views/Dashboard/ViewInstruçãoTrabalho/InstrucaoTrabalho.vue'
import InstrucaoTrabalhoVersao from '../views/Dashboard/ViewInstruçãoTrabalho/InstrucaoTrabalhoVersao.vue'
import InstrucaoTrabalhoVersaoEditar from '../views/Dashboard/ViewInstruçãoTrabalho/InstrucaoTrabalhoVersaoEditar.vue'
import InstrucaoTrabalhoEditar from '../views/Dashboard/ViewInstruçãoTrabalho/InstrucaoTrabalhoEditar.vue'
//-->CONTROLE DE ACESSO<--//
import ControleAcesso from '../views/Dashboard/ViewControleAcesso/ControleAcesso.vue'
import ControleAcessoFormulario from '../views/Dashboard/ViewControleAcesso/ControleAcessoFormulario.vue'
import ControleAcessoRamais from '../views/Dashboard/ViewControleAcesso/ControleAcessoRamais.vue'
import ControleAcessoHistorico from '../views/Dashboard/ViewControleAcesso/ControleAcessoHistorico.vue'
//-->CHAMADOS<--//
import SolicitacaoHistorico from '../views/Dashboard/ViewChamado/SolicitacaoHistorico.vue'
import HistoricoChamado from '../views/Dashboard/ViewChamado/HistoricoChamado.vue'
import ChamadoRecebido from '../views/Dashboard/ViewChamado/ChamadoRecebido.vue'
import NovoChamado from '../views/Dashboard/ViewChamado/NovoChamado.vue'
import SolicitacaoAtendimento from '../views/Dashboard/ViewChamado/SolicitacaoAtendimento.vue'
//-->AVALIAÇÃO<--//
import Avaliacao from '../views/Dashboard/ViewAvaliacao/Avaliacao.vue'
//-->ADMCONFIG<--//
import AdicionaLider from '../views/Dashboard/ViewConfigAdm/AdicionaLider.vue'
import AtivaDesativaEquipe from '../views/Dashboard/ViewConfigAdm/AtivaDesativaEquipe.vue'
import AtivaDesativaUsuario from '../views/Dashboard/ViewConfigAdm/AtivaDesativaUsuario.vue'
import CriarEquipe from '../views/Dashboard/ViewConfigAdm/CriarEquipe.vue'
import TrocaUsuarioEquipe from '../views/Dashboard/ViewConfigAdm/TrocaUsuarioEquipe.vue'
import TransferirMembrosEquipe from '../views/Dashboard/ViewConfigAdm/TransferirMembrosEquipe.vue'
import AlterarSenhaUsers from '../views/Dashboard/ViewConfigAdm/AlterarSenhaUsers.vue'
//-->STVFLIX<--//
import StvFlix from '../views/Dashboard/ViewStvFlix/StvFlix.vue'
//-->AGENDA<--//
import Agenda from '../views/Dashboard/ViewAgenda/Agenda.vue'
import AgendaEquipe from '../views/Dashboard/ViewAgenda/AgendaEquipe.vue'
import AgendaMedica from '../views/Dashboard/ViewAgenda/AgendaMedica.vue'
//-->FEED<--//
import Feed from '../views/Dashboard/ViewFeed/AdicionarFeed.vue'
//-->RELATORIO E-MAIL<--//
import RelatorioEmail from '../views/Dashboard/ViewRelatorioEmail/RelatorioEmail.vue'
//-->RAMAL<--//
import Ramal from '../views/Dashboard/ViewRamal/Ramal.vue'
//-->ASSINATURA DIGITAL<--//
import AssinaturaDigital from '../views/Dashboard/ViewAssinaturaDigital/AssinaturaDigital.vue'
//-->TODOIST<--//
import Todoist from '../views/Dashboard/ViewTodoist/Todoist.vue'
//-->LiberarExtratoEmail<--//
import LiberarExtratoEmail from '../views/Dashboard/ViewRelatorioEmail/LiberarExtratoEmail.vue'



//-->ERROR<--//
import Error404 from '../views/Dashboard/ViewError/Error404.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:[
    {
      path: '/',
      redirect: {
          name: "FormLogin"
      }
    },
    {
      path: '/login',
      name: 'FormLogin',
      component: FormLogin,      
    },
    {
      path: '/recuperar-senha',
      name: 'FormRecoverPassword',
      component: FormRecoverPassword,
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
    },
    {
      path: '/perfil',
      name: 'Perfil',
      component: Perfil,
    },
    {
      path: '/alterar-senha',
      name: 'AlterarSenha',
      component: AlterarSenha
    },
    {
      path: '/unidades',
      name: 'Unidade',
      component: Unidade
    },
    {
      path: '/membros-unidade',
      name: 'MembrosUnidade',
      component: MembrosUnidade
    },
    {
      path: '/perfil-usuario',
      name: 'PerfilUsuario',
      component: PerfilUsuario
    },  
    {
      path: '/equipes',
      name: 'Equipes',
      component: Equipes
    }, 
    {
      path: '/membros-equipe',
      name: 'MembrosEquipe',
      component: MembrosEquipe
    },
  
    {
      path: '/instrucao-trabalho-lista',
      name: 'InstrucaoTrabalhoLista',
      component: InstrucaoTrabalhoLista
    },    
    {
      path: '/instrucao-trabalho-nova',
      name: 'InstrucaoTrabalhoNova',
      component: InstrucaoTrabalhoNova
    },    
    {
      path: '/instrucao-trabalho',
      name: 'InstrucaoTrabalho',
      component: InstrucaoTrabalho
    },
    {
      path: '/instrucao-trabalho-versao',
      name: 'InstrucaoTrabalhoVersao',
      component: InstrucaoTrabalhoVersao
    },
    {
      path: '/instrucao-trabalho-editar',
      name: 'InstrucaoTrabalhoEditar',
      component: InstrucaoTrabalhoEditar
    },
    {
      path: '/instrucao-trabalho-editar-versao',
      name: 'InstrucaoTrabalhoVersaoEditar',
      component: InstrucaoTrabalhoVersaoEditar
    },
    {
      path: '/controle-acesso',
      name: 'ControleAcesso',
      component: ControleAcesso
    },
    {
      path: '/controle-acesso-formulario',
      name: 'ControleAcessoFormulario',
      component: ControleAcessoFormulario
    },
    {
      path: '/ramais-unidade',
      name: 'ControleAcessoRamais',
      component: ControleAcessoRamais
    },
    {
      path: '/controle-acesso-historico',
      name: 'ControleAcessoHistorico',
      component: ControleAcessoHistorico
    },
    {
      path: '/novo-chamado',
      name: 'NovoChamado',
      component: NovoChamado
    },
    {
      path: '/ticket-recebido',
      name: 'ChamadoRecebido',
      component: ChamadoRecebido
    },
    {
      path: '/solicitacao-atendimento',
      name: 'SolicitacaoAtendimento',
      component: SolicitacaoAtendimento
    },
    {
      path: '/historico-chamado',
      name: 'HistoricoChamado',
      component: HistoricoChamado
    },
    {
      path: '/solicitacao-historico',
      name: 'SolicitacaoHistorico',
      component: SolicitacaoHistorico
    },

    {
      path: '/adiciona-lider',
      name: 'AdicionaLider',
      component: AdicionaLider
    },
    {
      path: '/ativa-desativa-equipe',
      name: 'AtivaDesativaEquipe',
      component: AtivaDesativaEquipe
    },
    {
      path: '/criar-equipe',
      name: 'CriarEquipe',
      component: CriarEquipe
    },
    {
      path: '/ativa-desativa-usuario',
      name: 'AtivaDesativaUsuario',
      component: AtivaDesativaUsuario
    },
    {
      path: '/troca-usuario-equipe',
      name: 'TrocaUsuarioEquipe',
      component: TrocaUsuarioEquipe
    },
    {
      path: '/transferir-membros-equipe',
      name: 'TransferirMembrosEquipe',
      component: TransferirMembrosEquipe
    },
    {
      path: '/alterar-senha-usuarios',
      name: 'AlterarSenhaUsers',
      component: AlterarSenhaUsers
    },
    {
      path: '/avaliacao-chamado',
      name: 'Avaliacao',
      component: Avaliacao
    },
    
    {
      path: '/stv-flix',
      name: 'StvFlix',
      component: StvFlix
    },

    {
      path: '/agenda',
      name: 'Agenda',
      component: Agenda
    },

    {
      path: '/agenda-equipe',
      name: 'AgendaEquipe',
      component: AgendaEquipe
    },

    {
      path: '/agenda-medica',
      name: 'AgendaMedica',
      component: AgendaMedica
    },  
    
    {
      path: '/feed',
      name: 'Feed',
      component: Feed
    },

    {
      path: '/relatorio-email',
      name: 'RelatorioEmail',
      component: RelatorioEmail
    },

    {
      path: '/assinatura-digital',
      name: 'AssinaturaDigital',
      component: AssinaturaDigital
    },

    {
      path: '/ramal',
      name: 'Ramal',
      component: Ramal
    },

    {
      path: '/todoist',
      name: 'Todoist',
      component: Todoist
    },

    {
      path: '/ativar-extrato-email',
      name: 'LiberarExtratoEmail',
      component: LiberarExtratoEmail
    },

    {
      path: '*',
      name: 'Error404',
      component: Error404
    },
  ]
});


router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/recuperar-senha', '/avaliacao-chamado', '/'];
  const hibridaPages = ['/avaliacao-chamado'];
  
  const valida = hibridaPages.includes(to.path);
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token')

  if (authRequired && !loggedIn){
    next('/login');  
  }
  else if(!authRequired && !valida && loggedIn){
    next('/home');
  }
  else
    next()

})

export default router
