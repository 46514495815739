<template>
  <div>
    <b-overlay :show="overlayShow" rounded="sm">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Meu Ticket</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">Home</li>
                  <li class="breadcrumb-item active">Meu Ticket</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div v-if="tipoTicket == 'historico'">
          <b-tabs
            content-class="mt-3"
            align="center"
            active-nav-item-class="font-weight-bold text-uppercase text-success"
            ><b-tab title="Solicitação em atendimento" active>
              <section class="content">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                          <h3 class="profile-username"><b>Título</b></h3>
                          <p class="text-muted">
                            {{ dadosTicketAtendendo.titulo }}
                          </p>
                          <br />

                          <b>Atividade</b> <br />
                          <p class="text-muted">
                            {{ dadosTicketAtendendo.nome_categora_atendimento }}
                          </p>

                          <b>Solicitante</b> <br />
                          <p class="text-muted">
                            {{ dadosTicketAtendendo.nome_requerente }}
                          </p>

                          <b>Protocolo</b> <br />
                          <p class="text-muted">
                            {{ dadosTicketAtendendo.protocolo }}
                          </p>

                          <b>Status</b> <br />
                          <p class="text-muted">
                            {{ dadosTicketAtendendo.status }}
                          </p>

                          <b>Atribuido</b> <br />
                          <p
                            v-if="dadosTicketAtendendo.nome_destino"
                            class="text-muted"
                          >
                            {{ dadosTicketAtendendo.nome_destino }}
                          </p>
                          <p v-else class="text-muted">Equipe</p>

                          {{ anexoChamados["anexoChamados"] }}

                          <p>
                            <router-link to="/novo-chamado">
                              <b-button
                                variant="outline-secondary"
                                size="sm"
                                class="btn btn-block"
                              >
                                <i class="fas fa-headset"></i>
                                Novo Ticket
                              </b-button>
                            </router-link>
                          </p>

                          <p>
                            <b-button
                              v-if="dadosTicketAtendendo.formulario == 'Sim'"
                              class="btn btn-block"
                              variant="secondary"
                              size="sm"
                              v-on:click="tipoTicket = 'editar'"
                            >
                              <i class="fas fa-recycle"></i>
                              Reutilizar Ticket</b-button
                            >
                          </p>
                          <div v-if="anexoChamados['0']">
                            <b-dropdown
                              size="sm"
                              variant="outline-secondary"
                              dropright
                              text="Anexos"
                              class="btn-block m-0"
                            >
                              <div
                                v-for="anexo in anexoChamados"
                                :key="anexo.pk_id_anexo"
                              >
                                <b-dropdown-item-button
                                  @click="
                                    downloadAnexo(anexo.pk_id_anexo, anexo.nome)
                                  "
                                >
                                  <i class="fas fa-file-download"></i>
                                  <span id="nome-anexo">
                                    <span id="data-anexo">
                                      {{
                                        anexo.data_hora_cadastro
                                          | momentTimeAnexo
                                      }}</span
                                    >
                                    - {{ anexo.nome }}</span
                                  >
                                </b-dropdown-item-button>
                              </div>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-9">
                      <div
                        id="back-descricao"
                        class="card card-primary card-outline"
                      >
                        <div class="card-header">
                          <div id="descricao" class="mb-3">
                            <b>Descrição:</b> <br />
                            <p class="text-muted" align="justify">
                              {{ dadosTicketAtendendo.descricao }}
                            </p>
                          </div>

                          <div
                            class="mb-0"
                            v-if="dadosTicketAtendendo.status != 'Fechado'"
                          >
                            <div class="input-group mb-0">
                              <b-form-textarea
                                id="textarea-state"
                                v-model="contentHTML"
                                :state="contentHTML.length >= 10"
                                placeholder="O que foi feito e qual o proximo passo...?"
                                rows="3"
                              ></b-form-textarea>

                              <span class="input-group-append">
                                <b-button
                                  :disabled="statusButton"
                                  @click="addNovoHistoricoTicket"
                                  variant="info"
                                  ><i class="fas fa-save"></i
                                ></b-button>
                              </span>

                              <b-form-invalid-feedback class="mt-0 mb-0">
                                Insira pelo menos 10 letras
                              </b-form-invalid-feedback>
                            </div>

                            <div
                              style="text-align: right"
                              class="mt-0 float-right"
                            >
                              <input
                                ref="files"
                                type="file"
                                class="d-none"
                                accept=".png, .jpg, .jpeg, .doc,.docx,.pdf,.xls,.xlsx"
                                @change="handleFile"
                                multiple
                              />

                              <button
                                type="button"
                                @click="openFileDialog()"
                                class="btn badge bagde-light"
                                style="font-size: 16px"
                              >
                                <i class="fas fa-paperclip text-secondary"></i>
                              </button>

                              <div class="mt-1 mb-0">
                                <p
                                  id="ndome-anexo"
                                  v-for="(filesAnexo, index) in fileReceipt"
                                  :key="index"
                                  class="mt-0 mb-0"
                                >
                                  {{ filesAnexo.name }}
                                  <button
                                    type="button"
                                    @click="apagarAnexo(index)"
                                    class="btn badge bagde-light"
                                  >
                                    <i class="fa fa-trash text-danger"></i>
                                  </button>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card-body" id="backBody">
                          <div class="timeline timeline-inverse">
                            <div class="time-label">
                              <span class="bg-red">
                                {{ dataAtual | moment }}</span
                              >
                            </div>

                            <div
                              v-for="atendendoHistorico in dadosTicketAtendendoHistorico"
                              :key="
                                atendendoHistorico.pk_id_atendimento_historico
                              "
                            >
                              <i class="fas fa-user bg-green"></i>
                              <div class="timeline-item">
                                <span class="time"
                                  ><i class="fas fa-clock"></i>
                                  {{
                                    atendendoHistorico.data_hora_cadastro
                                      | momentTime
                                  }}</span
                                >
                                <h3 class="timeline-header">
                                  <a>{{
                                    atendendoHistorico.nome_user_add_historico
                                  }}</a>
                                </h3>

                                <div class="timeline-body">
                                  <span
                                    v-html="atendendoHistorico.historico"
                                  ></span>

                                  <!--
                            <div  style="text-align: right;" v-if="anexoChamados['0']">
                              <b-dropdown size="sm" variant="secondary" dropleft text="Anexos" class="m-1">
                                <div
                                  v-for="anexo in anexoChamados"
                                  :key="anexo.pk_id_anexo"
                                >
                                  <b-dropdown-item-button
                                    @click="
                                      downloadAnexo(anexo.pk_id_anexo, anexo.nome)
                                    "
                                    style="font-size: 13px"
                                    v-if="atendendoHistorico.pk_id_atendimento_historico == anexo.fk_id_atendimento_historico"
                                  >
                                    <i class="fas fa-file-download"></i>  {{ anexo.nome }}
                                  </b-dropdown-item-button>
                                </div>
                              </b-dropdown>
                            </div>
                            -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </b-tab>

            <b-tab
              v-if="dadosTicketAtendendo.formulario == 'Sim'"
              title="Formulário"
            >
              <section class="content">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                          <h3 class="profile-username"><b>Título</b></h3>
                          <p class="text-muted">
                            {{ dadosTicketAtendendo.titulo }}
                          </p>
                          <br />

                          <b>Atividade</b> <br />
                          <p class="text-muted">
                            {{ dadosTicketAtendendo.nome_categora_atendimento }}
                          </p>

                          <b>Solicitante</b> <br />
                          <p class="text-muted">
                            {{ dadosTicketAtendendo.nome_requerente }}
                          </p>

                          <b>Protocolo</b> <br />
                          <p class="text-muted">
                            {{ dadosTicketAtendendo.protocolo }}
                          </p>

                          <b>Status</b> <br />
                          <p class="text-muted">
                            {{ dadosTicketAtendendo.status }}
                          </p>

                          <b>Atribuido</b> <br />
                          <p
                            v-if="dadosTicketAtendendo.nome_destino"
                            class="text-muted"
                          >
                            {{ dadosTicketAtendendo.nome_destino }}
                          </p>
                          <p v-else class="text-muted">Equipe</p>

                          {{ anexoChamados["anexoChamados"] }}

                          <p>
                            <router-link to="/novo-chamado">
                              <b-button
                                variant="outline-secondary"
                                size="sm"
                                class="btn btn-block"
                              >
                                <i class="fas fa-headset"></i>
                                Novo Ticket
                              </b-button>
                            </router-link>
                          </p>

                          <p>
                            <b-button
                              v-if="dadosTicketAtendendo.formulario == 'Sim'"
                              class="btn btn-block"
                              variant="secondary"
                              size="sm"
                              v-on:click="tipoTicket = 'editar'"
                            >
                              <i class="fas fa-recycle"></i>
                              Reutilizar Ticket</b-button
                            >
                          </p>
                          <div v-if="anexoChamados['0']">
                            <b-dropdown
                              size="sm"
                              variant="outline-secondary"
                              dropright
                              text="Anexos"
                              class="btn-block m-0"
                            >
                              <div
                                v-for="anexo in anexoChamados"
                                :key="anexo.pk_id_anexo"
                              >
                                <b-dropdown-item-button
                                  @click="
                                    downloadAnexo(anexo.pk_id_anexo, anexo.nome)
                                  "
                                >
                                  <i class="fas fa-file-download"></i>
                                  <span id="nome-anexo">
                                    <span id="data-anexo">
                                      {{
                                        anexo.data_hora_cadastro
                                          | momentTimeAnexo
                                      }}</span
                                    >
                                    - {{ anexo.nome }}</span
                                  >
                                </b-dropdown-item-button>
                              </div>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-9">
                      <div class="card card-primary card-outline">
                        <div class="card-header">
                          <h3 class="card-title">
                            <b>Solicitação de Pessoal</b>
                          </h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-6">
                              <!-- text input -->
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-building"></i>
                                  DIVISÃO:</label
                                >
                                <input
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_divisao
                                  "
                                  disabled
                                  type="text"
                                  class="form-control form-control-sm"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-calendar-alt"></i> DATA DE
                                  ADMISSÃO:</label
                                >
                                <input
                                  v-model="
                                    dataAdmissao
                                  "
                                  type="text"
                                  class="form-control form-control-sm"
                                  placeholder="Data de admissão"
                                  disabled=""
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-6">
                              <!-- text input -->
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-portrait"></i> CARGO:</label
                                >
                                <input
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_cargo
                                  "
                                  type="text"
                                  class="form-control form-control-sm"
                                  disabled=""
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-user-shield"></i>
                                  LIDER:</label
                                >
                                <input
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_nome_lider
                                  "
                                  type="text"
                                  class="form-control form-control-sm"
                                  disabled=""
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-user-tie"></i>
                                  GESTOR:</label
                                >
                                <input
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_nome_gestor
                                  "
                                  type="text"
                                  class="form-control form-control-sm"
                                  disabled=""
                                />
                              </div>
                            </div>

                            <div class="col-sm-6">
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-coins"></i> C.CUSTO:</label
                                >
                                <input
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_centro_custo
                                  "
                                  type="text"
                                  class="form-control form-control-sm"
                                  disabled=""
                                />
                              </div>
                            </div>
                          </div>

                          <div class="tab-custom-content mb-4"></div>

                          <div class="form-group row">
                            <label
                              id="label-style"
                              for="razao-social"
                              class="col-sm-2 col-form-label"
                              >R. Social:</label
                            >
                            <div class="col-sm-10">
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_razao_social
                                "
                                type="text"
                                class="form-control form-control-sm"
                                id="razao-social"
                                disabled=""
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              id="label-style"
                              for="endereco"
                              class="col-sm-2 col-form-label"
                              >Endereço:</label
                            >
                            <div class="col-sm-10">
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_endereco
                                "
                                type="text"
                                class="form-control form-control-sm"
                                id="endereco"
                                disabled=""
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              id="label-style"
                              for="bairro"
                              class="col-sm-2 col-form-label"
                              >Bairro:</label
                            >
                            <div class="col-sm-10">
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_bairro
                                "
                                type="text"
                                class="form-control form-control-sm"
                                id="bairro"
                                disabled=""
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              id="label-style"
                              for="escala"
                              class="col-sm-2 col-form-label"
                              >Escala:</label
                            >
                            <div class="col-sm-10">
                              <input
                                v-model="
                                  dadosTicketAtendendo.solicitacao_pessoal_escala
                                "
                                type="text"
                                class="form-control form-control-sm"
                                id="escala"
                                disabled=""
                              />
                            </div>
                          </div>

                          <div class="tab-custom-content mb-4 mt-4"></div>
                        </div>

                        <div class="card-header">
                          <h3 class="card-title"><b>Perfil de Cargo</b></h3>
                        </div>
                        <div class="card-body">
                          <!-- input states -->

                          <div class="row">
                            <div class="col-sm-4">
                              <!-- textarea -->
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-bookmark"></i>
                                  CONHECIMENTOS</label
                                >
                                <textarea
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_conhecimento
                                  "
                                  class="form-control"
                                  rows="3"
                                  disabled="Conhecimento ..."
                                ></textarea>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-bookmark"></i>
                                  HABILIDADES</label
                                >
                                <textarea
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_habilidade
                                  "
                                  class="form-control"
                                  rows="3"
                                  disabled="Habilidades ..."
                                ></textarea>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-bookmark"></i>
                                  ATITUDES</label
                                >
                                <textarea
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_atitude
                                  "
                                  class="form-control"
                                  rows="3"
                                  disabled="Atitudes ..."
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-12">
                              <!-- textarea -->
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fab fa-black-tie"></i> REQUISITOS
                                  DE EXPERIÊNCIA PROFISSIONAL</label
                                >
                                <textarea
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_requisito_experiencia
                                  "
                                  class="form-control"
                                  rows="3"
                                  disabled=""
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-sm-6">
                              <!-- text input -->
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-user-clock"></i> IDADE
                                  REFERENCIAL:</label
                                >
                                <input
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_idade_referencial
                                  "
                                  type="text"
                                  class="form-control form-control-sm"
                                  disabled=""
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-venus-mars"></i>
                                  GENERO:</label
                                >
                                <input
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_genero
                                  "
                                  type="text"
                                  class="form-control form-control-sm"
                                  disabled=""
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-adjust"></i> TURNO:</label
                                >
                                <input
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_turno
                                  "
                                  type="text"
                                  class="form-control form-control-sm"
                                  disabled=""
                                />
                              </div>
                            </div>

                            <div class="col-sm-6">
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-asterisk"></i>
                                  MOTIVO:</label
                                >
                                <input
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_motivo
                                  "
                                  type="text"
                                  class="form-control form-control-sm"
                                  disabled=""
                                />
                              </div>
                            </div>
                          </div>

                          <div class="tab-custom-content mb-4 mt-4"></div>

                          <div class="row">
                            <div class="col-sm-12">
                              <!-- textarea -->
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-money-check-alt"></i>
                                  SALÁRIO E BENEFÍCIOS</label
                                >
                                <textarea
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_salario_beneficio
                                  "
                                  class="form-control"
                                  rows="3"
                                  disabled=""
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div class="tab-custom-content mb-4 mt-4"></div>

                          <div class="row">
                            <div class="col-sm-12">
                              <!-- textarea -->
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-file-alt"></i> DESCRIÇÃO DE
                                  ATIVIDADES (CONFORME IT - CENTRO
                                  RECRUTAMENTO)</label
                                >
                                <textarea
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_salario_descricao_atividade
                                  "
                                  class="form-control"
                                  rows="3"
                                  disabled=""
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div class="tab-custom-content mb-4 mt-4"></div>

                          <div class="row">
                            <div class="col-sm-12">
                              <!-- textarea -->
                              <div class="form-group">
                                <label id="label-style"
                                  ><i class="fas fa-file-signature"></i>
                                  OBSERVAÇÕES:</label
                                >
                                <textarea
                                  v-model="
                                    dadosTicketAtendendo.solicitacao_pessoal_observacao
                                  "
                                  class="form-control"
                                  rows="3"
                                  disabled=""
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--
                      <b-card header="Form Data Result">
                        <pre class="m-0">{{ dadosTicketAtendendo }}</pre>
                      </b-card>
                      --></div>
                    </div>
                  </div>
                </div>
              </section>
            </b-tab>
          </b-tabs>
        </div>

        <div v-else>
          <section class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-3">
                  <div class="card card-primary card-outline">
                    <div class="card-body box-profile">
                      <h3 class="profile-username"><b>Título</b></h3>
                      <p class="text-muted">
                        {{ dadosTicketAtendendo.titulo }}
                      </p>
                      <br />

                      <b>Atividade</b> <br />
                      <p class="text-muted">
                        {{ dadosTicketAtendendo.nome_categora_atendimento }}
                      </p>

                      <b>Solicitante</b> <br />
                      <p class="text-muted">
                        {{ dadosTicketAtendendo.nome_requerente }}
                      </p>

                      <p>
                        <b-button
                          v-if="dadosTicketAtendendo.formulario == 'Sim'"
                          class="btn btn-block"
                          variant="secondary"
                          size="sm"
                          v-on:click="tipoTicket = 'historico'"
                        >
                          <i class="far fa-trash-alt"></i>
                          Cancelar</b-button
                        >
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-9">
                  <div class="card card-primary card-outline">
                    <div class="card-header">
                      <h3 class="card-title">
                        <b>Solicitação de Pessoal</b>
                      </h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-6">
                          <!-- text input -->
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-building"></i> DIVISÃO:</label
                            >
                            <input
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_divisao
                              "
                              type="text"
                              class="form-control form-control-sm"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-calendar-alt"></i> DATA DE
                              ADMISSÃO:</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Data de admissão"
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-6">
                          <!-- text input -->
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-portrait"></i> CARGO:</label
                            >
                            <input
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_cargo
                              "
                              type="text"
                              class="form-control form-control-sm"
                            />
                          </div>
                        </div>

                        <!--
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-user-shield"></i> LIDER:</label
                            >
                            <input
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_nome_lider
                              "
                              type="text"
                              class="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        -->

                        <div class="col-sm-6">
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-user-shield"></i> LIDER:</label
                            >
                            <div>
                              <AutocompleteComponents
                                v-model="solicitacaoPessoal.userLider"
                                :options="solicitacaoPessoal.usersLider"
                                label-key="nome"
                                value-key="pk_id_usuario"
                                placeholder="Pesquisar líder..."
                                @shouldSearch="pesquisarUsuariosLider"
                                @select="onSelectLider"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-user-tie"></i> GESTOR:</label
                            >
                            <div>
                              <AutocompleteComponents
                                v-model="solicitacaoPessoal.userGestor"
                                :options="solicitacaoPessoal.usersGestor"
                                label-key="nome"
                                value-key="pk_id_usuario"
                                placeholder="Pesquisar Gestor..."
                                @shouldSearch="pesquisarUsuariosGestor"
                                @select="onSelectGestor"
                              />
                            </div>
                          </div>
                        </div>

                        <!--
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-user-tie"></i> GESTOR:</label
                            >
                            <input
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_nome_gestor
                              "
                              type="text"
                              class="form-control form-control-sm"
                            />
                          </div>
                        </div>
                        -->

                        <div class="col-sm-6">
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-coins"></i> C.CUSTO:</label
                            >
                            <input
                              v-mask="'##-###'"
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_centro_custo
                              "
                              type="text"
                              class="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4 mt-3">
                        <div class="col-sm-12">
                          <div id="label-style">
                            <b-form-radio-group
                              v-model="solicitacaoPessoal.selectedMotivo"
                              :options="solicitacaoPessoal.optionsMotivo"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </div>

                      <div class="tab-custom-content mb-4"></div>

                      <div class="form-group row">
                        <label
                          id="label-style"
                          for="razao-social"
                          class="col-sm-2 col-form-label"
                          >R. Social:</label
                        >
                        <div class="col-sm-10">
                          <input
                            v-model="
                              dadosTicketAtendendoReciclar.solicitacao_pessoal_razao_social
                            "
                            type="text"
                            class="form-control form-control-sm"
                            id="razao-social"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          id="label-style"
                          for="endereco"
                          class="col-sm-2 col-form-label"
                          >Endereço:</label
                        >
                        <div class="col-sm-10">
                          <input
                            v-model="
                              dadosTicketAtendendoReciclar.solicitacao_pessoal_endereco
                            "
                            type="text"
                            class="form-control form-control-sm"
                            id="endereco"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          id="label-style"
                          for="bairro"
                          class="col-sm-2 col-form-label"
                          >Bairro:</label
                        >
                        <div class="col-sm-10">
                          <input
                            v-model="
                              dadosTicketAtendendoReciclar.solicitacao_pessoal_bairro
                            "
                            type="text"
                            class="form-control form-control-sm"
                            id="bairro"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          id="label-style"
                          for="escala"
                          class="col-sm-2 col-form-label"
                          >Escala:</label
                        >
                        <div class="col-sm-10">
                          <input
                            v-model="
                              dadosTicketAtendendoReciclar.solicitacao_pessoal_escala
                            "
                            type="text"
                            class="form-control form-control-sm"
                            id="escala"
                          />
                        </div>
                      </div>

                      <div class="tab-custom-content mb-4 mt-4"></div>

                      <div class="row mb-4 mt-3">
                        <div class="col-sm-12">
                          <label id="label-style">TURNO:</label>
                          <div id="label-style">
                            <b-form-radio-group
                              v-model="solicitacaoPessoal.selectedTurno"
                              :options="solicitacaoPessoal.optionsTurno"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-header">
                      <h3 class="card-title"><b>Perfil de Cargo</b></h3>
                    </div>
                    <div class="card-body">
                      <!-- input states -->

                      <div class="row">
                        <div class="col-sm-4">
                          <!-- textarea -->
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-bookmark"></i>
                              CONHECIMENTOS</label
                            >
                            <textarea
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_conhecimento
                              "
                              class="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-bookmark"></i>
                              HABILIDADES</label
                            >
                            <textarea
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_habilidade
                              "
                              class="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-bookmark"></i> ATITUDES</label
                            >
                            <textarea
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_atitude
                              "
                              class="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <!-- textarea -->
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fab fa-black-tie"></i> REQUISITOS DE
                              EXPERIÊNCIA PROFISSIONAL</label
                            >
                            <textarea
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_requisito_experiencia
                              "
                              class="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4 mt-3">
                        <div class="col-sm-12">
                          <div id="label-style">
                            <b-form-radio-group
                              v-model="solicitacaoPessoal.selectedGenero"
                              :options="solicitacaoPessoal.optionsGenero"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                            >
                            </b-form-radio-group>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label
                          id="label-style"
                          class="col-form-label"
                          for="inputSuccess"
                          ><i class="fas fa-user-clock"></i> IDADE
                          REFERENCIAL:</label
                        >
                        <input
                          v-mask="'##'"
                          v-model="
                            dadosTicketAtendendoReciclar.solicitacao_pessoal_idade_referencial
                          "
                          type="text"
                          class="form-control form-control-sm col-sm-5"
                          id="inputSuccess"
                        />
                      </div>

                      <div class="tab-custom-content mb-4 mt-4"></div>

                      <div class="row">
                        <div class="col-sm-12">
                          <!-- textarea -->
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-money-check-alt"></i> SALÁRIO E
                              BENEFÍCIOS</label
                            >
                            <textarea
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_salario_beneficio
                              "
                              class="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="tab-custom-content mb-4 mt-4"></div>

                      <div class="row">
                        <div class="col-sm-12">
                          <!-- textarea -->
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-file-alt"></i> DESCRIÇÃO DE
                              ATIVIDADES (CONFORME IT - CENTRO
                              RECRUTAMENTO)</label
                            >
                            <textarea
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_salario_descricao_atividade
                              "
                              class="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="tab-custom-content mb-4 mt-4"></div>

                      <div class="row">
                        <div class="col-sm-12">
                          <!-- textarea -->
                          <div class="form-group">
                            <label id="label-style"
                              ><i class="fas fa-file-signature"></i>
                              OBSERVAÇÕES:</label
                            >
                            <textarea
                              v-model="
                                dadosTicketAtendendoReciclar.solicitacao_pessoal_observacao
                              "
                              class="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="mb-0 mt-3">
                        <b-button
                          type="submit"
                          variant="primary"
                          size="sm"
                          class="float-right"
                          @click="editarReciclar"
                          editarReciclar
                        >
                          <i class="fas fa-paper-plane"></i>
                          <b> ENVIAR SOLICITAÇÂO</b></b-button
                        >

                        <input
                          ref="files"
                          type="file"
                          class="d-none"
                          accept=".png, .jpg, .jpeg, .doc,.docx,.pdf,.xls,.xlsx"
                          @change="handleFile"
                          multiple
                        />

                        <b-button
                          id="text-button-anexo"
                          @click="openFileDialog()"
                          type="button"
                          variant="outline-dark"
                          size="sm"
                          class="mb-2"
                        >
                          <i id="anexo" class="fas fa-paperclip"></i>
                          Anexo
                        </b-button>

                        <div class="m-auto">
                          <p
                            id="nome-anexo"
                            class="mb-0"
                            v-for="(filesAnexo, index) in fileReceipt"
                            :key="index"
                          >
                            {{ filesAnexo.name }}
                            <button
                              type="button"
                              @click="apagarAnexo(index)"
                              class="btn badge bagde-light"
                            >
                              <i class="fa fa-trash text-danger"></i>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                    <!--
                    <b-card header="Form Data Result">
                      <pre class="m-0">{{ solicitacaoPessoal.userLider }}</pre>
                      <pre class="m-0">{{ solicitacaoPessoal.usersLider }}</pre>
                      <pre class="m-0">{{
                        solicitacaoPessoal.userSelectLider
                      }}</pre>
                    </b-card>
                    -->
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "SolicitacaoHistorico",

  data() {
    return {
      dataAdmissao:"",
      solicitacaoPessoal: {
        selectedMotivo: "",
        optionsMotivo: [
          { item: "Ampliação", name: "AMPLIAÇÃO" },
          { item: "Implantação", name: "IMPLANTAÇÃO" },
          { item: "Reposição", name: "REPOSIÇÃO" },
        ],

        selectedGenero: "",
        optionsGenero: [
          { item: "Masculino", name: "MASCULINO" },
          { item: "Feminino", name: "FEMININO" },
        ],

        selectedTurno: "",
        optionsTurno: [
          { item: "Dia", name: "DIA" },
          { item: "Noite", name: "NOITE" },
          { item: "Folguista", name: "FOLGUISTA" },
        ],

        userLider: "", //===>Autocomplete<===\\
        usersLider: [], //===>Autocomplete<===\\
        userSelectLider: {
          nome: "",
          pk_id_usuario: "",
        }, //===>Autocomplete<===\\

        userGestor: "", //===>Autocomplete<===\\
        usersGestor: [], //===>Autocomplete<===\\
        userSelectGestor: {
          nome: "",
          pk_id_usuario: "",
        }, //===>Autocomplete<===\\
      },

      tipoTicket: "historico",
      contentHTML: "",
      dataAtual: new Date(),
      overlayShow: true,

      dadosTicketAtendendoReciclar: [],
      dadosTicketAtendendo: [],
      dadosTicketAtendendoHistorico: [],

      anexoChamados: [],

      fileReceipt: [],
    };
  },

  components: {
    AutocompleteComponents, //===>Autocomplete<===\\
  },

  computed: {
    statusButton() {
      return this.contentHTML.length > 9 ? false : true;
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("MMM Do YY");
    },

    momentTime: function (date) {
      return moment(date).format("LLL");
    },

    momentTimeAnexo: function (date) {
      return moment(date).format("lll");
    },
  },

  mounted() {
    this.getTicket();
  },

  methods: {
    pesquisarUsuariosLider(query) {
      //===>Autocomplete<===\\
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.solicitacaoPessoal.usersLider = response.data;
      });
    },

    pesquisarUsuariosGestor(query) {
      //===>Autocomplete<===\\
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.solicitacaoPessoal.usersGestor = response.data;
      });
    },

    onSelectLider(userLider) {
      //===>Autocomplete<===\\
      this.solicitacaoPessoal.userSelectLider = userLider;
    },

    onSelectGestor(userGestor) {
      //===>Autocomplete<===\\
      this.solicitacaoPessoal.userSelectGestor = userGestor;
    },

    async getTicket() {
      try {
        const response = await axios.post("chamado/meuTicket", {
          idTicket: this.$route.query.id,
        });

        this.dadosTicketAtendendoReciclar =
          response.data.ticketAtendendo.original;
        this.dadosTicketAtendendo = response.data.ticketAtendendo.original;
        this.dadosTicketAtendendoHistorico =
          response.data.ticketAtendendoHistorico.original.ticketAtendendoHistorico;

        //===>Anexo<===\\
        this.anexoChamados =
          response.data.ticketAtendendoHistorico.original.anexo;
        //===>Anexo<===\\

        //===>Options<===\\
        this.solicitacaoPessoal.selectedMotivo =
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_motivo;

        this.solicitacaoPessoal.selectedTurno =
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_turno;

        this.solicitacaoPessoal.selectedGenero =
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_genero;
        //===>Options<===\\

        //===>Autocomplete<===\\
        this.solicitacaoPessoal.userLider =
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_nome_lider;
        this.solicitacaoPessoal.userSelectLider.nome =
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_nome_lider;
        this.solicitacaoPessoal.userSelectLider.pk_id_usuario =
          this.dadosTicketAtendendoReciclar.fk_id_solicitacao_pessoal_lider;

        this.solicitacaoPessoal.userGestor =
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_nome_gestor;
        this.solicitacaoPessoal.userSelectGestor.nome =
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_nome_gestor;
        this.solicitacaoPessoal.userSelectGestor.pk_id_usuario =
          this.dadosTicketAtendendoReciclar.fk_id_solicitacao_pessoal_gestor;
        //===>Autocomplete<===\\

        if(this.dadosTicketAtendendo.solicitacao_pessoal_data_admissao == "" || this.dadosTicketAtendendo.solicitacao_pessoal_data_admissao == null){
          this.dataAdmissao = ""
        }else{
          this.dataAdmissao = moment(this.dadosTicketAtendendo.solicitacao_pessoal_data_admissao).format('LLL')
        }

        console.log(this.dadosTicketAtendendoReciclar);

        this.overlayShow = false;
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async editarReciclar() {
      try {
        //Limpar null Autocomplete
        if (
          this.solicitacaoPessoal.userSelectLider == "" ||
          this.solicitacaoPessoal.userSelectLider == null
        ) {
          var solicitacaoPessoalLider = "";
        } else {
          var solicitacaoPessoalLider =
            this.solicitacaoPessoal.userSelectLider.pk_id_usuario;
        }

        if (
          this.solicitacaoPessoal.userSelectGestor == "" ||
          this.solicitacaoPessoal.userSelectGestor == null
        ) {
          var solicitacaoPessoalGestor = "";
        } else {
          var solicitacaoPessoalGestor =
            this.solicitacaoPessoal.userSelectGestor.pk_id_usuario;
        }
        //Limpar null Autocomplete

        //Limpar null das variaveis
        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_motivo == "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_motivo == null
        ) {
          var solicitacaoMotivo = "";
        } else {
          var solicitacaoMotivo =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_motivo;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_genero == "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_genero == null
        ) {
          var solicitacaoGenero = "";
        } else {
          var solicitacaoGenero =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_genero;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_turno == "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_turno == null
        ) {
          var solicitacaoTurno = "";
        } else {
          var solicitacaoTurno =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_turno;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_divisao == "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_divisao == null
        ) {
          var solicitacaoDivisao = "";
        } else {
          var solicitacaoDivisao =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_divisao;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_cargo == "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_cargo == null
        ) {
          var solicitacaoCargo = "";
        } else {
          var solicitacaoCargo =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_cargo;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_centro_custo ==
            "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_centro_custo ==
            null
        ) {
          var solicitacaoCentroCusto = "";
        } else {
          var solicitacaoCentroCusto =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_centro_custo;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_razao_social ==
            "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_razao_social ==
            null
        ) {
          var solicitacaoRazaoSocial = "";
        } else {
          var solicitacaoRazaoSocial =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_razao_social;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_endereco ==
            "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_endereco == null
        ) {
          var solicitacaoEndereco = "";
        } else {
          var solicitacaoEndereco =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_endereco;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_bairro == "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_bairro == null
        ) {
          var solicitacaoBairro = "";
        } else {
          var solicitacaoBairro =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_bairro;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_escala == "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_escala == null
        ) {
          var solicitacaoEscala = "";
        } else {
          var solicitacaoEscala =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_escala;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_conhecimento ==
            "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_conhecimento ==
            null
        ) {
          var solicitacaoConhecimento = "";
        } else {
          var solicitacaoConhecimento =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_conhecimento;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_habilidade ==
            "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_habilidade ==
            null
        ) {
          var solicitacaoHabilidade = "";
        } else {
          var solicitacaoHabilidade =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_habilidade;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_atitude == "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_atitude == null
        ) {
          var solicitacaoAtitude = "";
        } else {
          var solicitacaoAtitude =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_atitude;
        }

        if (
          this.dadosTicketAtendendoReciclar
            .solicitacao_pessoal_requisito_experiencia == "" ||
          this.dadosTicketAtendendoReciclar
            .solicitacao_pessoal_requisito_experiencia == null
        ) {
          var solicitacaoRequisitoExperiencia = "";
        } else {
          var solicitacaoRequisitoExperiencia =
            this.dadosTicketAtendendoReciclar
              .solicitacao_pessoal_requisito_experiencia;
        }

        if (
          this.dadosTicketAtendendoReciclar
            .solicitacao_pessoal_idade_referencial == "" ||
          this.dadosTicketAtendendoReciclar
            .solicitacao_pessoal_idade_referencial == null
        ) {
          var solicitacaoIdadeReferencial = "";
        } else {
          var solicitacaoIdadeReferencial =
            this.dadosTicketAtendendoReciclar
              .solicitacao_pessoal_idade_referencial;
        }

        if (
          this.dadosTicketAtendendoReciclar
            .solicitacao_pessoal_salario_beneficio == "" ||
          this.dadosTicketAtendendoReciclar
            .solicitacao_pessoal_salario_beneficio == null
        ) {
          var solicitacaoSalarioBeneficio = "";
        } else {
          var solicitacaoSalarioBeneficio =
            this.dadosTicketAtendendoReciclar
              .solicitacao_pessoal_salario_beneficio;
        }

        if (
          this.dadosTicketAtendendoReciclar
            .solicitacao_pessoal_salario_descricao_atividade == "" ||
          this.dadosTicketAtendendoReciclar
            .solicitacao_pessoal_salario_descricao_atividade == null
        ) {
          var solicitacaoDescricaoAtividade = "";
        } else {
          var solicitacaoDescricaoAtividade =
            this.dadosTicketAtendendoReciclar
              .solicitacao_pessoal_salario_descricao_atividade;
        }

        if (
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_observacao ==
            "" ||
          this.dadosTicketAtendendoReciclar.solicitacao_pessoal_observacao ==
            null
        ) {
          var solicitacaoObservacao = "";
        } else {
          var solicitacaoObservacao =
            this.dadosTicketAtendendoReciclar.solicitacao_pessoal_observacao;
        }
        //Limpar null das variaveis

        const perfil = JSON.parse(localStorage.getItem("storageData"));

        var self = this;

        let formData = new FormData();
        for (let i = 0; i < this.fileReceipt.length; i++) {
          let file = self.fileReceipt[i];
          formData.append("files[" + i + "]", file);
        }

        formData.append("idUser", perfil.pk_id_usuario);
        formData.append(
          "atividade",
          this.dadosTicketAtendendoReciclar.fk_id_atendimento_categoria
        );
        formData.append("destinatario", "");
        formData.append(
          "solicitante",
          this.dadosTicketAtendendoReciclar.requerente
        );

        formData.append("motivo", solicitacaoMotivo);
        formData.append("genero", solicitacaoGenero);
        formData.append("turno", solicitacaoTurno);
        formData.append("divisao", solicitacaoDivisao);
        formData.append("cargo", solicitacaoCargo);
        formData.append("centroCusto", solicitacaoCentroCusto);
        formData.append("razaoSocial", solicitacaoRazaoSocial);
        formData.append("endereco", solicitacaoEndereco);
        formData.append("bairro", solicitacaoBairro);
        formData.append("escala", solicitacaoEscala);
        formData.append("conhecimentos", solicitacaoConhecimento);
        formData.append("habilidades", solicitacaoHabilidade);
        formData.append("atitudes", solicitacaoAtitude);
        formData.append(
          "requisitosExperiencia",
          solicitacaoRequisitoExperiencia
        );
        formData.append("idadeReferencial", solicitacaoIdadeReferencial);
        formData.append("salarioBeneficios", solicitacaoSalarioBeneficio);
        formData.append("descricaoAtividade", solicitacaoDescricaoAtividade);
        formData.append("observacoes", solicitacaoObservacao);
        formData.append("lider", solicitacaoPessoalLider);
        formData.append("gestor", solicitacaoPessoalGestor);

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        try {
          //Requisição atualizar dados
          await axios
            .post("chamado/addNovaSolicitaçãoPessoal", formData, config)
            .then((response) => {
              self.$refs.files.value = "";
              self.fileReceipt = [];

              console.log(response.data);

              alert(response.data);

              this.$router.push("historico-chamado");
            });
        } catch (error) {
          console.log(error.response.status);

          const erroHttp = error.response.status;
          switch (erroHttp) {
            case 401:
              alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
              break;
            case 404:
              alert(
                "Pagina não encontrada na Api! - [Erro] " +
                  error.response.status
              );
              break;
          }
        }
      } catch (error) {
        //console.log(error.response.status)

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async addNovoHistoricoTicket() {
      this.overlayShow = true;

      var self = this;

      const perfil = JSON.parse(localStorage.getItem("storageData"));

      let formData = new FormData();

      for (let i = 0; i < this.fileReceipt.length; i++) {
        let file = self.fileReceipt[i];
        formData.append("files[" + i + "]", file);
      }

      formData.append("idTicket", this.$route.query.id);
      formData.append("contentTextArea", this.contentHTML);
      formData.append("idUsuario", perfil.pk_id_usuario);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        //Requisição atualizar dados
        await axios
          .post("chamado/addNovoHistoricoTicket", formData, config)
          .then((response) => {
            console.log(response.data);

            self.$refs.files.value = "";
            self.fileReceipt = [];

            this.contentHTML = "";

            this.getTicket();

            this.overlayShow = false;
          });
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            this.overlayShow = false;
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            this.overlayShow = false;
            break;
        }
      }
    },

    showModal() {
      this.$refs["modal-transfer"].show();
    },

    hideModal() {
      this.transferirTicket();

      this.$refs["modal-transfer"].hide();
    },

    async downloadAnexo(idAnexo, nomeAnexo) {
      await axios
        .get(`anexo/downloadAnexoChamado/${idAnexo}`, { responseType: "blob" })
        .then((response) => {
          console.log(response.data);
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var filelink = document.createElement("a");
          filelink.href = fileURL;
          filelink.setAttribute("download", nomeAnexo);
          document.body.appendChild(filelink);
          filelink.click();
        });
    },

    openFileDialog() {
      this.$refs.files.value = null;
      this.$refs.files.click();
    },

    apagarAnexo(e) {
      this.fileReceipt.splice(e, 1);
    },

    handleFile() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.fileReceipt.push(this.$refs.files.files[i]);

        console.log(this.fileReceipt);
      }
    },
  },
};
</script>


<style scoped>
#nome-anexo {
  font-size: 12px;
  font-style: italic;
  color: dimgray;
}

#data-anexo {
  font-style: normal;
  font-weight: bold;
  color: black;
}

#descricao {
  background-color: #f8f8fa;
  padding: 10px 20px 1px 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

#backBody {
  background-color: #343a40;
}

#back-descricao {
  background-color: #e7e7e7;
}
</style>