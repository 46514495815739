import { render, staticRenderFns } from "./HistoricoChamado.vue?vue&type=template&id=06882b2c&scoped=true&"
import script from "./HistoricoChamado.vue?vue&type=script&scoped=true&lang=js&"
export * from "./HistoricoChamado.vue?vue&type=script&scoped=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06882b2c",
  null
  
)

export default component.exports