<template>
  <div>
    <b-overlay :show="overlayShow" rounded="sm">
      
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Unidades</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/perfil">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Unidades</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section v-if="showsection" class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary card-outline">
                  <div class="card-body">
                    <div class="row">
                      <div
                        v-for="unidade in dadosUnidade"
                        :key="unidade.pk_id_unidade"
                        class="col-md-4"
                      >
                        <router-link
                          :to="'/membros-unidade?id=' + unidade.pk_id_unidade"
                        >
                          <div class="box">
                            <div class="card mb-4 box-shadow">
                              <img
                                class="card-img-top"
                                :src="
                                  require(`../../../assets/img/unidades/${unidade.foto}`)
                                "
                                alt="Card image cap"
                              />

                              <div class="card-body">
                                <h2 class="lead">
                                  <b>{{ unidade.nome_unidade }}</b>
                                </h2>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                  <li class="small">
                                    <span class="fa-li"
                                      ><i class="fas fa-lg fa-user-tie"></i
                                    ></span>
                                    Gestor: {{ unidade.gestor_unidade }} -
                                    <p>
                                      <b
                                        ><u>{{ unidade.email_gestor }}</u></b
                                      >
                                    </p>
                                  </li>
                                  <li class="small">
                                    <span class="fa-li"
                                      ><i class="fas fa-lg fa-building"></i
                                    ></span>
                                    Endereço:
                                    {{ unidade.endereco }}
                                  </li>
                                  <br />
                                  <li class="small">
                                    <span class="fa-li"
                                      ><i class="fas fa-lg fa-phone"></i
                                    ></span>
                                    Telefone:
                                    {{
                                      unidade.telefone | VMask("(##) ####-####")
                                    }}
                                  </li>
                                </ul>
                                <br />
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-overlay>
  </div>
</template>

<script scoped>
const axios = require("axios").default;

export default {
  name: "Unidade",

  data() {
    return {
      dadosUnidade: [],
      showsection: false,
      overlayShow: true,
    };
  },

  mounted() {
    this.unidades();
  },

  methods: {
    async unidades() {
      try {
        //Requisição atualizar dados
        const response = await axios.get("unidade/unidades");

        this.dadosUnidade = response.data;

        this.showsection = true;
        this.overlayShow = false;

        //console.log(this.dadosUnidade);
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>


<style scoped>
.box:hover {
  transition: 0.1s;
}

.box:hover {
  transform: scale(1.0);
  background: #444444;
  z-index: 4;
  box-shadow: 4px 4px 4px #444444;

}

a:link {
  text-decoration: none;
}

.lead {
  color: #444444;
}
</style>