<template>
  <div>
    <b-overlay :show="overlayShow" rounded="sm">
      
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Controle de Acesso - Base</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="">Home</a></li>
                  <li class="breadcrumb-item active">
                    Controle de Acesso - Unidades
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section v-if="showsection" class="content">
          <div class="card card-solid">
            <div class="card-body pb-0">
              <div class="row d-flex align-items-stretch">
                <div
                  v-for="unidade in dadosUnidade"
                  :key="unidade.pk_id_unidade"
                  class="col-md-3"
                >
                  <div class="box">
                    <router-link
                      :to="
                        '/controle-acesso-formulario?id=' +
                        unidade.pk_id_unidade
                      "
                    >
                      <div class="card mb-4 box-shadow">
                        <img
                          class="card-img-top"
                          :src="
                            require(`../../../assets/img/unidades/${unidade.foto}`)
                          "
                          alt="Card image cap"
                        />
                        <div class="card-body">
                          <div class="text-muted text-center">
                            {{ unidade.nome_unidade }}
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-overlay>
  </div>
</template>

<script scoped>
const axios = require("axios").default;

export default {
  name: "ControleAcesso",

  data() {
    return {
      dadosUnidade: [],
      overlayShow: true,
      showsection: false,
    };
  },

  mounted() {
    this.unidades();
  },

  methods: {
    async unidades() {
      try {
        //Requisição atualizar dados
        const response = await axios.get("unidade/unidades");

        this.dadosUnidade = response.data;

        this.showsection = true;
        this.overlayShow = false

        //console.log(this.dadosUnidade);
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>


<style scoped>
.box:hover {
  transition: 0.1s;
}

.box:hover {
  transform: scale(1.1);
  background: #444444;
  z-index: 2;
  box-shadow: 2px 2px 2px #000;
}

a:link {
  text-decoration: none;
}
</style>