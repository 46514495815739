<template>
  <div>
    <body>
      <div class="limiter">
        <div class="container-login100">
          <div class="wrap-login100">
            <div class="login100-form-title" :style="cssProps">
              <span class="login100-form-title-1">Conecta-STV</span>
            </div>

            <p class="login-box-msg mt-3">
              <b> Complemento Cadastral </b> <br />
              Você está a apenas um passo do seu Perfil.
            </p>

            <div class="card-body">
              <div class="form-group row mb-5">
                <div class="col-sm-12">
                  <label id="label-decoration" class="mb-0"
                    >Selecione sua Equipe</label
                  >

                  <v-select
                    placeholder="--Selecione sua Equipe--"
                    v-model="selectedEquipe"
                    :options="optionEquipes"
                    required
                    label="nome_equipe"
                  >
                  
                  </v-select>
                </div>
              </div>

              <div class="mt-5">
                <b-button
                  @click="onSubmit"
                  pill
                  variant="primary"
                  :disabled="statusButton"
                  class="float-right"
                  ><i class="fas fa-save"></i> Salvar</b-button
                >
                <b-button type="reset" @click="logout" pill variant="secondary"
                  ><i class="fas fa-times-circle"></i> Cancelar</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "ComplementoCadastralEquipe",

  data() {
    return {  
      selectedEquipe: "",
      optionEquipes:[],

      cssProps: {
        backgroundImage: `url(${require("./Components/img/teste1.jpg")})`,
      }, 
    };
  },

  computed: {
    statusButton() {
      return this.selectedEquipe != "" && this.selectedEquipe != null ? false : true
    }
  },

  mounted() {
    this.dadosAtualizacaoCadastral();
  },

  methods: {

    async logout() {     
      try {
          //Requisição de Logout
          const response = await axios.post('auth/logout')

          //Limpar localStorage
          localStorage.clear();

          delete axios.defaults.headers.common["Authorization"];

          //Redirecionamento para Dashboard
          window.location.href="/";

      } catch (error) {
          alert('Erro no logout');
      }
    },

    async onSubmit() {

      const perfil = JSON.parse(localStorage.getItem("storageData"));

      try {
        const response = await axios.post("atualizacaoCadastral/addComplementoCadastralEquipe", {
          idEquipe: this.selectedEquipe.pk_id_equipe,
          idUsuario: perfil.pk_id_usuario
        });

        localStorage.setItem( 'equipeUser', JSON.stringify(response.data));

        alert("Complemento cadastral efetuado com sucesso!!")

        //this.dadosAtualizacaoCadastral();

        //Redirecionamento para Dashboard
        window.location.href="perfil";

      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
      
    },

    async dadosAtualizacaoCadastral() {
      try {
        const response = await axios.get("atualizacaoCadastral/dadosComplementoCadastral");

        this.optionEquipes = response.data.equipes;

        // const storageData = JSON.parse(localStorage.getItem("storageData"));
        // const equipeteste = JSON.parse(localStorage.getItem("equipeUser"));
        
        // console.log(storageData);
        // console.log(equipeteste);
  
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>

<style scoped src="./Components/css/style.css">
</style>