<template>
  <div>
    <div v-if="isLogged">

      <div v-if="primeiroAcesso == 'Sim'">

        <div v-if="route == '/avaliacao-chamado?id='+urlParametroId+'&iduser='+urlParametroIdUser">
          <AvaliacaoTicket/>
        </div>

        <div v-else>              
          <div v-if="temEquipe['0']">
            <HeaderComponents/>
            <NavbarComponents/>
            <SidebarComponents/> 
            <router-view :key="$route.fullPath"></router-view>      
            <FooterComponents/>
          </div>

          <div v-else>
            <ComplementoCadastralEquipe/>
          </div>
        </div>

      </div>

      <div v-else>
        <PrimeiroAcesso/>
      </div>

    </div>
 
    <router-view v-else/>
   
  </div>
</template>

<script scoped>
  import HeaderComponents from './views/Dashboard/ComponentsLayout/HeaderComponents'
  import NavbarComponents from './views/Dashboard/ComponentsLayout/NavbarComponents'
  import SidebarComponents from './views/Dashboard/ComponentsLayout/SidebarComponents'
  import FooterComponents from './views/Dashboard/ComponentsLayout/FooterComponents.vue'

  import PrimeiroAcesso from './views/Dashboard/ViewPrimeiroAcesso/PrimeiroAcesso.vue'

  import ComplementoCadastralEquipe from './views/Dashboard/ViewPrimeiroAcesso/ComplementoCadastralEquipe.vue'

  import AvaliacaoTicket from './views/Dashboard/ViewAvaliacao/Avaliacao.vue'




export default {
  data: () => ({ 
    isLogged: false,
    primeiroAcesso: '',
    temEquipe: [],
    route: '',
    urlParametroId: '',
    urlParametroIdUser: '',
    
  }),
  
  mounted(){

    const perfil = JSON.parse(localStorage.getItem("storageData"));
    const equipe = JSON.parse(localStorage.getItem('equipeUser'));

    this.primeiroAcesso = perfil.atualizacao_cadastral
    this.temEquipe = equipe

    this.isLogged = !!localStorage.getItem('token')

    this.route = this.$route.fullPath
    this.urlParametroId = this.$route.query.id
    this.urlParametroIdUser = this.$route.query.iduser

    console.log(this.urlParametroIdUser)
  },

  components: {
    HeaderComponents,
    NavbarComponents,
    SidebarComponents,
    FooterComponents,
    PrimeiroAcesso,
    ComplementoCadastralEquipe,
    AvaliacaoTicket
  },
}
</script>

<style>

</style>

