<template>
  <div>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"
            ><i class="fas fa-bars"></i
          ></a>
        </li>
        <!--
        <li class="nav-item d-none d-sm-inline-block">
          <router-link to="/perfil" class="nav-link">Home</router-link>
        </li>
        -->
      </ul>

      <div class="form-inline ml-4">
        <div class="input-group input-group-sm">
          <AutocompleteComponents
            v-model="pesquisaUser"
            :options="pesquisaUsers"
            label-key="nome"
            value-key="pk_id_usuario"
            placeholder="Pesquisar Usuário..."
            id="autocomplete"
            @shouldSearch="pesquisarUsuarios"
            @select="userSelect"
          />
          <!-- <div class="input-group-append">
            <button class="btn btn-navbar" type="button" @click="perfilUser()">
              <i class="fas fa-search"></i>
            </button>
          </div> -->
        </div>
      </div>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" data-widget="fullscreen" href="#" role="button">
            <i class="fas fa-expand-arrows-alt"></i>
          </a>
        </li>

        <li
          v-if="
            user.nivel_atendimento == 'Administrador'"
          class="nav-item"
        >
          <a
            class="nav-link"
            data-widget="control-sidebar"
            data-slide="true"
            href="#"
            role="button"
          >
            <i class="fas fa-cogs"></i>
          </a>
        </li>

        <li
          class="dropdown user user-menu"
          style="margin-top: 10px; margin-left: 10px"
        >
          <a href="#" data-toggle="dropdown">
            <img
              v-if="user.thumbnail"
              :src="require(`../../../assets/img/profiles/profile.png`)"
              class="user-image"
              alt="img usuario"
            />

            <img
              v-else
              :src="require(`../../../assets/img/profiles/profile.png`)"
              class="user-image"
              alt="img usuario"
            />
          </a>

          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <!-- User image -->
            <li class="user-header">
              <img
                v-if="user.thumbnail"
                :src="require(`../../../assets/img/profiles/profile.png`)"
                class="img-circle"
                alt="img usuario"
              />

              <img
                v-else
                :src="require(`../../../assets/img/profiles/profile.png`)"
                class="img-circle"
                alt="img usuario"
              />

              <p>
                {{ user.nome }} - {{ user.funcao }}
                <small
                  >Membro desde {{ user.data_hora_cadastro | moment }}</small
                >
              </p>
            </li>
            <!-- Menu Footer-->
            <li class="user-footer">
              <router-link
                to="/alterar-senha"
                style="width: 80px; margin-left: 10%"
                class="btn btn-sm btn-secondary float-left"
                ><i class="nav-icon fas fa-key"></i> Senha
              </router-link>

              <a
                v-on:click="logout"
                style="width: 80px; margin-right: 10%"
                class="btn btn-sm btn-danger float-right"
                ><i class="fas fa-sign-out-alt"></i> Sair</a
              >
            </li>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "NavbarComponents",

  data() {
    return {
      user: [],

      pesquisaUser: "", //===>Autocomplete<===\\
      pesquisaUsers: [], //===>Autocomplete<===\\
      usuarioSelecionado: "", //===>Autocomplete<===\\
    };
  },

  mounted() {
    const perfil = JSON.parse(localStorage.getItem("storageData"));
    this.user = perfil;
  },

  filters: {
    moment: function (date) {
      return moment(date).format("ll");
    },
  },

  components: {
    AutocompleteComponents,
  },


  methods: {
    pesquisarUsuarios(query) {
      //===>Autocomplete<===\\
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.pesquisaUsers = response.data;
      });
    },

    userSelect(pesquisaUser) {
      //===>Autocomplete<===\\
      console.log(pesquisaUser);
      this.usuarioSelecionado = pesquisaUser;

      const idUser = this.usuarioSelecionado["pk_id_usuario"]

      if (this.usuarioSelecionado) {

        this.$router.push(
          "perfil-usuario?id=" + idUser
        );

        //window.location.href=("perfil-usuario?id=" + this.usuarioSelecionado["pk_id_usuario"]);
      } else {
      }
    },

    // perfilUser(idUser) {
    //   if (this.usuarioSelecionado) {
    //     this.$router.push(
    //       "perfil-usuario?id=" + this.usuarioSelecionado["pk_id_usuario"]
    //     );
    //     //window.location.href=("perfil-usuario?id=" + this.usuarioSelecionado["pk_id_usuario"]);
    //   } else {
    //   }
    // },

    async logout() {
      try {
        //Requisição de Logout
        const response = await axios.post("auth/logout");

        //Limpar localStorage
        localStorage.clear();

        delete axios.defaults.headers.common["Authorization"];

        //Redirecionamento para Dashboard
        window.location.href = "/";
      } catch (error) {
        alert("Erro no logout");
      }
    },
  },
};
</script>


<style scoped>

#autocomplete {
  font-style: bold;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: rgba(87, 140, 165, 0.322);
}
</style>