<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>
              <b>Membros Equipe:</b> <i>{{ equipe }}</i>
            </h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Membros Equipe</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary card-outline">

             
                <router-link :to="'/troca-usuario-equipe'">
                  <b-button
                    type="button"
                    class="float-right mr-2 mt-2 mb-0"
                    variant="outline-secondary"
                    size="sm"
                  >
                    <i id="size-ico" class="fas fa-undo"> Voltar</i>
                  </b-button>
                </router-link>
             

              <div class="mt-0" v-if="gestorEquipe.nome">
                <div class="ml-4">
                  <div class="card-title">
                    <b>Responsável:</b> {{ gestorEquipe.nome }}
                    <ul class="ml-4 mb-0 fa-ul text-muted">
                      <li class="small">
                        <span class="fa-li"
                          ><i class="fas fa-envelope"></i
                        ></span>
                        {{ gestorEquipe.email }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="mt-0" v-else>
                <div class="ml-4">
                  <div class="card-title">
                    <b>Responsável: </b>
                    <i>Equipe sem Responsável selecionado</i>
                  </div>
                </div>
                <br /><br />
              </div>

              <div class="card-body">
                <b-container fluid>
                  <b-row>
                    <b-input-group class="mt-0 mb-1">
                      <b-input-group-append>
                        <b-form-input
                          size="sm"
                          id="filter-input"
                          v-model="configTableMembros.filter"
                          type="search"
                          placeholder="Pesquisar..."
                        ></b-form-input>
                      </b-input-group-append>
                    </b-input-group>

                    <b-table
                      :busy="isBusy"
                      striped
                      outlined
                      hover
                      bordered
                      head-variant="dark"
                      :dark="true"
                      :footClone="true"
                      :items="itemsMembros"
                      :fields="fieldsMembros"
                      :current-page="configTableMembros.currentPage"
                      :per-page="configTableMembros.perPage"
                      :filter="configTableMembros.filter"
                      :filter-included-fields="configTableMembros.filterOn"
                      :sort-by.sync="configTableMembros.sortBy"
                      :sort-desc.sync="configTableMembros.sortDesc"
                      :sort-direction="configTableMembros.sortDirection"
                      stacked="md"
                      show-empty
                      small
                    >
                      <template #empty>
                        <h5 class="text-center">
                          {{ tableMembros.emptyTexts }}
                        </h5>
                      </template>

                      <template #emptyfiltered>
                        <h5 class="text-center">
                          {{ tableMembros.emptyFilteredTexts }}
                        </h5>
                      </template>

                      <template #table-busy>
                        <div class="text-center text-danger my-3">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong> Carregando...</strong>
                        </div>
                      </template>

                      <template #head(foto)="data">
                        <span class="text-info">{{
                          data.label.toUpperCase()
                        }}</span>
                      </template>
                      <template #head(nome)="data">
                        <span class="text-info">{{
                          data.label.toUpperCase()
                        }}</span>
                      </template>
                      <template #head(nivel_relacao)="data">
                        <span class="text-info">{{
                          data.label.toUpperCase()
                        }}</span>
                      </template>
                      <template #head(funcao)="data">
                        <span class="text-info">{{
                          data.label.toUpperCase()
                        }}</span>
                      </template>
                      <template #head(email)="data">
                        <span class="text-info">{{
                          data.label.toUpperCase()
                        }}</span>
                      </template>
                      <template #head(ramal)="data">
                        <span class="text-info">{{
                          data.label.toUpperCase()
                        }}</span>
                      </template>
                      <template #head(nome_unidade)="data">
                        <span class="text-info">{{
                          data.label.toUpperCase()
                        }}</span>
                      </template>
                      <template #head(actions)="data">
                        <span class="text-info">{{
                          data.label.toUpperCase()
                        }}</span>
                      </template>

                      <template #cell(foto)="row">
                        <div class="user-panel pb-1 d-flex">
                          <div v-if="row.item.foto" class="image">
                            <img
                              :src="
                                require(`../../../assets/img/profiles/${row.item.foto}`)
                              "
                              class="img-circle elevation-1"
                              alt="User Image"
                            />
                          </div>

                          <div class="image">
                            <img
                              :src="
                                require(`../../../assets/img/profiles/profile.png`)
                              "
                              class="img-circle elevation-1"
                              alt="User Image"
                            />
                          </div>
                        </div>
                      </template>

                      <template #cell(actions)="row">
                        <div class="text-center">
                          <b-button
                            variant="info"
                            size="sm"
                            @click="editarUsuarioEquipe(row.item.pk_id_usuario)"
                            v-b-modal.editUsuarioEquipe
                          >
                            <i class="fas fa-exchange-alt"></i> Transferir
                          </b-button>
                        </div>
                      </template>
                    </b-table>

                    <b-tfoot>
                      <div class="text-muted">
                        <div class="small">
                          <span><i class="fas fa-list-ol"></i></span> Total de
                          registros: <b>{{ itemsMembros.length }}</b>
                        </div>
                      </div>
                    </b-tfoot>

                    <b-col sm="5" md="0" class="my-1">
                      <b-form-group
                        label="Quantidade"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="8"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="configTableMembros.perPage"
                          :options="configTableMembros.pageOptions"
                          size="sm"
                        >
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col sm="5" md="4" class="my-1">
                      <b-pagination
                        v-model="configTableMembros.currentPage"
                        :total-rows="membrosRows"
                        :per-page="configTableMembros.perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </b-container>

                <b-modal
                  id="editUsuarioEquipe"
                  centered
                  title="Transferir Usuário"
                  hide-footer
                  no-close-on-backdrop
                >
                  <div class="card-body">
                    <form @submit="onSubmitEdit">
                      <div class="form-group row">
                        <div class="col-sm-12"></div>
                        <div id="text-decoration" class="col-sm-12 text-center">
                          {{ usuarioTransferir.nomeUsuarioTransferir }}
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label id="label-decoration" class="mb-0"
                            >Equipes</label
                          >

                          <v-select
                            placeholder="-Selecione uma Equipe-"
                            v-model="usuarioTransferir.selectedEquipe"
                            :options="usuarioTransferir.optionEquipe"
                            label="nome_equipe"
                          >
                          </v-select>
                        </div>
                      </div>

                      <div class="mt-4">
                        <b-form-group>
                          <div class="mt-2 mb-3">
                            Selecione o nível de relação deste funcionário à
                            esta equipe:
                          </div>

                          <b-form-radio-group
                            id="btn-nivel-relacao"
                            v-model="selectedNivelRelacao"
                            :options="optionsNivelRelacao"
                            class="mb-3"
                            value-field="value"
                            text-field="text"
                          ></b-form-radio-group>

                          <b-tooltip
                            target="btn-nivel-relacao"
                            triggers="hover"
                          >
                            <p>
                              Escolha o nível de relação do usuário com a equipe
                              de detino.
                            </p>
                            <div><b>Relação direta.</b></div>
                            <div><b>Relação indireta.</b></div>
                          </b-tooltip>
                        </b-form-group>
                      </div>

                      <div>
                        <b-button
                          @click="$bvModal.hide('editUsuarioEquipe')"
                          type="submit"
                          block
                          variant="primary"
                          class="float-right"
                          ><i class="fas fa-save"></i> Salvar</b-button
                        >
                      </div>
                    </form>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script scoped>
const axios = require("axios").default;

export default {
  name: "TransferirMembrosEquipe",

  components: {},

  data() {
    return {
      usuarioTransferir: {
        nomeUsuarioTransferir: "",
        idUsuarioTransferir: "",
        optionEquipe: [],
        selectedEquipe: "",
      },

      selectedNivelRelacao: "1",
      optionsNivelRelacao: [
        { text: "Relação direta", value: "1" },
        { text: "Relação indireta", value: "2" },
      ],

      equipe: "",
      isBusy: true,
      gestorEquipe: [],
      itemsMembros: [],

      tableMembros: {
        emptyTexts: "Não há membros cadastrado nesta equipe!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsMembros: [
        {
          key: "foto",
          label: "#",
        },

        {
          key: "nome",
          label: "Nome",
          sortable: true,
        },

        {
          key: "nivel_relacao",
          label: "Relação",
          sortable: true,
        },

        {
          key: "funcao",
          label: "Função",
          sortable: true,
        },

        {
          key: "email",
          label: "E-mail",
          sortable: true,
        },

        {
          key: "ramal",
          label: "Ramal",
          sortable: true,
        },

        {
          key: "nome_unidade",
          label: "Unidade",
          sortable: true,
        },

        {
          key: "actions",
          label: "Ação",
        },
      ],

      configTableMembros: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
    };
  },

  computed: {
    membrosRows() {
      return this.itemsMembros.length;
    },
  },

  mounted() {
    this.equipeMembros();
    this.optionEquipes();
  },

  methods: {
    async equipeMembros() {
      try {
        //Requisição atualizar dados
        const response = await axios.post("equipe/membrosEquipe", {
          idEquipe: this.$route.query.id,
        });

        this.equipe = response.data.equipe.original.nome_equipe;
        this.gestorEquipe = response.data.gestorEquipe.original;
        this.itemsMembros = response.data.membros;

        this.isBusy = false;
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async optionEquipes() {
      this.isBusy = true;
      try {
        const response = await axios.get("equipe/todasEquipes");

        this.usuarioTransferir.optionEquipe = response.data;

        this.isBusy = false;
      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async editarUsuarioEquipe(idUser) {
      this.onResetEdit();

      this.itemsMembros.forEach((itemsMembros) => {
        if (itemsMembros.pk_id_usuario == idUser) {
          this.usuarioTransferir.nomeUsuarioTransferir = itemsMembros.nome;
          this.usuarioTransferir.idUsuarioTransferir =
            itemsMembros.pk_id_usuario;
        }
      });
    },

    onResetEdit() {
      this.usuarioTransferir.nomeUsuarioTransferir = "";
      this.usuarioTransferir.idUsuarioTransferir = "";
      this.usuarioTransferir.selectedEquipe = "";
      this.selectedNivelRelacao = "1";
    },

    async onSubmitEdit(event) {
      event.preventDefault();

      if (this.usuarioTransferir.selectedEquipe == "") {
        alert("Escolha uma Equipe!");
      } else {
        try {
          //Requisição atualizar dados
          const response = await axios.post("equipe/trasferirUsuarioEquipe", {
            idEquipeOld: this.$route.query.id,
            idEquipeNew: this.usuarioTransferir.selectedEquipe.pk_id_equipe,
            idUsuario: this.usuarioTransferir.idUsuarioTransferir,
            nivelRelacao: this.selectedNivelRelacao,
          });

          // console.log(response.data);

          alert(response.data);

          this.equipeMembros();
        } catch (error) {
          console.log(error.response.status);

          const erroHttp = error.response.status;
          switch (erroHttp) {
            case 401:
              alert("Api Pabx fora do ar! - [Erro] " + error.response.status);

              this.statusButton = false;
              break;
            case 404:
              alert(
                "Pagina não encontrada na Api! - [Erro] " +
                  error.response.status
              );

              this.statusButton = false;
              break;
          }
        }
      }
    },
  },
};
</script>


<style scoped>
#label-decoration {
  color: rgb(139, 139, 139);
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
}

#text-decoration {
  color: rgb(139, 139, 139);
  font-style: italic;
  font-weight: bold;
  font-size: 24px;
}

#input-autocomplete {
  border-style: solid;
}
</style>