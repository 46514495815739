<template>
  <div>
    <b-overlay :show="overlayShow" rounded="sm">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1
                  style="
                    color: #0d7cff;
                    text-transform: uppercase;
                    font-weight: bold;
                  "
                >
                  {{ dadosUsuario.nome }}
                </h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/perfil">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Perfil</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section v-if="showsection" class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3">
                <div class="card card-primary card-outline">
                  <div class="card-body box-profile">
                    <div class="text-center">
                      <img
                        v-if="dadosUsuario.foto"
                        :src="
                          require(`../../../assets/img/profiles/${dadosUsuario.foto}`)
                        "
                        class="profile-user-img img-fluid img-circle"
                        alt="img usuario"
                      />
                      <img
                        v-else
                        :src="
                          require(`../../../assets/img/profiles/profile.png`)
                        "
                        class="profile-user-img img-fluid img-circle"
                        alt="img usuario"
                      />
                    </div>
                    <h3 class="profile-username text-center">
                      {{ dadosUsuario.nome }}
                    </h3>
                    <p class="text-muted text-center">
                      {{ dadosUsuario.equipe }}
                    </p>
                  </div>
                </div>

                <!--=========================================================================-->
                <div
                  v-if="dadosRamalUsuario.status == 'Ocupado'"
                  class="card card-warning shadow-lg"
                >
                  <div class="card-header">
                    <div>
                      <i class="fas fa-tty"></i>
                      <b> {{ dadosRamalUsuario.numero_ramal }} </b>
                      <small>({{ dadosRamalUsuario.status }})</small>
                    </div>

                    <div>
                      <small>
                        <i class="fas fa-user"></i>
                        {{ dadosUsuario.nome }}
                      </small>
                    </div>
                  </div>

                  <div class="mb-2 mt-2 ml-2 mr-2">
                    <small>
                      <i class="fas fa-phone-alt"></i>
                      (51)99443-2851

                      <u v-if="2 > 3" class="float-right">
                        <i class="fas fa-arrow-up"></i>
                      </u>

                      <u v-else class="float-right">
                        <i class="fas fa-arrow-down"></i>
                      </u>
                    </small>
                  </div>
                </div>

                <div
                  v-else-if="dadosRamalUsuario.status == 'Disponivel'"
                  class="card card-success shadow-lg"
                >
                  <div class="card-header">
                    <div>
                      <i class="fas fa-tty"></i>
                      <b> {{ dadosRamalUsuario.numero_ramal }} </b>
                      <small>({{ dadosRamalUsuario.status }})</small>
                    </div>

                    <div>
                      <small>
                        <i class="fas fa-user"></i>
                        {{ dadosUsuario.nome }}
                      </small>
                    </div>
                  </div>

                  <div class="mb-2 mt-2 ml-2 mr-2">
                    <small>
                     <i class="fas fa-thumbs-up"></i>
                      Disponível
                    </small>
                  </div>
                </div>

                <div
                  v-else-if="dadosRamalUsuario.status == 'Ausente'"
                  class="card card-secondary shadow-lg"
                >
                  <div class="card-header">
                    <div>
                      <i class="fas fa-tty"></i>
                      <b> {{ dadosRamalUsuario.numero_ramal }} </b>
                      <small>({{ dadosRamalUsuario.status }})</small>
                    </div>

                    <div>
                      <small>
                        <i class="fas fa-user"></i>
                        {{ dadosUsuario.nome }}
                      </small>
                    </div>
                  </div>

                  <div class="mb-2 mt-2 ml-2 mr-2">
                    <small>
                      <i class="fas fa-exclamation-triangle"></i>
                      Ausente
                    </small>
                  </div>
                </div>

                <div
                  v-else-if="dadosRamalUsuario.status == 'Em Ligação'"
                  class="card card-danger shadow-lg"
                >
                  <div class="card-header">
                    <div>
                      <i class="fas fa-tty"></i>
                      <b> {{ dadosRamalUsuario.numero_ramal }} </b>
                      <small>({{ dadosRamalUsuario.status }})</small>
                    </div>

                    <div>
                      <small>
                        <i class="fas fa-user"></i>
                        {{ dadosUsuario.nome }}
                      </small>
                    </div>
                  </div>

                  <div class="mb-2 mt-2 ml-2 mr-2">
                    <small>
                      <i class="fas fa-phone-alt"></i>
                      (51)99443-2851

                      <u v-if="2 > 1" class="float-right">
                        <i class="fas fa-arrow-up"></i>
                      </u>

                      <u v-else class="float-right">
                        <i class="fas fa-arrow-down"></i>
                      </u>
                    </small>
                  </div>
                </div>

                <div
                  v-else
                  class="card card-secondary shadow-lg"
                >
                  <div class="card-header">
                    <div>
                      <i class="fas fa-tty"></i>
                      <b> Sem Ramal</b>
                    </div>

                    <div>
                      <small>
                        <i class="fas fa-user"></i>
                        {{ dadosUsuario.nome }}
                      </small>
                    </div>
                  </div>
                </div>

                <!--=========================================================================-->
              </div>

              <div class="col-md-9">
                <div class="card card-secondary card-outline">
                  <div class="card-body">
                    <div class="tab-pane" id="settings">
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <label for="nome">Nome</label><br />
                          <div class="conteudo">{{ dadosUsuario.nome }}</div>
                        </div>
                        <div class="col-sm-6">
                          <label for="matricula">Matrícula</label><br />
                          <div class="conteudo">
                            {{ dadosUsuario.matricula }}
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <label for="funcao">Função</label><br />
                          <div class="conteudo">{{ dadosUsuario.funcao }}</div>
                        </div>
                        <div class="col-sm-6">
                          <label for="centro_custo">Centro de Custo</label
                          ><br />
                          <div class="conteudo">
                            {{ dadosUsuario.unidade }}-{{
                              dadosUsuario.setor
                            }}-{{ dadosUsuario.area }}
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <label for="equipe">Equipe</label><br />
                          <div class="conteudo">{{ dadosUsuario.equipe }}</div>
                        </div>
                        <div class="col-sm-6">
                          <label for="aniversario">Aniversário</label><br />
                          <div class="conteudo">
                            {{ dadosUsuario.data_nascimento }}
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <label for="email">E-mail</label><br />
                          <div class="conteudo">{{ dadosUsuario.email }}</div>
                        </div>
                        <div class="col-sm-6">
                          <label for="celular_empresa">Celular Empresa</label
                          ><br />
                          <div class="conteudo">
                            {{ dadosUsuario.celular_empresa }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-overlay>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "PerfilUsuario",

  data() {
    return {
      dadosUsuario: [],
      dadosRamalUsuario: [],
      showsection: false,
      overlayShow: true,
    };
  },

  mounted() {
    this.ramalUsuario();
    this.perfilUsuario();
    this.refresh();
  },

  beforeDestroy() {
    clearInterval(this.intervalid);
  },


  methods: {
    async perfilUsuario() {
      try {
        //Requisição atualizar dados
        const response = await axios.post("usuario/usuarioPerfil", {
          idUsuario: this.$route.query.id,
        });

        this.dadosUsuario = response.data;

        if (this.dadosUsuario.data_nascimento) {
          this.dadosUsuario.data_nascimento =
            this.dadosUsuario.data_nascimento.substring(0, 5);
        }

        this.showsection = true;
        this.overlayShow = false;
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async ramalUsuario() {
      try {
        //Requisição atualizar dados
        const response = await axios.post("usuario/ramalPerfil", {
          idUsuario: this.$route.query.id,
        });

        this.dadosRamalUsuario = response.data;

        console.log('teste')

      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    refresh: function () {
      this.intervalid = setInterval(() => {
        this.ramalUsuario();
      }, 2000);
    },
  },
};
</script>

<style>
</style>