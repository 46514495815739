<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Troca de Senha</h1>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"> <router-link to="/perfil">Home</router-link></li>
                <li class="breadcrumb-item active">Troca de Senha</li>
                </ol>
            </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <section class="content">
            <div class="container-fluid" style="display: flex; justify-content: center; align-items: center;">
                <section class="col-lg-6 connectedSortable">
                    <!-- Custom tabs (Charts with tabs)-->
                    <div class="card">
                        <div class="card-body">
                            <div class="login-logo">
                                <a><b>Alterar</b>Senha</a>
                            </div>
                            <div class="card-body login-card-body">
                                <p class="login-box-msg">Você está a apenas um passo de sua nova senha.</p>

                                <form @submit.prevent="handleSubmit">

                                   
                                    <ErrorComponents v-if="error" :errorComponents="error"/>
                                    <ErrorComponents v-if="repasswordError" :errorComponents="repasswordError"/>  
                                    <CorrectComponents v-if="correct" :correctComponents="correct"/>
                                 

                                    <div class="input-group mb-3">
                                        <input type="password" name="password" class="form-control" id="password" v-model.trim="senha" placeholder="Senha" required>
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                            <span class="fas fa-lock"></span>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="input-group mb-3">
                                        <input type="password" name="repassword" class="form-control" id="repassword" v-model.trim="repassword" placeholder="Repita a Senha" required>
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                            <span class="fas fa-lock"></span>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="row"> 
                                        <div class="col-12">
                                            <button type="submit" class="btn btn-primary btn-block">Alterar Senha</button>
                                        </div>
                                    </div>
        
                                </form>
                            </div>
                        </div><!-- /.card-body -->
                    </div>
                </section>
            </div><!-- /.container-fluid -->
        </section>
    </div>  
</template>

<script>
const axios = require('axios').default
import ErrorComponents from './Components/ErrorComponents.vue'
import CorrectComponents from './Components/CorrectComponents.vue'

export default {
    name: 'AlterarSenha',

    data() {
        return {
            senha: '',
            repassword: '',
            error: '',
            correct: '',
            repasswordError: '',
        }
    },

    methods: {
        async handleSubmit(){

            if(this.senha == this.repassword){
                try {

                    const perfil = await (JSON.parse(localStorage.getItem('storageData')))

                    const response = await axios.post('auth/newPassword',{
                        senha: this.senha,
                        pk_id_usuario: perfil.pk_id_usuario
                    });

                    this.correct = response.data.message
                    this.repasswordError = ''

                } catch (error) {

                   // console.log(error.response.status)

                    const erroHttp = error.response.status;
                    switch (erroHttp) {
                    case 404:
                        this.error = ('Pagina não encontrada na Api! - [Erro] '+error.response.status);
                        break;
                    }

                }   
            }else{
                this.repasswordError = 'Campo REPITA A SENHA não confere!'
                this.correct = ''
            }   
        },
    },

    components: {
        ErrorComponents,
        CorrectComponents
    }
}
</script>

<style>

</style>