<template>
  <div class="content-wrapper">
    <b-overlay :show="isBusy" rounded="lg">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>Relatório E-mails</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link to="/perfil">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Relatório E-mails</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-primary card-outline">
                <div class="col-sm-12 mt-2 mb-4">
                  <b-form inline>
                    <b-form-datepicker
                      size="sm"
                      placeholder="De:"
                      id="datepicker-valid"
                      v-model="dataInicial"
                      :state="true"
                      class="mr-1"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        weekday: 'short',
                      }"
                    ></b-form-datepicker>

                    <b-form-datepicker
                      size="sm"
                      placeholder="Até:"
                      id="datepicker-invalid"
                      v-model="dataFinal"
                      :state="false"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        weekday: 'short',
                      }"
                    ></b-form-datepicker>

                    <v-select
                      class="col-sm-4"
                      size="sm"
                      placeholder="-Selecione unidade-"
                      v-model="selectedUnidade"
                      :options="optionUnidade"
                      label="nomeUnidade"
                    >
                    </v-select>

                    <b-button
                      :disabled="statusButton"
                      size="sm"
                      variant="primary"
                      @click="consultarEmails()"
                      >Pesquisar</b-button
                    >
                  </b-form>
                </div>

                <b-tabs
                  active-nav-item-class="font-weight-bold text-uppercase"
                  content-class="mt-3"
                >
                  <!--///////////////////====Emails Enviados====//////////////////////-->
                  <b-tab title="Enviados" active>
                    <div class="card-body">
                      <b-container fluid>
                        <b-row>
                          <b-input-group class="mt-0 mb-1">
                            <b-input-group-append>
                              <b-form-input
                                size="sm"
                                id="filter-input"
                                v-model="configTableEmailsEnviados.filter"
                                type="search"
                                placeholder="Pesquisar..."
                              ></b-form-input>
                            </b-input-group-append>
                          </b-input-group>

                          <b-table
                            :busy="isBusy"
                            :striped="true"
                            :outlined="true"
                            :hover="true"
                            :bordered="false"
                            :borderless="false"
                            :noCollapse="false"
                            :dark="false"
                            :fixed="false"
                            :small="true"
                            :footClone="true"
                            head-variant="dark"
                            :items="itemsEmailsEnviados"
                            :fields="fieldsEmailsEnviados"
                            :current-page="
                              configTableEmailsEnviados.currentPage
                            "
                            :per-page="configTableEmailsEnviados.perPage"
                            :filter="configTableEmailsEnviados.filter"
                            :filter-included-fields="
                              configTableEmailsEnviados.filterOn
                            "
                            :sort-by.sync="configTableEmailsEnviados.sortBy"
                            :sort-desc.sync="configTableEmailsEnviados.sortDesc"
                            :sort-direction="
                              configTableEmailsEnviados.sortDirection
                            "
                            stacked="md"
                            show-empty
                          >
                            <template #empty>
                              <h5 class="text-center">
                                {{ tableEmailsEnviados.emptyTexts }}
                              </h5>
                            </template>

                            <template #emptyfiltered>
                              <h5 class="text-center">
                                {{ tableEmailsEnviados.emptyFilteredTexts }}
                              </h5>
                            </template>

                            <template #table-busy>
                              <div class="text-center text-danger my-3">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong> Carregando...</strong>
                              </div>
                            </template>

                            <template #cell(data_hora_cadastro)="row">
                              <div class="small">
                                <span
                                  ><i class="fas fa-history text-muted"></i
                                ></span>
                                {{ row.data_hora_cadastro | moment }}
                              </div>
                            </template>
                          </b-table>

                          <b-tfoot>
                            <div class="text-muted">
                              <div class="small">
                                <span><i class="fas fa-list-ol"></i></span>
                                Total de registros:
                                <b>{{ itemsEmailsEnviados.length }}</b>
                              </div>
                            </div>
                          </b-tfoot>

                          <b-col sm="5" md="0" class="my-1">
                            <b-form-group
                              label="Quantidade"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="8"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="configTableEmailsEnviados.perPage"
                                :options="configTableEmailsEnviados.pageOptions"
                                size="sm"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col sm="5" md="4" class="my-1">
                            <b-pagination
                              v-model="configTableEmailsEnviados.currentPage"
                              :total-rows="emailsEnviadosRows"
                              :per-page="configTableEmailsEnviados.perPage"
                              align="fill"
                              size="sm"
                              class="my-0"
                            ></b-pagination>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </b-tab>

                  <!--///////////////////====Emails Lidos====//////////////////////-->
                  <b-tab title="Lidos">
                    <div class="card-body">
                      <b-container fluid>
                        <b-row>
                          <b-input-group class="mt-0 mb-1">
                            <b-input-group-append>
                              <b-form-input
                                size="sm"
                                id="filter-input"
                                v-model="configTableEmailsLidos.filter"
                                type="search"
                                placeholder="Pesquisar..."
                              ></b-form-input>
                            </b-input-group-append>
                          </b-input-group>

                          <b-table
                            :striped="true"
                            :outlined="true"
                            :hover="true"
                            :bordered="false"
                            :borderless="false"
                            :noCollapse="false"
                            :dark="false"
                            :fixed="false"
                            :small="true"
                            :footClone="true"
                            head-variant="dark"
                            :items="itemsEmailsLidos"
                            :fields="fieldsEmailsLidos"
                            :current-page="configTableEmailsLidos.currentPage"
                            :per-page="configTableEmailsLidos.perPage"
                            :filter="configTableEmailsLidos.filter"
                            :filter-included-fields="
                              configTableEmailsLidos.filterOn
                            "
                            :sort-by.sync="configTableEmailsLidos.sortBy"
                            :sort-desc.sync="configTableEmailsLidos.sortDesc"
                            :sort-direction="
                              configTableEmailsLidos.sortDirection
                            "
                            stacked="md"
                            show-empty
                          >
                            <template #empty>
                              <h5 class="text-center">
                                {{ tableEmailsLidos.emptyTexts }}
                              </h5>
                            </template>

                            <template #emptyfiltered>
                              <h5 class="text-center">
                                {{ tableEmailsLidos.emptyFilteredTexts }}
                              </h5>
                            </template>

                            <template #cell(data_hora_cadastro)="row">
                              <div class="small">
                                <span
                                  ><i class="fas fa-history text-muted"></i
                                ></span>
                                {{ row.data_hora_cadastro | moment }}
                              </div>
                            </template>
                          </b-table>

                          <b-tfoot>
                            <div class="text-muted">
                              <div class="small">
                                <span><i class="fas fa-list-ol"></i></span>
                                Total de registros:
                                <b>{{ itemsEmailsLidos.length }}</b>
                              </div>
                            </div>
                          </b-tfoot>

                          <b-col sm="5" md="0" class="my-1">
                            <b-form-group
                              label="Quantidade"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="8"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="configTableEmailsLidos.perPage"
                                :options="configTableEmailsLidos.pageOptions"
                                size="sm"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col sm="5" md="4" class="my-1">
                            <b-pagination
                              v-model="configTableEmailsLidos.currentPage"
                              :total-rows="emailsLidosRows"
                              :per-page="configTableEmailsLidos.perPage"
                              align="fill"
                              size="sm"
                              class="my-0"
                            ></b-pagination>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </b-tab>

                  <!--///////////////////====Emails Visualizados====//////////////////////-->
                  <b-tab title="Visualizações">
                    <div class="card-body">
                      <b-container fluid>
                        <b-row>
                          <b-input-group class="mt-0 mb-1">
                            <b-input-group-append>
                              <b-form-input
                                size="sm"
                                id="filter-input"
                                v-model="configTableEmailsVisualizados.filter"
                                type="search"
                                placeholder="Pesquisar..."
                              ></b-form-input>
                            </b-input-group-append>
                          </b-input-group>

                          <b-table
                            :striped="true"
                            :outlined="true"
                            :hover="true"
                            :bordered="false"
                            :borderless="false"
                            :noCollapse="false"
                            :dark="false"
                            :fixed="false"
                            :small="true"
                            :footClone="true"
                            head-variant="dark"
                            :items="itemsEmailsVisualizados"
                            :fields="fieldsEmailsVisualizados"
                            :current-page="
                              configTableEmailsVisualizados.currentPage
                            "
                            :per-page="configTableEmailsVisualizados.perPage"
                            :filter="configTableEmailsVisualizados.filter"
                            :filter-included-fields="
                              configTableEmailsVisualizados.filterOn
                            "
                            :sort-by.sync="configTableEmailsVisualizados.sortBy"
                            :sort-desc.sync="
                              configTableEmailsVisualizados.sortDesc
                            "
                            :sort-direction="
                              configTableEmailsVisualizados.sortDirection
                            "
                            stacked="md"
                            show-empty
                          >
                            <template #empty>
                              <h5 class="text-center">
                                {{ tableEmailsVisualizados.emptyTexts }}
                              </h5>
                            </template>

                            <template #emptyfiltered>
                              <h5 class="text-center">
                                {{ tableEmailsVisualizados.emptyFilteredTexts }}
                              </h5>
                            </template>

                            <template #cell(data_hora_cadastro)="row">
                              <div class="small">
                                <span
                                  ><i class="fas fa-history text-muted"></i
                                ></span>
                                {{ row.data_hora_cadastro | moment }}
                              </div>
                            </template>
                          </b-table>

                          <b-tfoot>
                            <div class="text-muted">
                              <div class="small">
                                <span><i class="fas fa-list-ol"></i></span>
                                Total de registros:
                                <b>{{ itemsEmailsVisualizados.length }}</b>
                              </div>
                            </div>
                          </b-tfoot>

                          <b-col sm="5" md="0" class="my-1">
                            <b-form-group
                              label="Quantidade"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="8"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="configTableEmailsVisualizados.perPage"
                                :options="
                                  configTableEmailsVisualizados.pageOptions
                                "
                                size="sm"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col sm="5" md="4" class="my-1">
                            <b-pagination
                              v-model="
                                configTableEmailsVisualizados.currentPage
                              "
                              :total-rows="emailsVisualizadosRows"
                              :per-page="configTableEmailsVisualizados.perPage"
                              align="fill"
                              size="sm"
                              class="my-0"
                            ></b-pagination>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </b-tab>

                  <!--///////////////////====Emails Rejeitados====//////////////////////-->
                  <b-tab title="Rejeitados">
                    <div class="card-body">
                      <b-container fluid>
                        <b-row>
                          <b-input-group class="mt-0 mb-1">
                            <b-input-group-append>
                              <b-form-input
                                size="sm"
                                id="filter-input"
                                v-model="configTableEmailsRejeitados.filter"
                                type="search"
                                placeholder="Pesquisar..."
                              ></b-form-input>
                            </b-input-group-append>
                          </b-input-group>

                          <b-table
                            :striped="true"
                            :outlined="true"
                            :hover="true"
                            :bordered="false"
                            :borderless="false"
                            :noCollapse="false"
                            :dark="false"
                            :fixed="false"
                            :small="true"
                            :footClone="true"
                            head-variant="dark"
                            :items="itemsEmailsRejeitados"
                            :fields="fieldsEmailsRejeitados"
                            :current-page="
                              configTableEmailsRejeitados.currentPage
                            "
                            :per-page="configTableEmailsRejeitados.perPage"
                            :filter="configTableEmailsRejeitados.filter"
                            :filter-included-fields="
                              configTableEmailsRejeitados.filterOn
                            "
                            :sort-by.sync="configTableEmailsRejeitados.sortBy"
                            :sort-desc.sync="
                              configTableEmailsRejeitados.sortDesc
                            "
                            :sort-direction="
                              configTableEmailsRejeitados.sortDirection
                            "
                            stacked="md"
                            show-empty
                          >
                            <template #empty>
                              <h5 class="text-center">
                                {{ tableEmailsRejeitados.emptyTexts }}
                              </h5>
                            </template>

                            <template #emptyfiltered>
                              <h5 class="text-center">
                                {{ tableEmailsRejeitados.emptyFilteredTexts }}
                              </h5>
                            </template>

                            <template #cell(data_hora_cadastro)="row">
                              <div class="small">
                                <span
                                  ><i class="fas fa-history text-muted"></i
                                ></span>
                                {{ row.data_hora_cadastro | moment }}
                              </div>
                            </template>
                          </b-table>

                          <b-tfoot>
                            <div class="text-muted">
                              <div class="small">
                                <span><i class="fas fa-list-ol"></i></span>
                                Total de registros:
                                <b>{{ itemsEmailsRejeitados.length }}</b>
                              </div>
                            </div>
                          </b-tfoot>

                          <b-col sm="5" md="0" class="my-1">
                            <b-form-group
                              label="Quantidade"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="8"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="configTableEmailsRejeitados.perPage"
                                :options="
                                  configTableEmailsRejeitados.pageOptions
                                "
                                size="sm"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col sm="5" md="4" class="my-1">
                            <b-pagination
                              v-model="configTableEmailsRejeitados.currentPage"
                              :total-rows="emailsRejeitadosRows"
                              :per-page="configTableEmailsRejeitados.perPage"
                              align="fill"
                              size="sm"
                              class="my-0"
                            ></b-pagination>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </b-tab>

                  <!--///////////////////====Emails Não lidos====//////////////////////-->
                  <b-tab title="Não lidos">
                    <div class="card-body">
                      <b-container fluid>
                        <b-row>
                          <b-input-group class="mt-0 mb-1">
                            <b-input-group-append>
                              <b-form-input
                                size="sm"
                                id="filter-input"
                                v-model="configTableEmailsNaoLidos.filter"
                                type="search"
                                placeholder="Pesquisar..."
                              ></b-form-input>
                            </b-input-group-append>
                          </b-input-group>

                          <b-table
                            :striped="true"
                            :outlined="true"
                            :hover="true"
                            :bordered="false"
                            :borderless="false"
                            :noCollapse="false"
                            :dark="false"
                            :fixed="false"
                            :small="true"
                            :footClone="true"
                            head-variant="dark"
                            :items="itemsEmailsNaoLidos"
                            :fields="fieldsEmailsNaoLidos"
                            :current-page="
                              configTableEmailsNaoLidos.currentPage
                            "
                            :per-page="configTableEmailsNaoLidos.perPage"
                            :filter="configTableEmailsNaoLidos.filter"
                            :filter-included-fields="
                              configTableEmailsNaoLidos.filterOn
                            "
                            :sort-by.sync="configTableEmailsNaoLidos.sortBy"
                            :sort-desc.sync="configTableEmailsNaoLidos.sortDesc"
                            :sort-direction="
                              configTableEmailsNaoLidos.sortDirection
                            "
                            stacked="md"
                            show-empty
                          >
                            <template #empty>
                              <h5 class="text-center">
                                {{ tableEmailsNaoLidos.emptyTexts }}
                              </h5>
                            </template>

                            <template #emptyfiltered>
                              <h5 class="text-center">
                                {{ tableEmailsNaoLidos.emptyFilteredTexts }}
                              </h5>
                            </template>

                            <template #cell(data_hora_cadastro)="row">
                              <div class="small">
                                <span
                                  ><i class="fas fa-history text-muted"></i
                                ></span>
                                {{ row.data_hora_cadastro | moment }}
                              </div>
                            </template>
                          </b-table>

                          <b-tfoot>
                            <div class="text-muted">
                              <div class="small">
                                <span><i class="fas fa-list-ol"></i></span>
                                Total de registros:
                                <b>{{ itemsEmailsNaoLidos.length }}</b>
                              </div>
                            </div>
                          </b-tfoot>

                          <b-col sm="5" md="0" class="my-1">
                            <b-form-group
                              label="Quantidade"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="8"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                            >
                              <b-form-select
                                id="per-page-select"
                                v-model="configTableEmailsNaoLidos.perPage"
                                :options="configTableEmailsNaoLidos.pageOptions"
                                size="sm"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col sm="5" md="4" class="my-1">
                            <b-pagination
                              v-model="configTableEmailsNaoLidos.currentPage"
                              :total-rows="emailsNaoLidosRows"
                              :per-page="configTableEmailsNaoLidos.perPage"
                              align="fill"
                              size="sm"
                              class="my-0"
                            ></b-pagination>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </b-overlay>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "ChamadoRecebido",

  data() {
    return {
      dataInicial: "",
      dataFinal: "",

      selectedUnidade: "",
      optionUnidade: [
        {
          codeUnidade: [
            "619",
            "639",
            "1298",
            "642",
            "1299",
            "641",
            "1200",
            "1199",
            "644",
            "1198",
            "1291",
            "640",
            "643",
            "1321",
            "1309",
            "1310",
            "659",
          ],
          nomeUnidade: "Todas",
        },
        { codeUnidade: ["659"], nomeUnidade: "Administrativo" },
        { codeUnidade: ["619"], nomeUnidade: "Porto Alegre - Alarme" },
        { codeUnidade: ["1299"], nomeUnidade: "Porto Alegre - Empresa" },
        { codeUnidade: ["1298"], nomeUnidade: "Porto Alegre - Condomínio" },
        { codeUnidade: ["642"], nomeUnidade: "Xangri-lá" },
        { codeUnidade: ["639"], nomeUnidade: "Canoas" },
        { codeUnidade: ["641"], nomeUnidade: "Caxias do Sul" },
        { codeUnidade: ["1200"], nomeUnidade: "Cachoeirinha" },
        { codeUnidade: ["1199"], nomeUnidade: "Bento" },
        { codeUnidade: ["644"], nomeUnidade: "São Leopoldo" },
        { codeUnidade: ["1198"], nomeUnidade: "Novo Hamburgo" },
        { codeUnidade: ["640"], nomeUnidade: "Veicular" },
        { codeUnidade: ["1291"], nomeUnidade: "Santa Cruz" },
        { codeUnidade: ["643"], nomeUnidade: "Itajaí" },
        { codeUnidade: ["1321"], nomeUnidade: "Curitiba" },
        { codeUnidade: ["1309"], nomeUnidade: "São Paulo" },
        { codeUnidade: ["1310"], nomeUnidade: "Campinas" },
      ],

      isBusy: false,

      /////////////////////////<====Emails Enviados====>///////////////////////////////////////////
      itemsEmailsEnviados: [],

      tableEmailsEnviados: {
        emptyTexts: "Não há registro de e-mail nesta categoria!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsEmailsEnviados: [
        {
          key: "data_hora_cadastro",
          label: "Data",
          sortable: true,
        },

        {
          key: "campanhaID",
          label: "Códico",
          sortable: true,
        },

        {
          key: "email",
          label: "E-mail",
          sortable: true,
        },

        {
          key: "assunto",
          label: "Assunto",
          sortable: true,
        },

        {
          key: "tipo",
          label: "Evento",
          sortable: true,
        },

        {
          key: "status_email",
          label: "Lido",
          sortable: true,
        },
      ],

      configTableEmailsEnviados: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },

      /////////////////////////<====Emails Lidos====>///////////////////////////////////////////
      itemsEmailsLidos: [],

      tableEmailsLidos: {
        emptyTexts: "Não há registro de e-mail nesta categoria!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsEmailsLidos: [
        {
          key: "data_hora_cadastro",
          label: "Data",
          sortable: true,
        },

        {
          key: "campanhaID",
          label: "Códico",
          sortable: true,
        },

        {
          key: "email",
          label: "E-mail",
          sortable: true,
        },

        {
          key: "assunto",
          label: "Assunto",
          sortable: true,
        },
      ],

      configTableEmailsLidos: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },

      /////////////////////////<====Emails Visualizados====>///////////////////////////////////////////
      itemsEmailsVisualizados: [],

      tableEmailsVisualizados: {
        emptyTexts: "Não há registro de e-mail nesta categoria!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsEmailsVisualizados: [
        {
          key: "data_hora_cadastro",
          label: "Data",
          sortable: true,
        },

        {
          key: "email",
          label: "E-mail",
          sortable: true,
        },

        {
          key: "assunto",
          label: "Assunto",
          sortable: true,
        },

        {
          key: "tipo",
          label: "Evento",
          sortable: true,
        },

        {
          key: "descricao",
          label: "Descrição",
          sortable: true,
        },
      ],

      configTableEmailsVisualizados: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },

      /////////////////////////<====Emails Rejeitados====>///////////////////////////////////////////
      itemsEmailsRejeitados: [],

      tableEmailsRejeitados: {
        emptyTexts: "Não há registro de e-mail nesta categoria!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsEmailsRejeitados: [
        {
          key: "data_hora_cadastro",
          label: "Data",
          sortable: true,
        },

        {
          key: "campanhaID",
          label: "Códico",
          sortable: true,
        },

        {
          key: "email",
          label: "E-mail",
          sortable: true,
        },

        {
          key: "assunto",
          label: "Assunto",
          sortable: true,
        },

        {
          key: "tipo",
          label: "Evento",
          sortable: true,
        },

        {
          key: "descricao",
          label: "Descrição",
          sortable: true,
        },
      ],

      configTableEmailsRejeitados: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },

      /////////////////////////<====Emails Não Lidos====>///////////////////////////////////////////
      itemsEmailsNaoLidos: [],

      tableEmailsNaoLidos: {
        emptyTexts: "Não há registro de e-mail nesta categoria!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsEmailsNaoLidos: [
        {
          key: "data_hora_cadastro",
          label: "Data",
          sortable: true,
        },

        {
          key: "campanhaID",
          label: "Códico",
          sortable: true,
        },

        {
          key: "email",
          label: "E-mail",
          sortable: true,
        },

        {
          key: "assunto",
          label: "Assunto",
          sortable: true,
        },

        {
          key: "tipo",
          label: "Evento",
          sortable: true,
        },

        {
          key: "status_email",
          label: "Lido",
          sortable: true,
        },
      ],

      configTableEmailsNaoLidos: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
    };
  },

  computed: {
    emailsEnviadosRows() {
      return this.itemsEmailsEnviados.length;
    },

    emailsLidosRows() {
      return this.itemsEmailsLidos.length;
    },

    emailsVisualizadosRows() {
      return this.itemsEmailsVisualizados.length;
    },

    emailsRejeitadosRows() {
      return this.itemsEmailsRejeitados.length;
    },

    emailsNaoLidosRows() {
      return this.itemsEmailsNaoLidos.length;
    },

    statusButton() {
      return this.dataInicial.length > 1 &&
        this.dataFinal.length > 1 &&
        this.selectedUnidade
        ? false
        : true;
    },
  },

  methods: {
    async consultarEmails() {
      try {
        this.isBusy = true;

        if (this.dataInicial > this.dataFinal) {
          alert(
            "A data final de sua pesquisa tem que ser maior ou igual a data inicial"
          );
        } else if (!this.selectedUnidade) {
          alert("Sem unidade selecionada");
        } else {
          const response = await axios.post("email/extratoEmail", {
            dataInicio: this.dataInicial,
            dataFim: this.dataFinal,
            unidade: this.selectedUnidade.codeUnidade,
          });

          this.itemsEmailsEnviados = response.data.emailsEnviados;
          this.itemsEmailsLidos = response.data.emailsLidos;
          this.itemsEmailsVisualizados = response.data.emailsVisualizados;
          this.itemsEmailsRejeitados = response.data.emailsRejeitados;
          this.itemsEmailsNaoLidos = response.data.emailsNaoLidos;

          //console.log(response.data);

          this.isBusy = false;
        }
      } catch (error) {
        //console.log(error.response.status)

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("L - LTS");
    },
  },
};
</script>


<style scoped>
</style>