<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Chamado - Novo</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Chamado</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="card card-primary card-outline">
              <div class="card-body">
                <div class="tab-pane" id="settings">
                  <div class="form-group row text-center">
                    <div class="col-sm-12">
                      <b-form-radio-group
                        id="btn-radios-2"
                        v-model="selectedEquipe"
                        :options="optionsEquipe"
                        button-variant="outline-secondary"
                        name="radio-btn-outline"
                        buttons
                      ></b-form-radio-group>
                    </div>
                  </div>

                  <div class="form-group row mt-5">
                    <div class="col-sm-12">
                      <label id="label-decoration" class="mb-0" for="nome"
                        >Solicitante</label
                      >
                      <div>
                        <AutocompleteComponents
                          v-model="userSolicitante"
                          :options="usersSolicitante"
                          label-key="nome"
                          value-key="pk_id_usuario"
                          placeholder="Solicitante..."
                          @shouldSearch="pesquisarUsuarios"
                          @select="onSelect"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="selectedEquipe == 'minhaEquipe'"
                    class="form-group row mt-4"
                  >
                    <div class="col-sm-12">
                      <label id="label-decoration" class="mb-0"
                        >Atividade Equipe</label
                      >

                      <v-select
                        placeholder="-Selecione uma Atividade-"
                        v-model="selectedAtividade"
                        :options="optionAtividade"
                        label="nome"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div v-else class="form-group row mt-4">
                    <div class="col-sm-12">
                      <label id="label-decoration" class="mb-0"
                        >Atividade Todas</label
                      >

                      <v-select
                        placeholder="-Selecione uma Atividade-"
                        v-model="selectedAtividadeTodas"
                        :options="optionAtividadeTodas"
                        label="nome"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div
                    v-if="selectedEquipe == 'minhaEquipe'"
                    class="form-group row mt-4"
                  >
                    <div class="col-sm-12">
                      <label id="label-decoration" class="mb-0"
                        >Destinatário</label
                      >

                      <v-select
                        placeholder="Equipe"
                        v-model="selectedDestinatario"
                        :options="optionDestinatario"
                        label="label"
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="
              selectedAtividadeTodas.formulario == 'Sim' ||
              selectedAtividadeTodas.formulario == 'Sim'
            "
            class="col-md-8"
          >
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><b>Solicitação de Pessoal</b></h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-6">
                    <!-- text input -->
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-building"></i> DIVISÃO:</label
                      >
                      <input
                        v-model="solicitacaoPessoal.divisao"
                        type="text"
                        class="form-control form-control-sm"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-calendar-alt"></i> DATA DE
                        ADMISSÃO:</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Data de admissão"
                        disabled=""
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <!-- text input -->
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-portrait"></i> CARGO:</label
                      >
                      <input
                        v-model="solicitacaoPessoal.cargo"
                        type="text"
                        class="form-control form-control-sm"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-user-shield"></i> LIDER:</label
                      >
                      <div>
                        <AutocompleteComponents
                          v-model="solicitacaoPessoal.userLider"
                          :options="solicitacaoPessoal.usersLider"
                          label-key="nome"
                          value-key="pk_id_usuario"
                          placeholder="Pesquisar líder..."
                          @shouldSearch="pesquisarUsuariosLider"
                          @select="onSelectLider"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-user-tie"></i> GESTOR:</label
                      >
                      <div>
                        <AutocompleteComponents
                          v-model="solicitacaoPessoal.userGestor"
                          :options="solicitacaoPessoal.usersGestor"
                          label-key="nome"
                          value-key="pk_id_usuario"
                          placeholder="Pesquisar Gestor..."
                          @shouldSearch="pesquisarUsuariosGestor"
                          @select="onSelectGestor"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-coins"></i> C.CUSTO:</label
                      >
                      <input
                        v-mask="'##-###'"
                        v-model="solicitacaoPessoal.centroCusto"
                        type="text"
                        class="form-control form-control-sm"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div class="row mb-4 mt-3">
                  <div class="col-sm-12">
                    <div id="label-style">
                      <b-form-radio-group
                        v-model="solicitacaoPessoal.selectedMotivo"
                        :options="solicitacaoPessoal.optionsMotivo"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-radio-group>
                    </div>
                  </div>
                </div>

                <div class="tab-custom-content mb-4"></div>

                <div class="form-group row">
                  <label
                    id="label-style"
                    for="razao-social"
                    class="col-sm-2 col-form-label"
                    >R. Social:</label
                  >
                  <div class="col-sm-10">
                    <input
                      v-model="solicitacaoPessoal.razaoSocial"
                      type="text"
                      class="form-control form-control-sm"
                      id="razao-social"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    id="label-style"
                    for="endereco"
                    class="col-sm-2 col-form-label"
                    >Endereço:</label
                  >
                  <div class="col-sm-10">
                    <input
                      v-model="solicitacaoPessoal.endereco"
                      type="text"
                      class="form-control form-control-sm"
                      id="endereco"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    id="label-style"
                    for="bairro"
                    class="col-sm-2 col-form-label"
                    >Bairro:</label
                  >
                  <div class="col-sm-10">
                    <input
                      v-model="solicitacaoPessoal.bairro"
                      type="text"
                      class="form-control form-control-sm"
                      id="bairro"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    id="label-style"
                    for="escala"
                    class="col-sm-2 col-form-label"
                    >Escala:</label
                  >
                  <div class="col-sm-10">
                    <input
                      v-model="solicitacaoPessoal.escala"
                      type="text"
                      class="form-control form-control-sm"
                      id="escala"
                      placeholder=""
                    />
                  </div>
                </div>

                <div class="tab-custom-content mb-4 mt-4"></div>

                <div class="row mb-4 mt-3">
                  <div class="col-sm-12">
                    <label id="label-style">TURNO:</label>
                    <div id="label-style">
                      <b-form-radio-group
                        v-model="solicitacaoPessoal.selectedTurno"
                        :options="solicitacaoPessoal.optionsTurno"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-radio-group>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-header">
                <h3 class="card-title"><b>Perfil de Cargo</b></h3>
              </div>
              <div class="card-body">
                <!-- input states -->

                <div class="row">
                  <div class="col-sm-4">
                    <!-- textarea -->
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-bookmark"></i> CONHECIMENTOS</label
                      >
                      <textarea
                        v-model="solicitacaoPessoal.conhecimentos"
                        class="form-control"
                        rows="3"
                        placeholder="Conhecimento ..."
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-bookmark"></i> HABILIDADES</label
                      >
                      <textarea
                        v-model="solicitacaoPessoal.habilidades"
                        class="form-control"
                        rows="3"
                        placeholder="Habilidades ..."
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-bookmark"></i> ATITUDES</label
                      >
                      <textarea
                        v-model="solicitacaoPessoal.atitudes"
                        class="form-control"
                        rows="3"
                        placeholder="Atitudes ..."
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <!-- textarea -->
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fab fa-black-tie"></i> REQUISITOS DE
                        EXPERIÊNCIA PROFISSIONAL</label
                      >
                      <textarea
                        v-model="solicitacaoPessoal.requisitosExperiencia"
                        class="form-control"
                        rows="3"
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="row mb-4 mt-3">
                  <div class="col-sm-12">
                    <div id="label-style">
                      <b-form-radio-group
                        v-model="solicitacaoPessoal.selectedGenero"
                        :options="solicitacaoPessoal.optionsGenero"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                      >
                      </b-form-radio-group>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    id="label-style"
                    class="col-form-label"
                    for="inputSuccess"
                    ><i class="fas fa-user-clock"></i> IDADE REFERENCIAL:
                  </label>
                  <input
                    v-mask="'##'"
                    v-model="solicitacaoPessoal.idadeReferencial"
                    type="text"
                    class="form-control form-control-sm col-sm-5"
                    id="inputSuccess"
                    placeholder=""
                  />
                </div>

                <div class="tab-custom-content mb-4 mt-4"></div>

                <div class="row">
                  <div class="col-sm-12">
                    <!-- textarea -->
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-money-check-alt"></i> SALÁRIO E
                        BENEFÍCIOS</label
                      >
                      <textarea
                        v-model="solicitacaoPessoal.salarioBeneficios"
                        class="form-control"
                        rows="3"
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="tab-custom-content mb-4 mt-4"></div>

                <div class="row">
                  <div class="col-sm-12">
                    <!-- textarea -->
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-file-alt"></i> DESCRIÇÃO DE ATIVIDADES
                        (CONFORME IT - CENTRO RECRUTAMENTO)</label
                      >
                      <textarea
                        v-model="solicitacaoPessoal.descricaoAtividade"
                        class="form-control"
                        rows="3"
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="tab-custom-content mb-4 mt-4"></div>

                <div class="row">
                  <div class="col-sm-12">
                    <!-- textarea -->
                    <div class="form-group">
                      <label id="label-style"
                        ><i class="fas fa-file-signature"></i>
                        OBSERVAÇÕES:</label
                      >
                      <textarea
                        v-model="solicitacaoPessoal.observacoes"
                        class="form-control"
                        rows="3"
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="mb-0 mt-3">
                  <b-button
                    @click="addSolicitacaoPessoal"
                    type="submit"
                    variant="primary"
                    size="sm"
                    class="float-right"
                  >
                    <i class="fas fa-paper-plane"></i>
                    <b> ENVIAR SOLICITAÇÂO</b></b-button
                  >

                  <input
                    ref="files"
                    type="file"
                    class="d-none"
                    accept=".png, .jpg, .jpeg, .doc,.docx,.pdf,.xls,.xlsx"
                    @change="handleFile"
                    multiple
                  />

                  <b-button
                    id="text-button-anexo"
                    @click="openFileDialog()"
                    type="button"
                    variant="outline-dark"
                    size="sm"
                    class="mb-2"
                  >
                    <i id="anexo" class="fas fa-paperclip"></i>
                    Anexo
                  </b-button>

                  <div class="m-auto">
                    <p
                      id="nome-anexo"
                      class="mb-0"
                      v-for="(filesAnexo, index) in fileReceipt"
                      :key="index"
                    >
                      {{ filesAnexo.name }}
                      <button
                        type="button"
                        @click="apagarAnexo(index)"
                        class="btn badge bagde-light"
                      >
                        <i class="fa fa-trash text-danger"></i>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
          </div>

          <div v-else class="col-md-8">
            <div class="card card-secondary card-outline">
              <div class="card-body">
                <b-form-group
                  id="input-group-1"
                  class="float-left col-sm-6 mb-4 mt-3"
                >
                  <label id="label-decoration" class="mb-0">Assunto</label>

                  <b-form-input
                    id="input-titulo"
                    v-model="titulo"
                    :state="titulo.length >= 5"
                    type="text"
                    placeholder="Título...."
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback class="mt-0" id="input-titulo">
                    Insira pelo menos 5 letras
                  </b-form-invalid-feedback>
                </b-form-group>

                <div class="float-left col-sm-12 mb-0">
                  <div>
                    <b-form-textarea
                      id="textarea-state"
                      v-model="contentHTML"
                      :state="contentHTML.length >= 10"
                      placeholder="Digite sua solicitação...."
                      rows="6"
                    ></b-form-textarea>

                    <b-form-invalid-feedback class="mt-0">
                      Insira pelo menos 10 letras
                    </b-form-invalid-feedback>
                  </div>

                  <div class="mb-0 mt-3">
                    <b-button
                      :disabled="statusButton"
                      @click="addChamado"
                      type="submit"
                      variant="primary"
                      size="sm"
                      class="float-right"
                    >
                      <i class="fas fa-paper-plane"></i>
                      <b> ENVIAR TICKET</b></b-button
                    >

                    <input
                      ref="files"
                      type="file"
                      class="d-none"
                      accept=".png, .jpg, .jpeg, .doc,.docx,.pdf,.xls,.xlsx"
                      @change="handleFile"
                      multiple
                    />

                    <b-button
                      id="text-button-anexo"
                      @click="openFileDialog()"
                      type="button"
                      variant="outline-dark"
                      size="sm"
                      class="mb-2"
                    >
                      <i id="anexo" class="fas fa-paperclip"></i>
                      Anexo
                    </b-button>

                    <div class="m-auto">
                      <p
                        id="nome-anexo"
                        class="mb-0"
                        v-for="(filesAnexo, index) in fileReceipt"
                        :key="index"
                      >
                        {{ filesAnexo.name }}
                        <button
                          type="button"
                          @click="apagarAnexo(index)"
                          class="btn badge bagde-light"
                        >
                          <i class="fa fa-trash text-danger"></i>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <b-card class="mt-5" header="Form Data Result">
        <pre class="m-0">{{ solicitacaoPessoal }}</pre>
      </b-card> -->

      <b-modal ref="my-modal" hide-footer title="Using Component Methods">
        <div class="d-block text-center">
          <h3>Hello From My Modal!</h3>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click="hideModal"
          >Close Me</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-warning"
          block
          @click="toggleModal"
          >Toggle Me</b-button
        >
      </b-modal>
    </section>
  </div>
</template>

<script>
const axios = require("axios").default;
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\

export default {
  name: "NovoChamado",

  computed: {
    statusButton() {
      return this.titulo.length > 4 && this.contentHTML.length > 9
        ? false
        : true;
    },
  },

  components: {
    AutocompleteComponents, //===>Autocomplete<===\\
  },

  data() {
    return {
      solicitacaoPessoal: {
        selectedMotivo: "Ampliação",
        optionsMotivo: [
          { item: "Ampliação", name: "AMPLIAÇÃO" },
          { item: "Implantação", name: "IMPLANTAÇÃO" },
          { item: "Reposição", name: "REPOSIÇÃO" },
        ],

        selectedGenero: "",
        optionsGenero: [
          { item: "Masculino", name: "MASCULINO" },
          { item: "Feminino", name: "FEMININO" },
        ],

        selectedTurno: "Dia",
        optionsTurno: [
          { item: "Dia", name: "DIA" },
          { item: "Noite", name: "NOITE" },
          { item: "Folguista", name: "FOLGUISTA" },
        ],

        divisao: "",
        dataAdmissao: "",
        cargo: "",
        // lider: "",
        // gestor: "",
        centroCusto: "",
        razaoSocial: "",
        endereco: "",
        bairro: "",
        escala: "",
        conhecimentos: "",
        habilidades: "",
        atitudes: "",
        requisitosExperiencia: "",
        idadeReferencial: "",
        salarioBeneficios: "",
        descricaoAtividade: "",
        observacoes: "",

        userLider: "", //===>Autocomplete<===\\
        usersLider: [], //===>Autocomplete<===\\
        userSelectLider: "", //===>Autocomplete<===\\

        userGestor: "", //===>Autocomplete<===\\
        usersGestor: [], //===>Autocomplete<===\\
        userSelectGestor: "", //===>Autocomplete<===\\
      },

      userSolicitante: "", //===>Autocomplete<===\\
      usersSolicitante: [], //===>Autocomplete<===\\
      userSelectSolicitante: "", //===>Autocomplete<===\\

      titulo: "",
      contentHTML: "",
      // solicitante: "",
      fileReceipt: [],

      selectedDestinatario: null,
      optionDestinatario: [],

      selectedAtividade: "",
      optionAtividade: [],

      selectedAtividadeTodas: "",
      optionAtividadeTodas: [],

      selectedEquipe: "outraEquipe",
      optionsEquipe: [
        { text: "Outra Equipe", value: "outraEquipe" },
        { text: "Minha Equipe", value: "minhaEquipe" },
      ],
    };
  },

  mounted() {
    this.dadosChamadoUser();
  },

  methods: {
    pesquisarUsuarios(query) {
      //===>Autocomplete<===\\
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.usersSolicitante = response.data;
      });
    },

    pesquisarUsuariosLider(query) {
      //===>Autocomplete<===\\
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.solicitacaoPessoal.usersLider = response.data;
      });
    },

    pesquisarUsuariosGestor(query) {
      //===>Autocomplete<===\\
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.solicitacaoPessoal.usersGestor = response.data;
      });
    },

    onSelect(userSolicitante) {
      //===>Autocomplete<===\\
      this.userSelectSolicitante = userSolicitante;
    },

    onSelectLider(userLider) {
      //===>Autocomplete<===\\
      this.solicitacaoPessoal.userSelectLider = userLider;
    },

    onSelectGestor(userGestor) {
      //===>Autocomplete<===\\
      this.solicitacaoPessoal.userSelectGestor = userGestor;
    },

    async dadosChamadoUser() {
      const perfil = JSON.parse(localStorage.getItem("storageData"));
      const equipe = JSON.parse(localStorage.getItem("equipeUser"));

      if (equipe["0"]) {
        try {
          const perfil = JSON.parse(localStorage.getItem("storageData"));
          const equipe = JSON.parse(localStorage.getItem("equipeUser"));

          const response = await axios.post("chamado/dadosChamado", {
            idUsuario: perfil.pk_id_usuario,
            equipes: equipe,
          });

          this.optionAtividadeTodas =
            response.data.todasAtividadesEquipes.original.flat(); //===>Achata Array .flat()<===\\
          var chamadoAtividades =
            response.data.atividadesEquipes.original.flat(); //===>Achata Array .flat()<===\\
          var userEquipe = response.data.usersEquipes.original.flat(); //===>Achata Array .flat()<===\\

          //===>Função limpar array item duplicado<===\\
          const userEquipeUnico = new Map();

          userEquipe.forEach((user) => {
            if (!userEquipeUnico.has(user.pk_id_usuario)) {
              userEquipeUnico.set(user.pk_id_usuario, user);
            }
          });

          this.optionDestinatario = [...userEquipeUnico.values()];
          //===>Função limpar array item duplicado<===\\

          console.log(this.optionAtividadeTodas);

          //===>Função limpar array item duplicado<===\\
          const chamadoAtividadesUnico = new Map();

          chamadoAtividades.forEach((atividade) => {
            if (
              !chamadoAtividadesUnico.has(atividade.pk_id_atendimento_categoria)
            ) {
              chamadoAtividadesUnico.set(
                atividade.pk_id_atendimento_categoria,
                atividade
              );
            }
          });

          this.optionAtividade = [...chamadoAtividadesUnico.values()];
          //===>Função limpar array item duplicado<===\\
        } catch (error) {
          //console.log(error.response.status)

          const erroHttp = error.response.status;
          switch (erroHttp) {
            case 500:
              alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
              break;
            case 404:
              alert(
                "Pagina não encontrada na Api! - [Erro] " +
                  error.response.status
              );
              break;
          }
        }
      } else {
        this.showModal();
      }
    },

    showModal() {
      this.$refs["my-modal"].show();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },

    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },

    async addSolicitacaoPessoal() {
      try {
        if (this.userSelectSolicitante == "") {
          alert("Adicione um Solicitante!");
        } else {
          if (this.selectedEquipe == "outraEquipe") {
            var destinatario = "";
            var atividade = this.selectedAtividadeTodas;
          } else {
            var atividade = this.selectedAtividade;

            if (
              this.selectedDestinatario == "" ||
              this.selectedDestinatario == null
            ) {
              var destinatario = "";
            } else {
              var destinatario = this.selectedDestinatario.pk_id_usuario;
            }
          }

          if (
            this.solicitacaoPessoal.userSelectLider == "" ||
            this.solicitacaoPessoal.userSelectLider == null
          ) {
            var solicitacaoPessoalLider = "";
          } else {
            var solicitacaoPessoalLider =
              this.solicitacaoPessoal.userSelectLider.pk_id_usuario;
          }

          if (
            this.solicitacaoPessoal.userSelectGestor == "" ||
            this.solicitacaoPessoal.userSelectGestor == null
          ) {
            var solicitacaoPessoalGestor = "";
          } else {
            var solicitacaoPessoalGestor =
              this.solicitacaoPessoal.userSelectGestor.pk_id_usuario;
          }

          if (atividade == "" || atividade == null) {
            alert("Selecione uma categoria de atividade!");
          } else {
            const perfil = JSON.parse(localStorage.getItem("storageData"));

            var self = this;

            let formData = new FormData();
            for (let i = 0; i < this.fileReceipt.length; i++) {
              let file = self.fileReceipt[i];
              formData.append("files[" + i + "]", file);
            }

            formData.append("idUser", perfil.pk_id_usuario);
            formData.append("atividade", atividade.pk_id_atendimento_categoria);
            formData.append("destinatario", destinatario);
            formData.append(
              "solicitante",
              this.userSelectSolicitante.pk_id_usuario
            );

            formData.append("motivo", this.solicitacaoPessoal.selectedMotivo);
            formData.append("genero", this.solicitacaoPessoal.selectedGenero);
            formData.append("turno", this.solicitacaoPessoal.selectedTurno);
            formData.append("divisao", this.solicitacaoPessoal.divisao);
            formData.append("cargo", this.solicitacaoPessoal.cargo);
            formData.append("centroCusto", this.solicitacaoPessoal.centroCusto);
            formData.append("razaoSocial", this.solicitacaoPessoal.razaoSocial);
            formData.append("endereco", this.solicitacaoPessoal.endereco);
            formData.append("bairro", this.solicitacaoPessoal.bairro);
            formData.append("escala", this.solicitacaoPessoal.escala);
            formData.append(
              "conhecimentos",
              this.solicitacaoPessoal.conhecimentos
            );
            formData.append("habilidades", this.solicitacaoPessoal.habilidades);
            formData.append("atitudes", this.solicitacaoPessoal.atitudes);
            formData.append(
              "requisitosExperiencia",
              this.solicitacaoPessoal.requisitosExperiencia
            );
            formData.append(
              "idadeReferencial",
              this.solicitacaoPessoal.idadeReferencial
            );
            formData.append(
              "salarioBeneficios",
              this.solicitacaoPessoal.salarioBeneficios
            );
            formData.append(
              "descricaoAtividade",
              this.solicitacaoPessoal.descricaoAtividade
            );
            formData.append("observacoes", this.solicitacaoPessoal.observacoes);
            formData.append("lider", solicitacaoPessoalLider);
            formData.append("gestor", solicitacaoPessoalGestor);

            const config = {
              headers: { "content-type": "multipart/form-data" },
            };

            try {
              //Requisição atualizar dados
              await axios
                .post("chamado/addNovaSolicitaçãoPessoal", formData, config)
                .then((response) => {
                  self.$refs.files.value = "";
                  self.fileReceipt = [];

                  console.log(response.data);

                  alert(response.data);

                  this.limparFormulario();
                });
            } catch (error) {
              console.log(error.response.status);

              const erroHttp = error.response.status;
              switch (erroHttp) {
                case 401:
                  alert(
                    "Api Pabx fora do ar! - [Erro] " + error.response.status
                  );
                  break;
                case 404:
                  alert(
                    "Pagina não encontrada na Api! - [Erro] " +
                      error.response.status
                  );
                  break;
              }
            }
          }
        }
      } catch (error) {
        //console.log(error.response.status)

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    limparFormulario() {
      (this.userSolicitante = ""),
        (this.titulo = ""),
        (this.contentHTML = ""),
        (this.selectedDestinatario = null),
        (this.selectedAtividade = ""),
        (this.selectedAtividadeTodas = ""),
        (this.selectedEquipe = "outraEquipe");

      this.solicitacaoPessoal.selectedMotivo = "Ampliação";
      this.solicitacaoPessoal.selectedGenero = "";
      this.solicitacaoPessoal.selectedTurno = "Dia";
      this.solicitacaoPessoal.divisao = "";
      this.solicitacaoPessoal.cargo = "";
      this.solicitacaoPessoal.centroCusto = "";
      this.solicitacaoPessoal.razaoSocial = "";
      this.solicitacaoPessoal.endereco = "";
      this.solicitacaoPessoal.bairro = "";
      this.solicitacaoPessoal.escala = "";
      this.solicitacaoPessoal.conhecimentos = "";
      this.solicitacaoPessoal.habilidades = "";
      this.solicitacaoPessoal.atitudes = "";
      this.solicitacaoPessoal.requisitosExperiencia = "";
      this.solicitacaoPessoal.idadeReferencial = "";
      this.solicitacaoPessoal.salarioBeneficios = "";
      this.solicitacaoPessoal.descricaoAtividade = "";
      this.solicitacaoPessoal.observacoes = "";

      this.solicitacaoPessoal.userLider = "";
      this.solicitacaoPessoal.usersLider = [];
      this.solicitacaoPessoal.userSelectLider = "";
      this.solicitacaoPessoal.userGestor = "";
      this.solicitacaoPessoal.usersGestor = [];
      this.solicitacaoPessoal.userSelectGestor = "";
    },

    async addChamado() {
      try {
        if (this.userSelectSolicitante == "") {
          alert("Adicione um Solicitante!");
        } else if (this.titulo == "") {
          alert("Digite um Título para sua solicitação!");
        } else if (this.contentHTML == "") {
          alert("Digite o conteúdo de sua solicitação!");
        } else {
          if (this.selectedEquipe == "outraEquipe") {
            var destinatario = "";
            var atividade = this.selectedAtividadeTodas;
          } else {
            var atividade = this.selectedAtividade;

            if (
              this.selectedDestinatario == "" ||
              this.selectedDestinatario == null
            ) {
              var destinatario = "";
            } else {
              var destinatario = this.selectedDestinatario.pk_id_usuario;
            }
          }

          if (atividade == "" || atividade == null) {
            alert("Selecione uma categoria de atividade!");
          } else {
            const perfil = JSON.parse(localStorage.getItem("storageData"));

            var self = this;

            let formData = new FormData();
            for (let i = 0; i < this.fileReceipt.length; i++) {
              let file = self.fileReceipt[i];
              formData.append("files[" + i + "]", file);
            }

            formData.append("idUser", perfil.pk_id_usuario);
            formData.append("atividade", atividade.pk_id_atendimento_categoria);
            formData.append("destinatario", destinatario);
            formData.append(
              "solicitante",
              this.userSelectSolicitante.pk_id_usuario
            );
            formData.append("titulo", this.titulo);
            formData.append("conteudo", this.contentHTML);

            const config = {
              headers: { "content-type": "multipart/form-data" },
            };

            try {
              //Requisição atualizar dados
              await axios
                .post("chamado/addNovaChamado", formData, config)
                .then((response) => {
                  self.$refs.files.value = "";
                  self.fileReceipt = [];

                  alert(response.data);

                  (this.userSolicitante = ""),
                    (this.titulo = ""),
                    (this.contentHTML = ""),
                    (this.selectedDestinatario = null),
                    (this.selectedAtividade = ""),
                    (this.selectedAtividadeTodas = ""),
                    (this.selectedEquipe = "outraEquipe");
                });
            } catch (error) {
              console.log(error.response.status);

              const erroHttp = error.response.status;
              switch (erroHttp) {
                case 401:
                  alert(
                    "Api Pabx fora do ar! - [Erro] " + error.response.status
                  );
                  break;
                case 404:
                  alert(
                    "Pagina não encontrada na Api! - [Erro] " +
                      error.response.status
                  );
                  break;
              }
            }
          }
        }
      } catch (error) {
        //console.log(error.response.status)

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    openFileDialog() {
      this.$refs.files.value = null;
      this.$refs.files.click();
    },

    apagarAnexo(e) {
      this.fileReceipt.splice(e, 1);
    },

    handleFile() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.fileReceipt.push(this.$refs.files.files[i]);
      }
    },
  },
};
</script>

<style scoped>
#label-decoration {
  color: rgb(139, 139, 139);
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
}

#label-style {
  color: rgb(107, 107, 107);
  font-weight: bold;
  font-size: 15px;
}

#nome-anexo {
  font-size: 14px;
  font-style: italic;
}

#text-button-anexo {
  font-size: 15px;
  font-style: italic;
}
</style>
