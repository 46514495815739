import Vue from 'vue'
import router from './router'
import App from './App.vue'

import VMask from "v-mask"
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './axios'
import VueQuillEditor from 'vue-quill-editor'
import vSelect from 'vue-select'
import { BEmbed } from 'bootstrap-vue'

// import VueSidebarMenu from 'vue-sidebar-menu'
// import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
// Vue.use(VueSidebarMenu)


// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import 'vue-select/dist/vue-select.css';

Vue.component('b-embed', BEmbed)
Vue.component('v-select', vSelect)
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(VMask)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

//Bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//jQuery
import jQuery from 'jquery'
global.jQuery = jQuery

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router,
  // store
}).$mount('#app')