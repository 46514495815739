<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Controle de acesso - Histórico</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="">Home</a></li>
              <li class="breadcrumb-item active">Controle de acesso - Histórico</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary card-outline">
              <div class="card-header">
                <div class="card-title mb-0">
                  <b>
                    <u>{{ unidadeDados.nome }}</u></b
                  ><br /><br />
                  <ul class="ml-4 mb-0 fa-ul text-muted">
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-phone"></i
                      ></span>
                      Telefone: {{ unidadeDados.telefone }}
                    </li>
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-building"></i
                      ></span>
                      Endereço: {{ unidadeDados.endereco }}
                    </li>
                  </ul>
                </div>

                <div class="float-right" style="padding: 0px 0px 0px 10px">
                  <router-link
                    :to="'/controle-acesso-formulario?id=' + idUnidade"
                  >
                    <button class="btn-sm btn-outline-secondary">
                      <i class="fas fa-exchange-alt"></i> Controle de Acesso
                    </button>
                  </router-link>
                </div>
              </div>

              <div class="mt-4">
                <b-input-group id="centro">
                    <b-form-group>

                      <label id="label-decoration" class="mb-0" for="empresa">Intervalo de tempo</label>
                    
                      <b-input-group-append>
                        <date-picker v-model="valueDate" value-type="YYYY-MM-DD H:mm:ss" format="DD/MM/YYYY, H:mm:ss"  type="datetime" :editable="false" id="intervalo-tempo" placeholder="Selecione o intervalo" range> </date-picker>
                        <b-button  @click="dadosHistoricoAcesso" size="sm" variant="info" class="ml-1"><i class="fas fa-search"></i></b-button>
                      </b-input-group-append>
                      
                    </b-form-group>   
                </b-input-group>
              </div>

              <div class="card-body">
                <b-container fluid>
                  <b-row>
                    <b-input-group class="mt-2 mb-2">
                      <b-input-group-append>
                        <b-form-input
                          size="sm"
                          id="filter-input"
                          v-model="configTableHistoricoAcesso.filter"
                          type="search"
                          placeholder="Pesquisar..."
                        ></b-form-input>
                      </b-input-group-append>
                    </b-input-group>

                    <b-table
                      :busy="isBusy"
                      :striped="true"
                      :outlined="true"
                      :hover="true"
                      :bordered="false"
                      :borderless="false"
                      :noCollapse="false"
                      :dark="true"
                      :fixed="false"
                      :small="true"                      
                      :footClone="true"                      
                      head-variant="dark"
                      :items="itemsHistoricoAcesso"
                      :fields="fieldsHistoricoAcesso"
                      :current-page="configTableHistoricoAcesso.currentPage"
                      :per-page="configTableHistoricoAcesso.perPage"
                      :filter="configTableHistoricoAcesso.filter"
                      :filter-included-fields="configTableHistoricoAcesso.filterOn"
                      :sort-by.sync="configTableHistoricoAcesso.sortBy"
                      :sort-desc.sync="configTableHistoricoAcesso.sortDesc"
                      :sort-direction="configTableHistoricoAcesso.sortDirection"
                      stacked="md"
                      show-empty
                    >
                      <template #empty>
                        <h5 class="text-center">
                          {{ tableHistoricoAcesso.emptyTexts }}
                        </h5>
                      </template>

                      <template #emptyfiltered>
                        <h5 class="text-center">
                          {{ tableHistoricoAcesso.emptyFilteredTexts }}
                        </h5>
                      </template>

                      <template #table-busy>
                        <div class="text-center text-danger my-3">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong> Carregando...</strong>
                        </div>
                      </template>

                      <!-- A custom formatted header cell for field 'name' -->
                      <template #head(nome_visitante)="data">
                        <span class="text-info">{{ data.label.toUpperCase()}}</span>
                      </template>
                      <template #head(empresa)="data">
                        <span class="text-info">{{ data.label.toUpperCase()}}</span>
                      </template>
                      <template #head(matricula)="data">
                        <span class="text-info">{{ data.label.toUpperCase()}}</span>
                      </template>
                      <template #head(nomeUsuarioDestino)="data">
                        <span class="text-info">{{ data.label.toUpperCase()}}</span>
                      </template>

                      <template #head(data_hora_entrada)="data">
                        <span class="text-info">{{ data.label.toUpperCase()}}</span>
                      </template>
                      <template #head(data_hora_saida)="data">
                        <span class="text-info">{{ data.label.toUpperCase()}}</span>
                      </template>

                      <template #cell(nome_visitante)="row">
                        <div class="user-panel pb-1 text-center mb-0">                      
                          <img v-if="row.item.foto_visitante" :src="require(`../../../assets/img/profiles/${row.item.foto_visitante}`)" id="fotoTabelaVisitante" class="table-visitante" alt="Avatar">
                          <img v-else :src="require(`../../../assets/adminLTE/dist/img/user2-160x160.jpg`)" id="fotoTabelaVisitante" class="table-visitante" alt="Avatar">
                          <br>
                          <small>
                            {{ row.item.nome_visitante }}
                          </small>                                         
                        </div>
                      </template>

                        <template #cell(empresa)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-building text-muted"></i
                            ></span>
                            {{ row.item.empresa }}
                          </div>
                        </template>

                        <template #cell(matricula)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-id-badge text-muted"></i
                            ></span>
                            {{ row.item.matricula }}
                          </div>
                        </template>

                        <template #cell(nomeUsuarioDestino)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-door-open text-muted"></i
                            ></span>
                            {{ row.item.nomeUsuarioDestino }}
                          </div>
                        </template>

                        <template #cell(data_hora_entrada)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-clock text-muted"></i
                            ></span>
                            <b class="text-info"> {{ row.item.data_hora_saida }}</b>
                          </div>
                        </template>

                        <template #cell(data_hora_saida)="row">
                          <div class="text-center">
                            <span class="small"
                              ><i class="fas fa-clock text-muted"></i
                            ></span>
                            <b class="text-danger"> {{ row.item.data_hora_saida }}</b>
                          </div>
                        </template>

                    </b-table>

                    <b-tfoot>
                      <div class="text-muted">
                        <div class="small">
                          <span><i class="fas fa-list-ol"></i></span> Total de
                          registros: <b>{{ itemsHistoricoAcesso.length }}</b>
                        </div>
                      </div>
                    </b-tfoot>

                    <b-col sm="5" md="0" class="my-1">
                      <b-form-group
                        label="Quantidade"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="8"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="configTableHistoricoAcesso.perPage"
                          :options="configTableHistoricoAcesso.pageOptions"
                          size="sm"
                        >
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col sm="5" md="4" class="my-1">
                      <b-pagination
                        v-model="configTableHistoricoAcesso.currentPage"
                        :total-rows="historicoAcessoRows"
                        :per-page="configTableHistoricoAcesso.perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';

const axios = require("axios").default;
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "ControleAcessoHistorico",
  components: { DatePicker },


  data() {

    var nowDate = new Date()
    var dia = nowDate.getDate()
    var mes = nowDate.getMonth()
    var ano = nowDate.getFullYear()

    var hora = nowDate.getHours()
    var minuto = nowDate.getMinutes()
    var segundos = nowDate.getSeconds()

    return {
      unidadeDados: {
        nome: "",
        telefone: "",
        endereco: "",
      },

      
      valueDate: [(ano+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundos),(ano+"-"+(mes +1)+"-"+dia+" "+hora+":"+minuto+":"+segundos)],


      isBusy: true,
      idUnidade: this.$route.query.id,

      /////////////////////////<====TABELA DE HISTORICO ACESSO====>///////////////////////////////////////////
      itemsHistoricoAcesso: [],

      tableHistoricoAcesso: {
        emptyTexts: "Não há usuarios cadastrado!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsHistoricoAcesso: [
        {
          key: "nome_visitante",
          label: "Nome",
          sortable: true,
        },
        {
          key: "empresa",
          label: "Empresa",
          sortable: true,
        },
        {
          key: "matricula",
          label: "Matricula",
          sortable: true,
        },
        {
          key: "nomeUsuarioDestino",
          label: "Destino",
          sortable: true,
        },
        {
          key: "data_hora_entrada",
          label: "Entrada",
          sortable: true,
        },
        {
          key: "data_hora_saida",
          label: "Saída",
          sortable: true,
        },
      ],

      configTableHistoricoAcesso: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
    };
  },

  mounted() {
    this.dadosHistoricoAcesso();
  },

  filters: {
    moment: function (date) {
      return moment(date).format("LT - L");
    },
  },

  computed: {
    historicoAcessoRows() {
      return this.itemsHistoricoAcesso.length;
    },
  },

  methods: {

    async dadosHistoricoAcesso() {
      this.isBusy = true

      try {
        const response = await axios.post("unidade/intervaloDataHistoricoAcesso", {
          intervaloData: this.valueDate,
          idUnidade: this.idUnidade
        });

        this.unidadeDados.nome = response.data.dadosUnidade.original.nome_unidade,
        this.unidadeDados.telefone = response.data.dadosUnidade.original.telefone,
        this.unidadeDados.endereco = response.data.dadosUnidade.original.endereco,

        this.itemsHistoricoAcesso = response.data.historicoTabela

        this.isBusy = false

        //console.log(this.itemsHistoricoAcesso)

      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            this.isBusy = false
            break;
          case 404:
            alert("Pagina não encontrada na Api! - [Erro] " + error.response.status);
            this.isBusy = false
            break;
        }
      }
    },
  },
};
</script>

<style scoped>

#label-decoration {
  color: rgb(139, 139, 139);
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
}

#fotoTabelaVisitante {
    
    border-radius: 50%;
    display: inline;
    width: 3.5rem;
    box-shadow: 3px 5px 5px rgb(0, 0, 0);
}

#centro {
  width: 200%;
  margin-left: -100px;
  text-align:center;
  position: relative;
  left: 40%;
}


/*Para alinhar um elemento verticalmente no centro da tela, apenas com CSS ele precisa ter altura fixa, exemplo:*/
/*
#centro {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align:center;
  background-color: rgba(10,23,55,0.7);
  color: white;
  
  position: absolute;
  top: 50%;
  margin-top: -25px;
}
*/ 

/*Para alinhar horizontalmente a lógica é a mesma:*/
/*
#centro {
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align:center;
  background-color: rgba(10,23,55,0.7);
  color: white;
  
  position: absolute;
  top: 50%;
  margin-top: -25px;
  left: 50%;
  margin-left: -100px;
}
*/
</style>