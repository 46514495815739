<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Meus Tickets</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Meus Tickets</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary card-outline">
              <div class="mt-2 mr-4 mb-0">
                <div class="float-right">
                  <router-link to="/novo-chamado">
                    <button class="btn-sm btn-outline-secondary">
                      <i class="fas fa-headset"></i>
                      Novo Ticket
                    </button>
                  </router-link>
                </div>
              </div>

              <div class="card-body">
                <b-container fluid>
                  <b-row>
                    <b-input-group class="mt-0 mb-1">
                      <b-input-group-append>
                        <b-form-input
                          size="sm"
                          id="filter-input"
                          v-model="configTableMeuTicket.filter"
                          type="search"
                          placeholder="Pesquisar..."
                        ></b-form-input>
                      </b-input-group-append>
                    </b-input-group>

                    <b-table
                      :busy="isBusy"
                      :striped="true"
                      :outlined="true"
                      :hover="true"
                      :bordered="false"
                      :borderless="false"
                      :noCollapse="false"
                      :dark="false"
                      :fixed="false"
                      :small="true"
                      :footClone="true"
                      head-variant="dark"
                      :items="itemsMeuTicket"
                      :fields="fieldsMeuTicket"
                      :current-page="configTableMeuTicket.currentPage"
                      :per-page="configTableMeuTicket.perPage"
                      :filter="configTableMeuTicket.filter"
                      :filter-included-fields="configTableMeuTicket.filterOn"
                      :sort-by.sync="configTableMeuTicket.sortBy"
                      :sort-desc.sync="configTableMeuTicket.sortDesc"
                      :sort-direction="configTableMeuTicket.sortDirection"
                      stacked="md"
                      show-empty
                    >
                      <template #empty>
                        <h5 class="text-center">
                          {{ tableMeuTicket.emptyTexts }}
                        </h5>
                      </template>

                      <template #emptyfiltered>
                        <h5 class="text-center">
                          {{ tableMeuTicket.emptyFilteredTexts }}
                        </h5>
                      </template>

                      <template #table-busy>
                        <div class="text-center text-danger my-3">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong> Carregando...</strong>
                        </div>
                      </template>

                      <template #cell(protocolo)="row">
                        <router-link
                          :to="
                            '/solicitacao-historico?id=' +
                            row.item.pk_id_atendimento
                          "
                        >
                          <div class="text-center small">
                            <span
                              ><i class="fas fa-building text-muted"></i
                            ></span>
                            {{ row.item.protocolo }}
                          </div>
                        </router-link>
                      </template>

                      <template #cell(titulo)="row">
                        <div>
                          <span
                            ><i class="fas fa-edit small text-muted"></i
                          ></span>
                          {{ row.item.titulo }}
                        </div>
                      </template>

                      <template #cell(nome_requerente)="row">
                        <div>
                          <span><i class="far fa-user text-muted"></i></span>
                          {{ row.item.nome_requerente }}
                        </div>
                      </template>

                      <template #cell(nome_atendente)="row">
                        <div v-if="row.item.nome_atendente">
                          <span
                            ><i class="fas fa-user-tie text-muted"></i
                          ></span>
                          {{ row.item.nome_atendente }}
                        </div>

                        <div v-else>
                          <span><i class="fas fa-users text-muted"></i></span>
                          Equipe
                        </div>
                      </template>

                      <template #cell(status)="row">
                        <div>
                          <span v-if="row.item.status == 'Fechado'"><i class="fas fa-toggle-off text-muted"></i></span>
                          <span v-else><i class="fas fa-toggle-on text-muted"></i></span>
                          {{ row.item.status }}
                        </div>
                      </template>

                      <template #cell(data_hora_cadastro)="row">
                        <div class="small">
                          <span><i class="fas fa-history text-muted"></i></span>
                          {{ row.item.data_hora_cadastro | moment }}
                        </div>
                      </template>
                    </b-table>

                    <b-tfoot>
                      <div class="text-muted">
                        <div class="small">
                          <span><i class="fas fa-list-ol"></i></span> Total de
                          registros:
                          <b>{{ itemsMeuTicket.length }}</b>
                        </div>
                      </div>
                    </b-tfoot>

                    <b-col sm="5" md="0" class="my-1">
                      <b-form-group
                        label="Quantidade"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="8"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="configTableMeuTicket.perPage"
                          :options="configTableMeuTicket.pageOptions"
                          size="sm"
                        >
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col sm="5" md="4" class="my-1">
                      <b-pagination
                        v-model="configTableMeuTicket.currentPage"
                        :total-rows="meuTicketRows"
                        :per-page="configTableMeuTicket.perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "HistoricoChamado",

  data() {
    return {
      isBusy: true,

      itemsMeuTicket: [],

      tableMeuTicket: {
        emptyTexts: "Não há registro de tickets!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsMeuTicket: [
        {
          key: "protocolo",
          label: "Protocolo",
          sortable: true,
        },

        {
          key: "titulo",
          label: "Título",
          sortable: true,
        },

        {
          key: "nome_requerente",
          label: "Solicitante",
          sortable: true,
        },

        {
          key: "nome_atendente",
          label: "Destinatário",
          sortable: true,
        },

        {
          key: "status",
          label: "Status",
          sortable: true,
        },

        {
          key: "data_hora_cadastro",
          label: "Data Abertura",
          sortable: true,
        },
      ],

      configTableMeuTicket: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
    };
  },

  computed: {
    meuTicketRows() {
      return this.itemsMeuTicket.length;
    },
  },

  mounted() {
    this.meuTicket();

  },

  methods: {
    async meuTicket() {
      try {
        const perfil = JSON.parse(localStorage.getItem("storageData"));
        this.user = perfil;

        const equipe = JSON.parse(localStorage.getItem("equipeUser"));
        this.equipeUser = equipe;

        //Requisição
        const response = await axios.post("chamado/listaMeusChamados", {
          idUsuario: this.user.pk_id_usuario,
        });

        this.itemsMeuTicket = response.data['0']

        this.isBusy = false;

        //console.log(response.data);
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("L - LTS");
    },
  },
};
</script>


<style scoped>
</style>