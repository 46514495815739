<template>
     <b-alert show variant="danger" dismissible
            fade class="text-center">{{errorComponents}}</b-alert>
</template>

<script>
    export default{
        name:'ErrorComponents',
        props: ['errorComponents']
    }
</script>

<style>

</style>