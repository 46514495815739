<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>STV-Flix</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">STV-Flix</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <b-tabs
      active-nav-item-class="font-weight-bold text-uppercase text-info"
      content-class="mt-3"
      justified
    >
      <b-tab title="Upload Videos" active>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-secondary card-outline">
                  <div class="card-body">
                    <div class="justify-content-center col-4 m-auto">
                      <div class="form-group">
                        <input
                          ref="files"
                          type="file"
                          class="d-none"
                          accept=".avi, .mp4, .m4v, .mov, .mpg, .mpeg, .wmv"
                          @change="handleFile"
                          multiple
                        />

                        <b-button
                          @click="openFileDialog()"
                          type="button"
                          variant="outline-dark"
                          class="btn btn-block"
                        >
                          <i
                            id="ico-upload"
                            class="fas fa-cloud-upload-alt"
                          ></i>
                          <p>Upload</p>
                        </b-button>
                      </div>

                      <div class="form-group">
                        <b-button
                          @click="uploadVideo()"
                          type="button"
                          variant="dark"
                          class="btn btn-block"
                        >
                          <i class="fas fa-save"></i>
                          Salvar
                        </b-button>

                        <div class="m-auto">
                          <p
                            id="nome-anexo"
                            class="mb-0"
                            v-for="(filesAnexo, index) in fileReceipt"
                            :key="index"
                          >
                            {{ filesAnexo.name }}
                            <button
                              type="button"
                              @click="apagarAnexo(index)"
                              class="btn badge bagde-light"
                            >
                              <i class="fa fa-trash text-danger"></i>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </b-tab>

      <b-tab title="Videos" class="bg-dark">
        <section class="content">
          <!--
          <div class="container-fluid">
            <div class="row">
              <div class="card-body">
                <b-container fluid class="p-12">
                  <b-row>
                    <b-col>
                      
                      <b-embed
                        type="video"
                        aspect="4by3"
                        controls
                        poster="poster.png"
                      >
                        <source :src="teste + picture.path" type="video/mp4" />
                      </b-embed>

                      <div
                        v-for="(picture, index) in pictures"
                        :key="index"
                        id="border-video"
                      >
                        <video
                          class="justify-content-center col-4 ml-0 mr-0 mb-3"
                          width="320"
                          height="240"
                          controls
                        >
                          <source :src="teste + picture.path" />
                        </video>
                        <p>{{ picture.nome }}</p>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
          -->

          <div class="card-body">
            <div class="row">
              <div
                class="col-sm-3 ml-0 mr-0 mb-3"
                v-for="(picture, index) in pictures"
                :key="index"
              >
                <video
                  class="justify-content-center col-12 mb-0"
                  width="320"
                  height="240"
                  controls
                  id="border-video"
                >
                  <source :src="testeLocal + picture.path" />
                </video>

                <div>
                  <p class="mb-0">
                    {{ picture.nome }}
                  </p>

                  <div
                    v-if="perfilUser.pk_id_usuario == picture.fk_autor"
                    class="btn-group"
                  >
                    <button
                      @click="excluirVideo(picture.pk_id_stvflix, picture.path)"
                      type="button"
                      class="btn btn-sm btn-default"
                    >
                      <i class="fa fa-trash text-danger"> Excluir</i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-default"
                      v-b-modal.modal-edita-nome
                      v-on:click="idEditarNome = picture.pk_id_stvflix"
                    >
                      <i class="fas fa-pencil-alt text-info"> Editar</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

            <!-- <b-card class="mt-5" header="Form Data Result">
          <pre class="m-0">{{ solicitacaoPessoal }}</pre>
        </b-card> -->
        </section>
      </b-tab>
    </b-tabs>

    <b-modal
      id="modal-edita-nome"
      ref="modal"
      hide-footer
      centered
      hideHeaderClose="false"
      no-close-on-backdrop="true"
    >
      <div>
        <b-form-group
          label="Editar Nome"
          label-for="name-input"
          
        >
          <b-form-input
            id="name-input"
            v-model.trim="nomeEditado"
            :state="nomeEditado.length >= 5"
            type="text"
            placeholder="Digite o novo nome aqui..."
            required
          ></b-form-input>

          <b-form-invalid-feedback class="mt-0" id="input-titulo">
            O novo nome deve conter pelo menos 5 caracteris!!
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="text-right">
        <b-button class="mt-3 mr-1" variant="secondary" @click="hideModal">Cancelar</b-button>
        <b-button @click="editarNome()" :disabled="statusButton" variant="primary" class="mt-3"><i class="fas fa-save"></i> Salvar</b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "StvFlix",

  computed: {
    statusButton() {
      return this.nomeEditado.length > 5
        ? false
        : true;
    },
  },

  data() {
    return {
      fileReceipt: [],
      pictures: "",
      testeLocal:
        "http://localhost/dev/laravelVue/conectaVueLaravel/apiLaravel8-conecta/storage/app/public/",
      teste:
        "http://images.stv.com.br/",

        
      perfilUser: "",
      idEditarNome: "",
      nomeEditado: "",
    };
  },

  methods: {
    openFileDialog() {
      this.$refs.files.value = null;
      this.$refs.files.click();
    },

    apagarAnexo(e) {
      this.fileReceipt.splice(e, 1);
    },

    handleFile() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.fileReceipt.push(this.$refs.files.files[i]);
      }
    },

    async uploadVideo() {
      const perfil = JSON.parse(localStorage.getItem("storageData"));
      var self = this;

      let formData = new FormData();
      for (let i = 0; i < this.fileReceipt.length; i++) {
        let file = self.fileReceipt[i];

        formData.append("files[" + i + "]", file);
      }
      formData.append("idUser", perfil.pk_id_usuario);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        //Requisição atualizar dados
        await axios
          .post("stvFlix/addVideos", formData, config)
          .then((response) => {
            self.$refs.files.value = "";
            self.fileReceipt = [];
            self.getImages();

            alert(response.data);
          });
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 401:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async getImages() {
      try {
        //Requisição atualizar dados
        await axios.get("stvFlix/getVideos").then((response) => {
          this.pictures = response.data.images;
          console.log(this.pictures);
        });
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 401:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async excluirVideo(id, patch) {
      try {
        const response = await axios.post("stvFlix/deletaVideo", {
          idVideo: id,
          local: patch,
        });

        alert(response.data);

        this.getImages();
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 401:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async editarNome() {
      try {
        const response = await axios.post("stvFlix/editarVideo", {
          idVideoEditar: this.idEditarNome,
          nomeEditar: this.nomeEditado,
        });

        alert(response.data);

        this.hideModal()

        this.getImages();
      } catch (error) {
        console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 401:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    hideModal() {
      this.$refs['modal'].hide()

      this.nomeEditado = ""
    },
  },

  mounted() {
    this.perfilUser = JSON.parse(localStorage.getItem("storageData"));
    this.getImages();
  },
};
</script>

<style scoped>
#ico-upload {
  font-size: 50px;
  color: rgb(0, 174, 255);
}

#nome-anexo {
  font-size: 14px;
  font-style: bold;
}

#border-video {
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: rgba(175, 175, 175, 0.637);
}
</style>
