<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Agenda Equipe</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Agenda Equipe</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary card-outline">
              <div class="col-sm-6 mt-4 mb-4" id="centro">
                <label id="label-decoration" class="mb-0" for="empresa">Membros Equipe</label>
                <v-select
                  placeholder="Membros Equipe"
                  v-model="selectedDestinatario"
                  :options="optionDestinatario"
                  label="label"
                >
                </v-select>
              </div>

              <div v-if="estadoMembrosEquipe" class="card-body">
                <b-overlay :show="show" rounded="sm">
                  <div class="demo-app-main">
                    <FullCalendar
                      class="demo-app-calendar"
                      :options="calendarOptions"
                    >
                      <template v-slot:eventContent="arg">
                        <b>{{ arg.timeText }}</b>
                        <i>{{ arg.event.title }}</i>
                      </template>
                    </FullCalendar>
                  </div>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal ref="tarefa" title="Detalhes da Tarefa">
      <template #modal-header>
        <h5>Detalhes da Tarefa</h5>
      </template>

      <template #default>
        <div class="tab-pane" id="settings">
          <div class="form-group row">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12">
                  <ul class="list-unstyled">
                    <li><b>Titulo:</b> {{ eventEdit.title }}</li>
                    <li v-if="1 > 2"><b>Para:</b> Ana Paula Brum Cardoso</li>
                  </ul>
                </div>
                <div class="col-lg-12">
                  <div id="bordaBody" class="well well-sm">
                    <ul class="list-unstyled">
                      <li>
                        <b>Início</b> - {{ eventEdit.start | moment }} às
                        <b class="text-secondary">{{ eventEdit.timeStart }}.</b>
                      </li>

                      <li>
                        <b>Final</b> - {{ eventEdit.end | moment }} às
                        <b class="text-secondary">{{ eventEdit.timeEnd }}.</b>
                      </li>

                      <li v-if="eventEdit.descricao" class="mt-3">
                        <b>Descrição:</b> {{ eventEdit.descricao }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div v-if="eventEdit.convidadoNome" class="col-lg-12 mt-2">
                  <div id="bordaBody" class="well well-sm">
                    <b> <u> Convidados: </u></b>

                    <div class="mt-3">
                      <div>
                        <li
                          class="list-unstyled"
                          id="labelcomplementoConvidado"
                        >
                          <b>
                            <i>{{ eventEdit.convidadoNome }} </i></b
                          >
                        </li>

                        <ul>
                          <li
                            v-if="eventEdit.convidadoFuncao"
                            id="complementoConvidado"
                          >
                            <b class="text-black">Cargo:</b>
                            {{ eventEdit.convidadoFuncao }}
                          </li>
                          <li
                            v-if="eventEdit.convidadoMatricula"
                            id="complementoConvidado"
                          >
                            <b class="text-black">Matricula:</b>
                            {{ eventEdit.convidadoMatricula }}
                          </li>
                          <li
                            v-if="eventEdit.convidadoTelefone"
                            id="complementoConvidado"
                          >
                            <b class="text-black">Telefone:</b>
                            {{ eventEdit.convidadoTelefone }}
                          </li>
                          <li
                            v-if="eventEdit.convidadoEmail"
                            id="complementoConvidado"
                          >
                            <b class="text-black">E-mail:</b>
                            {{ eventEdit.convidadoEmail }}
                          </li>
                          <li
                            v-if="eventEdit.convidadoUnidade"
                            id="complementoConvidado"
                          >
                            <b class="text-black">C.C.:</b>
                            {{ eventEdit.convidadoUnidade }}-{{
                              eventEdit.convidadoSetor
                            }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #modal-footer>
        <div class="float-right">
          <b-button
            size="sm"
            class="mr-1"
            variant="secondary"
            @click="hideModalTarefa"
            >Fechar</b-button
          >

          <!--
          <b-button
            size="sm"
            class="mr-1"
            variant="danger"
            @click="excluirTarefa(eventEdit.id)"
            >Excluir</b-button
          >
          
          <b-button
            size="sm"
            class="mr-1"
            variant="info"
            @click="showModalEditarTarefa()"
            >Editar</b-button
          >
          -->
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script scoped>
const axios = require("axios").default;
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\

var moment = require("moment");
moment.locale("pt-br");

import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import TimeGridPlugin from "@fullcalendar/timegrid";
import ListPlugin from "@fullcalendar/list";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    AutocompleteComponents, //===>Autocomplete<===\\
  },

  computed: {
    estadoMembrosEquipe() {
      if (this.selectedDestinatario != null) {
        this.carregarAgenda();
        return true;
      } else {
        return false;
      }
    },
  },

  data() {
    return {
      usuario: "",
      show: true,

      selectedDestinatario: null,
      optionDestinatario: [],

      calendarOptions: {
        locale: "pt-br",
        allDayText: "24 horas",
        plugins: [dayGridPlugin, interactionPlugin, TimeGridPlugin, ListPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        buttonText: {
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Hoje",
          list: "Lista",
        },
        initialView: "dayGridMonth",
        editable: false,
        navLinks: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true, // initial value

        eventClick: this.showModalTarefa,

        events: [],
      },

      eventEdit: {
        id: "",
        title: "",
        start: "",
        end: "",
        timeStart: "",
        timeEnd: "",
        dataStart: "",
        dataEnd: "",
        descricao: "",
        color: "",
        allDay: "",

        convidadoIdUsuario: "",
        convidadoNome: "",
        convidadoFuncao: "",
        convidadoMatricula: "",
        convidadoEmpresa: "",
        convidadoTelefone: "",
        convidadoEmail: "",
        convidadoUnidade: "",
        convidadoSetor: "",
        convidadoExportOrigem: "",
      },
    };
  },

  mounted() {
    //this.carregarAgenda();
    this.membrosEquipe();
  },

  methods: {
    async membrosEquipe() {
      const equipe = JSON.parse(localStorage.getItem("equipeUser"));

      if (equipe["0"]) {
        try {
          const perfil = JSON.parse(localStorage.getItem("storageData"));
          const equipe = JSON.parse(localStorage.getItem("equipeUser"));

          const response = await axios.post("chamado/dadosChamado", {
            idUsuario: perfil.pk_id_usuario,
            equipes: equipe,
          });

          var userEquipe = response.data.usersEquipes.original.flat(); //===>Achata Array .flat()<===\\

          //===>Função limpar array item duplicado<===\\
          const userEquipeUnico = new Map();

          userEquipe.forEach((user) => {
            if (!userEquipeUnico.has(user.pk_id_usuario)) {
              userEquipeUnico.set(user.pk_id_usuario, user);
            }
          });

          this.optionDestinatario = [...userEquipeUnico.values()];
          //===>Função limpar array item duplicado<===\\
        } catch (error) {
          //console.log(error.response.status)

          const erroHttp = error.response.status;
          switch (erroHttp) {
            case 500:
              alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
              break;
            case 404:
              alert(
                "Pagina não encontrada na Api! - [Erro] " +
                  error.response.status
              );
              break;
          }
        }
      } 
    },

    showModalTarefa(clickInfo) {
      this.$refs["tarefa"].show();

      this.calendarOptions.events.forEach((events, i) => {
        if (events.id == clickInfo.event.id) {
          this.eventEdit.id = events.id;
          this.eventEdit.title = events.title;
          this.eventEdit.start = events.start;
          this.eventEdit.end = events.end;
          this.eventEdit.descricao = events.descricao;
          this.eventEdit.color = events.color;
          this.eventEdit.allDay = events.allDay;
          this.eventEdit.convidadoIdUsuario = events.idUsuario;
          this.eventEdit.convidadoNome = events.nome;
          this.eventEdit.convidadoFuncao = events.funcao;
          this.eventEdit.convidadoMatricula = events.matricula;
          this.eventEdit.convidadoEmpresa = events.empresa;
          this.eventEdit.convidadoTelefone = events.telefone;
          this.eventEdit.convidadoEmail = events.email;
          this.eventEdit.convidadoUnidade = events.unidade;
          this.eventEdit.convidadoSetor = events.setor;
          this.eventEdit.convidadoExportOrigem = events.exportOrigem;
        }
      });

      if (this.eventEdit.allDay == 1) {
        this.diaInteiroEditar = true;
      } else {
        this.diaInteiroEditar = false;
      }

      const timeStart = new Date(this.eventEdit.start);
      const timeEnd = new Date(this.eventEdit.end);

      this.eventEdit.timeStart = timeStart.toLocaleTimeString();
      this.eventEdit.timeEnd = timeEnd.toLocaleTimeString();
    },

    hideModalTarefa() {
      this.$refs["tarefa"].hide();
    },

    async carregarAgenda() {
      (this.show = true),
        (this.usuario = JSON.parse(localStorage.getItem("storageData")));

      const response = await axios.post("agenda/minhaAgenda", {
        idUsuario: this.selectedDestinatario.pk_id_usuario,
      });

      // console.log(this.selectedDestinatario.pk_id_usuario);
      // console.log(response.data);

      this.calendarOptions.events = response.data;

      this.refresh();

      this.show = false;
    },

    async agendaReload() {
      const response = await axios.post("agenda/minhaAgenda", {
        idUsuario: this.selectedDestinatario.pk_id_usuario,
      });

      //console.log(response.data);

      this.calendarOptions.events = response.data;
    },

    refresh: function () {
      this.intervalid = setInterval(() => {
        this.agendaReload();
      }, 60000);
    },
  },

  beforeDestroy() {
    clearInterval(this.intervalid);
  },

  filters: {
    moment: function (date) {
      return moment(date).format("LL");
    },
  },
};
</script>

<style scoped>
#bordaBody {
  background-color: #f1f4f9;
  padding: 15px 15px;
  border: 1px solid #d0d9e8;
}

#complementoConvidado {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.699);
}

#labelcomplementoConvidado {
  font-size: 14px;
  color: rgb(122, 122, 122);
}

#centro {
  margin-left: -100px;
  text-align:center;
  position: relative;
  left: 35%;
}

#label-decoration {
  color: rgb(139, 139, 139);
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
}
</style>