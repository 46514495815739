<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Alterar Senha de Usuários</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Alterar Senha</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <section class="content">
      <div
        class="container-fluid"
        style="display: flex; justify-content: center; align-items: center"
      >
        <section class="col-lg-6 connectedSortable">
          <!-- Custom tabs (Charts with tabs)-->
          <div class="card">
            <div class="card-body">
              <div class="login-logo">
                <a><b>Alterar</b>Senha</a>
              </div>
              <div class="card-body login-card-body">
                <p class="login-box-msg">
                  Alterar a senha do usuário selecionado
                </p>

                <form @submit.prevent="handleSubmit">
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <label
                        id="label-decoration"
                        class="mb-0"
                        for="nome-destino"
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                        >Usuário</label
                      >
                      <div>
                        <AutocompleteComponents
                          v-model="user"
                          :options="users"
                          label-key="nome"
                          value-key="pk_id_usuario"
                          placeholder="Pesquisar..."
                          @shouldSearch="pesquisarUsuarios"
                          @select="onSelect"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="input-group mb-3">
                    <input
                      type="password"
                      name="password"
                      class="form-control"
                      id="password"
                      v-model.trim="senha"
                      placeholder="Senha"
                      required
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>

                  <div class="input-group mb-3">
                    <input
                      type="password"
                      name="repassword"
                      class="form-control"
                      id="repassword"
                      v-model.trim="repassword"
                      placeholder="Repita a Senha"
                      required
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-primary btn-block">
                        Alterar Senha
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </section>
      </div>
      <!-- /.container-fluid -->
    </section>
  </div>
</template>

<script>
const axios = require("axios").default;
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\

export default {
  name: "AlterarSenha",

  data() {
    return {
      user: "", //===>Autocomplete<===\\
      users: [], //===>Autocomplete<===\\
      userSelect: "", //===>Autocomplete<===\\

      senha: "",
      repassword: "",

    };
  },

  components: {
    AutocompleteComponents,
  },

  methods: {
    pesquisarUsuarios(query) {
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.users = response.data;
      });
    },

    onSelect(user) {
      //===>Autocomplete<===\\
      this.userSelect = user;
    },

    async handleSubmit() {

      const usuario = JSON.parse(localStorage.getItem("storageData"))

      if (this.senha == this.repassword) {
        try {
          if (this.userSelect == "") {
            alert("Nem um usuário válido selecionado");
          } else if (this.user == "") {
            alert("Nem um usuário válido selecionado");
          } else {
            const response = await axios.post("auth/newPasswordOtherUser", {
              senha: this.senha,
              usuario_solicitante: this.userSelect["pk_id_usuario"],
              idUsuario_admin: usuario.pk_id_usuario,
            });

            //console.log(response.data)

            alert("Senha alterada com sucesso");

            (this.user = ""),
              (this.users = []),
              (this.userSelect = ""),
              (this.senha = "");
            this.repassword = "";
          }
        } catch (error) {

          const erroHttp = error.response.status;
          switch (erroHttp) {
            case 404:
              this.error =
                "Pagina não encontrada na Api! - [Erro] " +
                error.response.status;
              break;
          }
        }
      } else {
        alert("Campo REPITA A SENHA não confere!");

        this.senha = "";
        this.repassword = "";
      }
    },
  },
};
</script>

<style>
</style>