<template>
  <div class="content-wrapper" style="min-height: 1172.8px">
    <b-overlay :show="overlayShow" rounded="sm">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>Ramal</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Ramais</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">

            <div  
              v-for="ramal in dadosRamais"
              :key="ramal.pk_id_ramal" 
              class="col-md-3"
            >
              
              <div v-if="ramal.status == 'Ausente'" class="card card-secondary shadow-lg">              
                <div class="card-header">

                  <div>
                    <i class="fas fa-tty"></i>
                    <b> {{ramal.numero_ramal}}</b> <small>({{ramal.status}})</small>
                  </div>

                  <div>
                    <small>
                      <i class="fas fa-user"></i>
                      {{ramal.nomeUsuario}}
                    </small>
                  </div>

                </div>

                <div class="mb-2 mt-2 ml-2 mr-2">
                  <small>
                    <i class="fas fa-exclamation-triangle"></i>
                    Ausente
                  </small>
                </div>
              </div>
              
              <div v-if="ramal.status == 'Disponivel'" class="card card-success shadow-lg">              
                <div class="card-header">

                  <div>
                    <i class="fas fa-tty"></i>
                    <b> {{ramal.numero_ramal}}</b> <small>({{ramal.status}})</small>
                  </div>

                  <div>
                    <small>
                      <i class="fas fa-user"></i>
                      {{ramal.nomeUsuario}}
                    </small>
                  </div>

                </div>

                <div class="mb-2 mt-2 ml-2 mr-2">
                  <small>
                    <i class="fas fa-thumbs-up"></i>
                    Disponivel
                  </small>
                </div>
              </div>

              <div v-if="ramal.status == 'Ocupado'" class="card card-warning shadow-lg">              
                <div class="card-header">

                  <div>
                    <i class="fas fa-tty"></i>
                    <b> {{ramal.numero_ramal}}</b> <small>({{ramal.status}})</small>
                  </div>

                  <div>
                    <small>
                      <i class="fas fa-user"></i>
                      {{ramal.nomeUsuario}}
                    </small>
                  </div>

                </div>

                <div class="mb-2 mt-2 ml-2 mr-2">
                  <small>
                    <i class="fas fa-phone-alt"></i>
                    (51)99443-2851

                    <u v-if="2>3" class="float-right">
                      <i class="fas fa-arrow-up"></i>
                    </u>

                    <u v-else class="float-right">
                      <i class="fas fa-arrow-down"></i>
                    </u>
                  </small>
                </div>
              </div>

              <div v-if="ramal.status == 'Em Ligação'" class="card card-danger shadow-lg">              
                <div class="card-header">

                  <div>
                    <i class="fas fa-tty"></i>
                    <b> {{ramal.numero_ramal}}</b> <small>({{ramal.status}})</small>
                  </div>

                  <div>
                    <small>
                      <i class="fas fa-user"></i>
                      {{ramal.nomeUsuario}}
                    </small>
                  </div>

                </div>

                <div class="mb-2 mt-2 ml-2 mr-2">
                  <small>
                    <i class="fas fa-phone-alt"></i>
                    (51)99443-2851

                    <u v-if="2>1" class="float-right">
                      <i class="fas fa-arrow-up"></i>
                    </u>

                    <u v-else class="float-right">
                      <i class="fas fa-arrow-down"></i>
                    </u>
                  </small>
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>

      <a
        id="back-to-top"
        href="#"
        class="btn btn-primary back-to-top"
        role="button"
        aria-label="Scroll to top"
      >
        <i class="fas fa-chevron-up"></i>
      </a>
    </b-overlay>
  </div>
</template>

<script scoped>
const axios = require("axios").default;

export default {
  name: "Ramal",

  mounted() {
    this.getRamais();
    this.refresh();
  },

  beforeDestroy() {
    clearInterval(this.intervalid);
  },

  data() {
    return {
      dadosRamais: [],
      overlayShow: true,
    };
  },

  methods: {
    async getRamais() {
      try {
        const response = await axios.post("asterisk/ramais", {
          intervaloData: this.valueDate,
          idUnidade: this.idUnidade
        });

        this.dadosRamais = response.data;

        this.overlayShow = false;

        console.log(this.dadosRamais);
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async getRamalReload() {
      const response = await axios.post("asterisk/ramais", {
          intervaloData: this.valueDate,
          idUnidade: this.idUnidade
      });
      this.dadosRamais = response.data;
      console.log(this.dadosRamais);
    },

    refresh: function () {
      this.intervalid = setInterval(() => {
        this.getRamalReload();
      }, 2000);
    },
  },
};
</script>