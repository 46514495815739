<template>
  <div>
    <b-overlay :show="overlayShow" rounded="sm">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Instrução de Trabalho - Editar</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/perfil">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">It</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section class="content" v-if="contentHTML">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary card-outline">
                  <div class="card-header">
                    <div class="float-left mb-0 mr-sm-1 mb-sm-0">
                      <b-form-radio-group
                        id="btn-radios-2"
                        v-model="selectedTipo"
                        :options="optionsTipo"
                        button-variant="outline-secondary"
                        size="sm"
                        name="radio-btn-outline"
                        buttons
                      ></b-form-radio-group>
                    </div>

                    <div class="float-left col-sm-4 mb-0 mr-sm-1 mb-sm-0">
                      <b-form-select
                        required
                        v-model="selectedEquipe"
                        size="sm"
                      >
                        <option :value="null" disabled selected>
                          -- Selecione uma equipe --
                        </option>
                        <option
                          :value="option.pk_id_equipe"
                          v-for="option in optionsEquipe"
                          :key="option.pk_id_equipe"
                        >
                          {{ option.nome_equipe }}
                        </option>
                      </b-form-select>
                    </div>

                    <div class="float-right mb-0">
                      <button
                        @click="editarInstrucaoTrabalho"
                        class="btn-sm btn-primary"
                      >
                        <i class="fas fa-save"> Salvar</i>
                      </button>
                    </div>
                    <!--
                    <b-card class="mt-5" header="Form Data Result">
                      <pre class="m-0">{{ selectedMultVinculo }}</pre>
                    </b-card>
                    -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="content" v-if="contentHTML">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-secondary card-outline">
                  <div class="float-right">
                    <b-form-group
                      id="input-group-1"
                      label="Título da IT:"
                      label-for="input-1"
                      class="float-left col-sm-5 mb-2 mt-2 mr-0 ml-2"
                      description="Digíte um título para sua Instrução de Trabalho."
                    >
                      <b-form-input
                        id="input-1"
                        v-model.trim="titulo"
                        type="text"
                        placeholder="Título...."
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      id="input-group-1"
                      label="Vincule a atividade:"
                      label-for="input-1"
                      class="float-right col-sm-5 mb-2 mt-2 mr-2 ml-0"
                    >
                      <v-select
                        :disabled="atividadeEquipe"
                        multiple
                        placeholder="-Atividades-"
                        v-model="selectedMultVinculo"
                        :options="optionsAtividadeEquipe"
                        label="nome"
                      >
                      </v-select>
                    </b-form-group>
                  </div>

                  <div class="quillEditorContainer">
                    <div class="quillEditor">
                      <quill-editor
                        v-model="contentHTML"
                        :options="quillOptions"
                      ></quill-editor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-overlay>
  </div>
</template>

<script>
const axios = require("axios").default;
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "InstrucaoTrabalhoEditar",

  data() {
    return {
      overlayShow: true,

      contentHTML: "",
      quillOptions: {
        placeholder: "Digite sua IT aqui....",
      },
      titulo: "",
      selectedTipo: "",
      selectedEquipe: "",

      optionsTipo: [
        { text: "Rascunho", value: "Rascunho" },
        { text: "Privada", value: "Privada" },
        { text: "Equipe", value: "Equipe" },
        { text: "Geral", value: "Geral" },
      ],

      optionsEquipe: [],

      optionsAtividade: [],

      selectedMultVinculo: [],

      optionsAtividadeEquipe: [],
    };
  },

  mounted() {
    this.instrucaoTrabalho();
    this.myequipes();
  },

  computed: {
    atividadeEquipe() {
      if (this.selectedEquipe != null) {
        this.optionsAtividadeEquipe = [];
        //this.selectedMultVinculo = [];

        var indice = "0";

        this.optionsAtividade.forEach((atividades, i) => {
          if (atividades.pk_id_equipe == this.selectedEquipe) {
            this.optionsAtividadeEquipe[indice] = atividades;

            indice++;
          }
        });

        //console.log(this.optionsAtividadeEquipe);

        return this.selectedEquipe != null ? false : true;
      }
    },
  },

  methods: {
    async myequipes() {
      const perfil = JSON.parse(localStorage.getItem("storageData"));
      const equipe = JSON.parse(localStorage.getItem("equipeUser"));

      try {
        const perfil = JSON.parse(localStorage.getItem("storageData"));
        const equipe = JSON.parse(localStorage.getItem("equipeUser"));

        const response = await axios.post("equipe/minhasEquipes", {
          idUser: perfil.pk_id_usuario,
          equipes: equipe,
        });

        //console.log(response.data);

        this.optionsEquipe = response.data.equipes;
        this.optionsAtividade = response.data.atividades["0"];
      } catch (error) {
        //console.log(error.response.status)

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 401:
            this.error = "[Erro] " + error.response.status;
            break;
          case 404:
            this.error =
              "Pagina não encontrada na Api! - [Erro] " + error.response.status;
            break;
        }
      }
    },

    async editarInstrucaoTrabalho() {
      try {
        if (this.selectedEquipe == null) {
          alert("Selecione uma equipe para salvar a IT!");
        } else if (this.contentHTML == "") {
          alert("Digite o conteudo de sua IT!");
        } else if (this.titulo == "") {
          alert("Digite um Título para sua IT!");
        } else {
          const response = await axios.post(
            "instrucaoTrabalho/editarInstrucaoTrabalho",
            {
              idIt: this.$route.query.id,
              equipe: this.selectedEquipe,
              tipo: this.selectedTipo,
              titulo: this.titulo,
              conteudo: this.contentHTML,
              atividadeVinculada: this.selectedMultVinculo,
            }
          );

          alert(response.data);

          this.$router.push("instrucao-trabalho?id=" + this.$route.query.id);
        }
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async instrucaoTrabalho() {
      try {
        const response = await axios.post(
          "instrucaoTrabalho/instrucaoTrabalhoEditar",
          {
            idIT: this.$route.query.id,
          }
        );

          (this.selectedEquipe = response.data.it.pk_id_equipe),
          (this.selectedTipo = response.data.it.tipo),
          (this.contentHTML = response.data.it.conteudo),
          (this.titulo = response.data.it.titulo),
          (this.selectedMultVinculo = response.data.atendimentoCategoria);

        this.overlayShow = false;
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>