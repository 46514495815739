<template>
<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>Instrução de Trabalho - Lista</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"> <router-link to="/perfil">Home</router-link></li>
            <li class="breadcrumb-item active">It</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary card-outline">

              <b-tabs active-nav-item-class="font-weight-bold text-uppercase" content-class="mt-3">

                <div class="float-right mr-4">    
                  <router-link to="/instrucao-trabalho-nova">
                    <button class="btn-sm btn-outline-secondary">
                        <i class="fas fa-plus-square"> Novo</i>
                    </button> 
                  </router-link> 
                </div><br>
                   
<!--///////////////////====TABELA IT-PRIVADA====//////////////////////-->
                <b-tab title="Privado" active>
                
                  <div class="card-body">
            
                    <b-container fluid>
                      <b-row>

                        <b-input-group class="mt-0 mb-1">
                          <b-input-group-append>
                            <b-form-input
                              size="sm"
                              id="filter-input"
                              v-model="configTableItPrivado.filter"
                              type="search"
                              placeholder="Pesquisar..."
                            ></b-form-input>
                          </b-input-group-append> 
                        </b-input-group>

                        <b-table
                          :busy="isBusy"
                          striped
                          outlined
                          hover
                          bordered
                          head-variant="dark"
                          :items="itemsItPrivado"
                          :fields="fieldsItPrivado"
                          :current-page="configTableItPrivado.currentPage"
                          :per-page="configTableItPrivado.perPage"
                          :filter="configTableItPrivado.filter"
                          :filter-included-fields="configTableItPrivado.filterOn"
                          :sort-by.sync="configTableItPrivado.sortBy"
                          :sort-desc.sync="configTableItPrivado.sortDesc"
                          :sort-direction="configTableItPrivado.sortDirection"
                          stacked="md"
                          show-empty
                          small
                        > 

                          <template #empty>
                            <h5 class="text-center">{{ tableItPrivado.emptyTexts }}</h5>
                          </template>

                          <template #emptyfiltered>
                            <h5 class="text-center">{{ tableItPrivado.emptyFilteredTexts }}</h5>
                          </template>

                          <template #table-busy>
                            <div class="text-center text-danger my-3">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong> Carregando...</strong>
                            </div>
                          </template>

                          <template #cell(nome)="row">

                            <div class="user-panel pb-1">
                              <div v-if="row.item.foto" class="image">
                                <img :src="require(`../../../assets/img/profiles/${row.item.foto}`)" class="img-circle elevation-1" alt="User Image">
                              </div>

                              <div class="image">
                                <img :src="require(`../../../assets/img/profiles/profile.png`)" class="img-circle elevation-1" alt="User Image">
                              </div>

                              <div class="small ml-2">
                                {{row.item.nome}}
                              </div>
                                                          
                            </div>
                          </template>

                          <template #cell(titulo)="row">

                            <router-link :to="'/instrucao-trabalho?id='+row.item.pk_id_instrucao_trabalho">
                              <div>
                                {{row.item.titulo}}
                              </div>
                            </router-link>

                            <div class="text-muted">
                              <div class="small"><span><i class="fas fa-clock"></i></span>
                                {{row.item.data_hora | moment }}
                              </div>
                            </div>

                          </template>

                        </b-table>

                        <b-tfoot>
                          <div class="text-muted">
                            <div class="small"><span><i class="fas fa-list-ol"></i></span>
                              Total de registros: <b>{{itemsItPrivado.length}}</b>
                            </div>
                          </div>
                        </b-tfoot>

                        <b-col sm="5" md="0" class="my-1">
                          <b-form-group
                            label="Quantidade"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="8"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                          >
                            <b-form-select
                              id="per-page-select"
                              v-model="configTableItPrivado.perPage"
                              :options="configTableItPrivado.pageOptions"
                              size="sm"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col sm="5" md="4" class="my-1">
                          <b-pagination
                            v-model="configTableItPrivado.currentPage"
                            :total-rows="itPrivadoRows"
                            :per-page="configTableItPrivado.perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>

                      </b-row>
                    </b-container>                                                                     
                  </div>

                </b-tab>

<!--///////////////////====TABELA IT-RASCUNHO====//////////////////////-->
                <b-tab title="Rascunho">
                
                  <div class="card-body">
                                     
                    <b-container fluid>
                      <b-row>

                        <b-input-group class="mt-0 mb-1">
                          <b-input-group-append>
                            <b-form-input
                              size="sm"
                              id="filter-input"
                              v-model="configTableItRascunho.filter"
                              type="search"
                              placeholder="Pesquisar..."
                            ></b-form-input>
                          </b-input-group-append> 
                        </b-input-group>

                        <b-table
                          striped
                          outlined
                          hover
                          bordered
                          head-variant="dark"
                          :items="itemsItRascunho"
                          :fields="fieldsItRascunho"
                          :current-page="configTableItRascunho.currentPage"
                          :per-page="configTableItRascunho.perPage"
                          :filter="configTableItRascunho.filter"
                          :filter-included-fields="configTableItRascunho.filterOn"
                          :sort-by.sync="configTableItRascunho.sortBy"
                          :sort-desc.sync="configTableItRascunho.sortDesc"
                          :sort-direction="configTableItRascunho.sortDirection"
                          stacked="md"
                          show-empty
                          small
                        > 

                          <template #empty>
                            <h5 class="text-center">{{ tableItRascunho.emptyTexts }}</h5>
                          </template>

                          <template #emptyfiltered>
                            <h5 class="text-center">{{ tableItRascunho.emptyFilteredTexts }}</h5>
                          </template>

                          <template #cell(nome)="row">
                            <div class="user-panel pb-1">
                              <div v-if="row.item.foto" class="image">
                                <img :src="require(`../../../assets/img/profiles/${row.item.foto}`)" class="img-circle elevation-1" alt="User Image">
                              </div>

                              <div class="image">
                                <img :src="require(`../../../assets/img/profiles/profile.png`)" class="img-circle elevation-1" alt="User Image">
                              </div>

                              <div class="small ml-2">
                                {{row.item.nome}}
                              </div>
                                                          
                            </div>
                          </template>

                          <template #cell(titulo)="row">

                            <router-link :to="'/instrucao-trabalho?id='+row.item.pk_id_instrucao_trabalho">
                              <div>
                                {{row.item.titulo}}
                              </div>
                            </router-link>

                            <div class="text-muted">
                              <div class="small"><span><i class="fas fa-clock"></i></span>
                                {{row.item.data_hora  | moment}}
                              </div>
                            </div>

                          </template>

                        </b-table>

                        <b-tfoot>
                          <div class="text-muted">
                            <div class="small"><span><i class="fas fa-list-ol"></i></span>
                              Total de registros: <b>{{itemsItRascunho.length}}</b>
                            </div>
                          </div>
                        </b-tfoot>

                        <b-col sm="5" md="0" class="my-1">
                          <b-form-group
                            label="Quantidade"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="8"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                          >
                            <b-form-select
                              id="per-page-select"
                              v-model="configTableItRascunho.perPage"
                              :options="configTableItRascunho.pageOptions"
                              size="sm"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col sm="5" md="4" class="my-1">
                          <b-pagination
                            v-model="configTableItRascunho.currentPage"
                            :total-rows="itRascunhoRows"
                            :per-page="configTableItRascunho.perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>

                      </b-row>
                    </b-container>                                                                     
                  </div>
                
                </b-tab>

<!--///////////////////====TABELA IT-GERAL====//////////////////////-->
                <b-tab title="Geral">
                
                  <div class="card-body">
                                      
                      <b-container fluid>
                          <b-row>

                          <b-input-group class="mt-0 mb-1">
                              <b-input-group-append>
                              <b-form-input
                                  size="sm"
                                  id="filter-input"
                                  v-model="configTableItGeral.filter"
                                  type="search"
                                  placeholder="Pesquisar..."
                              ></b-form-input>
                              </b-input-group-append> 
                          </b-input-group>

                          <b-table
                              striped
                              outlined
                              hover
                              bordered
                              head-variant="dark"
                              :items="itemsItGeral"
                              :fields="fieldsItGeral"
                              :current-page="configTableItGeral.currentPage"
                              :per-page="configTableItGeral.perPage"
                              :filter="configTableItGeral.filter"
                              :filter-included-fields="configTableItGeral.filterOn"
                              :sort-by.sync="configTableItGeral.sortBy"
                              :sort-desc.sync="configTableItGeral.sortDesc"
                              :sort-direction="configTableItGeral.sortDirection"
                              stacked="md"
                              show-empty
                              small
                          > 

                              <template #empty>
                              <h5 class="text-center">{{ tableItGeral.emptyTexts }}</h5>
                              </template>

                              <template #emptyfiltered>
                              <h5 class="text-center">{{ tableItGeral.emptyFilteredTexts }}</h5>
                              </template>

                              <template #cell(nome)="row">

                              <div class="user-panel pb-1">
                                  <div v-if="row.item.foto" class="image">
                                    <img :src="require(`../../../assets/img/profiles/${row.item.foto}`)" class="img-circle elevation-1" alt="User Image">
                                  </div>

                                  <div class="image">
                                    <img :src="require(`../../../assets/img/profiles/profile.png`)" class="img-circle elevation-1" alt="User Image">
                                  </div>

                                  <div class="small ml-2">
                                  {{row.item.nome}}
                                  </div>
                                                              
                              </div>
                              </template>

                              <template #cell(titulo)="row">

                              <router-link :to="'/instrucao-trabalho?id='+row.item.pk_id_instrucao_trabalho">
                                  <div>
                                  {{row.item.titulo}}
                                  </div>
                              </router-link>

                              <div class="text-muted">
                                  <div class="small"><span><i class="fas fa-clock"></i></span>
                                  {{row.item.data_hora  | moment}}
                                  </div>
                              </div>

                              </template>

                          </b-table>

                          <b-tfoot>
                              <div class="text-muted">
                              <div class="small"><span><i class="fas fa-list-ol"></i></span>
                                  Total de registros: <b>{{itemsItGeral.length}}</b>
                              </div>
                              </div>
                          </b-tfoot>

                          <b-col sm="5" md="0" class="my-1">
                              <b-form-group
                              label="Quantidade"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="8"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                              >
                              <b-form-select
                                  id="per-page-select"
                                  v-model="configTableItGeral.perPage"
                                  :options="configTableItGeral.pageOptions"
                                  size="sm"
                              >
                              </b-form-select>
                              </b-form-group>
                          </b-col>

                          <b-col sm="5" md="4" class="my-1">
                              <b-pagination
                              v-model="configTableItGeral.currentPage"
                              :total-rows="itGeralRows"
                              :per-page="configTableItGeral.perPage"
                              align="fill"
                              size="sm"
                              class="my-0"
                              ></b-pagination>
                          </b-col>

                          </b-row>
                      </b-container>                                                                     
                  </div>

                </b-tab>


<!--///////////////////====TABELA IT-EQUIPE====//////////////////////-->
                <b-tab title="Equipe">
                
                  <div class="card-body">
                                      
                      <b-container fluid>
                          <b-row>

                          <b-input-group class="mt-0 mb-1">
                              <b-input-group-append>
                              <b-form-input
                                  size="sm"
                                  id="filter-input"
                                  v-model="configTableItEquipe.filter"
                                  type="search"
                                  placeholder="Pesquisar..."
                              ></b-form-input>
                              </b-input-group-append> 
                          </b-input-group>

                          <b-table
                              striped
                              outlined
                              hover
                              bordered
                              head-variant="dark"
                              :items="itemsItEquipe"
                              :fields="fieldsItEquipe"
                              :current-page="configTableItEquipe.currentPage"
                              :per-page="configTableItEquipe.perPage"
                              :filter="configTableItEquipe.filter"
                              :filter-included-fields="configTableItEquipe.filterOn"
                              :sort-by.sync="configTableItEquipe.sortBy"
                              :sort-desc.sync="configTableItEquipe.sortDesc"
                              :sort-direction="configTableItEquipe.sortDirection"
                              stacked="md"
                              show-empty
                              small
                          > 

                              <template #empty>
                              <h5 class="text-center">{{ tableItEquipe.emptyTexts }}</h5>
                              </template>

                              <template #emptyfiltered>
                              <h5 class="text-center">{{ tableItEquipe.emptyFilteredTexts }}</h5>
                              </template>

                              <template #cell(nome)="row">
                              <div class="user-panel pb-1">
                                  <div v-if="row.item.foto" class="image">
                                    <img :src="require(`../../../assets/img/profiles/${row.item.foto}`)" class="img-circle elevation-1" alt="User Image">
                                  </div>

                                  <div class="image">
                                    <img :src="require(`../../../assets/img/profiles/profile.png`)" class="img-circle elevation-1" alt="User Image">
                                  </div>
                                  <div class="small ml-2">
                                  {{row.item.nome}}
                                  </div>
                                                              
                              </div>
                              </template>

                              <template #cell(titulo)="row">

                              <router-link :to="'/instrucao-trabalho?id='+row.item.pk_id_instrucao_trabalho">
                                  <div>
                                  {{row.item.titulo}}
                                  </div>
                              </router-link>

                              <div class="text-muted">
                                  <div class="small"><span><i class="fas fa-clock"></i></span>
                                  {{row.item.data_hora | moment}}
                                  </div>
                              </div>

                              </template>

                          </b-table>

                          <b-tfoot>
                              <div class="text-muted">
                              <div class="small"><span><i class="fas fa-list-ol"></i></span>
                                  Total de registros: <b>{{itemsItEquipe.length}}</b>
                              </div>
                              </div>
                          </b-tfoot>

                          <b-col sm="5" md="0" class="my-1">
                              <b-form-group
                              label="Quantidade"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="8"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                              >
                              <b-form-select
                                  id="per-page-select"
                                  v-model="configTableItEquipe.perPage"
                                  :options="configTableItEquipe.pageOptions"
                                  size="sm"
                              >
                              </b-form-select>
                              </b-form-group>
                          </b-col>

                          <b-col sm="5" md="4" class="my-1">
                              <b-pagination
                              v-model="configTableItEquipe.currentPage"
                              :total-rows="itEquipeRows"
                              :per-page="configTableItEquipe.perPage"
                              align="fill"
                              size="sm"
                              class="my-0"
                              ></b-pagination>
                          </b-col>

                          </b-row>
                      </b-container>                                                                     
                  </div>

                </b-tab>
              </b-tabs>

            </div>
          </div>
        </div>
      </div>
  </section>

</div>  
</template>

<script scoped>
const axios = require('axios').default
var moment = require('moment');
moment.locale('pt-br');

export default {
    name: 'InstrucaoTrabalhoLista',
    
    data() {
      return {       
        isBusy: true,   

/////////////////////////<====TABELA DE IT-PRIVADAS====>///////////////////////////////////////////
        itemsItPrivado: [],

        tableItPrivado: {
          emptyTexts: 'Não há membros cadastrado nesta equipe!',
          emptyFilteredTexts: 'Não há registros que correspondam à sua pesquisa'
        },

        fieldsItPrivado: [
          { 
            key: 'nome',
            label: 'Criador',
            sortable: true,
          },

          {
            key: 'nome_equipe',
            label: 'Equipe',
            sortable: true
          },

          {
            key: 'tipo',
            label: 'Tipo',
            sortable: true
          },

          {
            key: 'titulo',
            label: 'Título',
            sortable: true,
            variant: 'secondary'
          },

        ],

        configTableItPrivado: {
          perPage: 10,
          currentPage: 1,
          totalRows: 1,
          pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
          sortBy: '',
          sortDesc: false,
          sortDirection: 'asc',
          filter: null,
          filterOn: [],
        },


/////////////////////////<====TABELA DE IT-RASCUNHO====>///////////////////////////////////////////
        itemsItRascunho: [],

        tableItRascunho: {
          emptyTexts: 'Não há membros cadastrado nesta equipe!',
          emptyFilteredTexts: 'Não há registros que correspondam à sua pesquisa'
        },

        fieldsItRascunho: [
          { 
            key: 'nome',
            label: 'Criador',
            sortable: true,
          },

          {
            key: 'nome_equipe',
            label: 'Equipe',
            sortable: true
          },

          {
            key: 'tipo',
            label: 'Tipo',
            sortable: true
          },

          {
            key: 'titulo',
            label: 'Título',
            sortable: true,
            variant: 'secondary'
          },

        ],

        configTableItRascunho: {
          perPage: 10,
          currentPage: 1,
          totalRows: 1,
          pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
          sortBy: '',
          sortDesc: false,
          sortDirection: 'asc',
          filter: null,
          filterOn: [],
        },


/////////////////////////<====TABELA DE IT-GERAL====>///////////////////////////////////////////
        itemsItGeral: [],

        tableItGeral: {
          emptyTexts: 'Não há membros cadastrado nesta equipe!',
          emptyFilteredTexts: 'Não há registros que correspondam à sua pesquisa'
        },

        fieldsItGeral: [
          { 
            key: 'nome',
            label: 'Criador',
            sortable: true,
          },

          {
            key: 'nome_equipe',
            label: 'Equipe',
            sortable: true
          },

          {
            key: 'tipo',
            label: 'Tipo',
            sortable: true
          },

          {
            key: 'titulo',
            label: 'Título',
            sortable: true,
            variant: 'secondary'
          },

        ],

        configTableItGeral: {
          perPage: 10,
          currentPage: 1,
          totalRows: 1,
          pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
          sortBy: '',
          sortDesc: false,
          sortDirection: 'asc',
          filter: null,
          filterOn: [],
        },

/////////////////////////<====TABELA DE IT-EQUIPE====>///////////////////////////////////////////
        itemsItEquipe: [],

        tableItEquipe: {
          emptyTexts: 'Não há membros cadastrado nesta equipe!',
          emptyFilteredTexts: 'Não há registros que correspondam à sua pesquisa'
        },

        fieldsItEquipe: [
          { 
            key: 'nome',
            label: 'Criador',
            sortable: true,
          },

          {
            key: 'nome_equipe',
            label: 'Equipe',
            sortable: true
          },

          {
            key: 'tipo',
            label: 'Tipo',
            sortable: true
          },

          {
            key: 'titulo',
            label: 'Título',
            sortable: true,
            variant: 'secondary'
          },

        ],

        configTableItEquipe: {
          perPage: 10,
          currentPage: 1,
          totalRows: 1,
          pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
          sortBy: '',
          sortDesc: false,
          sortDirection: 'asc',
          filter: null,
          filterOn: [],
        },
      }
    },

    computed: {
      itPrivadoRows() {
        return this.itemsItPrivado.length
      },

      itRascunhoRows() {
        return this.itemsItRascunho.length
      },

       itGeralRows() {
        return this.itemsItGeral.length
      },

       itEquipeRows() {
        return this.itemsItEquipe.length
      },
    },

    mounted() {   
        this.instrucaoTrabalho()
        
        // console.log(this.$route.query.id)
    },

    methods: {

      async instrucaoTrabalho(){
        try{

          const perfil = (JSON.parse(localStorage.getItem('storageData')))
          this.user = perfil

          const equipe = (JSON.parse(localStorage.getItem('equipeUser')))
          this.equipeUser = equipe

          //Requisição
          const response = await axios.post('instrucaoTrabalho/instrucaoTrabalhoLista',{
            idUsuario: this.user.pk_id_usuario,
            equipes: this.equipeUser,
          });

          //console.log(response.data)

          this.itemsItPrivado = response.data.itPrivado.original;
          this.itemsItRascunho = response.data.itRascunho.original;
          this.itemsItGeral = response.data.itGeral.original;

          var itEquipeArray = response.data.itEquipe.original.flat();
          this.itemsItEquipe = itEquipeArray;

          this.isBusy = false;

////////////////////////////<==Util==>///////////////////////////////////////////
          // // Junta Array
          // var hege = response.data.itEquipe.original['1'];
          // var stale = response.data.itEquipe.original['0'];
          // var children = hege.concat(stale);
          // console.log(children);
////////////////////////////<==Util==>///////////////////////////////////////////

        }catch(error){
      
          //console.log(error.response.status)

          const erroHttp = error.response.status;
          switch (erroHttp) {
          case 500:
            alert('Api Pabx fora do ar! - [Erro] '+error.response.status);
            break;
          case 404:
            alert('Pagina não encontrada na Api! - [Erro] '+error.response.status);
            break;
          }
        }    
      },
    },

    filters: {
      moment: function (date) {
        return moment(date).format('llll');
      }
    }
}
</script>


<style scoped>
   
</style>