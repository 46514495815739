import { render, staticRenderFns } from "./InstrucaoTrabalho.vue?vue&type=template&id=cf3f5664&scoped=true&"
import script from "./InstrucaoTrabalho.vue?vue&type=script&lang=js&"
export * from "./InstrucaoTrabalho.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf3f5664",
  null
  
)

export default component.exports