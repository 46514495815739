<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Liberar Extrato de Email</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Extrato de Email</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <section class="content">
      <div
        class="container-fluid"
        style="display: flex; justify-content: center; align-items: center"
      >
        <section class="col-lg-6 connectedSortable">
          <!-- Custom tabs (Charts with tabs)-->
          <div class="card">
            <div class="card-body">
              <div class="login-logo">
                <a><b>Liberar</b>Extrato</a>
              </div>
              <div class="card-body login-card-body">
                <p class="login-box-msg">
                  Liberar extrato de Email para o usuario selecionado
                </p>

                <div>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <label
                        id="label-decoration"
                        class="mb-0"
                        for="nome-destino"
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                        >Usuário</label
                      >
                      <div>
                        <AutocompleteComponents
                          v-model="user"
                          :options="users"
                          label-key="nome"
                          value-key="pk_id_usuario"
                          placeholder="Pesquisar..."
                          @shouldSearch="pesquisarUsuarios"
                          @select="onSelect"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="mb-0 mt-4">
                    <b-button
                      :disabled="statusButton"
                      @click="liberarUsuario"
                      type="submit"
                      variant="success"
                      size="sm"
                      class="float-right"
                    >
                      <i class="fas fa-user-check"></i>
                      <b> Liberar</b></b-button
                    >

                    <b-button
                      :disabled="statusButton"
                      @click="bloquearUsuario"
                      type="submit"
                      variant="danger"
                      size="sm"
                    >
                      <i class="fas fa-user-slash"></i>
                      <b> Bloquear</b></b-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </section>
      </div>
      <!-- /.container-fluid -->
    </section>
  </div>
</template>

<script>
const axios = require("axios").default;
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\

export default {
  name: "LiberarExtratoEmail",

  data() {
    return {
      user: "", //===>Autocomplete<===\\
      users: [], //===>Autocomplete<===\\
      userSelect: "", //===>Autocomplete<===\\
    };
  },

  computed: {
    statusButton() {
      return this.user.length > 2 && this.userSelect ? false : true;
    },
  },

  components: {
    AutocompleteComponents,
  },

  methods: {
    pesquisarUsuarios(query) {
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.users = response.data;
      });
    },

    onSelect(user) {
      //===>Autocomplete<===\\
      this.userSelect = user;
    },

    async bloquearUsuario() {
      try {
        if (this.userSelect == "") {
          alert("Nem um usuário válido selecionado");
        } else if (this.user == "") {
          alert("Nem um usuário válido selecionado");
        } else {
          const response = await axios.post("email/bloquearExtratoEmail", {
            usuario_solicitante: this.userSelect["pk_id_usuario"],
            acao: "bloquear",
          });

          alert("Feito o bloqueio de acesso ao Extrato de e-mail");

          (this.user = ""), (this.users = []), (this.userSelect = "");
        }
      } catch (error) {
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 404:
            this.error =
              "Pagina não encontrada na Api! - [Erro] " + error.response.status;
            break;
        }
      }
    },

    async liberarUsuario() {
      try {
        if (this.userSelect == "") {
          alert("Nem um usuário válido selecionado");
        } else if (this.user == "") {
          alert("Nem um usuário válido selecionado");
        } else {
          const response = await axios.post("email/liberarExtratoEmail", {
            usuario_solicitante: this.userSelect["pk_id_usuario"],
            acao: "liberar",
          });

          alert("Feito a liberação de acesso ao Extrato de e-mail");

          (this.user = ""), (this.users = []), (this.userSelect = "");
        }
      } catch (error) {
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 404:
            this.error =
              "Pagina não encontrada na Api! - [Erro] " + error.response.status;
            break;
        }
      }
    },
  },
};
</script>

<style>
</style>