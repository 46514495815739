<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Todoist</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Todoist</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card-body">
              <div class="row" id="borderTodoist">
                <div class="col-md-4" id="navTodoist">
                  <!--==================================================================-->

                  <!-- <div class="sidebar">
                    <nav class="mt-4">
                      <ul
                        class="
                          nav nav-pills nav-sidebar
                          flex-column
                          nav-child-indent nav-flat
                        "
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                      >
                        <li class="nav-item">
                          <router-link to="/home" class="nav-link">
                            <i class="far fa-list-alt nav-icon"></i>
                            <p>Entrada</p>
                          </router-link>
                        </li>

                        <li class="nav-item">
                          <router-link to="/feed" class="nav-link">
                            <i class="fas fa-rss nav-icon"></i>
                            <p>Hoje</p>
                          </router-link>
                        </li>

                        <li class="nav-item">
                          <router-link to="/perfil" class="nav-link">
                            <i class="fas fa-address-card nav-icon"></i>
                            <p>Em Breve</p>
                          </router-link>
                        </li>

                        <li class="nav-header"></li>

                        <li class="nav-item has-treeview">
                          <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-adjust nav-icon"></i>
                            <p>
                              Projetos
                              <i class="right fas fa-angle-left"></i>
                            </p>
                          </a>
                          <ul class="nav nav-treeview">
                            <li class="nav-item">
                              <div class="nav-link">
                                <i class="fas fa-adjust nav-icon"></i>
                                <p>teste 1</p>
                              </div>
                            </li>

                            <li class="nav-item">
                              <div class="nav-link">
                                <i class="fas fa-adjust nav-icon"></i>
                                <p>teste2</p>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div> -->

                  <div class="mt-3">
                    <div class="card collapsed-card">
                      <div class="card-header">
                        <div class="mb-2">
                          <a href="#">
                            <i class="fas fa-inbox"></i> Entrada
                            <span class="badge bg-primary float-right">3</span>
                          </a>
                        </div>
                        <div class="mb-2">
                          <a href="#">
                            <i class="fas fa-calendar-day"></i> Hoje
                            <span class="badge bg-primary float-right">7</span>
                          </a>
                        </div>
                        <div class="mb-2">
                          <a href="#">
                            <i class="fas fa-calendar-alt"></i> Em Breve
                            <span class="badge bg-primary float-right">18</span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header">
                        <!-- <h3 class="card-title">Labels</h3> -->

                        <a href="#" type="button" data-card-widget="collapse">
                          Projetos

                          <button
                            type="button"
                            class="btn btn-tool float-right"
                            data-card-widget="collapse"
                          >
                            <i class="fas fa-minus"></i>
                          </button>
                        </a>
                      </div>
                      <div class="card-body p-0">
                        <ul class="nav nav-pills flex-column">
                          <li class="nav-item">
                            <a href="#" class="nav-link">
                              <i class="far fa-circle text-danger"></i>
                              Important
                            </a>
                          </li>
                          <li class="nav-item">
                            <a href="#" class="nav-link">
                              <i class="far fa-circle text-warning"></i>
                              Promotions
                            </a>
                          </li>
                          <li class="nav-item">
                            <a href="#" class="nav-link">
                              <i class="far fa-circle text-primary"></i>
                              Social
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <!--==================================================================-->
                </div>
                <div class="col-md-8" id="painelTodoist">
                  hdbgfhdkfgdgfhdgfshg
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script scoped>
const axios = require("axios").default;

var moment = require("moment");
moment.locale("pt-br");

export default {
  data() {
    return {
      usuario: "",
    };
  },

  methods: {},

  filters: {
    moment: function (date) {
      return moment(date).format("LL");
    },
  },
};
</script>

<style scoped>
#navTodoist {
  background-color: #4d4d4d;
}

#painelTodoist {
  background-color: #1f1f1f;
}

#borderTodoist {
  border: 2px solid rgb(5, 39, 10);
  border-radius: 5px;
}
</style>