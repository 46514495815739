<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Add Feed</h1>
          </div>

          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Feed</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div id="" class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-body">
                <div id="bordaBody" class="well well-sm">
                  <div class="quillEditorContainer">
                    <div class="quillEditor">
                      <quill-editor
                        v-model="contentHTML"
                        :options="editorOption"
                      ></quill-editor>

                      <div class="mt-2">
                        <p
                          id="nome-anexo"
                          class="mb-0"
                          v-for="(filesAnexo, index) in fileReceipt"
                          :key="index"
                        >
                          {{ filesAnexo.name }}
                          <button
                            type="button"
                            @click="apagarAnexo(index)"
                            class="btn badge bagde-light"
                          >
                            <i class="fa fa-trash text-danger"></i>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="float-right mt-4">
                  <b-button
                    id="text-button-anexo"
                    @click="openFileDialog()"
                    type="button"
                    variant="outline-dark"
                    class="mr-2"
                  >
                    <i id="anexo" class="fas fa-paperclip"></i>
                    Anexo
                  </b-button>

                  <b-button variant="primary" @click="salvarFeed()">
                    <i class="fas fa-save"></i>
                    Salvar
                  </b-button>

                  <input
                    ref="files"
                    type="file"
                    class="d-none"
                    accept=".png, .jpg, .jpeg, .pdf, .avi, .mp4,"
                    @change="handleFile"
                    multiple
                  />

                  <!-- <input
                    ref="files"
                    type="file"
                    class="d-none"
                    accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .avi, .mp4, .ppt, .pptx"
                    @change="handleFile"
                    multiple
                  /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script scoped>
const axios = require("axios").default;

export default {
  name: "AdicionarFeed",

  mounted() {},

  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
        },
        placeholder: "Digite seu feed aqui....",
      },

      contentHTML: "",

      fileReceipt: [],

      user: [],
      feed: [],
      feedEditar: [],
    };
  },

  methods: {
    openFileDialog() {
      this.$refs.files.value = null;
      this.$refs.files.click();
    },

    handleFile() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.fileReceipt.push(this.$refs.files.files[i]);
      }
    },

    apagarAnexo(e) {
      this.fileReceipt.splice(e, 1);
    },

    async salvarFeed() {
      if (this.contentHTML == "") {
        alert("Digite o conteúdo de seu Feed!");
      } else {
        const perfil = JSON.parse(localStorage.getItem("storageData"));

        var self = this;

        let formData = new FormData();
        for (let i = 0; i < this.fileReceipt.length; i++) {
          let file = self.fileReceipt[i];
          formData.append("files[" + i + "]", file);
        }
        formData.append("idUser", perfil.pk_id_usuario);
        formData.append("conteudo", this.contentHTML);

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        try {
          //Requisição atualizar dados
          await axios
            .post("feed/addNovoFeed", formData, config)
            .then((response) => {
              self.$refs.files.value = "";
              self.fileReceipt = [];

              alert(response.data);

              this.contentHTML = "";

              this.$router.push(
                "home"
              );
            });
        } catch (error) {
          console.log(error.response.status);

          const erroHttp = error.response.status;
          switch (erroHttp) {
            case 401:
              alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
              break;
            case 404:
              alert(
                "Pagina não encontrada na Api! - [Erro] " +
                  error.response.status
              );
              break;
          }
        }
      }
    },
  },
};
</script>


<style scoped>
.quillEditor {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.quillEditorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>