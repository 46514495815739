<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Agenda</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Agenda</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary card-outline">
              <div class="card-body">
                <b-overlay :show="show" rounded="sm">
                  <div class="demo-app-main">
                    <FullCalendar
                      class="demo-app-calendar"
                      :options="calendarOptions"
                    >
                      <template v-slot:eventContent="arg">
                        <b>{{ arg.timeText }}</b>
                        <i>{{ arg.event.title }}</i>
                      </template>
                    </FullCalendar>
                  </div>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--------------------------------Modal Detalhes da Tarefa-------------------------------------->

    <b-modal
      scrollable
      ref="tarefa"
      no-close-on-backdrop
      title="Detalhes da Tarefa"
    >
      <template #modal-header>
        <h5>Detalhes da Tarefa</h5>
      </template>

      <template #default>
        <div class="tab-pane" id="settings">
          <div class="form-group row">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12">
                  <ul class="list-unstyled">
                    <li><b>Titulo:</b> {{ eventEdit.title }}</li>
                  </ul>
                </div>
                <div class="col-lg-12">
                  <div id="bordaBody" class="well well-sm">
                    <ul class="list-unstyled">
                      <li>
                        <b>Início:</b> {{ eventEdit.start | moment }} às
                        <b class="text-secondary">{{ eventEdit.timeStart }}</b>
                      </li>

                      <li>
                        <b>Final:</b> {{ eventEdit.end | moment }} às
                        <b class="text-secondary"> {{ eventEdit.timeEnd }}</b>
                      </li>

                      <li v-if="eventEdit.descricao" class="mt-3">
                        <b>Descrição:</b> {{ eventEdit.descricao }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  v-for="convidado in convidadosAgenda"
                  :key="convidado.index"
                  class="col-lg-12 mt-2"
                >
                  <div id="bordaBody" class="well well-sm">
                    <b> <u> Convidado: </u></b>

                    <div class="mt-3">
                      <div>
                        <li
                          class="list-unstyled"
                          id="labelcomplementoConvidado"
                        >
                          <b>
                            <i>{{ convidado.nome }} </i></b
                          >
                        </li>

                        <ul>
                          <li v-if="convidado.funcao" id="complementoConvidado">
                            <b class="text-black">Cargo:</b>
                            {{ convidado.funcao }}
                          </li>
                          <li
                            v-if="convidado.matricula"
                            id="complementoConvidado"
                          >
                            <b class="text-black">Matricula:</b>
                            {{ convidado.matricula }}
                          </li>
                          <li
                            v-if="convidado.telefone"
                            id="complementoConvidado"
                          >
                            <b class="text-black">Telefone:</b>
                            {{ convidado.telefone }}
                          </li>
                          <li v-if="convidado.email" id="complementoConvidado">
                            <b class="text-black">E-mail:</b>
                            {{ convidado.email }}
                          </li>
                          <li
                            v-if="convidado.unidade"
                            id="complementoConvidado"
                          >
                            <b class="text-black">C.C.:</b>
                            {{ convidado.unidade }}-{{ convidado.setor }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #modal-footer>
        <div class="float-right">
          <b-button
            size="sm"
            class="mr-1"
            variant="secondary"
            @click="hideModalTarefa"
            >Fechar</b-button
          >
          <b-button
            size="sm"
            class="mr-1"
            variant="danger"
            @click="excluirTarefa(eventEdit.id)"
            >Excluir</b-button
          >
          <b-button
            size="sm"   
            variant="info"
            @click="showModalEditarTarefa()"
            >Editar</b-button
          >
        </div>
      </template>
    </b-modal>

    <!--------------------------------Modal Detalhes da Tarefa-------------------------------------->

    <!--------------------------------Modal Editar Tarefa-------------------------------------->

    <b-modal scrollable ref="editar-tarefa" no-close-on-backdrop title="Tarefa">
      <template #modal-header>
        <h5>Tarefa</h5>
      </template>

      <template #default>
        <div class="tab-pane" id="settings">
          <div class="form-group row">
            <div class="col-sm-12">
              <label class="label-editar mb-0">Título</label>
              <div class="input-group">
                <input
                  type="text"
                  v-model="eventEdit.title"
                  name="numero-contrato"
                  class="form-control"
                  id="numero-contrato"
                  required
                  placeholder="Título"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-cash-register"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12">
              <label class="label-editar mb-0" for="nome-contrato"
                >Descrição</label
              >
              <div class="input-group">
                <b-form-textarea
                  v-model="eventEdit.descricao"
                  id="textarea-state"
                  placeholder="Descrição...."
                  rows="4"
                  required
                ></b-form-textarea>
              </div>
            </div>

            <div class="col-sm-12 mb-3 mt-3">
              <b-button
                @click="showModalEditarConvidado"
                class="mr-1"
                variant="outline-info"
                size="sm"
                >Adicionar Convidado</b-button
              >
            </div>

            <div
              v-for="convidado in convidadosAgenda"
              :key="convidado.index"
              class="col-lg-12 mt-2"
            >
              <div id="bordaBody" class="well well-sm">
                <b> <u> Convidado: </u></b>

                <div class="mt-3">
                  <div>
                    <li class="list-unstyled" id="labelcomplementoConvidado">
                      <b>
                        <i>{{ convidado.nome }} </i></b
                      >
                    </li>

                    <ul>
                      <li v-if="convidado.funcao" id="complementoConvidado">
                        <b class="text-black">Cargo:</b>
                        {{ convidado.funcao }}
                      </li>
                      <li v-if="convidado.matricula" id="complementoConvidado">
                        <b class="text-black">Matricula:</b>
                        {{ convidado.matricula }}
                      </li>
                      <li v-if="convidado.telefone" id="complementoConvidado">
                        <b class="text-black">Telefone:</b>
                        {{ convidado.telefone }}
                      </li>
                      <li v-if="convidado.email" id="complementoConvidado">
                        <b class="text-black">E-mail:</b>
                        {{ convidado.email }}
                      </li>
                      <li v-if="convidado.unidade" id="complementoConvidado">
                        <b class="text-black">C.C.:</b>
                        {{ convidado.unidade }}-{{ convidado.setor }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 mt-3 mb-2">
              <b-form-checkbox
                v-model="diaInteiroEditar"
                name="check-button"
                switch
              >
                Dia inteiro
              </b-form-checkbox>
            </div>

            <div class="col-sm-8">
              <label class="label-editar mb-0" for="data-inicio"
                >Data Início</label
              >
              <div class="input-group">
                <b-form-datepicker
                  placeholder="Data Inicial"
                  id="datepicker-valid"
                  v-model="eventEdit.start"
                  :state="true"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                ></b-form-datepicker>
              </div>
            </div>

            <div v-if="diaInteiroEditar == false" class="col-sm-4">
              <label class="mb-0">Hora Início</label>
              <div class="input-group">
                <b-form-timepicker
                  v-model="eventEdit.timeStart"
                  placeholder=""
                  id="time-valid"
                  :state="true"
                ></b-form-timepicker>
              </div>
            </div>

            <div class="col-sm-8">
              <label class="label-editar mb-0" for="data-final"
                >Data Final</label
              >
              <div class="input-group">
                <b-form-datepicker
                  placeholder="Data Final"
                  id="datepicker-invalid"
                  v-model="eventEdit.end"
                  :state="false"
                  class="mb-2"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                ></b-form-datepicker>
              </div>
            </div>

            <div v-if="diaInteiroEditar == false" class="col-sm-4">
              <label class="label-editar mb-0" for="data-final"
                >Hora Final</label
              >
              <div class="input-group">
                <b-form-timepicker
                  placeholder=""
                  v-model="eventEdit.timeEnd"
                  id="time-invalid"
                  :state="false"
                  class="mb-2"
                ></b-form-timepicker>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #modal-footer>
        <div class="float-right">
          <b-button
            size="sm"
            class="mr-1"
            variant="secondary"
            @click="hideModalEditarTarefa"
            >Fechar</b-button
          >

          <b-button
            size="sm"          
            variant="info"
            @click="editarTarefa()"
            >Salvar</b-button
          >
        </div>
      </template>
    </b-modal>
  
    <!--------------------------------Modal Editar Tarefa-------------------------------------->

    <!--------------------------------Modal Adicionar Tarefa-------------------------------------->

    <b-modal scrollable ref="adicionar-tarefa" no-close-on-backdrop title="Tarefa">
      <template #modal-header>
        <h5>Tarefa</h5>
      </template>

      <template #default>
        <div class="tab-pane" id="settings">
          <div class="form-group row">
            <div class="col-sm-12 mb-3">
              <label class="label-editar mb-0">Título</label>
              <div class="input-group">
                <input
                  type="text"
                  v-model="eventAdd.title"
                  name="numero-contrato"
                  class="form-control"
                  id="numero-contrato"
                  required
                  placeholder="Título"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-cash-register"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12 mb-3">
              <label class="label-editar mb-0" for="nome-contrato"
                >Descrição</label
              >
              <div class="input-group">
                <b-form-textarea
                  v-model="eventAdd.descricao"
                  id="textarea-state"
                  placeholder="Descrição...."
                  rows="4"
                  required
                ></b-form-textarea>
              </div>
            </div>

            <div class="col-sm-12 mb-3 mt-3">
              <b-button
                @click="showModalAddConvidado"
                class="mr-1"
                variant="outline-info"
                size="sm"
                >Adicionar Convidado</b-button
              >
            </div>

            <div
              v-for="(convid, index) in convidadosArray"
              :key="index"
              class="col-lg-12 mt-2"
            >
              <div id="bordaBody" class="well well-sm">
                <button
                  type="button"
                  @click="apagarConvidado(index)"
                  class="btn badge bagde-light"
                >
                  <i class="fa fa-trash text-danger"></i>
                </button>
                <b> <u> Convidado </u></b>

                <div class="mt-3">
                  <div>
                    <li class="list-unstyled" id="labelcomplementoConvidado">
                      <b>
                        <i>{{ convid.nome }}</i></b
                      >
                    </li>

                    <ul>
                      <li v-if="convid.funcao" id="complementoConvidado">
                        <b class="text-black">Cargo:</b>
                        {{ convid.funcao }}
                      </li>
                      <li v-if="convid.matricula" id="complementoConvidado">
                        <b class="text-black">Matricula:</b>
                        {{ convid.matricula }}
                      </li>
                      <li v-if="convid.celular_empresa" id="complementoConvidado">
                        <b class="text-black">Telefone:</b>
                        {{ convid.celular_empresa }}
                      </li>
                      <li v-if="convid.telefone" id="complementoConvidado">
                        <b class="text-black">Telefone:</b>
                        {{ convid.telefone }}
                      </li>
                      <li v-if="convid.email" id="complementoConvidado">
                        <b class="text-black">E-mail:</b>
                        {{ convid.email }}
                      </li>
                      <li v-if="convid.unidade" id="complementoConvidado">
                        <b class="text-black">C.C.:</b>
                        {{ convid.unidade }}-{{ convid.setor }}
                      </li>
                      <li v-if="convid.centroCusto" id="complementoConvidado">
                        <b class="text-black">C.C.:</b>
                        {{ convid.centroCusto }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 mt-3 mb-2">
              <b-form-checkbox
                v-model="diaInteiroAdicionar"
                name="check-button"
                switch
              >
                Dia inteiro
              </b-form-checkbox>
            </div>

            <div class="col-sm-8">
              <label class="label-editar mb-0" for="data-inicio"
                >Data Início</label
              >
              <div class="input-group">
                <b-form-datepicker
                  placeholder="Data Inicial"
                  id="datepicker-valid"
                  v-model="eventAdd.dataStart"
                  :state="true"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                ></b-form-datepicker>
              </div>
            </div>

            <div v-if="diaInteiroAdicionar == false" class="col-sm-4">
              <label class="mb-0">Hora Início</label>
              <div class="input-group">
                <b-form-timepicker
                  v-model="eventAdd.timeStart"
                  placeholder=""
                  id="time-valid"
                  :state="true"
                ></b-form-timepicker>
              </div>
            </div>

            <div class="col-sm-8">
              <label class="label-editar mb-0" for="data-final">Data Final</label>
              <div class="input-group">
                <b-form-datepicker
                  placeholder="Data Final"
                  id="datepicker-invalid"
                  v-model="eventAdd.dataEnd"
                  :state="false"
                  class="mb-2"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                ></b-form-datepicker>
              </div>
            </div>

            <div v-if="diaInteiroAdicionar == false" class="col-sm-4">
              <label class="label-editar mb-0" for="data-final">Hora Final</label>
              <div class="input-group">
                <b-form-timepicker
                  placeholder=""
                  v-model="eventAdd.timeEnd"
                  id="time-invalid"
                  :state="false"
                  class="mb-2"
                ></b-form-timepicker>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #modal-footer>
        <div class="float-right">
          <b-button
            class="mr-1"
            size="sm"   
            variant="secondary"
            @click="hideModalAdicionarTarefa"
            >Fechar</b-button
          >
          <b-button size="sm" variant="primary" @click="adicionarTarefa()"
            >Salvar</b-button
          >
        </div>
      </template>

    </b-modal>

    <!--------------------------------Modal Adicionar Tarefa-------------------------------------->

    <!--------------------------------Modal Adicionar Convidado-------------------------------------->

    <b-modal
      ref="modalAdicionarConvidado"
      hide-footer
      title="Adicionar Convidados"
    >
      <div class="form-group row">
        <div class="col-sm-12 mb-3">
          <label class="label-editar mb-0">Convidado</label>
          <div>
            <AutocompleteComponents
              v-model="convidado.nome"
              :options="autocomplete.usersConvidado"
              label-key="nome"
              value-key="pk_id_usuario"
              placeholder="Pesquisar..."
              @shouldSearch="pesquisarUsuariosConvidado"
              @select="onSelectConvidado"
            />
          </div>
        </div>

        <div class="col-sm-3 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Matrícula</label
          >
          <div class="input-group">
            <input
              type="text"
              v-mask="'###########'"
              v-model="convidado.matricula"
              name="matricula"
              class="form-control"
              id="complementoConvidado"
              placeholder="Matrícula"
            />
          </div>
        </div>

        <div class="col-sm-2 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Unidade</label
          >
          <div class="input-group">
            <input
              type="text"
              v-mask="'###'"
              v-model="convidado.unidade"
              name="centroDeCusto"
              class="form-control"
              id="complementoConvidado"
              placeholder="C.C."
            />
          </div>
        </div>

        <div class="col-sm-2 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Setor</label
          >
          <div class="input-group">
            <input
              type="text"
              v-mask="'####'"
              v-model="convidado.setor"
              name="centroDeCusto"
              class="form-control"
              id="complementoConvidado"
              placeholder="C.C."
            />
          </div>
        </div>

        <div class="col-sm-5 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Telefone</label
          >
          <div class="input-group">
            <input
              type="text"
              v-mask="'(##) ####-#####'"
              v-model="convidado.telefone"
              name="telefone"
              class="form-control"
              id="complementoConvidado"
              required
              placeholder="Telefone"
            />
          </div>
        </div>

        <div class="col-sm-6 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >E-mail</label
          >
          <div class="input-group">
            <input
              type="email"
              v-model="convidado.email"
              name="email"
              class="form-control"
              id="complementoConvidado"
              placeholder="E-mail"
            />
          </div>
        </div>

        <!--
        <div class="col-sm-3 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Empresa</label
          >
          <div class="input-group">
            <input
              type="text"
              v-model="convidado.empresa"
              name="empresa"
              class="form-control"
              id="complementoConvidado"
              required
              placeholder="Empresa"
            />
          </div>
        </div>
        -->

        <div class="col-sm-6 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Função</label
          >
          <div class="input-group">
            <input
              type="text"
              v-model="convidado.funcao"
              name="funcao"
              class="form-control"
              id="complementoConvidado"
              required
              placeholder="Função"
            />
          </div>
        </div>
      </div>

      <div class="float-right mt-4">
        <b-button
          class="mr-1"
          variant="secondary"
          @click="hideModalAddConvidado"
          >Fechar</b-button
        >
        <b-button class="mr-1" variant="primary" @click="adicionarConvidado()"
          >Adicionar</b-button
        >
      </div>
    </b-modal>
    <!--------------------------------Modal Adicionar Convidado-------------------------------------->

    <!--------------------------------Modal Editar Convidado-------------------------------------->

    <b-modal
      ref="modalEditarConvidado"
      hide-footer
      title="Adicionar Convidados"
    >
      <div class="form-group row">
        <div class="col-sm-12 mb-3">
          <label class="label-editar mb-0">Convidado</label>
          <div>
            <AutocompleteComponents
              v-model="convidadoEditar.nome"
              :options="autocomplete.usersConvidadoEditar"
              label-key="nome"
              value-key="pk_id_usuario"
              placeholder="Pesquisar..."
              @shouldSearch="pesquisarUsuariosConvidadoEditar"
              @select="onSelectConvidadoEditar"
            />
          </div>
        </div>

        <div class="col-sm-3 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Matrícula</label
          >
          <div class="input-group">
            <input
              type="text"
              v-mask="'###########'"
              v-model="convidadoEditar.matricula"
              name="matricula"
              class="form-control"
              id="complementoConvidado"
              placeholder="Matrícula"
            />
          </div>
        </div>

        <div class="col-sm-2 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Unidade</label
          >
          <div class="input-group">
            <input
              type="text"
              v-mask="'###'"
              v-model="convidadoEditar.unidade"
              name="centroDeCusto"
              class="form-control"
              id="complementoConvidado"
              placeholder="C.C."
            />
          </div>
        </div>

        <div class="col-sm-2 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Setor</label
          >
          <div class="input-group">
            <input
              type="text"
              v-mask="'####'"
              v-model="convidadoEditar.setor"
              name="centroDeCusto"
              class="form-control"
              id="complementoConvidado"
              placeholder="C.C."
            />
          </div>
        </div>

        <div class="col-sm-5 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >E-mail</label
          >
          <div class="input-group">
            <input
              type="email"
              v-model="convidadoEditar.email"
              name="email"
              class="form-control"
              id="complementoConvidado"
              placeholder="E-mail"
            />
          </div>
        </div>

        <div class="col-sm-5 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Telefone</label
          >
          <div class="input-group">
            <input
              type="text"
              v-mask="'(##) ####-#####'"
              v-model="convidadoEditar.telefone"
              name="telefone"
              class="form-control"
              id="complementoConvidado"
              required
              placeholder="Telefone"
            />
          </div>
        </div>

        <div class="col-sm-3 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Empresa</label
          >
          <div class="input-group">
            <input
              type="text"
              v-model="convidadoEditar.empresa"
              name="empresa"
              class="form-control"
              id="complementoConvidado"
              required
              placeholder="Empresa"
            />
          </div>
        </div>

        <div class="col-sm-4 mb-3">
          <label id="labelcomplementoConvidado" class="label-editar mb-0"
            >Função</label
          >
          <div class="input-group">
            <input
              type="text"
              v-model="convidadoEditar.funcao"
              name="funcao"
              class="form-control"
              id="complementoConvidado"
              required
              placeholder="Função"
            />
          </div>
        </div>
      </div>

      <div class="float-right mt-4">
        <b-button
          class="mr-1"
          variant="secondary"
          @click="hideModalEditarConvidado"
          >Fechar</b-button
        >
        <b-button class="mr-1" variant="primary" @click="adicionarConvidado()"
          >Adicionar</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\

var moment = require("moment");
moment.locale("pt-br");

import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import TimeGridPlugin from "@fullcalendar/timegrid";
import ListPlugin from "@fullcalendar/list";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    AutocompleteComponents, //===>Autocomplete<===\\
  },

  computed: {},

  data() {
    return {
      usuario: "",
      show: true,

      agendaFull: [],
      agendaFullConvidados: [],
      convidadosAgenda: [],

      convidadosArray: [],

      autocomplete: {
        usersConvidado: [], //===>Autocomplete<===\\
        usersConvidadoEditar: [], //===>Autocomplete<===\\
      },

      convidado: {
        matricula: "",
        funcao: "",
        email: "",
        telefone: "",
        empresa: "",
        nome: "",
        id: "",
        exportOrigem: "",
        unidade: "",
        setor: "",
      },

      convidadoEditar: {
        matricula: "",
        funcao: "",
        email: "",
        telefone: "",
        empresa: "",
        nome: "",
        id: "",
        exportOrigem: "",
        unidade: "",
        setor: "",
      },

      calendarOptions: {
        locale: "pt-br",
        allDayText: "24 horas",
        plugins: [dayGridPlugin, interactionPlugin, TimeGridPlugin, ListPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        buttonText: {
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Hoje",
          list: "Lista",
        },
        initialView: "dayGridMonth",
        editable: true,
        navLinks: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true, // initial value

        select: this.showModalAdicionarTarefa,
        eventClick: this.showModalTarefa,
        eventsSet: this.handleEvents,
        eventDrop: this.handleEventDropResize,
        eventResize: this.handleEventDropResize,

        events: [],
      },

      eventEdit: {
        id: "",
        title: "",
        start: "",
        end: "",
        timeStart: "",
        timeEnd: "",
        dataStart: "",
        dataEnd: "",
        descricao: "",
        color: "",
        allDay: "",

        convidadoIdUsuario: "",
        convidadoNome: "",
        convidadoFuncao: "",
        convidadoMatricula: "",
        convidadoEmpresa: "",
        convidadoTelefone: "",
        convidadoEmail: "",
        convidadoUnidade: "",
        convidadoSetor: "",
        convidadoExportOrigem: "",
      },

      diaInteiroEditar: false,

      eventAdd: {
        id: "",
        title: "",
        start: "",
        end: "",
        timeStart: "",
        timeEnd: "",
        dataStart: "",
        dataEnd: "",
        descricao: "",
        color: "",
        allDay: "",
        convidado: "",
      },

      diaInteiroAdicionar: true,
    };
  },

  mounted() {
    this.carregarAgenda();
  },

  methods: {
    apagarConvidado(e) {
      this.convidadosArray.splice(e, 1);
    },

    limparConvidado() {
      this.convidado.matricula = "";
      this.convidado.funcao = "";
      this.convidado.email = "";
      this.convidado.telefone = "";
      this.convidado.empresa = "";
      this.convidado.nome = "";
      this.convidado.id = "";
      this.convidado.unidade = "";
      this.convidado.setor = "";
      this.convidado.exportOrigem = "";
      this.autocomplete.usersConvidado = [];
    },

    pesquisarUsuariosConvidado(query) {
      const idUsuario = this.usuario.pk_id_usuario;
      axios
        .get(`autocomplete/userAutocompleteAgenda/${query}/${idUsuario}`)
        .then((response) => {
          this.autocomplete.usersConvidado = response.data;
        });
    },

    onSelectConvidado(user) {
      //===>Autocomplete<===\\

      this.convidado.matricula = user.matricula;
      this.convidado.funcao = user.funcao;
      this.convidado.email = user.email;
      this.convidado.telefone = user.celular_empresa;
      this.convidado.empresa = user.empresa;
      this.convidado.nome = user.nome;
      this.convidado.id = user.pk_id_usuario;
      this.convidado.exportOrigem = user.export_origem;
      this.convidado.unidade = user.unidade;
      this.convidado.setor = user.setor;
    },

    //////////////////////////////////===> Autocomplete Convidado Editar <===////////////////////////////////////////////////

    limparConvidadoEditar() {
      this.convidadoEditar.matricula = "";
      this.convidadoEditar.funcao = "";
      this.convidadoEditar.email = "";
      this.convidadoEditar.telefone = "";
      this.convidadoEditar.empresa = "";
      this.convidadoEditar.nome = "";
      this.convidadoEditar.id = "";
      this.convidadoEditar.unidade = "";
      this.convidadoEditar.setor = "";
      this.convidadoEditar.exportOrigem = "";
      this.autocomplete.usersConvidadoEditar = [];
    },

    pesquisarUsuariosConvidadoEditar(query) {
      const idUsuario = this.usuario.pk_id_usuario;
      axios
        .get(`autocomplete/userAutocompleteAgenda/${query}/${idUsuario}`)
        .then((response) => {
          this.autocomplete.usersConvidadoEditar = response.data;
        });
    },

    onSelectConvidadoEditar(user) {
      //===>Autocomplete<===\\

      this.convidadoEditar.matricula = user.matricula;
      this.convidadoEditar.funcao = user.funcao;
      this.convidadoEditar.email = user.email;
      this.convidadoEditar.telefone = user.celular_empresa;
      this.convidadoEditar.empresa = user.empresa;
      this.convidadoEditar.nome = user.nome;
      this.convidadoEditar.id = user.pk_id_usuario;
      this.convidadoEditar.exportOrigem = user.export_origem;
      this.convidadoEditar.unidade = user.unidade;
      this.convidadoEditar.setor = user.setor;

      //console.log(user)
    },

    //////////////////////////////////===> Editar Tarefa <===////////////////////////////////////////////////
    showModalTarefa(clickInfo) {
      this.$refs["tarefa"].show();

      this.agendaFullConvidados.forEach((events, i) => {
        if (events.agenda.id == clickInfo.event.id) {
          this.eventEdit.id = events.agenda.id;
          this.eventEdit.title = events.agenda.title;
          this.eventEdit.start = events.agenda.start;
          this.eventEdit.end = events.agenda.end;
          this.eventEdit.descricao = events.agenda.descricao;
          this.eventEdit.color = events.agenda.color;
          this.eventEdit.allDay = events.agenda.allDay;
          this.eventEdit.convidadoIdUsuario = events.agenda.idUsuario;
          this.eventEdit.convidadoNome = events.agenda.nome;
          this.eventEdit.convidadoFuncao = events.agenda.funcao;
          this.eventEdit.convidadoMatricula = events.agenda.matricula;
          this.eventEdit.convidadoEmpresa = events.agenda.empresa;
          this.eventEdit.convidadoTelefone = events.agenda.telefone;
          this.eventEdit.convidadoEmail = events.agenda.email;
          this.eventEdit.convidadoUnidade = events.agenda.unidade;
          this.eventEdit.convidadoSetor = events.agenda.setor;
          this.eventEdit.convidadoExportOrigem = events.agenda.exportOrigem;

          this.convidadosAgenda = events.convidado;
        }
      });

      console.log(this.convidadosAgenda);

      // this.calendarOptions.events.forEach((events, i) => {
      //   if (events.id == clickInfo.event.id) {
      //     this.eventEdit.id = events.id;
      //     this.eventEdit.title = events.title;
      //     this.eventEdit.start = events.start;
      //     this.eventEdit.end = events.end;
      //     this.eventEdit.descricao = events.descricao;
      //     this.eventEdit.color = events.color;
      //     this.eventEdit.allDay = events.allDay;
      //     this.eventEdit.convidadoIdUsuario = events.idUsuario;
      //     this.eventEdit.convidadoNome = events.nome;
      //     this.eventEdit.convidadoFuncao = events.funcao;
      //     this.eventEdit.convidadoMatricula = events.matricula;
      //     this.eventEdit.convidadoEmpresa = events.empresa;
      //     this.eventEdit.convidadoTelefone = events.telefone;
      //     this.eventEdit.convidadoEmail = events.email;
      //     this.eventEdit.convidadoUnidade = events.unidade;
      //     this.eventEdit.convidadoSetor = events.setor;
      //     this.eventEdit.convidadoExportOrigem = events.exportOrigem;
      //   }
      // });

      if (this.eventEdit.allDay == 1) {
        this.diaInteiroEditar = true;
      } else {
        this.diaInteiroEditar = false;
      }

      const timeStart = new Date(this.eventEdit.start);
      const timeEnd = new Date(this.eventEdit.end);

      this.eventEdit.timeStart = timeStart.toLocaleTimeString();
      this.eventEdit.timeEnd = timeEnd.toLocaleTimeString();
    },

    hideModalTarefa() {
      this.$refs["tarefa"].hide();
    },

    //////////////////////////////////===> Editar Tarefa <===////////////////////////////////////////////////
    showModalEditarTarefa(clickInfo) {
      this.limparConvidadoEditar();

      this.hideModalTarefa();
      this.$refs["editar-tarefa"].show();

      if (this.eventEdit.allDay == 1) {
        this.diaInteiroEditar = true;
      } else {
        this.diaInteiroEditar = false;
      }

      const timeStart = new Date(this.eventEdit.start);
      const timeEnd = new Date(this.eventEdit.end);

      this.eventEdit.timeStart = timeStart.toLocaleTimeString();
      this.eventEdit.timeEnd = timeEnd.toLocaleTimeString();
    },

    hideModalEditarTarefa() {
      this.$refs["editar-tarefa"].hide();
    },

    async editarTarefa() {
      const dataStart = this.eventEdit.start.substring(0, 10);
      const dataEnd = this.eventEdit.end.substring(0, 10);
      const timeStart = this.eventEdit.timeStart;
      const timeEnd = this.eventEdit.timeEnd;

      if (this.diaInteiroEditar == true) {
        if (dataStart == dataEnd) {
          // Criar uma data e adicionar 1 hora
          var dataFinal = moment(dataEnd).add(1, "day");

          var dataTimeStart = dataStart + " 00:00:00";
          var dataTimeEnd =
            moment(dataFinal.toDate()).format("YYYY-MM-DD") + " 00:00:00";
        } else {
          var dataTimeStart = dataStart + " 00:00:00";
          var dataTimeEnd = dataEnd + " 00:00:00";
        }
      } else {
        var dataTimeStart = dataStart + " " + timeStart;
        var dataTimeEnd = dataEnd + " " + timeEnd;
      }

      if (this.eventEdit.title == "" || this.eventEdit.title == null) {
        alert("Insira um Titulo");
      } else if (
        this.eventEdit.descricao == "" ||
        this.eventEdit.descricao == null
      ) {
        alert("Insira uma descrição");
      } else if (dataTimeStart > dataTimeEnd) {
        alert(
          "A data final de sua tarefa tem que ser maior ou igual a data inicial"
        );
      } else {
        if (confirm(`Tem certeza de que deseja salvar as alterações`)) {
          const response = await axios.post("agenda/dropResizeAgenda", {
            title: this.eventEdit.title,
            descricao: this.eventEdit.descricao,
            inicioDataHora: dataTimeStart,
            fimDataHora: dataTimeEnd,
            allDay: this.diaInteiroEditar,
            idEvento: this.eventEdit.id,
            idUsuario: this.usuario.pk_id_usuario,

            convidadoNome: this.convidadoEditar.nome,
            convidadoId: this.convidadoEditar.id,
            convidadoFuncao: this.convidadoEditar.funcao,
            convidadoEmail: this.convidadoEditar.email,
            convidadoTelefone: this.convidadoEditar.telefone,
            convidadoEmpresa: this.convidadoEditar.empresa,
            convidadoCentroCusto: this.convidadoEditar.centroCusto,
            convidadoMatricula: this.convidadoEditar.matricula,
            convidadoOrigem: this.convidadoEditar.exportOrigem,
          });

          console.log(response.data);
          //alert(response.data);
        }

        this.hideModalEditarTarefa();

        this.carregarAgenda();
      }
    },

    //////////////////////////////////===> Adicionar Tarefa <===////////////////////////////////////////////////
    showModalAdicionarTarefa(clickInfo) {
      this.$refs["adicionar-tarefa"].show();

      this.limparConvidado();

      this.eventAdd.title = "";
      this.eventAdd.descricao = "";
      this.eventAdd.dataStart = clickInfo.startStr;
      this.eventAdd.dataEnd = clickInfo.endStr;

      const timeStart = new Date(clickInfo.start);
      const timeEnd = new Date(clickInfo.end);

      this.eventAdd.timeStart = timeStart.toLocaleTimeString();
      this.eventAdd.timeEnd = timeEnd.toLocaleTimeString();

      this.diaInteiroAdicionar = clickInfo.allDay;
    },

    hideModalAdicionarTarefa() {
      this.$refs["adicionar-tarefa"].hide();
    },

    async adicionarTarefa() {
      const dataStart = this.eventAdd.dataStart.substring(0, 10);
      const dataEnd = this.eventAdd.dataEnd.substring(0, 10);
      const timeStart = this.eventAdd.timeStart;
      const timeEnd = this.eventAdd.timeEnd;

      if (this.diaInteiroAdicionar == true) {
        if (dataStart == dataEnd) {
          // Criar uma data e adicionar 1 hora
          var dataFinal = moment(dataEnd).add(1, "day");

          var dataTimeStart = dataStart + " 00:00:00";
          var dataTimeEnd =
            moment(dataFinal.toDate()).format("YYYY-MM-DD") + " 00:00:00";
        } else {
          var dataTimeStart = dataStart + " 00:00:00";
          var dataTimeEnd = dataEnd + " 00:00:00";
        }
      } else {
        var dataTimeStart = dataStart + " " + timeStart;
        var dataTimeEnd = dataEnd + " " + timeEnd;
      }

      if (this.eventAdd.title == "" || this.eventAdd.title == null) {
        alert("Insira um Titulo");
      } else if (
        this.eventAdd.descricao == "" ||
        this.eventAdd.descricao == null
      ) {
        alert("Insira uma descrição");
      } else if (dataTimeStart > dataTimeEnd) {
        alert(
          "A data final de sua tarefa tem que ser maior ou igual a data inicial"
        );
      } else {
        if (confirm(`Tem certeza de que deseja adicionar essa tarefa!?`)) {
          const response = await axios.post("agenda/addAgenda", {
            title: this.eventAdd.title,
            descricao: this.eventAdd.descricao,
            inicioDataHora: dataTimeStart,
            fimDataHora: dataTimeEnd,
            allDay: this.diaInteiroAdicionar,
            idUsuario: this.usuario.pk_id_usuario,

            convidados: this.convidadosArray,
          });

          //console.log(response.data);

          alert(response.data);

          this.hideModalAdicionarTarefa();

          this.convidadosArray = [];

          this.carregarAgenda();
        }
      }
    },

    //////////////////////////////////===> Excluir/Drop/Carregar Tarefa <===////////////////////////////////////////////////

    showModalEditarConvidado() {
      this.limparConvidadoEditar();

      this.$refs["modalEditarConvidado"].show();
    },

    hideModalEditarConvidado() {
      this.limparConvidadoEditar();
      this.$refs["modalEditarConvidado"].hide();
    },

    showModalAddConvidado() {
      this.limparConvidado();

      this.$refs["modalAdicionarConvidado"].show();
    },

    hideModalAddConvidado() {
      this.limparConvidado();
      this.$refs["modalAdicionarConvidado"].hide();
    },

    async adicionarConvidado() {
      this.convidadosArray.push({
        matricula: this.convidado.matricula,
        funcao: this.convidado.funcao,
        email: this.convidado.email,
        telefone: this.convidado.telefone,
        empresa: this.convidado.empresa,
        nome: this.convidado.nome,
        id: this.convidado.id,
        unidade: this.convidado.unidade,
        setor: this.convidado.setor,
        exportOrigem: this.convidado.exportOrigem,
      });

      console.log(this.convidadosArray);

      this.$refs["modalAdicionarConvidado"].hide();
    },

    async excluirTarefa(index) {
      if (confirm(`Tem certeza de que deseja excluir o evento?`)) {
        const response = await axios.post("agenda/excluirAgenda", {
          idExcluir: index,
        });

        this.hideModalTarefa();

        this.carregarAgenda();
      }
    },

    async handleEventDropResize(element) {
      const response = await axios.post("agenda/dropResizeAgenda", {
        title: element.event.title,
        inicioDataHora: element.event.startStr,
        fimDataHora: element.event.endStr,
        allDay: element.event.allDay,
        idEvento: element.event.id,
        idUsuario: this.usuario.pk_id_usuario,
      });

      alert(response.data);

      this.carregarAgenda();
    },

    async carregarAgenda() {
      (this.show = true),
        (this.usuario = JSON.parse(localStorage.getItem("storageData")));

      const response = await axios.post("agenda/minhaAgenda", {
        idUsuario: this.usuario.pk_id_usuario,
      });

      this.agendaFullConvidados = [];
      this.agendaFullConvidados = response.data;

      console.log(response.data);

      this.agendaFull = [];

      response.data.forEach((events, i) => {
        this.agendaFull[i] = events.agenda;
      });

      this.calendarOptions.events = this.agendaFull;

      this.show = false;
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("LL");
    },
  },
};
</script>

<style scoped>
#bordaBody {
  background-color: #f1f4f9;
  padding: 15px 15px;
  border: 1px solid #d0d9e8;
}

#complementoConvidado {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.699);
}

#labelcomplementoConvidado {
  font-size: 14px;
  color: rgb(122, 122, 122);
}
</style>