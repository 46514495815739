<template>
  <div class="relative">
    <div class="relative rounded-md">
      <input
        ref="input"
        :value="keyword"
        :placeholder="placeholder"
        @input="onInput($event.target.value)"
        @blur="onBlur"
        @keydown="onKeydown"
        class="form-control form-control-navbar px-2"
        type="search"
        aria-label="Search"
      />
    </div>

    <div
      v-show="mutableOptions.length"
      class="
        absolute
        right-0
        mt-2
        w-full
        rounded-md
        shadow-lg
        z-50
        overflow-y-scroll
      "
      style="max-height: 300px"
    >
      <ul class="rounded-md bg-white">
        <li
          v-for="(opt, index) in mutableOptions"
          :key="opt[valueKey]"
          :ref="`option_${index}`"
          class="
            autocomplete-item
            block
            px-4
            py-2
            text-sm
            leading-5
            text-gray-700
            cursor-pointer
          "
          :class="{ 'bg-gray-200': arrowCounter === index }"
          tabindex="0"
          @click="onSelect()"
          @mouseover="setArrowCounter(index)"
        >
          <span v-html="opt[`${labelKey}_highlighted`] || opt[labelKey]" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "AutocompleteComponents",

  props: {
    value: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    options: {
      type: Array,
      default: () => [],
    },

    labelKey: {
      type: String,
      default: "nome",
    },

    valueKey: {
      type: String,
      default: "pk_id_usuario",
    },

    searchMinLength: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      keyword: "",
      arrowCounter: 0,
      originalOptions: [],
      mutableOptions: [],
    };
  },

  watch: {
    value(value) {
      this.keyword = value;
    },

    options() {
      this.cloneOptions();
    },
  },

  created() {
    this.keyword = this.value;

    if (this.options.length) {
      this.cloneOptions();
    }
  },

  methods: {
    onInput(vl) {
      this.keyword = vl;
      this.emitInput();

      if (vl.length >= this.searchMinLength) {
        if (!this.originalOptions.length) {
          this.$emit("shouldSearch", vl);
        } else {
          this.searchInternally();
        }
      } else {
        this.resetOptions();
      }
    },

    searchInternally() {
      const search = this.keyword;
      this.mutableOptions = this.originalOptions.filter(
        (o) => o[this.labelKey].toLowerCase().search(search.toLowerCase()) >= 0
      );
      this.highlightOptions();
    },

    highlightOptions() {
      const search = this.keyword;
      const query = new RegExp(search, "i");

      this.mutableOptions.forEach((o) => {
        this.$set(
          o,
          `${this.labelKey}_highlighted`,
          o[this.labelKey].replace(query, '<span class="font-bold">$&</span>')
        );
      });
    },

    cloneOptions() {
      this.originalOptions = JSON.parse(JSON.stringify(this.options));
      this.mutableOptions = JSON.parse(JSON.stringify(this.options));
      this.searchInternally();
    },

    resetOptions() {
      this.originalOptions = [];
      this.mutableOptions = [];
    },

    onKeydown(evt) {
      if (!this.mutableOptions.length) {
        return;
      }

      switch (evt.code) {
        case "ArrowDown":
          evt.preventDefault();
          this.onArrowDown();
          break;
        case "ArrowUp":
          evt.preventDefault();
          this.onArrowUp();
          break;
        case "Enter":
          this.onSelect();
          break;
        case "Escape":
          this.onEsc();
          break;
      }
    },

    onEsc() {
      this.$refs.input.blur();
      this.resetArrowCounter();
      this.resetOptions();
    },

    onArrowDown() {
      if (this.arrowCounter < this.mutableOptions.length - 1) {
        this.arrowCounter += 1;
      }

      this.fixScrolling();
    },

    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
      }

      this.fixScrolling();
    },

    onBlur(evt) {
      const tgt = evt.relatedTarget;
      if (tgt && tgt.classList.contains("autocomplete-item")) {
        return;
      }

      this.resetOptions();
      this.resetArrowCounter();
    },

    setArrowCounter(number) {
      this.arrowCounter = number;
    },

    fixScrolling() {
      this.$refs[`option_${this.arrowCounter}`][0].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    },

    resetArrowCounter() {
      this.arrowCounter = 0;
    },

    onSelect() {
      const selected = this.mutableOptions[this.arrowCounter];
      const selectedOption = this.options.find(
        (o) => o[this.valueKey] === selected[this.valueKey]
      );

      if (selectedOption) {
        this.$emit("select", selectedOption);
        this.keyword = selectedOption[this.labelKey];
        this.emitInput();
        this.resetOptions();
        this.resetArrowCounter();
      }
    },

    emitInput() {
      this.$emit("input", this.keyword);
    },

    resetKeyword() {
      this.keyword = "";
      this.emitInput();
    },

    onClear() {
      this.$emit("select", null);
      this.resetKeyword();
      this.resetOptions();
    },
  },
};
</script>

<style scoped src="@/assets/css/tailwind.css"/>