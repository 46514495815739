<template>
  <div>
    <b-overlay :show="overlayShow" rounded="sm">
      
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>
                  <b>Unidade:</b> <i>{{ dadosUnidade.nome_unidade }}</i>
                </h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/perfil">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Equipe</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section v-if="showsection" class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary card-outline">
                  <div class="card-header">
                    <div class="card-title">
                      <b>
                        <u>{{ dadosUnidade.nome_unidade }}</u></b
                      ><br />
                      <ul class="ml-4 mb-0 fa-ul text-muted">
                        <li class="small">
                          <span class="fa-li"
                            ><i class="fas fa-user-tie"></i
                          ></span>
                          <b
                            >Gestor: {{ dadosUnidade.gestor_unidade }} -
                            <u>{{ dadosUnidade.email_gestor }}</u></b
                          >
                        </li>
                        <br />
                        <li class="small">
                          <span class="fa-li"
                            ><i class="fas fa-lg fa-phone"></i
                          ></span>
                          Telefone: {{ dadosUnidade.telefone }}
                        </li>
                        <li class="small">
                          <span class="fa-li"
                            ><i class="fas fa-lg fa-building"></i
                          ></span>
                          Endereço: {{ dadosUnidade.endereco }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section v-if="dadosMembrosUnidade['0']" class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-secondary card-outline">
                  <div class="card-body">
                    <div class="row">
                      <div
                        v-for="membros in dadosMembrosUnidade"
                        :key="membros.pk_id_usuario"
                        class="
                          col-12 col-sm-6 col-md-4
                          d-flex
                          align-items-stretch
                        "
                      >
                        <div class="card bg-light">
                          <!--<div class="card-header text-muted border-bottom-0">
                            Equipe: {{ membros.nome_equipe }}
                          </div>-->
                          <div class="card-body mt-2">
                            <div class="row">
                              <div class="col-8">
                                <h2 class="lead">
                                  <b>{{ membros.nome }}</b>
                                </h2>
                                <br />
                                <p class="text-muted text-sm">
                                  <b>Função: </b> {{ membros.funcao }}
                                </p>
                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                  <li class="small">
                                    <span class="fa-li"
                                      ><i class="fas fa-lg fa-phone"></i
                                    ></span>
                                    Ramal: {{ membros.ramal }}
                                  </li>
                                  <li class="small">
                                    <span class="fa-li"
                                      ><i class="fas fa-lg fa-mobile-alt"></i
                                    ></span>
                                    Celular: {{ membros.celular_empresa }}
                                  </li>
                                  <li class="small">
                                    <span class="fa-li"
                                      ><i class="fas fa-lg fa-at"></i
                                    ></span>
                                    E-mail: {{ membros.email }}
                                  </li>
                                </ul>
                              </div>
                              <div class="col-4 text-center">
                                
                                <img
                                  v-if="membros.foto"
                                  :src="require(`../../../assets/img/profiles/${membros.foto}`)"
                                  class="img-circle img-fluid"
                                  alt="img usuario"
                                />
                                <img
                                  v-else
                                  :src="require(`../../../assets/img/profiles/profile.png`)"
                                  class="img-circle img-fluid"
                                  alt="img usuario"
                                />
                               
                              </div>
                            </div>
                          </div>
                          <div class="card-footer">
                            <div class="text-right">
                              <router-link
                                href=""
                                class="btn btn-sm btn-primary"
                                :to="
                                  '/perfil-usuario?id=' + membros.pk_id_usuario
                                "
                              >
                                <i class="fas fa-user"></i> Visualizar Perfil
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-overlay>
  </div>
</template>

<script scoped>
const axios = require("axios").default;

export default {
  name: "MembrosUnidade",

  data() {
    return {
      dadosMembrosUnidade: [],
      dadosUnidade: [],
      showsection: false,
      showsectionMembros: false,
      overlayShow: true,
    };
  },

  mounted() {
    this.unidadeMembros();
  },

  methods: {
    async unidadeMembros() {
      try {
        //Requisição atualizar dados
        const response = await axios.post("unidade/membrosUnidade", {
          idUnidade: this.$route.query.id,
        });

        //console.log(response.data.membros);
        //console.log(response.data.unidade.original);

        this.dadosMembrosUnidade = response.data.membros;
        this.dadosUnidade = response.data.unidade.original;

        this.showsection = true;
        this.overlayShow = false;
        // console.log(this.dadosMembrosUnidade)
      } catch (error) {
       // console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>


<style scoped>
</style>