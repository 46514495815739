<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Feed de Notícias</h1>
          </div>

          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Home</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <b-overlay :show="show" rounded="sm">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div v-for="feeds in feed"
                    :key="feeds.feed.pk_id_feed" class="card card-secondary card-outline">
                <div class="card-body">
                  <div
                    class="post"
                  >
                    <div class="user-block">
                      <img
                        class="img-circle img-bordered-sm"
                        :src="
                          require(`../../../assets/img/profiles/profile.png`)
                        "
                        alt="user image"
                      />
                      <span class="username">
                        <router-link
                              :to="
                                '/perfil-usuario?id=' + feeds.feed.pk_id_usuario
                              "
                            >
                        <a href="#">{{ feeds.feed.nome }}</a>
                        </router-link>

                        <b-button
                          @click="showModalEditarFeed(feeds.feed.pk_id_feed)"
                          v-if="feeds.feed.fk_id_usuario == user.pk_id_usuario"
                          size="sm"
                          variant="info"
                          class="float-right btn-tool"
                          ><i class="far fa-edit"></i> Editar</b-button
                        >
                      </span>
                      <span class="description"
                        >Publicado
                        {{ feeds.feed.data_hora_cadastro | moment }}</span
                      >
                    </div>

                    <p v-html="feeds.feed.descricao"></p>

                    <div class="row ml-1">
                      <div
                        class="mr-2"
                        v-for="anexos in feeds.anexo"
                        :key="anexos.pk_id_feed_upload"
                      >
                        <div
                          v-if="
                            anexos.tipo == 'jpeg' ||
                            anexos.tipo == 'jpg' ||
                            anexos.tipo == 'png'
                          "
                        >
                          <a
                            target="_blank"
                            :href="url + anexos.descricao"
                          >
                            <img
                              :src="url + anexos.descricao"
                              alt="Photo"
                              width="200"
                              height="200"
                            />
                          </a>
                        </div>

                        <div
                          v-if="anexos.tipo == 'mp4' || anexos.tipo == 'avi'"
                        >
                          <video
                            width="200"
                            height="200"
                            controls
                            id="border-video"
                          >
                            <source :src="url + anexos.descricao" />
                          </video>
                        </div>

                        <div v-if="anexos.tipo == 'pdf'">
                          <a
                            target="_blank"
                            :href="url + anexos.descricao"
                          >
                            <img
                              :src="
                                require(`../../../assets/img/geral/pdf.png`)
                              "
                              alt="pdf"
                              width="100"
                              height="100"
                            />
                          </a>
                        </div>

                        <div
                          v-if="anexos.tipo == 'doc' || anexos.tipo == 'docx'"
                        >
                          <a
                            target="_blank"
                            :href="url + anexos.descricao"
                          >
                            <img
                              :src="
                                require(`../../../assets/img/geral/doc.png`)
                              "
                              alt="pdf"
                              width="100"
                              height="100"
                            />
                          </a>
                        </div>

                        <div
                          v-if="anexos.tipo == 'ppt' || anexos.tipo == 'pptx'"
                        >
                          <a
                            target="_blank"
                            :href="url + anexos.descricao"
                          >
                            <img
                              :src="
                                require(`../../../assets/img/geral/ppt.png`)
                              "
                              alt="pdf"
                              width="100"
                              height="100"
                            />
                          </a>
                        </div>

                        <div
                          v-if="anexos.tipo == 'xls' || anexos.tipo == 'xlsx'"
                        >
                          <a
                            target="_blank"
                            :href="url + anexos.descricao"
                          >
                            <img
                              :src="
                                require(`../../../assets/img/geral/xls.png`)
                              "
                              alt="pdf"
                              width="100"
                              height="100"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </b-overlay>

    <b-modal
      no-close-on-backdrop
      ref="editar-feed"
      title="Editar Feed"
      size="lg"
      scrollable
    >
      <template #modal-header>
        <h5>Editar Feed</h5>
      </template>

      <template #default>
        <div class="example">
          <quill-editor
            class="editor"
            v-model="feedEditar.feed.descricao"
            :options="editorOption"
          >
          </quill-editor>

          <div class="mt-2">
            <p
              id="nome-anexo"
              class="mb-0"
              v-for="(filesAnexo, index) in fileReceiptEdit"
              :key="index"
            >
              {{ filesAnexo.name }}
              <button
                type="button"
                @click="apagarAnexo(index)"
                class="btn badge bagde-light"
              >
                <i class="fa fa-trash text-danger"></i>
              </button>
            </p>
          </div>

          <div class="float-right mt-1">
            <b-button
              id="text-button-anexo"
              @click="openFileDialog()"
              type="button"
              variant="outline-dark"
            >
              <i id="anexo" class="fas fa-paperclip"></i>
              Anexo
            </b-button>
            <input
              ref="files"
              type="file"
              class="d-none"
               accept=".png, .jpg, .jpeg, .pdf, .avi, .mp4,"
              @change="handleFile"
              multiple
            />
          </div>
        </div>
      </template>

      <template #modal-footer>
        <div class="float-right">
          <b-button
            size="sm"
            class="mr-1"
            variant="secondary"
            @click="hideModalEditarFeed"
            >Fechar</b-button
          >
          <b-button
            size="sm"
            class="mr-1"
            variant="danger"
            @click="excluirFeed(feedEditar.feed.pk_id_feed)"
            >Excluir</b-button
          >
          <b-button
            size="sm"
            class="mr-1"
            variant="primary"
            @click="editarFeed(feedEditar.feed.pk_id_feed)"
            >Salvar</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script scoped>
var moment = require("moment");
moment.locale("pt-br");
const axios = require("axios").default;

export default {
  name: "Home",

  mounted() {
    this.me();
    this.getFeed();

    this.refresh();
  },

  components: {},

  filters: {
    moment: function (date) {
      return moment(date).format("llll");
    },
  },

  beforeDestroy() {
    clearInterval(this.intervalid);
  },

  data() {
    return {
      show: true,

      //url: "http://localhost/dev/laravelVue/conectaVueLaravel/apiLaravel8-conecta/storage/app/public/",
      url: "http://images.stv.com.br/",

      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
        },
        placeholder: "Digite seu feed aqui....",
      },

      user: [],
      feed: [],
      feedEditar: [],

      fileReceiptEdit: [],

      anexo: [],
    };
  },

  methods: {
    openFileDialog() {
      this.$refs.files.value = null;
      this.$refs.files.click();
    },

    handleFile() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.fileReceiptEdit.push(this.$refs.files.files[i]);
      }
    },

    apagarAnexo(e) {
      this.fileReceiptEdit.splice(e, 1);
    },

    showModalEditarFeed(idFeed) {
      this.$refs["editar-feed"].show();

      this.fileReceiptEdit = "";

      this.feed.forEach((feeds, i) => {
        if (feeds.feed.pk_id_feed == idFeed) {
          this.feedEditar = feeds;
        }
      });
    },

    hideModalEditarFeed() {
      this.$refs["editar-feed"].hide();

      this.getFeed();
    },

    async excluirFeed(index) {
      if (confirm(`Tem certeza de que deseja excluir este Feed?`)) {
        const response = await axios.post("feed/excluirFeed", {
          idExcluir: index,
        });

        alert(response.data);

        this.hideModalEditarFeed();

        this.getFeed();
      }
    },

    async editarFeed(index) {
      if (confirm(`Salvar as alterações?`)) {
        var self = this;

        let formData = new FormData();
        for (let i = 0; i < this.fileReceiptEdit.length; i++) {
          let file = self.fileReceiptEdit[i];
          formData.append("files[" + i + "]", file);
        }
        formData.append("idEditar", index);
        formData.append("descricao", this.feedEditar.feed.descricao);

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        //Requisição atualizar dados
        await axios
          .post("feed/editarFeed", formData, config)
          .then((response) => {
            self.$refs.files.value = "";
            self.fileReceipt = [];

            this.hideModalEditarFeed();

            this.getFeed();
          });
      }
    },

    me() {
      const perfil = JSON.parse(localStorage.getItem("storageData"));
      this.user = perfil;
    },

    async getFeed() {
      this.show = true;
      try {
        await axios.get("feed/getFeed").then((response) => {
          this.feed = response.data;

          console.log(response.data)

          this.show = false;
        });
      } catch (error) {

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 401:
            this.error =
              "Não foi possivel carregar os Feeds - [Erro] " +
              error.response.status;
            break;
          case 404:
            this.error =
              "Pagina não encontrada na Api! - [Erro] " + error.response.status;
            break;
        }
      }
    },

    async getFeedReload() {
      await axios.get("feed/getFeed").then((response) => {
        this.feed = response.data;
      });
    },

    refresh: function () {
      this.intervalid = setInterval(() => {
        this.getFeedReload();
      }, 120000);
    },
  },
};
</script>


<style scoped>
#tituloFeed {
  color: #505050;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
}

.quillEditor {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.quillEditorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

#border-video {
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: rgba(175, 175, 175, 0.637);
}
</style>