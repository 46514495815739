<template>
    <b-alert show variant="success" dismissible
            fade class="text-center">{{correctComponents}}</b-alert>
</template>

<script>
    export default{
        name:'CorrectComponents',
        props: ['correctComponents'],
    }
</script>

<style>

</style>