<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Ramais</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="">Home</a></li>
              <li class="breadcrumb-item active">Ramais</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary card-outline">
              <div class="card-header">
                <div class="card-title mb-0">
                  <b>
                    <u>{{ unidadeDados.nome }}</u></b
                  ><br /><br />
                  <ul class="ml-4 mb-0 fa-ul text-muted">
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-phone"></i
                      ></span>
                      Telefone: {{ unidadeDados.telefone }}
                    </li>
                    <li class="small">
                      <span class="fa-li"
                        ><i class="fas fa-lg fa-building"></i
                      ></span>
                      Endereço: {{ unidadeDados.endereco }}
                    </li>
                  </ul>
                </div>

                <div class="float-right" style="padding: 0px 0px 0px 10px">
                  <router-link
                    :to="'/controle-acesso-formulario?id=' + idUnidade"
                  >
                    <button class="btn-sm btn-outline-secondary">
                      <i class="fas fa-exchange-alt"></i> Controle de Acesso
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="modal-body">
                <b-container fluid>
                  <b-row>
                    <b-input-group class="mt-2 mb-2">
                      <b-input-group-append>
                        <b-form-input
                          size="sm"
                          id="filter-input"
                          v-model="configTableRamais.filter"
                          type="search"
                          placeholder="Pesquisar..."
                        ></b-form-input>
                      </b-input-group-append>
                    </b-input-group>

                    <b-table
                      :busy="isBusy"
                      striped
                      outlined
                      hover
                      bordered
                      head-variant="dark"
                      :items="itemsRamais"
                      :fields="fieldsRamais"
                      :current-page="configTableRamais.currentPage"
                      :per-page="configTableRamais.perPage"
                      :filter="configTableRamais.filter"
                      :filter-included-fields="configTableRamais.filterOn"
                      :sort-by.sync="configTableRamais.sortBy"
                      :sort-desc.sync="configTableRamais.sortDesc"
                      :sort-direction="configTableRamais.sortDirection"
                      stacked="md"
                      show-empty
                      small
                    >
                      <template #empty>
                        <h5 class="text-center">
                          {{ tableRamais.emptyTexts }}
                        </h5>
                      </template>

                      <template #emptyfiltered>
                        <h5 class="text-center">
                          {{ tableRamais.emptyFilteredTexts }}
                        </h5>
                      </template>

                      <template #table-busy>
                        <div class="text-center text-danger my-3">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong> Carregando...</strong>
                        </div>
                      </template>

                      <template #cell(nome)="row">
                        <div class="user-panel pb-1">
                          <router-link id="nomeUsuario" :to="'/perfil-usuario?id=' + row.item.pk_id_usuario">
                            
                            <div v-if="row.item.foto" class="image mr-2">
                              <img :src="require(`../../../assets/img/profiles/${row.item.foto}`)" class="img-circle elevation-1" alt="User Image">
                            </div> 

                            <div v-else class="image mr-2">
                              <img :src="require(`../../../assets/adminLTE/dist/img/user2-160x160.jpg`)" class="img-circle elevation-1" alt="User Image">
                            </div>

                            {{ row.item.nome }}
                            
                          </router-link>                                                       
                        </div>
                      </template>

                      <template #cell(ramal)="row">
                        <div>
                          <span class="small"
                            ><i class="fas fa-phone text-muted"></i
                          ></span>
                          {{ row.item.ramal }}
                        </div>
                      </template>

                      <template #cell(funcao)="row">
                        <div>
                          <span class="small"
                            ><i class="fas fa-briefcase text-muted"></i
                          ></span>
                          {{ row.item.funcao }}
                        </div>
                      </template>

                      <template #cell(nome_unidade)="row">
                        <div>
                          <span class="small"
                            ><i class="fas fa-building text-muted"></i
                          ></span>
                          {{ row.item.nome_unidade }}
                        </div>
                      </template>

                    </b-table>

                    <b-tfoot>
                      <div class="text-muted">
                        <div class="small">
                          <span><i class="fas fa-list-ol"></i></span> Total de
                          registros: <b>{{ itemsRamais.length }}</b>
                        </div>
                      </div>
                    </b-tfoot>

                    <b-col sm="5" md="0" class="my-1">
                      <b-form-group
                        label="Quantidade"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="8"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="configTableRamais.perPage"
                          :options="configTableRamais.pageOptions"
                          size="sm"
                        >
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col sm="5" md="4" class="my-1">
                      <b-pagination
                        v-model="configTableRamais.currentPage"
                        :total-rows="ramaisRows"
                        :per-page="configTableRamais.perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const axios = require("axios").default;
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "ControleAcessoRamais",

  data() {
    return {
      isBusy: true,

      unidadeDados: {
        nome: "",
        telefone: "",
        endereco: "",
      },

      idUnidade: this.$route.query.id,

      /////////////////////////<====TABELA DE RAMAIS====>///////////////////////////////////////////
      itemsRamais: [],

      tableRamais: {
        emptyTexts: "Não há usuarios cadastrado!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsRamais: [
        {
          key: "nome",
          label: "Nome",
          sortable: true,
        },
        {
          key: "ramal",
          label: "Ramal",
          sortable: true,
        },
        {
          key: "funcao",
          label: "Função",
          sortable: true,
        },
        {
        key: "nome_unidade",
        label: "Unidade",
        sortable: true,
        },

      ],

      configTableRamais: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
    };
  },

  mounted() {
    this.dadosRamaisUnidade();
  },

  filters: {
    moment: function (date) {
      return moment(date).format("LT - L");
    },
  },

  computed: {
    ramaisRows() {
      return this.itemsRamais.length;
    },
  },

  methods: {

    async dadosRamaisUnidade() {
      try {
        const response = await axios.post("unidade/ramaisUnidade", {
          idUnidade: this.$route.query.id,
        });

        this.unidadeDados.nome = response.data.dadosUnidade.original.nome_unidade,
        this.unidadeDados.telefone = response.data.dadosUnidade.original.telefone,
        this.unidadeDados.endereco = response.data.dadosUnidade.original.endereco,

        this.itemsRamais = response.data.ramaisUnidade

        //console.log(response.data.ramaisUnidade)

        this.isBusy = false;

      } catch (error) {
        //console.log(error.response.status);
        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>

<style scoped>

a:link 
{ 
 text-decoration:none; 
}

</style>