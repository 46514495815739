<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Assinatura Digital</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Assinatura Digital</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="card card-secondary card-outline">
              <div class="card-body">
                <b-form-group
                  id="input-group-1"
                  class="col-sm-12 mb-4 mt-3"
                >
                  <label id="label-decoration" class="mb-0">Título</label>

                  <b-form-input
                    id="input-titulo"
                    v-model="titulo"
                    :state="titulo.length >= 5"
                    type="text"
                    placeholder="Título..."
                  ></b-form-input>

                  

                  <b-form-invalid-feedback class="mt-0" id="input-titulo">
                    Insira pelo menos 5 letras
                  </b-form-invalid-feedback>
                </b-form-group>

              

                <div class="col-sm-12 mb-3 mt-3">
                  <b-button
                    @click="showModalAddAssinatura"
                    class="mr-1"
                    variant="outline-info"
                    size="sm"
                    >Adicionar Assinante</b-button
                  >
                </div>

                <div
                  v-for="(assinante, index) in assinantesArray"
                  :key="index"
                  class="col-lg-12 mt-2"
                >
                  <div id="bordaBody" class="well well-sm">
                    <button
                      type="button"
                      @click="apagarAssinante(index)"
                      class="btn badge bagde-light"
                    >
                      <i class="fa fa-trash text-danger"></i>
                    </button>
                    <b> <u>Assinante</u> </b>

                    <div class="mt-2">
                      <div>
                        <li
                          class="list-unstyled"
                          id="label-decoration"
                        >
                          <i>{{ assinante.nome }}</i>
                        </li>

                        <ul>
                          <li v-if="assinante.email" id="complementoAssinante">
                            <b class="text-black">E-mail:</b>
                            {{ assinante.email }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="float-left col-sm-12 mb-0">
                  <div class="mb-0 mt-3">
                    <b-button
                      :disabled="statusButton"
                      @click="addDocumentoAssinar"
                      type="submit"
                      variant="primary"
                      size="sm"
                      class="float-right"
                    >
                      <i class="fas fa-save"></i>
                      <b> Salvar</b></b-button
                    >

                    <input
                      ref="files"
                      type="file"
                      class="d-none"
                      accept=".png, .jpg, .jpeg, .doc,.docx,.pdf,.xls,.xlsx"
                      @change="handleFile"
                      multiple
                    />

                    <b-button
                      id="text-button-anexo"
                      @click="openFileDialog()"
                      type="button"
                      variant="outline-dark"
                      size="sm"
                      class="mb-2"
                    >
                      <i id="anexo" class="fas fa-paperclip"></i>
                      Anexar
                    </b-button>

                    <div class="m-auto">
                      <p
                        id="nome-anexo"
                        class="mb-0"
                        v-for="(filesAnexo, index) in fileReceipt"
                        :key="index"
                      >
                        {{ filesAnexo.name }}
                        <button
                          type="button"
                          @click="apagarAnexo(index)"
                          class="btn badge bagde-light"
                        >
                          <i class="fa fa-trash text-danger"></i>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--------------------------------Modal Adicionar Convidado-------------------------------------->

        <b-modal
          ref="modalAdicionarAssinante"
          hide-footer
          title="Adicionar assinante"
        >
          <div class="form-group row">
            <div class="col-sm-12 mb-3">
              <label id="label-decoration" class="mb-0">Assinante</label>
              <div>
                <AutocompleteComponents
                  v-model="assinante.nome"
                  :options="autocomplete.usersAssinante"
                  label-key="nome"
                  value-key="pk_id_usuario"
                  placeholder="Pesquisar..."
                  @shouldSearch="pesquisarUsuariosAssinante"
                  @select="onSelectAssinante"
                />
              </div>
            </div>

            <div class="col-sm-3 mb-3">
               <label id="label-decoration" class="mb-0">Email</label
              >
              <div class="input-group">
                <input
                  type="text"
                  v-model="assinante.email"
                  name="email"
                  class="form-control"
                  id="complementoAssinante"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>

          <div class="float-right mt-4">
            <b-button
              class="mr-1"
              variant="secondary"
              @click="hideModalAddConvidado"
              >Fechar</b-button
            >
            <b-button
              class="mr-1"
              variant="primary"
              @click="adicionarConvidado()"
              >Adicionar</b-button
            >
          </div>
        </b-modal>
      </div>
    </section>
  </div>
</template>

<script>
const axios = require("axios").default;
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\

export default {
  name: "AssinaturaDigital",

  computed: {
    statusButton() {
      return this.titulo.length > 4
        ? false
        : true;
    },
  },

  components: {
    AutocompleteComponents, //===>Autocomplete<===\\
  },

  data() {
    return {
      autocomplete: {
        usersAssinante: [], //===>Autocomplete<===\\
        usersConvidadoEditar: [], //===>Autocomplete<===\\
      },

      fileReceipt: [],

      assinante: {
        email: "",
        nome: "",
        id: "",
      },

      assinantesArray: [],
      titulo: "",
    };
  },

  methods: {
    showModalAddAssinatura() {
      this.limparConvidado();

      this.$refs["modalAdicionarAssinante"].show();
    },

    hideModalAddConvidado() {
      this.limparConvidado();
      this.$refs["modalAdicionarAssinante"].hide();
    },

    apagarAssinante(e) {
      this.assinantesArray.splice(e, 1);
    },

    limparConvidado() {
      this.assinante.matricula = "";
      this.assinante.funcao = "";
      this.assinante.email = "";
      this.assinante.telefone = "";
      this.assinante.empresa = "";
      this.assinante.nome = "";
      this.assinante.id = "";
      this.assinante.unidade = "";
      this.assinante.setor = "";
      this.assinante.exportOrigem = "";
      this.autocomplete.usersAssinante = [];
    },

    pesquisarUsuariosAssinante(query) {
      //===>Autocomplete<===\\
      axios
        .get(`autocomplete/userAutocompleteAssinatura/${query}`)
        .then((response) => {
          this.autocomplete.usersAssinante = response.data;
        });
    },

    onSelectAssinante(user) {
      //===>Autocomplete<===\\

      this.assinante.matricula = user.matricula;
      this.assinante.funcao = user.funcao;
      this.assinante.email = user.email;
      this.assinante.telefone = user.celular_empresa;
      this.assinante.empresa = user.empresa;
      this.assinante.nome = user.nome;
      this.assinante.id = user.pk_id_usuario;
      this.assinante.exportOrigem = user.export_origem;
      this.assinante.unidade = user.unidade;
      this.assinante.setor = user.setor;
    },

    async adicionarConvidado() {
      this.assinantesArray.push({
        matricula: this.assinante.matricula,
        funcao: this.assinante.funcao,
        email: this.assinante.email,
        telefone: this.assinante.telefone,
        empresa: this.assinante.empresa,
        nome: this.assinante.nome,
        id: this.assinante.id,
        unidade: this.assinante.unidade,
        setor: this.assinante.setor,
        exportOrigem: this.assinante.exportOrigem,
      });

      console.log(this.assinantesArray);

      this.$refs["modalAdicionarAssinante"].hide();
    },

    pesquisarUsuarios(query) {
      //===>Autocomplete<===\\
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.usersSolicitante = response.data;
      });
    },

    pesquisarUsuariosLider(query) {
      //===>Autocomplete<===\\
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.solicitacaoPessoal.usersLider = response.data;
      });
    },

    pesquisarUsuariosGestor(query) {
      //===>Autocomplete<===\\
      axios.get(`autocomplete/userAutocomplete/${query}`).then((response) => {
        this.solicitacaoPessoal.usersGestor = response.data;
      });
    },

    onSelect(userSolicitante) {
      //===>Autocomplete<===\\
      this.userSelectSolicitante = userSolicitante;
    },

    onSelectLider(userLider) {
      //===>Autocomplete<===\\
      this.solicitacaoPessoal.userSelectLider = userLider;
    },

    onSelectGestor(userGestor) {
      //===>Autocomplete<===\\
      this.solicitacaoPessoal.userSelectGestor = userGestor;
    },

    async dadosChamadoUser() {
      const perfil = JSON.parse(localStorage.getItem("storageData"));
      const equipe = JSON.parse(localStorage.getItem("equipeUser"));

      if (equipe["0"]) {
        try {
          const perfil = JSON.parse(localStorage.getItem("storageData"));
          const equipe = JSON.parse(localStorage.getItem("equipeUser"));

          const response = await axios.post("chamado/dadosChamado", {
            idUsuario: perfil.pk_id_usuario,
            equipes: equipe,
          });

          this.optionAtividadeTodas =
            response.data.todasAtividadesEquipes.original.flat(); //===>Achata Array .flat()<===\\
          var chamadoAtividades =
            response.data.atividadesEquipes.original.flat(); //===>Achata Array .flat()<===\\
          var userEquipe = response.data.usersEquipes.original.flat(); //===>Achata Array .flat()<===\\

          //===>Função limpar array item duplicado<===\\
          const userEquipeUnico = new Map();

          userEquipe.forEach((user) => {
            if (!userEquipeUnico.has(user.pk_id_usuario)) {
              userEquipeUnico.set(user.pk_id_usuario, user);
            }
          });

          this.optionDestinatario = [...userEquipeUnico.values()];
          //===>Função limpar array item duplicado<===\\

          console.log(this.optionAtividadeTodas);

          //===>Função limpar array item duplicado<===\\
          const chamadoAtividadesUnico = new Map();

          chamadoAtividades.forEach((atividade) => {
            if (
              !chamadoAtividadesUnico.has(atividade.pk_id_atendimento_categoria)
            ) {
              chamadoAtividadesUnico.set(
                atividade.pk_id_atendimento_categoria,
                atividade
              );
            }
          });

          this.optionAtividade = [...chamadoAtividadesUnico.values()];
          //===>Função limpar array item duplicado<===\\
        } catch (error) {
          //console.log(error.response.status)

          const erroHttp = error.response.status;
          switch (erroHttp) {
            case 500:
              alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
              break;
            case 404:
              alert(
                "Pagina não encontrada na Api! - [Erro] " +
                  error.response.status
              );
              break;
          }
        }
      } else {
        this.showModal();
      }
    },

    showModal() {
      this.$refs["my-modal"].show();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },

    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },

    async addSolicitacaoPessoal() {
      try {
        if (this.userSelectSolicitante == "") {
          alert("Adicione um Solicitante!");
        } else {
          if (this.selectedEquipe == "outraEquipe") {
            var destinatario = "";
            var atividade = this.selectedAtividadeTodas;
          } else {
            var atividade = this.selectedAtividade;

            if (
              this.selectedDestinatario == "" ||
              this.selectedDestinatario == null
            ) {
              var destinatario = "";
            } else {
              var destinatario = this.selectedDestinatario.pk_id_usuario;
            }
          }

          if (
            this.solicitacaoPessoal.userSelectLider == "" ||
            this.solicitacaoPessoal.userSelectLider == null
          ) {
            var solicitacaoPessoalLider = "";
          } else {
            var solicitacaoPessoalLider =
              this.solicitacaoPessoal.userSelectLider.pk_id_usuario;
          }

          if (
            this.solicitacaoPessoal.userSelectGestor == "" ||
            this.solicitacaoPessoal.userSelectGestor == null
          ) {
            var solicitacaoPessoalGestor = "";
          } else {
            var solicitacaoPessoalGestor =
              this.solicitacaoPessoal.userSelectGestor.pk_id_usuario;
          }

          if (atividade == "" || atividade == null) {
            alert("Selecione uma categoria de atividade!");
          } else {
            const perfil = JSON.parse(localStorage.getItem("storageData"));

            var self = this;

            let formData = new FormData();
            for (let i = 0; i < this.fileReceipt.length; i++) {
              let file = self.fileReceipt[i];
              formData.append("files[" + i + "]", file);
            }

            formData.append("idUser", perfil.pk_id_usuario);
            formData.append("atividade", atividade.pk_id_atendimento_categoria);
            formData.append("destinatario", destinatario);
            formData.append(
              "solicitante",
              this.userSelectSolicitante.pk_id_usuario
            );

            formData.append("motivo", this.solicitacaoPessoal.selectedMotivo);
            formData.append("genero", this.solicitacaoPessoal.selectedGenero);
            formData.append("turno", this.solicitacaoPessoal.selectedTurno);
            formData.append("divisao", this.solicitacaoPessoal.divisao);
            formData.append("cargo", this.solicitacaoPessoal.cargo);
            formData.append("centroCusto", this.solicitacaoPessoal.centroCusto);
            formData.append("razaoSocial", this.solicitacaoPessoal.razaoSocial);
            formData.append("endereco", this.solicitacaoPessoal.endereco);
            formData.append("bairro", this.solicitacaoPessoal.bairro);
            formData.append("escala", this.solicitacaoPessoal.escala);
            formData.append(
              "conhecimentos",
              this.solicitacaoPessoal.conhecimentos
            );
            formData.append("habilidades", this.solicitacaoPessoal.habilidades);
            formData.append("atitudes", this.solicitacaoPessoal.atitudes);
            formData.append(
              "requisitosExperiencia",
              this.solicitacaoPessoal.requisitosExperiencia
            );
            formData.append(
              "idadeReferencial",
              this.solicitacaoPessoal.idadeReferencial
            );
            formData.append(
              "salarioBeneficios",
              this.solicitacaoPessoal.salarioBeneficios
            );
            formData.append(
              "descricaoAtividade",
              this.solicitacaoPessoal.descricaoAtividade
            );
            formData.append("observacoes", this.solicitacaoPessoal.observacoes);
            formData.append("lider", solicitacaoPessoalLider);
            formData.append("gestor", solicitacaoPessoalGestor);

            const config = {
              headers: { "content-type": "multipart/form-data" },
            };

            try {
              //Requisição atualizar dados
              await axios
                .post("chamado/addNovaSolicitaçãoPessoal", formData, config)
                .then((response) => {
                  self.$refs.files.value = "";
                  self.fileReceipt = [];

                  console.log(response.data);

                  alert(response.data);

                  this.limparFormulario();
                });
            } catch (error) {
              console.log(error.response.status);

              const erroHttp = error.response.status;
              switch (erroHttp) {
                case 401:
                  alert(
                    "Api Pabx fora do ar! - [Erro] " + error.response.status
                  );
                  break;
                case 404:
                  alert(
                    "Pagina não encontrada na Api! - [Erro] " +
                      error.response.status
                  );
                  break;
              }
            }
          }
        }
      } catch (error) {
        //console.log(error.response.status)

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    limparFormulario() {
      (this.userSolicitante = ""),
        (this.titulo = ""),
        (this.contentHTML = ""),
        (this.selectedDestinatario = null),
        (this.selectedAtividade = ""),
        (this.selectedAtividadeTodas = ""),
        (this.selectedEquipe = "outraEquipe");

      this.solicitacaoPessoal.selectedMotivo = "Ampliação";
      this.solicitacaoPessoal.selectedGenero = "";
      this.solicitacaoPessoal.selectedTurno = "Dia";
      this.solicitacaoPessoal.divisao = "";
      this.solicitacaoPessoal.cargo = "";
      this.solicitacaoPessoal.centroCusto = "";
      this.solicitacaoPessoal.razaoSocial = "";
      this.solicitacaoPessoal.endereco = "";
      this.solicitacaoPessoal.bairro = "";
      this.solicitacaoPessoal.escala = "";
      this.solicitacaoPessoal.conhecimentos = "";
      this.solicitacaoPessoal.habilidades = "";
      this.solicitacaoPessoal.atitudes = "";
      this.solicitacaoPessoal.requisitosExperiencia = "";
      this.solicitacaoPessoal.idadeReferencial = "";
      this.solicitacaoPessoal.salarioBeneficios = "";
      this.solicitacaoPessoal.descricaoAtividade = "";
      this.solicitacaoPessoal.observacoes = "";

      this.solicitacaoPessoal.userLider = "";
      this.solicitacaoPessoal.usersLider = [];
      this.solicitacaoPessoal.userSelectLider = "";
      this.solicitacaoPessoal.userGestor = "";
      this.solicitacaoPessoal.usersGestor = [];
      this.solicitacaoPessoal.userSelectGestor = "";
    },

    async addDocumentoAssinar() {
      try {
        if (this.userSelectSolicitante == "") {
          alert("Adicione um Solicitante!");
        } else if (this.titulo == "") {
          alert("Digite um Título para sua solicitação!");
        } else if (this.contentHTML == "") {
          alert("Digite o conteúdo de sua solicitação!");
        } else {
          if (this.selectedEquipe == "outraEquipe") {
            var destinatario = "";
            var atividade = this.selectedAtividadeTodas;
          } else {
            var atividade = this.selectedAtividade;

            if (
              this.selectedDestinatario == "" ||
              this.selectedDestinatario == null
            ) {
              var destinatario = "";
            } else {
              var destinatario = this.selectedDestinatario.pk_id_usuario;
            }
          }

          if (atividade == "" || atividade == null) {
            alert("Selecione uma categoria de atividade!");
          } else {
            const perfil = JSON.parse(localStorage.getItem("storageData"));

            var self = this;

            let formData = new FormData();
            for (let i = 0; i < this.fileReceipt.length; i++) {
              let file = self.fileReceipt[i];
              formData.append("files[" + i + "]", file);
            }

            formData.append("idUser", perfil.pk_id_usuario);
            formData.append("atividade", atividade.pk_id_atendimento_categoria);
            formData.append("destinatario", destinatario);
            formData.append(
              "solicitante",
              this.userSelectSolicitante.pk_id_usuario
            );
            formData.append("titulo", this.titulo);
            formData.append("conteudo", this.contentHTML);

            const config = {
              headers: { "content-type": "multipart/form-data" },
            };

            try {
              //Requisição atualizar dados
              await axios
                .post("chamado/addNovaChamado", formData, config)
                .then((response) => {
                  self.$refs.files.value = "";
                  self.fileReceipt = [];

                  alert(response.data);

                  (this.userSolicitante = ""),
                    (this.titulo = ""),
                    (this.contentHTML = ""),
                    (this.selectedDestinatario = null),
                    (this.selectedAtividade = ""),
                    (this.selectedAtividadeTodas = ""),
                    (this.selectedEquipe = "outraEquipe");
                });
            } catch (error) {
              console.log(error.response.status);

              const erroHttp = error.response.status;
              switch (erroHttp) {
                case 401:
                  alert(
                    "Api Pabx fora do ar! - [Erro] " + error.response.status
                  );
                  break;
                case 404:
                  alert(
                    "Pagina não encontrada na Api! - [Erro] " +
                      error.response.status
                  );
                  break;
              }
            }
          }
        }
      } catch (error) {
        //console.log(error.response.status)

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    openFileDialog() {
      this.$refs.files.value = null;
      this.$refs.files.click();
    },

    apagarAnexo(e) {
      this.fileReceipt.splice(e, 1);
    },

    handleFile() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.fileReceipt.push(this.$refs.files.files[i]);
      }
    },
  },
};
</script>

<style scoped>
#label-decoration {
  color: rgb(139, 139, 139);
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
}

#label-style {
  color: rgb(107, 107, 107);
  font-weight: bold;
  font-size: 15px;
}

#nome-anexo {
  font-size: 14px;
  font-style: italic;
}

#text-button-anexo {
  font-size: 15px;
  font-style: italic;
}

#bordaBody {
  background-color: #f1f4f9;
  padding: 5px 5px 0px 20px;
  border: 1px solid #d0d9e8;
}

#complementoAssinante {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.699);
}

#labelcomplementoConvidado {
  font-size: 14px;
  color: rgb(122, 122, 122);
}
</style>
