<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Tickets Recebido</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Tickets Recebido</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary card-outline">
              <b-tabs
                active-nav-item-class="font-weight-bold text-uppercase"
                content-class="mt-3"
              >

                <div class="float-right mr-4">
                  <button
                    type="button"
                    class="btn-sm btn-outline-info"
                    size="sm"
                    @click="ticketRecebido()"
                  >
                    <i class="fas fa-sync"></i>
                    Refresh
                  </button>
                </div>

                <div class="float-right mr-1">
                  <router-link to="/novo-chamado">
                    <button class="btn-sm btn-outline-secondary">
                      <i class="fas fa-headset"></i>
                      Novo Ticket
                    </button>
                  </router-link>
                </div>
                <br />

                <!--///////////////////====Tickets Aberto====//////////////////////-->
                <b-tab title="Aberto" active>
                  <div class="card-body">
                    <b-container fluid>
                      <b-row>
                        <b-input-group class="mt-0 mb-1">
                          <b-input-group-append>
                            <b-form-input
                              size="sm"
                              id="filter-input"
                              v-model="configTableTicketAberto.filter"
                              type="search"
                              placeholder="Pesquisar..."
                            ></b-form-input>
                          </b-input-group-append>
                        </b-input-group>

                        <b-table
                          :busy="isBusy"
                          :striped="true"
                          :outlined="true"
                          :hover="true"
                          :bordered="false"
                          :borderless="false"
                          :noCollapse="false"
                          :dark="false"
                          :fixed="false"
                          :small="true"
                          :footClone="true"
                          head-variant="dark"
                          :items="itemsTicketAberto"
                          :fields="fieldsTicketAberto"
                          :current-page="configTableTicketAberto.currentPage"
                          :per-page="configTableTicketAberto.perPage"
                          :filter="configTableTicketAberto.filter"
                          :filter-included-fields="
                            configTableTicketAberto.filterOn
                          "
                          :sort-by.sync="configTableTicketAberto.sortBy"
                          :sort-desc.sync="configTableTicketAberto.sortDesc"
                          :sort-direction="
                            configTableTicketAberto.sortDirection
                          "
                          stacked="md"
                          show-empty
                        >
                          <template #empty>
                            <h5 class="text-center">
                              {{ tableTicketAberto.emptyTexts }}
                            </h5>
                          </template>

                          <template #emptyfiltered>
                            <h5 class="text-center">
                              {{ tableTicketAberto.emptyFilteredTexts }}
                            </h5>
                          </template>

                          <template #table-busy>
                            <div class="text-center text-danger my-3">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong> Carregando...</strong>
                            </div>
                          </template>

                          <template #cell(protocolo)="row">
                            <router-link
                              :to="
                                '/solicitacao-atendimento?id=' +
                                row.item.pk_id_atendimento
                              "
                            >
                              <div class="text-center small">
                                <span
                                  ><i class="fas fa-building text-muted"></i
                                ></span>
                                {{ row.item.protocolo }}
                              </div>
                            </router-link>
                          </template>

                          <template #cell(titulo)="row">
                            <div>
                              <span
                                ><i class="fas fa-edit small text-muted"></i
                              ></span>
                              {{ row.item.titulo }}
                            </div>
                          </template>

                          <template #cell(nome_requerente)="row">
                            <div>
                              <span
                                ><i class="far fa-user text-muted"></i
                              ></span>
                              {{ row.item.nome_requerente }}
                            </div>
                          </template>

                          <template #cell(nome_atendente)="row">
                            <div v-if="row.item.nome_atendente">
                              <span
                                ><i class="fas fa-user-tie text-muted"></i
                              ></span>
                              {{ row.item.nome_atendente }}
                            </div>

                            <div v-else>
                              <span
                                ><i class="fas fa-users text-muted"></i
                              ></span>
                              Equipe
                            </div>
                          </template>

                          <template #cell(status)="row">
                            <div>
                              <span
                                ><i class="fas fa-toggle-on text-muted"></i
                              ></span>
                              {{ row.item.status }}
                            </div>
                          </template>

                          <template #cell(data_hora_cadastro)="row">
                            <div class="small">
                              <span
                                ><i class="fas fa-history text-muted"></i
                              ></span>
                              {{ row.item.data_hora_cadastro | moment }}
                            </div>
                          </template>
                        </b-table>

                        <b-tfoot>
                          <div class="text-muted">
                            <div class="small">
                              <span><i class="fas fa-list-ol"></i></span> Total
                              de registros:
                              <b>{{ itemsTicketAberto.length }}</b>
                            </div>
                          </div>
                        </b-tfoot>

                        <b-col sm="5" md="0" class="my-1">
                          <b-form-group
                            label="Quantidade"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="8"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                          >
                            <b-form-select
                              id="per-page-select"
                              v-model="configTableTicketAberto.perPage"
                              :options="configTableTicketAberto.pageOptions"
                              size="sm"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col sm="5" md="4" class="my-1">
                          <b-pagination
                            v-model="configTableTicketAberto.currentPage"
                            :total-rows="ticketAbertoRows"
                            :per-page="configTableTicketAberto.perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </b-tab>

                <!--///////////////////====Tickets Atendimento====//////////////////////-->
                <b-tab title="Atendimento">
                  <div class="card-body">
                    <b-container fluid>
                      <b-row>
                        <b-input-group class="mt-0 mb-1">
                          <b-input-group-append>
                            <b-form-input
                              size="sm"
                              id="filter-input"
                              v-model="configTableTicketAtendimento.filter"
                              type="search"
                              placeholder="Pesquisar..."
                            ></b-form-input>
                          </b-input-group-append>
                        </b-input-group>

                        <b-table
                          :busy="isBusy"
                          :striped="true"
                          :outlined="true"
                          :hover="true"
                          :bordered="false"
                          :borderless="false"
                          :noCollapse="false"
                          :dark="false"
                          :fixed="false"
                          :small="true"
                          :footClone="true"
                          head-variant="dark"
                          :items="itemsTicketAtendimento"
                          :fields="fieldsTicketAtendimento"
                          :current-page="
                            configTableTicketAtendimento.currentPage
                          "
                          :per-page="configTableTicketAtendimento.perPage"
                          :filter="configTableTicketAtendimento.filter"
                          :filter-included-fields="
                            configTableTicketAtendimento.filterOn
                          "
                          :sort-by.sync="configTableTicketAtendimento.sortBy"
                          :sort-desc.sync="
                            configTableTicketAtendimento.sortDesc
                          "
                          :sort-direction="
                            configTableTicketAtendimento.sortDirection
                          "
                          stacked="md"
                          show-empty
                        >
                          <template #empty>
                            <h5 class="text-center">
                              {{ tableTicketAtendimento.emptyTexts }}
                            </h5>
                          </template>

                          <template #emptyfiltered>
                            <h5 class="text-center">
                              {{ tableTicketAtendimento.emptyFilteredTexts }}
                            </h5>
                          </template>

                          <template #table-busy>
                            <div class="text-center text-danger my-3">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong> Carregando...</strong>
                            </div>
                          </template>

                          <template #cell(protocolo)="row">
                            <router-link
                              :to="
                                '/solicitacao-atendimento?id=' +
                                row.item.pk_id_atendimento
                              "
                            >
                              <div class="text-center small">
                                <span
                                  ><i class="fas fa-building text-muted"></i
                                ></span>
                                {{ row.item.protocolo }}
                              </div>
                            </router-link>
                          </template>

                          <template #cell(titulo)="row">
                            <div>
                              <span
                                ><i class="fas fa-edit small text-muted"></i
                              ></span>
                              {{ row.item.titulo }}
                            </div>
                          </template>

                          <template #cell(nome_requerente)="row">
                            <div>
                              <span
                                ><i class="far fa-user text-muted"></i
                              ></span>
                              {{ row.item.nome_requerente }}
                            </div>
                          </template>

                          <template #cell(nome_atendente)="row">
                            <div v-if="row.item.nome_atendente">
                              <span
                                ><i class="fas fa-user-tie text-muted"></i
                              ></span>
                              {{ row.item.nome_atendente }}
                            </div>

                            <div v-else>
                              <span
                                ><i class="fas fa-users text-muted"></i
                              ></span>
                              Equipe
                            </div>
                          </template>

                          <template #cell(status)="row">
                            <div>
                              <span
                                ><i class="fas fa-toggle-on text-muted"></i
                              ></span>
                              {{ row.item.status }}
                            </div>
                          </template>

                          <template #cell(data_hora_cadastro)="row">
                            <div class="small">
                              <span
                                ><i class="fas fa-history text-muted"></i
                              ></span>
                              {{ row.item.data_hora_cadastro | moment }}
                            </div>
                          </template>
                        </b-table>

                        <b-tfoot>
                          <div class="text-muted">
                            <div class="small">
                              <span><i class="fas fa-list-ol"></i></span> Total
                              de registros:
                              <b>{{ itemsTicketAtendimento.length }}</b>
                            </div>
                          </div>
                        </b-tfoot>

                        <b-col sm="5" md="0" class="my-1">
                          <b-form-group
                            label="Quantidade"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="8"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                          >
                            <b-form-select
                              id="per-page-select"
                              v-model="configTableTicketAtendimento.perPage"
                              :options="
                                configTableTicketAtendimento.pageOptions
                              "
                              size="sm"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col sm="5" md="4" class="my-1">
                          <b-pagination
                            v-model="configTableTicketAtendimento.currentPage"
                            :total-rows="ticketAtendimentoRows"
                            :per-page="configTableTicketAtendimento.perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </b-tab>

                <!--///////////////////====Tickets Fechado====//////////////////////-->
                <b-tab title="Fechado">
                  <div class="card-body">
                    <b-container fluid>
                      <b-row>
                        <b-input-group class="mt-0 mb-1">
                          <b-input-group-append>
                            <b-form-input
                              size="sm"
                              id="filter-input"
                              v-model="configTableTicketFechado.filter"
                              type="search"
                              placeholder="Pesquisar..."
                            ></b-form-input>
                          </b-input-group-append>
                        </b-input-group>

                        <b-table
                          :busy="isBusy"
                          :striped="true"
                          :outlined="true"
                          :hover="true"
                          :bordered="false"
                          :borderless="false"
                          :noCollapse="false"
                          :dark="false"
                          :fixed="false"
                          :small="true"
                          :footClone="true"
                          head-variant="dark"
                          :items="itemsTicketFechado"
                          :fields="fieldsTicketFechado"
                          :current-page="configTableTicketFechado.currentPage"
                          :per-page="configTableTicketFechado.perPage"
                          :filter="configTableTicketFechado.filter"
                          :filter-included-fields="
                            configTableTicketFechado.filterOn
                          "
                          :sort-by.sync="configTableTicketFechado.sortBy"
                          :sort-desc.sync="configTableTicketFechado.sortDesc"
                          :sort-direction="
                            configTableTicketFechado.sortDirection
                          "
                          stacked="md"
                          show-empty
                        >
                          <template #empty>
                            <h5 class="text-center">
                              {{ tableTicketFechado.emptyTexts }}
                            </h5>
                          </template>

                          <template #emptyfiltered>
                            <h5 class="text-center">
                              {{ tableTicketFechado.emptyFilteredTexts }}
                            </h5>
                          </template>

                          <template #table-busy>
                            <div class="text-center text-danger my-3">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong> Carregando...</strong>
                            </div>
                          </template>

                          <template #cell(protocolo)="row">
                            <router-link
                              :to="
                                '/solicitacao-atendimento?id=' +
                                row.item.pk_id_atendimento
                              "
                            >
                              <div class="text-center small">
                                <span
                                  ><i class="fas fa-building text-muted"></i
                                ></span>
                                {{ row.item.protocolo }}
                              </div>
                            </router-link>
                          </template>

                          <template #cell(titulo)="row">
                            <div>
                              <span
                                ><i class="fas fa-edit small text-muted"></i
                              ></span>
                              {{ row.item.titulo }}
                            </div>
                          </template>

                          <template #cell(nome_requerente)="row">
                            <div>
                              <span
                                ><i class="far fa-user text-muted"></i
                              ></span>
                              {{ row.item.nome_requerente }}
                            </div>
                          </template>

                          <template #cell(nome_atendente)="row">
                            <div v-if="row.item.nome_atendente">
                              <span
                                ><i class="fas fa-user-tie text-muted"></i
                              ></span>
                              {{ row.item.nome_atendente }}
                            </div>

                            <div v-else>
                              <span
                                ><i class="fas fa-users text-muted"></i
                              ></span>
                              Equipe
                            </div>
                          </template>

                          <template #cell(status)="row">
                            <div>
                              <span
                                ><i class="fas fa-toggle-off text-muted"></i
                              ></span>
                              {{ row.item.status }}
                            </div>
                          </template>

                          <template #cell(data_hora_cadastro)="row">
                            <div class="small">
                              <span
                                ><i class="fas fa-history text-muted"></i
                              ></span>
                              {{ row.item.data_hora_cadastro | moment }}
                            </div>
                          </template>
                        </b-table>

                        <b-tfoot>
                          <div class="text-muted">
                            <div class="small">
                              <span><i class="fas fa-list-ol"></i></span> Total
                              de registros:
                              <b>{{ itemsTicketFechado.length }}</b>
                            </div>
                          </div>
                        </b-tfoot>

                        <b-col sm="5" md="0" class="my-1">
                          <b-form-group
                            label="Quantidade"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="8"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                          >
                            <b-form-select
                              id="per-page-select"
                              v-model="configTableTicketFechado.perPage"
                              :options="configTableTicketFechado.pageOptions"
                              size="sm"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>

                        <b-col sm="5" md="4" class="my-1">
                          <b-pagination
                            v-model="configTableTicketFechado.currentPage"
                            :total-rows="ticketFechadoRows"
                            :per-page="configTableTicketFechado.perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
var moment = require("moment");
moment.locale("pt-br");

export default {
  name: "ChamadoRecebido",

  data() {
    return {
      isBusy: true,

      /////////////////////////<====Tabela Ticket Aberto====>///////////////////////////////////////////
      itemsTicketAberto: [],

      tableTicketAberto: {
        emptyTexts: "Não há registro de ticket nesta categoria!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsTicketAberto: [
        {
          key: "protocolo",
          label: "Protocolo",
          sortable: true,
        },

        {
          key: "titulo",
          label: "Título",
          sortable: true,
        },

        {
          key: "nome_requerente",
          label: "Solicitante",
          sortable: true,
        },

        {
          key: "nome_atendente",
          label: "Destinatário",
          sortable: true,
        },

        {
          key: "status",
          label: "Status",
          sortable: true,
        },

        {
          key: "data_hora_cadastro",
          label: "Data Abertura",
          sortable: true,
        },
      ],

      configTableTicketAberto: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },

      /////////////////////////<====Tabela Ticket Atendimento====>///////////////////////////////////////////
      itemsTicketAtendimento: [],

      tableTicketAtendimento: {
        emptyTexts: "Não há registro de ticket nesta categoria!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsTicketAtendimento: [
        {
          key: "protocolo",
          label: "Protocolo",
          sortable: true,
        },

        {
          key: "titulo",
          label: "Título",
          sortable: true,
        },

        {
          key: "nome_requerente",
          label: "Solicitante",
          sortable: true,
        },

        {
          key: "nome_atendente",
          label: "Destinatário",
          sortable: true,
        },

        {
          key: "status",
          label: "Status",
          sortable: true,
        },

        {
          key: "data_hora_cadastro",
          label: "Data Abertura",
          sortable: true,
        },
      ],

      configTableTicketAtendimento: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },

      /////////////////////////<====Tabela Ticket Fechado====>///////////////////////////////////////////
      itemsTicketFechado: [],

      tableTicketFechado: {
        emptyTexts: "Não há registro de ticket nesta categoria!",
        emptyFilteredTexts: "Não há registros que correspondam à sua pesquisa",
      },

      fieldsTicketFechado: [
        {
          key: "protocolo",
          label: "Protocolo",
          sortable: true,
        },

        {
          key: "titulo",
          label: "Título",
          sortable: true,
        },

        {
          key: "nome_requerente",
          label: "Solicitante",
          sortable: true,
        },

        {
          key: "nome_atendente",
          label: "Destinatário",
          sortable: true,
        },

        {
          key: "status",
          label: "Status",
          sortable: true,
        },

        {
          key: "data_hora_cadastro",
          label: "Data Abertura",
          sortable: true,
        },
      ],

      configTableTicketFechado: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        pageOptions: [10, 20, 50, { value: 1000, text: "Todos" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
      },
    };
  },

  computed: {
    ticketAbertoRows() {
      return this.itemsTicketAberto.length;
    },

    ticketAtendimentoRows() {
      return this.itemsTicketAtendimento.length;
    },

    ticketFechadoRows() {
      return this.itemsTicketFechado.length;
    },
  },

  mounted() {
    this.ticketRecebido();

    this.refresh()
  },

  beforeDestroy () {

    clearInterval(this.intervalid)
  },

  methods: {
    async ticketRecebido() {
      this.isBusy = true;

      try {
        const perfil = JSON.parse(localStorage.getItem("storageData"));
        this.user = perfil;

        const equipe = JSON.parse(localStorage.getItem("equipeUser"));
        this.equipeUser = equipe;

        //Requisição
        const response = await axios.post("chamado/listaChamado", {
          idUsuario: this.user.pk_id_usuario,
          equipes: this.equipeUser,
        });

        this.itemsTicketAberto = response.data.ticketAberto.original["0"];
        this.itemsTicketAtendimento =
          response.data.ticketAtendimento.original["0"];
        this.itemsTicketFechado = response.data.ticketFechado.original["0"];

        this.isBusy = false;

        //console.log( this.itemsTicketAberto)
      } catch (error) {
        //console.log(error.response.status)

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    async refreshTicketRecebido() {

      try {
        const perfil = JSON.parse(localStorage.getItem("storageData"));
        this.user = perfil;

        const equipe = JSON.parse(localStorage.getItem("equipeUser"));
        this.equipeUser = equipe;

        //Requisição
        const response = await axios.post("chamado/listaChamado", {
          idUsuario: this.user.pk_id_usuario,
          equipes: this.equipeUser,
        });

        this.itemsTicketAberto = response.data.ticketAberto.original["0"];
        this.itemsTicketAtendimento =
          response.data.ticketAtendimento.original["0"];
        this.itemsTicketFechado = response.data.ticketFechado.original["0"];

      } catch (error) {

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    refresh: function(){  
        this.intervalid = setInterval(() => {
            this.refreshTicketRecebido()
        }, 300000);
    }

  },

  filters: {
    moment: function (date) {
      return moment(date).format("L - LTS");
    },
  },
};
</script>


<style scoped>
</style>