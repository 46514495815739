<template>

</template>

<script scoped>

export default {
    name: 'LoginLayoutComponents',
}
</script>

<style scoped src="../../../assets/login/main-login.css"/>