<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Perfil</h1>
          </div>

          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Perfil</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <div class="card card-primary card-outline">
              <div class="card-body box-profile">
                <div class="text-center mb-0">
                  <img
                    v-if="user.thumbnail"
                    class="profile-user-img img-fluid img-circle profileImgUrl"
                    :src="require(`../../../assets/img/profiles/profile.png`)"
                    alt="Nome Usuario"
                  />

                  <!--
                  <img
                    v-if="user.thumbnail"
                    class="profile-user-img img-fluid img-circle profileImgUrl"
                    :src="require(`/var/www/html/conecta-STV/apiLaravel8-conecta/public/images/profile/${user.thumbnail}`)"
                    alt="Nome Usuario"
                  />
                  -->

                  <img
                    v-else
                    class="profile-user-img img-fluid img-circle profileImgUrl"
                    :src="require(`../../../assets/img/profiles/profile.png`)"
                    alt="Nome Usuario"
                  />
                  <div class="mb-0">
                    <!--
                    <button
                      class="btn btn-ontline badge bagde-light text-info"
                      @click="showModal"
                    >
                      <i id="ico-cam" class="fas fa-camera-retro"></i>
                    </button>
                    -->

                    <b-modal
                      ref="my-modal"
                      hide-footer
                      title="Alterar foto:"
                      header-bg-variant="dark"
                      header-text-variant="light"
                      body-bg-variant="dark"
                      body-text-variant="info"
                      footer-bg-variant="dark"
                      footer-text-variant="info"
                    >
                      <div class="d-block text-center">
                        <form
                          @submit.prevent="profileUpload"
                          method="POST"
                          enctype="multipart/form-data"
                        >
                          <div class="custom-file">
                            <input
                              type="file"
                              @change="imageSelected"
                              class="custom-file-input"
                              id="customFile"
                            />
                            <label class="custom-file-label" for="customFile"
                              >Escolha foto . . .
                            </label>
                          </div>
                          <div v-if="imagepreview" class="mt-3">
                            <img
                              :src="imagepreview"
                              class="figure-img img-fluid rounded"
                              style="max-height: 100px"
                            />
                          </div>

                          <button
                            @click="hideModal"
                            class="btn btn-secondary mt-5 mr-2"
                            type="button"
                          >
                            <i class="fas fa-times-circle"></i>
                            Cancelar
                          </button>
                          <button class="btn btn-info mt-5" type="submit">
                            <i class="fas fa-save"></i>
                            Upload
                          </button>
                        </form>
                      </div>
                    </b-modal>
                  </div>
                </div>
                <h3 class="profile-username text-center mt-0">
                  {{ user.nome }}
                </h3>
                <p class="text-muted text-center">{{ user.equipe }}</p>
              </div>
            </div>
          </div>

          <div v-if="tipo_perfil == 'perfil'" class="col-md-9">
            <div class="card" id="element_overlap1">
              <div class="card-body">
                <div class="tab-pane" id="settings">
                  <!-- <div class="float-right">
                      <button class="btn-sm btn-outline-secondary" v-on:click="tipo_perfil = 'editar'">
                          <i class="fas fa-user-edit"></i> Editar
                      </button>  
                  </div><br><br> -->

                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label class="label-perfil" for="nome">Nome</label><br />
                      <div class="conteudo-perfil">{{ user.nome }}</div>
                    </div>
                    <div class="col-sm-6">
                      <label class="label-perfil" for="matricula"
                        >Matrícula</label
                      ><br />
                      <div class="conteudo-perfil">{{ user.matricula }}</div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label class="label-perfil" for="funcao">Função</label
                      ><br />
                      <div class="conteudo-perfil">{{ user.funcao }}</div>
                    </div>
                    <div class="col-sm-6">
                      <label class="label-perfil" for="centro_custo"
                        >Centro de Custo</label
                      ><br />
                      <div class="conteudo-perfil">
                        {{ user.unidade }}-{{ user.setor }}-{{ user.area }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label class="label-perfil" for="equipe">Equipe</label
                      ><br />
                      <div class="conteudo-perfil">{{ user.equipe }}</div>
                    </div>
                    <div class="col-sm-6">
                      <label class="label-perfil" for="aniversario"
                        >Aniversário</label
                      ><br />
                      <div class="conteudo-perfil">
                        {{ user.data_nascimento }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label class="label-perfil" for="ramal">Ramal</label
                      ><br />
                      <div class="conteudo-perfil">{{ user.ramal }}</div>
                    </div>
                    <div class="col-sm-6">
                      <label class="label-perfil" for="email">E-mail</label
                      ><br />
                      <div class="conteudo-perfil">{{ user.email }}</div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label class="label-perfil" for="celular_empresa"
                        >Celular Empresa</label
                      ><br />
                      <div class="conteudo-perfil">
                        {{ user.celular_empresa }}
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <label class="label-perfil" for="telefone_empresa"
                        >Telefone Empresa</label
                      ><br />
                      <div class="conteudo-perfil">
                        {{ user.telefone_empresa }}
                      </div>
                    </div>
                  </div>

                  <div class="float-right">
                    <button
                      class="btn-sm btn-outline-secondary"
                      v-on:click="tipo_perfil = 'editar'"
                    >
                      <i class="fas fa-user-edit"></i> Editar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="col-md-9">
            <div class="card" id="element_overlap1">
              <div class="card-body">
                <div class="tab-pane" id="settings">
                  <form @submit.prevent="atualizarDados">
                    <ErrorComponents v-if="error" :errorComponents="error" />

                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label class="label-editar" for="funcao">Função</label
                        ><br />
                        <div class="input-group mb-2">
                          <input
                            type="text"
                            name="funcao"
                            class="form-control"
                            v-model.trim="user.funcao"
                            id="funcao"
                            placeholder="Função"
                          />
                          <div class="input-group-append">
                            <div class="input-group-text">
                              <span class="fas fa-briefcase"></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <label class="label-editar" for="email">E-Mail</label
                        ><br />
                        <div class="input-group mb-2">
                          <input
                            type="email"
                            name="email"
                            class="form-control"
                            v-model.trim="user.email"
                            id="email"
                            placeholder="Email"
                          />
                          <div class="input-group-append">
                            <div class="input-group-text">
                              <span class="fas fa-envelope"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label class="label-editar" for="telefone"
                          >Telefone</label
                        ><br />
                        <div class="input-group mb-2">
                          <input
                            type="text"
                            name="telefone"
                            class="form-control"
                            v-mask="'(##) ####-####'"
                            v-model.trim="user.telefone_empresa"
                            id="telefone"
                            placeholder="Telefone"
                          />
                          <div class="input-group-append">
                            <div class="input-group-text">
                              <span class="fas fa-phone"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="label-editar" for="centro_custo"
                          >Celular</label
                        ><br />
                        <div class="input-group mb-2">
                          <input
                            type="text"
                            name="celular_empresa"
                            class="form-control"
                            v-mask="'(##) #####-####'"
                            v-model.trim="user.celular_empresa"
                            id="celular_empresa"
                            placeholder="Celular Empresa"
                          />
                          <div class="input-group-append">
                            <div class="input-group-text">
                              <span class="fas fa-mobile-alt"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--
                    <div class="form-group row">
                      <div class="col-sm-6 mb-3">
                        <div class="input-group mb-1">
                          <b-form-file
                            v-model="fileImg"
                            :state="Boolean(fileImg)"
                            placeholder="Carregar imagem. . ."
                            drop-placeholder="Drop file here..."
                          >
                          </b-form-file>
                        </div>
                        <div id="style-text-img" v-if="fileImg">
                          Foto: {{ fileImg ? fileImg.name : "" }}
                          <button
                            type="button"
                            class="btn badge badge-light"
                            @click="limparSelectImg"
                          >
                          <i id="size-ico" class="fa fa-trash text-danger"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    -->

                    <!-- <div class="row"> 
                        <div class="col-4">
                            <button type="submit" class="btn btn-primary btn-block" name="enviar"><i class="fas fa-save"></i> Salvar</button>
                        </div>
                    </div> -->

                    <div>
                      <button
                        type="submit"
                        class="btn-sm btn-outline-primary float-right"
                      >
                        <i class="fas fa-save"></i> Salvar
                      </button>
                      <button
                        v-on:click="tipo_perfil = 'perfil'"
                        class="btn-sm btn-outline-secondary"
                      >
                        <i class="fas fa-times-circle"></i> Cancelar
                      </button>

                      <!--
                      <button
                        v-on:click="tipo_perfil = 'perfil'"
                        @click="limparSelectImg"
                        class="btn-sm btn-outline-secondary"
                      >
                        <i class="fas fa-times-circle"></i> Cancelar
                      </button>
                      -->
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
import ErrorComponents from "./Components/ErrorComponents.vue";

export default {
  name: "Perfil",

  data() {
    return {
      user: [],
      tipo_perfil: "perfil",
      error: "",

      image: null,
      imagepreview: null,
      //urlImgPerfil: "C:/xampp/htdocs/Laravel-Vue/Conecta - Vue2-Laravel8/apiLaravel8-conecta/public/images/profile/"
    };
  },

  mounted() {
    this.me();
  },

  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },

    hideModal() {
      this.$refs["my-modal"].hide();

      this.limparSelectImg();
    },

    imageSelected(e) {
      this.image = e.target.files[0];

      let reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.onload = (e) => {
        this.imagepreview = e.target.result;
      };
    },

    async profileUpload() {
      try {
        let dataimg = new FormData();
        dataimg.append("image", this.image);

        //Requisição atualizar dados
        const response = await axios.post("userprofile", dataimg);

        //Salvando novos dados na localStorage

        this.limparSelectImg();

        localStorage.setItem("storageData", JSON.stringify(response.data));

        alert("Foto do perfil salva com sucesso!!");

        this.$router.go(this.$router.currentRoute);

        //window.location = 'perfil'
      } catch (error) {
        //console.log(error.response.status)

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 401:
            this.error =
              "Usuario ou senha incorreta - [Erro] " + error.response.status;
            break;
          case 404:
            this.error =
              "Pagina não encontrada na Api! - [Erro] " + error.response.status;
            break;
        }
      }
    },

    me() {
      const perfil = JSON.parse(localStorage.getItem("storageData"));
      this.user = perfil;
      //console.log(this.user)

      this.user.data_nascimento = this.user.data_nascimento.substring(0, 5);
    },

    limparSelectImg() {
      (this.image = null), (this.imagepreview = null);
    },

    async atualizarDados() {
      try {
        //Requisição atualizar dados
        const response = await axios.post("usuario/atualizaDados", {
          id: this.user.pk_id_usuario,
          funcao: this.user.funcao,
          email: this.user.email,
          celular: this.user.celular_empresa,
          telefone: this.user.telefone_empresa,
        });

        //Salvando novos dados na localStorage
        localStorage.setItem("storageData", JSON.stringify(response.data));

        alert("Atualização executada com sucesso!");

        this.tipo_perfil = "perfil";
      } catch (error) {
        //console.log(error.response.status)

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 401:
            this.error =
              "Usuario ou senha incorreta - [Erro] " + error.response.status;
            break;
          case 404:
            this.error =
              "Pagina não encontrada na Api! - [Erro] " + error.response.status;
            break;
        }
      }
    },
  },

  components: {
    ErrorComponents,
  },
};
</script>


<style scoped>
.label-perfil {
  color: #000000;
}

.label-editar {
  color: #88898b;
  text-decoration: underline;
  font-style: italic;
  font-weight: normal;
}

.conteudo-perfil {
  color: #575757;
}

#ico-cam {
  font-size: 18px;
}

#ico-cam:hover {
  opacity: 0.5;
}
</style>