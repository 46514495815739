<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Agenda Médica</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/perfil">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Agenda Médica</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-primary card-outline">
              <div class="card-body">
                <b-overlay :show="show" rounded="sm">
                  <div class="demo-app-main">
                    <FullCalendar
                      class="demo-app-calendar"
                      :options="calendarOptions"
                    >
                      <template v-slot:eventContent="arg">
                        <b>{{ arg.timeText }}</b>
                        <i>{{ arg.event.title }}</i>
                      </template>
                    </FullCalendar>
                  </div>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal ref="tarefa" title="Detalhes da Tarefa">
      <template #modal-header>
        <h5>Detalhes da Tarefa</h5>
      </template>

      <template #default>
        <div class="tab-pane" id="settings">
          <div class="form-group row">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12">
                  <ul class="list-unstyled">
                    <li><b>Titulo:</b> {{ eventEdit.title }}</li>
                    <li v-if="1 > 2"><b>Para:</b> Ana Paula Brum Cardoso</li>
                  </ul>
                </div>
                <div class="col-lg-12">
                  <div id="bordaBody" class="well well-sm">
                    <ul class="list-unstyled">
                      <li>
                        {{ eventEdit.start | moment }} -
                        <b class="text-primary"
                          >{{ eventEdit.timeStart }} até
                          {{ eventEdit.timeEnd }}</b
                        >
                      </li>
                      <li v-if="1 > 2">
                        Tipo:
                        <span style="color: #1dbb37">Finalizado</span>
                      </li>
                      <li v-if="eventEdit.descricao" class="mt-3">
                        <b>Descrição:</b> {{ eventEdit.descricao }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div v-if="eventEdit.convidadoNome" class="col-lg-12 mt-2">
                  <div id="bordaBody" class="well well-sm">
                    <b> <u> Convidados: </u></b>

                    <div class="mt-3">
                      <div>
                        <li
                          class="list-unstyled"
                          id="labelcomplementoConvidado"
                        >
                          <b>
                            <i>{{ eventEdit.convidadoNome }} </i></b
                          >
                        </li>

                        <ul>
                          <li
                            v-if="eventEdit.convidadoFuncao"
                            id="complementoConvidado"
                          >
                            <b class="text-black">Cargo:</b>
                            {{ eventEdit.convidadoFuncao }}
                          </li>
                          <li
                            v-if="eventEdit.convidadoMatricula"
                            id="complementoConvidado"
                          >
                            <b class="text-black">Matricula:</b>
                            {{ eventEdit.convidadoMatricula }}
                          </li>
                          <li
                            v-if="eventEdit.convidadoTelefone"
                            id="complementoConvidado"
                          >
                            <b class="text-black">Telefone:</b>
                            {{ eventEdit.convidadoTelefone }}
                          </li>
                          <li
                            v-if="eventEdit.convidadoEmail"
                            id="complementoConvidado"
                          >
                            <b class="text-black">E-mail:</b>
                            {{ eventEdit.convidadoEmail }}
                          </li>
                          <li
                            v-if="eventEdit.convidadoUnidade"
                            id="complementoConvidado"
                          >
                            <b class="text-black">C.C.:</b>
                            {{ eventEdit.convidadoUnidade }}-{{
                              eventEdit.convidadoSetor
                            }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #modal-footer>
        <div class="float-right">
          <b-button
            size="sm"
            class="mr-1"
            variant="secondary"
            @click="hideModalTarefa"
            >Fechar</b-button
          >
          <b-button
            size="sm"
            class="mr-1"
            variant="danger"
            @click="excluirTarefa(eventEdit.id)"
            >Excluir</b-button
          >
          <b-button
            size="sm"
            class="mr-1"
            variant="info"
            @click="showModalEditarTarefa()"
            >Editar</b-button
          >
        </div>
      </template>
    </b-modal>

    <b-modal ref="editar-tarefa" hide-footer title="Tarefa">
      <div class="tab-pane" id="settings">
        <div class="form-group row">
          <div class="col-sm-12 mb-3">
            <label class="label-editar mb-0">Título</label>
            <div class="input-group">
              <input
                type="text"
                v-model="eventEdit.title"
                name="numero-contrato"
                class="form-control"
                id="numero-contrato"
                required
                placeholder="Título"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-cash-register"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 mb-3">
            <label class="label-editar mb-0">Convidado</label>
            <div>
              <AutocompleteComponents
                v-model="convidadoEditar.nome"
                :options="autocomplete.usersConvidadoEditar"
                label-key="nome"
                value-key="pk_id_usuario"
                placeholder="Pesquisar..."
                @shouldSearch="pesquisarUsuariosConvidadoEditar"
                @select="onSelectConvidadoEditar"
              />
            </div>
          </div>
        </div>
        <div v-if="showConvidadoEditar" class="form-group row">
          <div class="col-sm-3 mb-3">
            <label id="labelcomplementoConvidado" class="label-editar mb-0"
              >Matrícula</label
            >
            <div class="input-group">
              <input
                type="text"
                v-mask="'###########'"
                v-model="convidadoEditar.matricula"
                name="matricula"
                class="form-control"
                id="complementoConvidado"
                placeholder="Matrícula"
              />
            </div>
          </div>

          <div class="col-sm-3 mb-3">
            <label id="labelcomplementoConvidado" class="label-editar mb-0"
              >C.C.</label
            >
            <div class="input-group">
              <input
                type="text"
                v-mask="'##-###'"
                v-model="convidadoEditar.centroCusto"
                name="centroDeCusto"
                class="form-control"
                id="complementoConvidado"
                placeholder="C.C."
              />
            </div>
          </div>

          <div class="col-sm-6 mb-3">
            <label id="labelcomplementoConvidado" class="label-editar mb-0"
              >Telefone</label
            >
            <div class="input-group">
              <input
                type="text"
                v-mask="'(##) ####-#####'"
                v-model="convidadoEditar.telefone"
                name="telefone"
                class="form-control"
                id="complementoConvidado"
                required
                placeholder="Telefone"
              />
            </div>
          </div>

          <div class="col-sm-6 mb-3">
            <label id="labelcomplementoConvidado" class="label-editar mb-0"
              >E-mail</label
            >
            <div class="input-group">
              <input
                type="email"
                v-model="convidadoEditar.email"
                name="email"
                class="form-control"
                id="complementoConvidado"
                placeholder="E-mail"
              />
            </div>
          </div>

          <!--
            <div class="col-sm-3 mb-3">
              <label id="labelcomplementoConvidado" class="label-editar mb-0"
                >Empresa</label
              >
              <div class="input-group">
                <input
                  type="text"
                  v-model="convidadoEditar.empresa"
                  name="empresa"
                  class="form-control"
                  id="complementoConvidado"
                  required
                  placeholder="Empresa"
                />
              </div>
            </div>
            -->

          <div class="col-sm-6 mb-3">
            <label id="labelcomplementoConvidado" class="label-editar mb-0"
              >Função</label
            >
            <div class="input-group">
              <input
                type="text"
                v-model="convidadoEditar.funcao"
                name="funcao"
                class="form-control"
                id="complementoConvidado"
                required
                placeholder="Função"
              />
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12 mb-3">
            <label class="label-editar mb-0" for="nome-contrato"
              >Descrição</label
            >
            <div class="input-group">
              <b-form-textarea
                v-model="eventEdit.descricao"
                id="textarea-state"
                placeholder="Descrição...."
                rows="4"
                required
              ></b-form-textarea>
            </div>
          </div>

          <div class="col-sm-12 mt-3 mb-2">
            <b-form-checkbox
              v-model="diaInteiroEditar"
              name="check-button"
              switch
            >
              Dia inteiro
            </b-form-checkbox>
          </div>

          <div class="col-sm-8">
            <label class="label-editar mb-0" for="data-inicio"
              >Data Início</label
            >
            <div class="input-group">
              <b-form-datepicker
                placeholder="Data Inicial"
                id="datepicker-valid"
                v-model="eventEdit.start"
                :state="true"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
              ></b-form-datepicker>
            </div>
          </div>

          <div v-if="diaInteiroEditar == false" class="col-sm-4">
            <label class="mb-0">Hora Início</label>
            <div class="input-group">
              <b-form-timepicker
                v-model="eventEdit.timeStart"
                placeholder=""
                id="time-valid"
                :state="true"
              ></b-form-timepicker>
            </div>
          </div>

          <div class="col-sm-8">
            <label class="label-editar mb-0" for="data-final">Data Final</label>
            <div class="input-group">
              <b-form-datepicker
                placeholder="Data Final"
                id="datepicker-invalid"
                v-model="eventEdit.end"
                :state="false"
                class="mb-2"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
              ></b-form-datepicker>
            </div>
          </div>

          <div v-if="diaInteiroEditar == false" class="col-sm-4">
            <label class="label-editar mb-0" for="data-final">Hora Final</label>
            <div class="input-group">
              <b-form-timepicker
                placeholder=""
                v-model="eventEdit.timeEnd"
                id="time-invalid"
                :state="false"
                class="mb-2"
              ></b-form-timepicker>
            </div>
          </div>
        </div>
      </div>

      <div class="float-right mt-4">
        <b-button
          class="mr-1"
          variant="secondary"
          @click="hideModalEditarTarefa"
          >Fechar</b-button
        >

        <b-button class="mr-1" variant="primary" @click="editarTarefa()"
          >Salvar</b-button
        >
      </div>
    </b-modal>

    <!---------------------------------------------------------------------------------------------->

    <b-modal ref="adicionar-tarefa" hide-footer title="Tarefa">
      <div class="tab-pane" id="settings">
        <div class="form-group row">
          <div class="col-sm-12 mb-3">
            <label class="label-editar mb-0">Título</label>
            <div class="input-group">
              <input
                type="text"
                v-model="eventAdd.title"
                name="numero-contrato"
                class="form-control"
                id="numero-contrato"
                required
                placeholder="Título"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-cash-register"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 mb-3">
            <label class="label-editar mb-0">Convidado</label>
            <div>
              <AutocompleteComponents
                v-model="convidado.nome"
                :options="autocomplete.usersConvidado"
                label-key="nome"
                value-key="pk_id_usuario"
                placeholder="Pesquisar..."
                @shouldSearch="pesquisarUsuariosConvidado"
                @select="onSelectConvidado"
              />
            </div>
          </div>
        </div>

        <div v-if="showConvidado" class="form-group row">
          <div class="col-sm-3 mb-3">
            <label id="labelcomplementoConvidado" class="label-editar mb-0"
              >Matrícula</label
            >
            <div class="input-group">
              <input
                type="text"
                v-mask="'###########'"
                v-model="convidado.matricula"
                name="matricula"
                class="form-control"
                id="complementoConvidado"
                placeholder="Matrícula"
              />
            </div>
          </div>

          <div class="col-sm-3 mb-3">
            <label id="labelcomplementoConvidado" class="label-editar mb-0"
              >C.C.</label
            >
            <div class="input-group">
              <input
                type="text"
                v-mask="'##-###'"
                v-model="convidado.centroCusto"
                name="centroDeCusto"
                class="form-control"
                id="complementoConvidado"
                placeholder="C.C."
              />
            </div>
          </div>

          <div class="col-sm-6 mb-3">
            <label id="labelcomplementoConvidado" class="label-editar mb-0"
              >Telefone</label
            >
            <div class="input-group">
              <input
                type="text"
                v-mask="'(##) ####-#####'"
                v-model="convidado.telefone"
                name="telefone"
                class="form-control"
                id="complementoConvidado"
                required
                placeholder="Telefone"
              />
            </div>
          </div>

          <div class="col-sm-6 mb-3">
            <label id="labelcomplementoConvidado" class="label-editar mb-0"
              >E-mail</label
            >
            <div class="input-group">
              <input
                type="email"
                v-model="convidado.email"
                name="email"
                class="form-control"
                id="complementoConvidado"
                placeholder="E-mail"
              />
            </div>
          </div>

          <!--
          <div class="col-sm-3 mb-3">
            <label id="labelcomplementoConvidado" class="label-editar mb-0"
              >Empresa</label
            >
            <div class="input-group">
              <input
                type="text"
                v-model="convidado.empresa"
                name="empresa"
                class="form-control"
                id="complementoConvidado"
                required
                placeholder="Empresa"
              />
            </div>
          </div>
          -->

          <div class="col-sm-6 mb-3">
            <label id="labelcomplementoConvidado" class="label-editar mb-0"
              >Função</label
            >
            <div class="input-group">
              <input
                type="text"
                v-model="convidado.funcao"
                name="funcao"
                class="form-control"
                id="complementoConvidado"
                required
                placeholder="Função"
              />
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12 mb-3">
            <label class="label-editar mb-0" for="nome-contrato"
              >Descrição</label
            >
            <div class="input-group">
              <b-form-textarea
                v-model="eventAdd.descricao"
                id="textarea-state"
                placeholder="Descrição...."
                rows="4"
                required
              ></b-form-textarea>
            </div>
          </div>

          <div class="col-sm-12 mt-3 mb-2">
            <b-form-checkbox
              v-model="diaInteiroAdicionar"
              name="check-button"
              switch
            >
              Dia inteiro
            </b-form-checkbox>
          </div>

          <div class="col-sm-8">
            <label class="label-editar mb-0" for="data-inicio"
              >Data Início</label
            >
            <div class="input-group">
              <b-form-datepicker
                placeholder="Data Inicial"
                id="datepicker-valid"
                v-model="eventAdd.dataStart"
                :state="true"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
              ></b-form-datepicker>
            </div>
          </div>

          <div v-if="diaInteiroAdicionar == false" class="col-sm-4">
            <label class="mb-0">Hora Início</label>
            <div class="input-group">
              <b-form-timepicker
                v-model="eventAdd.timeStart"
                placeholder=""
                id="time-valid"
                :state="true"
              ></b-form-timepicker>
            </div>
          </div>

          <div class="col-sm-8">
            <label class="label-editar mb-0" for="data-final">Data Final</label>
            <div class="input-group">
              <b-form-datepicker
                placeholder="Data Final"
                id="datepicker-invalid"
                v-model="eventAdd.dataEnd"
                :state="false"
                class="mb-2"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
              ></b-form-datepicker>
            </div>
          </div>

          <div v-if="diaInteiroAdicionar == false" class="col-sm-4">
            <label class="label-editar mb-0" for="data-final">Hora Final</label>
            <div class="input-group">
              <b-form-timepicker
                placeholder=""
                v-model="eventAdd.timeEnd"
                id="time-invalid"
                :state="false"
                class="mb-2"
              ></b-form-timepicker>
            </div>
          </div>
        </div>
      </div>

      <div class="float-right mt-4">
        <b-button
          class="mr-1"
          variant="secondary"
          @click="hideModalAdicionarTarefa"
          >Fechar</b-button
        >
        <b-button class="mr-1" variant="primary" @click="adicionarTarefa()"
          >Salvar</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script scoped>
const axios = require("axios").default;
import AutocompleteComponents from "./Components/AutocompleteComponents.vue"; //===>Autocomplete<===\\

var moment = require("moment");
moment.locale("pt-br");

import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import TimeGridPlugin from "@fullcalendar/timegrid";
import ListPlugin from "@fullcalendar/list";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    AutocompleteComponents, //===>Autocomplete<===\\
  },

  computed: {
    showConvidado() {
      if (this.convidado.nome.length > 0) {
        return true;
      } else {
        this.limparConvidado();
        return false;
      }
    },

    showConvidadoEditar() {
      if (this.convidadoEditar.nome.length > 0) {
        return true;
      } else {
        this.limparConvidadoEditar();
        return false;
      }
    },
  },

  data() {
    return {
      usuario: "",
      show: true,

      idMedico: "20891",

      autocomplete: {
        usersConvidado: [], //===>Autocomplete<===\\
        usersConvidadoEditar: [], //===>Autocomplete<===\\
      },

      convidado: {
        matricula: "",
        funcao: "",
        email: "",
        telefone: "",
        empresa: "",
        nome: "",
        id: "",
        centroCusto: "",
        exportOrigem: "",
      },

      convidadoEditar: {
        matricula: "",
        funcao: "",
        email: "",
        telefone: "",
        empresa: "",
        nome: "",
        id: "",
        centroCusto: "",
        exportOrigem: "",
      },

      calendarOptions: {
        locale: "pt-br",
        allDayText: "24 horas",
        plugins: [dayGridPlugin, interactionPlugin, TimeGridPlugin, ListPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        buttonText: {
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Hoje",
          list: "Lista",
        },
        initialView: "dayGridMonth",
        editable: true,
        navLinks: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true, // initial value

        select: this.showModalAdicionarTarefa,
        eventClick: this.showModalTarefa,
        eventsSet: this.handleEvents,
        eventDrop: this.handleEventDropResize,
        eventResize: this.handleEventDropResize,

        events: [],
      },

      eventEdit: {
        id: "",
        title: "",
        start: "",
        end: "",
        timeStart: "",
        timeEnd: "",
        dataStart: "",
        dataEnd: "",
        descricao: "",
        color: "",
        allDay: "",

        convidadoIdUsuario: "",
        convidadoNome: "",
        convidadoFuncao: "",
        convidadoMatricula: "",
        convidadoEmpresa: "",
        convidadoTelefone: "",
        convidadoEmail: "",
        convidadoUnidade: "",
        convidadoSetor: "",
        convidadoExportOrigem: "",
      },

      diaInteiroEditar: false,

      eventAdd: {
        id: "",
        title: "",
        start: "",
        end: "",
        timeStart: "",
        timeEnd: "",
        dataStart: "",
        dataEnd: "",
        descricao: "",
        color: "",
        allDay: "",
        convidado: "",
      },

      diaInteiroAdicionar: true,
    };
  },

  mounted() {
    this.carregarAgenda();

    this.refresh();
  },

  methods: {
    limparConvidado() {
      this.convidado.matricula = "";
      this.convidado.funcao = "";
      this.convidado.email = "";
      this.convidado.telefone = "";
      this.convidado.empresa = "";
      this.convidado.nome = "";
      this.convidado.id = "";
      this.convidado.centroCusto = "";
      this.autocomplete.usersConvidado = [];
    },

    pesquisarUsuariosConvidado(query) {
      const idUsuario = this.usuario.pk_id_usuario;
      axios
        .get(`autocomplete/userAutocompleteAgenda/${query}/${idUsuario}`)
        .then((response) => {
          this.autocomplete.usersConvidado = response.data;
        });
    },

    onSelectConvidado(user) {
      //===>Autocomplete<===\\

      this.convidado.matricula = user.matricula;
      this.convidado.funcao = user.funcao;
      this.convidado.email = user.email;
      this.convidado.telefone = user.celular_empresa;
      this.convidado.empresa = user.empresa;
      this.convidado.nome = user.nome;
      this.convidado.id = user.pk_id_usuario;
      this.convidado.exportOrigem = user.export_origem;
      this.convidado.centroCusto = user.unidade + "-" + user.setor;

      //console.log(user)
    },

    //////////////////////////////////===> Autocomplete Convidado Editar <===////////////////////////////////////////////////

    limparConvidadoEditar() {
      this.convidadoEditar.matricula = "";
      this.convidadoEditar.funcao = "";
      this.convidadoEditar.email = "";
      this.convidadoEditar.telefone = "";
      this.convidadoEditar.empresa = "";
      this.convidadoEditar.nome = "";
      this.convidadoEditar.id = "";
      this.convidadoEditar.centroCusto = "";
      this.autocomplete.usersConvidadoEditar = [];
    },

    pesquisarUsuariosConvidadoEditar(query) {
      const idUsuario = this.usuario.pk_id_usuario;
      axios
        .get(`autocomplete/userAutocompleteAgenda/${query}/${idUsuario}`)
        .then((response) => {
          this.autocomplete.usersConvidadoEditar = response.data;
        });
    },

    onSelectConvidadoEditar(user) {
      //===>Autocomplete<===\\

      this.convidadoEditar.matricula = user.matricula;
      this.convidadoEditar.funcao = user.funcao;
      this.convidadoEditar.email = user.email;
      this.convidadoEditar.telefone = user.celular_empresa;
      this.convidadoEditar.empresa = user.empresa;
      this.convidadoEditar.nome = user.nome;
      this.convidadoEditar.id = user.pk_id_usuario;
      this.convidadoEditar.exportOrigem = user.export_origem;
      this.convidadoEditar.centroCusto = user.unidade + "-" + user.setor;

      //console.log(user)
    },

    //////////////////////////////////===> Editar Tarefa <===////////////////////////////////////////////////
    showModalTarefa(clickInfo) {
      this.$refs["tarefa"].show();

      this.calendarOptions.events.forEach((events, i) => {
        if (events.id == clickInfo.event.id) {
          this.eventEdit.id = events.id;
          this.eventEdit.title = events.title;
          this.eventEdit.start = events.start;
          this.eventEdit.end = events.end;
          this.eventEdit.descricao = events.descricao;
          this.eventEdit.color = events.color;
          this.eventEdit.allDay = events.allDay;
          this.eventEdit.convidadoIdUsuario = events.idUsuario;
          this.eventEdit.convidadoNome = events.nome;
          this.eventEdit.convidadoFuncao = events.funcao;
          this.eventEdit.convidadoMatricula = events.matricula;
          this.eventEdit.convidadoEmpresa = events.empresa;
          this.eventEdit.convidadoTelefone = events.telefone;
          this.eventEdit.convidadoEmail = events.email;
          this.eventEdit.convidadoUnidade = events.unidade;
          this.eventEdit.convidadoSetor = events.setor;
          this.eventEdit.convidadoExportOrigem = events.exportOrigem;
        }
      });

      if (this.eventEdit.allDay == 1) {
        this.diaInteiroEditar = true;
      } else {
        this.diaInteiroEditar = false;
      }

      const timeStart = new Date(this.eventEdit.start);
      const timeEnd = new Date(this.eventEdit.end);

      this.eventEdit.timeStart = timeStart.toLocaleTimeString();
      this.eventEdit.timeEnd = timeEnd.toLocaleTimeString();
    },

    hideModalTarefa() {
      this.$refs["tarefa"].hide();
    },

    //////////////////////////////////===> Editar Tarefa <===////////////////////////////////////////////////
    showModalEditarTarefa(clickInfo) {
      this.limparConvidadoEditar();

      this.hideModalTarefa();
      this.$refs["editar-tarefa"].show();

      // this.calendarOptions.events.forEach((events, i) => {
      //   if (events.id == clickInfo.event.id) {
      //     (this.eventEdit.id = events.id),
      //       (this.eventEdit.title = events.title),
      //       (this.eventEdit.start = events.start),
      //       (this.eventEdit.end = events.end),
      //       (this.eventEdit.descricao = events.descricao),
      //       (this.eventEdit.color = events.color),
      //       (this.eventEdit.allDay = events.allDay);
      //   }
      // });

      if (this.eventEdit.allDay == 1) {
        this.diaInteiroEditar = true;
      } else {
        this.diaInteiroEditar = false;
      }

      const timeStart = new Date(this.eventEdit.start);
      const timeEnd = new Date(this.eventEdit.end);

      this.eventEdit.timeStart = timeStart.toLocaleTimeString();
      this.eventEdit.timeEnd = timeEnd.toLocaleTimeString();
    },

    hideModalEditarTarefa() {
      this.$refs["editar-tarefa"].hide();
    },

    async editarTarefa() {
      const dataStart = this.eventEdit.start.substring(0, 10);
      const dataEnd = this.eventEdit.end.substring(0, 10);
      const timeStart = this.eventEdit.timeStart;
      const timeEnd = this.eventEdit.timeEnd;

      if (this.diaInteiroEditar == true) {
        if (dataStart == dataEnd) {
          // Criar uma data e adicionar 1 hora
          var dataFinal = moment(dataEnd).add(1, "day");

          var dataTimeStart = dataStart + " 00:00:00";
          var dataTimeEnd =
            moment(dataFinal.toDate()).format("YYYY-MM-DD") + " 00:00:00";
        } else {
          var dataTimeStart = dataStart + " 00:00:00";
          var dataTimeEnd = dataEnd + " 00:00:00";
        }
      } else {
        var dataTimeStart = dataStart + " " + timeStart;
        var dataTimeEnd = dataEnd + " " + timeEnd;
      }

      if (this.eventEdit.title == "" || this.eventEdit.title == null) {
        alert("Insira um Titulo");
      } else if (
        this.eventEdit.descricao == "" ||
        this.eventEdit.descricao == null
      ) {
        alert("Insira uma descrição");
      } else if (dataTimeStart > dataTimeEnd) {
        alert(
          "A data final de sua tarefa tem que ser maior ou igual a data inicial"
        );
      } else {
        if (confirm(`Tem certeza de que deseja salvar as alterações`)) {
          const response = await axios.post("agenda/dropResizeAgenda", {
            title: this.eventEdit.title,
            descricao: this.eventEdit.descricao,
            inicioDataHora: dataTimeStart,
            fimDataHora: dataTimeEnd,
            allDay: this.diaInteiroEditar,
            idEvento: this.eventEdit.id,
            idUsuario: this.idMedico,

            convidadoNome: this.convidadoEditar.nome,
            convidadoId: this.convidadoEditar.id,
            convidadoFuncao: this.convidadoEditar.funcao,
            convidadoEmail: this.convidadoEditar.email,
            convidadoTelefone: this.convidadoEditar.telefone,
            convidadoEmpresa: this.convidadoEditar.empresa,
            convidadoCentroCusto: this.convidadoEditar.centroCusto,
            convidadoMatricula: this.convidadoEditar.matricula,
            convidadoOrigem: this.convidadoEditar.exportOrigem,
          });

          console.log(response.data);
          //alert(response.data);
        }

        this.hideModalEditarTarefa();

        this.carregarAgenda();
      }
    },

    //////////////////////////////////===> Adicionar Tarefa <===////////////////////////////////////////////////
    showModalAdicionarTarefa(clickInfo) {
      this.$refs["adicionar-tarefa"].show();

      this.limparConvidado();

      this.eventAdd.title = "";
      this.eventAdd.descricao = "";
      this.eventAdd.dataStart = clickInfo.startStr;
      this.eventAdd.dataEnd = clickInfo.endStr;

      const timeStart = new Date(clickInfo.start);
      const timeEnd = new Date(clickInfo.end);

      this.eventAdd.timeStart = timeStart.toLocaleTimeString();
      this.eventAdd.timeEnd = timeEnd.toLocaleTimeString();

      this.diaInteiroAdicionar = clickInfo.allDay;
    },

    hideModalAdicionarTarefa() {
      this.$refs["adicionar-tarefa"].hide();
    },

    async adicionarTarefa() {
      const dataStart = this.eventAdd.dataStart.substring(0, 10);
      const dataEnd = this.eventAdd.dataEnd.substring(0, 10);
      const timeStart = this.eventAdd.timeStart;
      const timeEnd = this.eventAdd.timeEnd;

      if (this.diaInteiroAdicionar == true) {
        if (dataStart == dataEnd) {
          // Criar uma data e adicionar 1 hora
          var dataFinal = moment(dataEnd).add(1, "day");

          var dataTimeStart = dataStart + " 00:00:00";
          var dataTimeEnd =
            moment(dataFinal.toDate()).format("YYYY-MM-DD") + " 00:00:00";
        } else {
          var dataTimeStart = dataStart + " 00:00:00";
          var dataTimeEnd = dataEnd + " 00:00:00";
        }
      } else {
        var dataTimeStart = dataStart + " " + timeStart;
        var dataTimeEnd = dataEnd + " " + timeEnd;
      }

      if (this.eventAdd.title == "" || this.eventAdd.title == null) {
        alert("Insira um Titulo");
      } else if (
        this.eventAdd.descricao == "" ||
        this.eventAdd.descricao == null
      ) {
        alert("Insira uma descrição");
      } else if (dataTimeStart > dataTimeEnd) {
        alert(
          "A data final de sua tarefa tem que ser maior ou igual a data inicial"
        );
      } else {
        if (confirm(`Tem certeza de que deseja adicionar essa tarefa!?`)) {
          const response = await axios.post("agenda/addAgenda", {
            title: this.eventAdd.title,
            descricao: this.eventAdd.descricao,
            inicioDataHora: dataTimeStart,
            fimDataHora: dataTimeEnd,
            allDay: this.diaInteiroAdicionar,
            idUsuario: this.idMedico,

            convidadoNome: this.convidado.nome,
            convidadoId: this.convidado.id,
            convidadoFuncao: this.convidado.funcao,
            convidadoEmail: this.convidado.email,
            convidadoTelefone: this.convidado.telefone,
            convidadoEmpresa: this.convidado.empresa,
            convidadoCentroCusto: this.convidado.centroCusto,
            convidadoMatricula: this.convidado.matricula,
            convidadoOrigem: this.convidado.exportOrigem,
          });

          console.log(response.data);

          //alert(response.data);

          this.hideModalAdicionarTarefa();

          this.carregarAgenda();
        }
      }
    },

    //////////////////////////////////===> Excluir/Drop/Carregar Tarefa <===////////////////////////////////////////////////
    async excluirTarefa(index) {
      if (confirm(`Tem certeza de que deseja excluir o evento?`)) {
        const response = await axios.post("agenda/excluirAgenda", {
          idExcluir: index,
        });

        this.hideModalTarefa();

        this.carregarAgenda();
      }
    },

    async handleEventDropResize(element) {
      const response = await axios.post("agenda/dropResizeAgenda", {
        title: element.event.title,
        inicioDataHora: element.event.startStr,
        fimDataHora: element.event.endStr,
        allDay: element.event.allDay,
        idEvento: element.event.id,
        idUsuario: this.idMedico,
      });

      alert(response.data);

      this.carregarAgenda();
    },

    async carregarAgenda() {
      (this.show = true),
        (this.usuario = JSON.parse(localStorage.getItem("storageData")));

      const response = await axios.post("agenda/minhaAgenda", {
        idUsuario: this.idMedico,
      });

      console.log(response.data);

      this.calendarOptions.events = response.data;

      this.show = false;
    },

    async agendaReload() {
      const response = await axios.post("agenda/minhaAgenda", {
        idUsuario: this.idMedico,
      });

      console.log(response.data);

      this.calendarOptions.events = response.data;
    },

    refresh: function () {
      this.intervalid = setInterval(() => {
        this.agendaReload();
      }, 60000);
    },
  },

  beforeDestroy() {
    clearInterval(this.intervalid);
  },

  filters: {
    moment: function (date) {
      return moment(date).format("LL");
    },
  },
};
</script>

<style scoped>
#bordaBody {
  background-color: #f1f4f9;
  padding: 15px 15px;
  border: 1px solid #d0d9e8;
}

#complementoConvidado {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.699);
}

#labelcomplementoConvidado {
  font-size: 14px;
  color: rgb(122, 122, 122);
}
</style>