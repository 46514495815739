<template>
  <div>
    <body>
      <div class="limiter">
        <div class="container-login100">
          <div class="wrap-login100">
            <section v-if="delay" class="content mt-5">
              <div
                class="container-fluid"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <section
                  v-if="
                    !ticket['nota_avaliacao'] &&
                    ticket['status'] == 'Fechado' &&
                    ticket['requerente'] == idUserRequerente"
                  class="col-lg-9 connectedSortable"
                >
                  <!-- Custom tabs (Charts with tabs)-->
                  <div class="card">
                    <div class="card-body">
                      <div class="login-logo">
                        <a><b>Avaliar</b> Atendimento!</a>
                      </div>
                      <div class="card-body login-card-body">
                        <p class="login-box-msg">
                          Você está à poucos cliques de efetuar sua avaliação.
                        </p>

                        <div
                          class="container-fluid"
                          style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <div class="col-lg-9">
                            <label for="rating-lg" class="mt-3"
                              >Avalie o atendimento deste chamado de 1 à
                              5!</label
                            >
                            <b-form-rating
                              id="rating-lg"
                              variant="warning"
                              v-model="notaAvaliação"
                              size="lg"
                            ></b-form-rating>
                          </div>
                        </div>

                        <div class="mt-5 mb-3">
                          <label for="textarea-state">Opcional</label>
                          <b-form-textarea
                            id="textarea-state"
                            v-model.trim="textArea"
                            :state="textArea.length >= 10"
                            placeholder="Descreva aqui sua avaliação...."
                            rows="4"
                          ></b-form-textarea>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <button
                              type="button"
                              @click="avaliar()"
                              class="btn btn-primary btn-block"
                            >
                              <i class="fas fa-save"></i> Salvar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </section>

                <section v-else class="col-lg-9 connectedSortable">
                  <!-- Custom tabs (Charts with tabs)-->
                  <div class="card">
                    <div class="card-body">
                      <div class="login-logo">
                        <a><b>Atendimento</b> Avaliado!</a>
                      </div>
                      <div class="card-body login-card-body">
                        <h3 class="login-box-msg">
                          Obrigado por sua avaliação!!! 🤩
                        </h3>
                      </div>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </section>

                  <!--
                  <b-card class="mt-5" header="Form Data Result">
                    <pre class="m-0">{{ ticket }}</pre>
                  </b-card>
                  -->

              </div>
              <!-- /.container-fluid -->
            </section>
          </div>
        </div>
      </div>
    </body>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "AlterarSenha",

  data() {
    return {
      notaAvaliação: null,
      textArea: "",
      ticket: "",
      delay: false,
      idUserRequerente: "",
    };
  },

  mounted() {
    this.verificarAvaliacao();
  },

  methods: {
    async avaliar() {
      try {
        if (this.notaAvaliação == null) {
          alert("Selecione uma nota para avaliar o atendimento!");
        } else {
          const response = await axios.post("chamado/avaliacaoChamado", {
            idChamado: this.$route.query.id,
            notaAtendimento: this.notaAvaliação,
            descricaoAvaliacao: this.textArea,
          });

          alert(response.data);

          window.location.href =
            "avaliacao-chamado?id=" +
            this.$route.query.id +
            "&iduser=" +
            this.$route.query.iduser;

          // this.$router.push("avaliacao-chamado?id=" + this.$route.query.id);
        }
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },

    verificarAvaliacao() {
      this.idUserRequerente = this.$route.query.iduser;
      const idAtendimento = this.$route.query.id;

      try {
        axios
          .get(`chamado/chamadoVerificado/${idAtendimento}`)
          .then((response) => {
            this.ticket = response.data;

            this.delay = true;

            console.log(this.ticket["nota_avaliacao"]);
          });
      } catch (error) {
        //console.log(error.response.status);

        const erroHttp = error.response.status;
        switch (erroHttp) {
          case 500:
            alert("Api Pabx fora do ar! - [Erro] " + error.response.status);
            break;
          case 404:
            alert(
              "Pagina não encontrada na Api! - [Erro] " + error.response.status
            );
            break;
        }
      }
    },
  },
};
</script>

<style>
</style>