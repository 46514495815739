<template>
  <div>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <router-link to="/perfil" class="brand-link">
        <img
          src="../../../assets/adminLTE/dist/img/logo3.png"
          alt="AdminLTE Logo"
          class="brand-image img-circle elevation-3"
          style="opacity: 0.8"
        />
        <span class="brand-text font-weight-light">ConectaSTV</span>
      </router-link>

      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->

        <!--
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image">

            <img
              v-if="user.thumbnail"
              :src="require(`../../../assets/img/profiles/profile.png`)"
              class="img-circle elevation-2"
              alt="User Image"
            />

            <img
              v-else
              :src="require(`../../../assets/img/profiles/profile.png`)"
              class="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div class="info">
            <router-link to="/perfil" class="d-block">{{
              user.nome
            }}</router-link>
          </div>
        </div>
        -->

        <!-- SidebarSearch Form -->
        
        <!--
        <div class="form-inline">
          <div class="input-group" data-widget="sidebar-search">
            <input
              class="form-control form-control-sidebar"
              type="search"
              placeholder="Pesquisar..."
              aria-label="Search"
            />
            <div class="input-group-append">
              <button class="btn btn-sidebar">
                <i class="fas fa-search fa-fw"></i>
              </button>
            </div>
          </div>
        </div>
        -->


        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul
            class="
              nav nav-pills nav-sidebar
              flex-column
              nav-child-indent nav-flat
            "
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li class="nav-item">
              <router-link to="/home" class="nav-link">
                <i class="far fa-list-alt nav-icon"></i>
                <p>Home</p>
              </router-link>
            </li>

            <li 
              v-if="
                user['nivel_acesso'] == 'Administrador' || 
                user['nivel_acesso'] == 'Colaborador' || 
                user['nivel_acesso'] == 'CIPA'
              "
              class="nav-item"
            >
              <router-link to="/feed" class="nav-link">
                <i class="fas fa-rss nav-icon"></i>
                <p>Adicionar Feeds</p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/perfil" class="nav-link">
                <i class="fas fa-address-card nav-icon"></i>
                <p>Perfil</p>
              </router-link>
            </li>

            <!--
            <li class="nav-item">
              <router-link to="/alterar-senha" class="nav-link">
                <i class="nav-icon fas fa-key"></i>
                <p>Alterar Senha</p>
              </router-link>
            </li>
            -->

            <li class="nav-item has-treeview">
              <a href="#" class="nav-link">
                <i class="nav-icon fas fa-people-arrows nav-icon"></i>
                <p>
                  Solicitação/Chamado
                  <i class="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item">
                  <router-link to="/novo-chamado" class="nav-link">
                    <i class="fas fa-headset nav-icon"></i>
                    <p>Nova Solicitação</p>
                  </router-link>
                </li>

                <li v-if="visualizaSolicitacao == true" class="nav-item">
                  <router-link to="/ticket-recebido" class="nav-link">
                    <i class="fas fa-file-download nav-icon"></i>
                    <p>Solicitação Recebidas</p>
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link to="/historico-chamado" class="nav-link">
                    <i class="fas fa-history nav-icon"></i>
                    <p>Histórico Chamados</p>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <router-link to="/unidades" class="nav-link">
                <i class="fas fa-university nav-icon"></i>
                <p>Unidades</p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/equipes" class="nav-link">
                <i class="fas fa-users nav-icon"></i>
                <p>Equipe</p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/instrucao-trabalho-lista" class="nav-link">
                <i class="fas fa-file-invoice nav-icon"></i>
                <p>Instrução de Trabalho</p>
              </router-link>
            </li>

            <li 
             v-if="
                user['extrato_email_nfe'] == '1'
              "
            class="nav-item">
              <router-link to="/relatorio-email" class="nav-link">
                <i class="fas fa-envelope nav-icon"></i>
                <p>Extrato e-mail NFe's</p>
              </router-link>
            </li>

            <!--
            <li class="nav-item">
              <router-link to="/stv-flix" class="nav-link">
                <i class="fab fa-youtube nav-icon"></i>
                <p>STV-Flix</p>
              </router-link>
            </li>
            -->

            <!--
            <li class="nav-item">
              <router-link to="/agenda" class="nav-link">
                <i class="fas fa-calendar-alt nav-icon"></i>
                <p>Agenda</p>
              </router-link>
            </li>
            -->

            <li class="nav-item has-treeview">
              <a href="#" class="nav-link">
                <i class="nav-icon fas fa-calendar-alt nav-icon"></i>
                <p>
                  Agendas
                  <i class="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item">
                  <router-link to="/agenda" class="nav-link">
                    <i class="fas fa-tasks nav-icon"></i>
                    <p>Minha Agenda</p>
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link to="/agenda-equipe" class="nav-link">
                    <i class="fas fa-tasks nav-icon"></i>
                    <p>Agenda Equipe</p>
                  </router-link>
                </li>

                <li v-if="user.nivel_atendimento == 'Agenda Médica' ||
                          user.matricula == '8489'" class="nav-item">
                  <router-link to="/agenda-medica" class="nav-link">
                    <i class="fas fa-tasks nav-icon"></i>
                    <p>Agenda Medica</p>
                  </router-link>
                </li>
              </ul>
            </li>

            <!-- <li class="nav-item">
              <router-link to="/ramal" class="nav-link">
                <i class="fas fa-tty nav-icon"></i>
                <p>Ramal</p>
              </router-link>
            </li> -->

            <li
              v-if="
                user['funcao'] == 'Portaria' || 
                user['funcao'] == 'Dev' || 
                user['funcao'] == 'VIGILANTE'
              "
              class="nav-item"
            >
              <router-link to="/controle-acesso" class="nav-link">
                <i class="fas fa-exchange-alt nav-icon"></i>
                <p>Controle de Acesso</p>
              </router-link>
            </li>

            <!-- <li class="nav-item">
              <router-link to="/todoist" class="nav-link">
                <i class="fas fa-exchange-alt nav-icon"></i>
                <p>Todoist</p>
              </router-link>
            </li> -->

            <li class="nav-header">Links</li>

            <li class="nav-item">
              <a
                href="http://webmail.stv.com.br"
                target="_blank"
                class="nav-link"
              >
                <i class="fas fa-envelope nav-icon"></i>
                <p>WebMail</p>
              </a>
            </li>

            <li class="nav-item">
              <a
                href="http://biblioteca.stv.com.br"
                target="_blank"
                class="nav-link"
              >
                <i class="fas fa-book nav-icon"></i>
                <p>Biblioteca</p>
              </a>
            </li>

            <li class="nav-item">
              <a href="http://ava.stv.com.br/" target="_blank" class="nav-link">
                <i class="fas fa-graduation-cap nav-icon"></i>
                <p>Educação Corporativa</p>
              </a>
            </li>

            <li class="nav-item">
              <a
                href="http://portaldocolaborador.stv.com.br/index.php/logon/login"
                target="_blank"
                class="nav-link"
              >
                <i class="fas fa-user-circle nav-icon"></i>
                <p>Portal do Colaborador</p>
              </a>
            </li>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
  </div>
</template>

<script scoped>
export default {
  name: "SidebarComponents",

  data() {
    return {
      user: [],
      visualizaSolicitacao: "",
    };
  },

  mounted() {
    const perfil = JSON.parse(localStorage.getItem("storageData"));
    this.user = perfil;

    this.visualizaRecebimentoChamado();
  },

  methods: {
    visualizaRecebimentoChamado() {
      const equipe = JSON.parse(localStorage.getItem("equipeUser"));
      var equipeUnico = equipe.flat();

      var altera = false;

      function validar(equipe) {
        if (equipe.visualiza_solicitacao == "Sim") {
          altera = true;
        }
      }

      equipeUnico.forEach(validar);

      this.visualizaSolicitacao = altera;
      // console.log(this.visualizaSolicitacao)
    },
  },
};
</script>

<style>
</style>